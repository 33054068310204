import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';
import { Getterbodyparam, Arcterbodyparam } from '../models/territories.model';

@Injectable()
export class TerritoriesService {

  constructor(private restAPI: RestApi) { }

  // get list of territories
  getTerritoriesList(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.TERRITORIES.GET_TERRITORIES}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // archive territory
  archiveTerritory(bodyParams: Arcterbodyparam) {
    const url = RestApi.buildUrl(`${ENDPOINTS.TERRITORIES.ARCHIVE_TERRITORY}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

   // save territory
   saveTerritory(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.TERRITORIES.SAVE_TERRITORY}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }
}
