import { Component, OnInit, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { EmailConfigurationModel } from 'src/app/models/email-configuration.model';
import { Role } from 'src/app/models/roles.enum';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { WebAccountService } from './../../services/web-account.service';
import { CommonService } from 'src/app/services/common.service';
import { NgForm } from '@angular/forms';
import { EmailConfigurationService } from 'src/app/services/email-configuration.service';

@Component({
    selector: 'app-manage-email-configuration',
    templateUrl: './manage-email-configuration.component.html'
})

export class ManageEmailConfigurationComponent implements OnInit {

    @Input() isEdit = false;
    @Input() emailconfigDetail: EmailConfigurationModel;
    @Output() close = new EventEmitter();
    @Output() submit = new EventEmitter();
    emailConfig: EmailConfigurationModel;
    role: string;
    roleEnum = Role;
    organizationId;
    webAccList: [];
    constructor(
        private loggedInUserService: LoggedInUserService,
        private webAccService: WebAccountService,
        private commonService: CommonService,
        private emailConfigSrv: EmailConfigurationService
    ) { }

    ngOnInit() {
        if (this.emailconfigDetail) {
            this.emailConfig = this.emailconfigDetail;
        }

        this.role = this.loggedInUserService.getUserData('role');
        this.getAccList();
        if (this.role === Role[5]) {
            this.organizationId = this.loggedInUserService.getUserData('organisationId');
            // organizationAdmin
            this.emailConfig.organisationId = this.organizationId;
        }
        // else if(this.role === Role[4]){
        //     this.emailConfig.organisationId = this.organizationId;
        // }
        // else if(this.role === Role[5]){
        //     this.emailConfig.organisationId = this.organizationId;
        // }
    }

    private getAccList(): void {
        const params = {
            organisationId: !(this.role === Role[1]) ? this.loggedInUserService.getUserData('organisationId') :
                this.emailConfig.organisationId,
            userId:  this.role === Role[1] ?  0 : this.loggedInUserService.getUserData('id')
        };
        this.emailConfigSrv.getOrganizationAdmins(params).subscribe(
            (res) => {
                this.commonService.setLoader(false);
                if (res && res.statusCode === 200 && res.data.length > 0) {
                    this.webAccList = res.data;
                }
            }
        );

    }

    // Save the email recipients
    saveEmailRecipient(event, form: NgForm) {
        event.stopPropagation();
        if (this.emailConfig.emailRecipients.length === 0) {
            // tslint:disable-next-line: no-string-literal
            form.controls['Recipients'].setErrors({ required: true });
            return;
        }
        if (form.form.invalid) {
            return;
        }

        const params = {
            organisationId: this.role !== Role[1]
                ? +this.loggedInUserService.getUserData('organisationId')
                : +this.emailConfig.organisationId,
            templateId: this.emailConfig.templateId,
            userId: this.loggedInUserService.getUserData('id'),
            isSuperAdmin: this.role === Role[1],
            recipientList: this.emailConfig.emailRecipients.map(user =>  {
                return {id: user.id};
            })
        };
        this.emailConfigSrv.saveEmailRecipient(params).subscribe(res => {
            if (res.statusCode === 200 && res.data) {
                this.submit.emit();
            }

        }, err => console.error(err));

    }

    dismissModal() {
        this.close.emit();
    }

    isItemSelected(id) {
        return this.emailConfig.emailRecipients.length > 0 ? this.emailConfig.emailRecipients.some(item => item.id === id) : false;
    }

    selectAllOptions(target: string, destination: string) {
        (this.emailConfig[target].length === this[destination].length) ? this.emailConfig[target] = [] : this.emailConfig[target] = this[destination];
    }
}
