

export class RefusalReasonModel {
    id: number;
    name: string;
    organisationId: number;
    userId: number;
    archived?: boolean;
    createdAt?: string;
    createdBy?: string;
    updatedAt?: string;
    updatedBy?: string;
    constructor() {}
  }
  
  