import { Component, OnInit, ViewChild } from '@angular/core';
import { API_URL } from 'src/app/network/endpoints';
import { Role } from 'src/app/models/roles.enum';
import { MESSAGE } from 'src/app/shared/messages';
import { UploadFileModel } from 'src/app/models/uploadFileModel';
import { OrganizationModel, UploadType } from 'src/app/models/organization.model';
import { GroupModel } from 'src/app/models/groups.model';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { SharedService } from 'src/app/services/shared.service';
import { CommonService } from 'src/app/services/common.service';
import { NgForm } from '@angular/forms';
import { OutletService } from 'src/app/services/outlet.service';
import { ToastrService } from 'ngx-toastr';
import { constants } from 'src/app/shared/directives/constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-upload-file-offline',
    templateUrl: './upload-file-offline.component.html',
    styleUrls: ["./upload-file-offline.component.css"],
})

export class UploadFileOfflineComponent implements OnInit {
    readonly URL_ENDPOINT = API_URL;
    readonly Role = Role;
    readonly MESSAGE = MESSAGE;
    uploadModel: UploadFileModel;
    uploadTypes: UploadType[] = [{id: 1, name: 'Outlet'}, {id: 2, name: 'Asset-Notification'}, {id: 3, name: 'Admin-Outlet'}];
    orgList: OrganizationModel[] = [];
    grpList: GroupModel[] = [];
    filebase64data;
    fileData: File;
    userRole: string;
    downloadURL: string = '';
    modalRef: BsModalRef;

    @ViewChild("uploadHistoryTablePop", { static: true }) uploadHistoryTablePop: any;

    constructor(
        private loggedInUserService: LoggedInUserService,
        private sharedService: SharedService,
        private commonService: CommonService,
        private outletService: OutletService,
        private toastr: ToastrService,
        private modalService: BsModalService
    ) { }

    ngOnInit() {
        this.userRole = this.loggedInUserService.getUserData('role');
        this.uploadModel = {
            organisationId: null,
            file: '',
        };

        if (this.userRole === Role[1]) {
            this.sharedService.getOrgList().then((resData: OrganizationModel[]) => {
                this.orgList = resData;
                this.commonService.setLoader(false);
            });
        } else {
            this.uploadModel.organisationId = this.loggedInUserService.getUserData('organisationId');
            this.changeOrganisation();
        }
    }

    changeOrganisation(org?: OrganizationModel) {
        if (org) {
            this.uploadModel.organisation = org;
            this.uploadModel.organisationId = org.id;
            this.uploadModel.group = null;
            this.uploadModel.groupId = null;
            this.uploadModel.uploadType = null;
            this.uploadModel.uploadTypeId = null;
        }
        this.sharedService.getGrpList(this.uploadModel.organisationId).then((res: GroupModel[]) => {
            this.grpList = res;
            this.commonService.setLoader(false);
        });
    }

    onChange(){    
        this.uploadModel.uploadType = {id: -1, name: ''};
        this.uploadModel.uploadTypeId = null;
    }

    downloadSample(event){
        if(this.uploadModel.organisationId && this.uploadModel.groupId && this.uploadModel.uploadTypeId){
            this.downloadURL = `${this.URL_ENDPOINT}sampleFile/${this.uploadModel.uploadType.name}.csv`;
            this.toastr.success(`${this.uploadModel.uploadType.name} template downloaded successfully`);
        } else {
            event.preventDefault()
            this.toastr.error("Can't download or upload. Please select all mandatory fields");
        }
    }

    convertFileToByteArray(file, form: NgForm) {
        if (this.fileData && !file) {
            file = this.fileData;
        }
        if (!file) {
            return;
        }
        if (!['csv'].includes(file.name.split('.')[1])) {
            // tslint:disable-next-line: no-string-literal
            form.controls['file'].setErrors({ format: 'Please attach csv format' });
            return;
        }
        // tslint:disable-next-line: no-string-literal
        form.controls['file'].setErrors(null);
        const reader = new FileReader();
        this.fileData = file;
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64data = reader.result;
            this.filebase64data = base64data.toString().split(',')[1];
        };
        reader.onerror = err => {
            console.log('error in converting file');
        };
    }

    uploadFile(form: NgForm) {
        if(this.fileData){
            const uploadedFileName = this.uploadModel.uploadType.name;
            const selectedFileName = this.fileData.name.split(".")[0];
            if(selectedFileName !== uploadedFileName){
                this.toastr.error("Can't upload. Please upload only allowed template downloaded as sample");
                return;
            }
        
            const params: UploadFileModel = {
                file: this.filebase64data,
                userId: this.loggedInUserService.getUserData('id'),
                fileName: this.fileData.name, // CR-5
                //fileName: this.fileData.name.split('.')[1] === 'zip' ? 'zip' : this.fileData.name, // w/o CR-5
                organisationId: +this.uploadModel.organisationId ? +this.uploadModel.organisationId: form.value.orgName,
                groupId: form.value.grpName ? form.value.grpName : +this.uploadModel.groupId
            };

            this.uploadModel.file = null;
            setTimeout(() => {
                // tslint:disable-next-line: no-string-literal
                form.controls['file'].setErrors(null);
            }, 0);
            //CR_5 uploadOutletAsset w/o uploadOfflineFile
            this.outletService.uploadOutletAsset(params).subscribe(res => {
                this.commonService.setLoader(false);
                if (res) {
                    if (res.statusCode === constants.success && res.data) {
                        form.resetForm();
                        this.fileData = null;
                        this.uploadModel =  {
                            organisationId: params.organisationId,
                            organisation: this.uploadModel.organisation,
                            group: this.uploadModel.group,
                            groupId: this.uploadModel.group.id,
                            file: '',
                        };
                        // CR_5 && res.data.fileFlag === 0 w/o -
                        if (res.data.generalFileFlag === 1 && res.data.fileFlag === 0  ) {
                            this.toastr.success('File is being processing, Email will be sent');
                        } else if ( res.data.generalFileFlag === 1  && res.data.fileFlag === -2 )  {
                            this.toastr.error("Can't upload. Please upload only allowed template downloaded as sample");
                        } else if (res.data.generalFileFlag === -1) {
                            this.toastr.error('File Name is invalid');
                        } else if(res.data.generalFileFlag === -4) {
                            this.toastr.success("Background upload initiated successfully.");
                        }
                    } else if (res.statusCode === constants.failed) {
                        this.toastr.error("Can't upload. Please upload only allowed template downloaded as sample");
                    }
                }
            }, err => console.error(err));
        }
    }

    viewUploadHistory(){
        this.modalRef = this.modalService.show(this.uploadHistoryTablePop, {
            class: "modal-dialog-centered modal-xl",
            ignoreBackdropClick: true,
        });
    }

    public closeModalPopup() {
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
    }
}
