import { Component, OnInit, Renderer2, OnChanges, ViewChild, TemplateRef, ElementRef, HostListener } from "@angular/core";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Router, NavigationEnd } from "@angular/router";
import { LoggedInUserService } from '../services/loggedInUser.service';
import { constants } from '../shared/directives/constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';


@Component({
    selector: "app-layout",
    templateUrl: "./layout.component.html",
    styleUrls: ["./layout.component.css"]
})
export class LayoutComponent implements OnInit {
    // Session TimeOut params
    idleState = "Not started.";
    timedOut = false;
    lastPing?: Date = null;
    sessionTimedOutPopup = false;
    masterPage: boolean = false;
    modalRef: BsModalRef;

    @ViewChild('sessionTemplate', { static: true }) template: ElementRef;

    constructor(
        private idle: Idle,
        private keepalive: Keepalive,
        private router: Router,
        private renderer: Renderer2,
        private modalService: BsModalService,
        private loggedInUserService: LoggedInUserService,
        private commonService: CommonService
    ) {
        this.renderer.addClass(document.body, "theme-style");
        this.renderer.addClass(document.body, "fixed-layout");
        this.renderer.addClass(document.body, "show-sidebar");
        idle.setIdle(constants.sessionTimeOut / 1000);
        // idle.setIdle(5);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(10);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
            this.idleState = "Welcome Back !! No longer idle.";
            setTimeout(() => {
                this.hideModal();
            }, 200);
        });
        idle.onTimeout.subscribe(() => {
            this.idleState = "Timed out!";
            this.timedOut = true;
            this.hideModal();
            this.closeAllModals();
            this.sessionTimedOutPopup = true;
            this.doLogout();
        });
        idle.onIdleStart.subscribe(() => {
            this.idleState = "You've gone idle!";
        });
        idle.onTimeoutWarning.subscribe(
            countdown => {
                (this.idleState = "You are being timedout due to in activity. Remaining " + countdown + " seconds!");
                console.log("Idle");

                if (!this.modalRef)
                    this.openModal();
            }
        );
        // sets the ping interval to 15 seconds
        keepalive.interval(15);
        keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
        this.reset();
    }

    private openModal() {
        this.modalRef = this.modalService.show(this.template, {
            class: 'modal-dialog modal-dialog-centered',
            ignoreBackdropClick: true
        });
    }

    private hideModal() {
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    redirectToLogin() {
        this.router.navigate(["login"]);
    }

    reset() {
        this.idle.watch();
        this.idleState = "Started.";
        this.timedOut = false;
    }

    ngOnInit() {
    }

    doLogout() {
        localStorage.clear();
        this.loggedInUserService.setUserData(null);
        this.router.navigate(["/"]);
        return;
    }

    private closeAllModals() {
        for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
            this.modalService._hideModal(i);
            this.modalService._hideBackdrop();
            this.modalService.hide(i);
        }
    }

    @HostListener('window:storage', ['$event'])
    onMessage(event) {
        if (event.storageArea == localStorage) {
            let token = localStorage.getItem('token');
            if (token == undefined) { // you can update this as per your key
                // DO LOGOUT FROM THIS TAB AS WELL
                this.hideModal();
                this.closeAllModals();
                this.idle.stop();
                this.router.navigate(['/']); // If you are using router
                // OR
                //  window.location.href = '<home page URL>';
            }
        }
    };
}
