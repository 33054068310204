export class OutletModel {
    id?: number;
    shipTo?: number;
    name?: string;
    city?: string;
    organisationId?: number;
    groupId?: number;
    regionId?: number;
    territoryId?: number;
    assets? : any[];
    archived?: boolean;
    createdBy?: number;
    updatedBy?:number;
    userId?: number;
    territories?: string;
    status?:string;
    latitude?: number | string;
    longitude?: number | string;
    cycleNumber?: number;
    set_frequency?: string;
    start_date?: string;
    end_date?: string;
    isEmail?: boolean;
    isSMS?: boolean;
    constructor() {}
}