import { Method } from './method.model';

export class VisitRequestModel {
    organisationId: number;
    from: Date;
    to: Date;
    shipTo: number;
    group = [];
    region = [];
    area = [];
    territory = [];
    user = [];
    rating? = [];
    userId?: number;
    outletName: string;
    deviceId: string;
    methodId?:  Method[];
    typeOfCleanId?: number;
    constructor() { }
}
