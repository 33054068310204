import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../layout/layout.module';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AssetTypeComponent } from './asset-type.component';
import { AssetTypeService } from '../services/asset-type.service';
import { ManageAssetTypeComponent } from './manage-asset-type/manage-asset-type.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [AssetTypeComponent, ManageAssetTypeComponent],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        RouterModule,
        LayoutModule,
        GridModule,
        ExcelModule,
        DialogModule,
        TranslateModule
    ],
    providers: [AssetTypeService],
})

export class AssetTypeModule { }
