import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-loader',
  template: `<div *ngIf="loading" class="k-i-loading"></div> `,
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  loading;
  constructor(private commonService : CommonService) {
    this.commonService.getloader().subscribe(res => {
      this.loading = res;
    });
  }
    ngOnInit() {
  }

}
