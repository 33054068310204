import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Role } from '../models/roles.enum';
import { ENDPOINTS } from '../network/endpoints';
import { METHODS, RestApi } from '../network/RestAPI';


@Injectable()
export class LoggedInUserService {
    private userData;
    constructor(public router: Router, private restAPI: RestApi) { }

    setUserData(data) {
        this.userData = data;
        if (this.userData) {
            this.userData.role = Role[this.userData.accountType];
        }
    }

    getUserData(field?: string) {
        return field ? (this.userData ? this.userData[field] : null) : this.userData;
    }

  getCookieConsentStatus(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.WEB_ACCOUNTS.GET_COOKIE_CONSENT_STATUS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  setCookieConsentStatus(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.WEB_ACCOUNTS.SET_COOKIE_CONSENT_STATUS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

}