import { Component, OnInit, TemplateRef } from '@angular/core';
import { IKendoColumnModel } from 'src/app/models/kendoColumn.model';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { convertTimeZone, getFormatedDate } from 'src/app/shared/handler.functions';
import { Role } from 'src/app/models/roles.enum';
import { MobileUserService } from 'src/app/services/mobile-user.service';
import { SearchDataPipe } from 'src/app/pipes/search-data.pipe';
import { State, process } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MESSAGE } from 'src/app/shared/messages';
import { CommonService } from 'src/app/services/common.service';
import { UserModel } from 'src/app/models/mobile-user.model';
import { constants } from 'src/app/shared/directives/constants';

@Component({
  selector: 'app-mobile-users',
  templateUrl: './mobile-users.component.html',
})
export class MobileUsersComponent implements OnInit {

  columns: IKendoColumnModel[];
  readonly Role = Role;
  role: string;
  usersList :UserModel[] = [];
  gridData: any = [];
  searchKeyword: string;
  searchableColumns = ["name", "email", "role","organisationName","territories"];
  state: State = {
    skip: 0,
    take: 50
  };
  userIsEdit: boolean = false;
  modalRef : BsModalRef;
  confirmModalRef: BsModalRef;
  selectedUser: UserModel;
  timeZone: string;
  constructor(private toastr: ToastrService,private commonService: CommonService, private modalService:BsModalService, private loggedInUserService: LoggedInUserService, private mobileUserService: MobileUserService) { }

  ngOnInit() {
    this.getUsersList();
    this.role = this.loggedInUserService.getUserData('role');
    this.timeZone = this.loggedInUserService.getUserData('timezone');

    this.columns = [
      {
        field: "name",
        title: "Name",
        width: 150,
        locked: true,
        sortable: true
      },
      {
        field: "email",
        title: "Email",
        width: 250,
        sortable: true
      },
      {
        field: "lastLogin",
        title: "Last Login",
        width: 180,
        cellTextCenter: true,
        sortable: true,
        cellFormat: (data) => getFormatedDate(data.lastLogin,constants.dateTimeFormat) || '-'
      },
      {
        field: "organisationName",
        title: "Organisation",
        width: 150,
        sortable: true,
        groupable: true,
        display: this.role !== Role[1],
      },
      {
        field: "territories",
        title: "Territories",
        width: 200,
        cellFormat: (data) => data.territories ? data.territories.length > 25 ? data.territories.split(/\s+/).slice(0, 10).join(" ") + '...': data.territories : '-',
        sortable: false,
      },
      {
        field: "createdAt",
        title: "Created On",
        width: 180,
        sortable: true,
        cellTextCenter: true,
        cellFormat: (data) => getFormatedDate(data.createdAt, constants.dateTimeFormat)
      },
      {
        field: "createdBy",
        title: "Created By",
        width: 230,
        sortable: true
      },
      {
        field: "updatedAt",
        title: "Updated On",
        width: 180,
        cellTextCenter: true,
        sortable: true,
        cellFormat: data => getFormatedDate(data.updatedAt, constants.dateTimeFormat) || '-'
      },
      {
        field: "updatedBy",
        title: "Updated By",
        width: 230,
        sortable: true,
        cellFormat: (data) => data.updatedBy || "-"
      },
      {
        field: "loginAttempts",
        title: "Login Attempts",
        width: 130,
        sortable: true,
        cellTextCenter: true,
        cellFormat: (data) => data.loginAttempts === null ? '-' : data.loginAttempts
      }
    ];

  }

 // Add Mobile User
  public addMobileUser(template: TemplateRef<any>) {
    this.userIsEdit = false;
    this.modalRef = this.modalService.show(template , {class :'modal-dialog modal-dialog-centered', ignoreBackdropClick: true});
  }

  public closeModalPopup() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }


// User Listing
  private getUsersList() {
    const params = {
      "organisationId": this.loggedInUserService.getUserData('organisationId'),
      "groupId": 0
    };

    this.mobileUserService.getUserList(params).subscribe(
      (res) => {
        this.commonService.setLoader(false);
        if (res) {
          if (res.statusCode === 200 && res.data) {

            convertTimeZone(res.data, ['createdAt', 'updatedAt', 'lastLogin'], this.timeZone);
            
            this.usersList = res.data;
            this.usersList.forEach(user=> {
              user.territories = user.lstTerritoryUser && user.lstTerritoryUser.length > 0 ? user.lstTerritoryUser.map(teritory => teritory.name).join(', ') :  null
            });
            this.loadItems();
          } else if (res && res.statusCode === 404) {
            this.usersList = [];
            // this.toastr.error(res.message);
          }
        }
      }
    );
  }

  private loadItems() {
    const searchedRecords = new SearchDataPipe().transform(
      this.usersList,
      this.searchKeyword,
      this.searchableColumns
    );
    this.gridData = process(searchedRecords, this.state);
  }

  // REALTED to grid Functionality paging , sorting , groupping
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadItems();
  }

//Search users
  public filterSearch() {
    this.loadItems();
    this.state.skip = 0;
  }

// Arctive / Inactive

// archive group or change status
public archivedMobileUser() {
  const bodyParams = {
    id: this.selectedUser.id,
    archived: !this.selectedUser.archived,
    updatedBy: this.loggedInUserService.getUserData('id')
  };

  this.mobileUserService.archiveUser(bodyParams).subscribe(
    (res) => {
      if(res) {
        this.commonService.setLoader(false);
      if (res.statusCode === 200 && res.data > 0) {
        this.toastr.success(MESSAGE.ARCHIVED_DONE_SUCCESSFULLY, "");
        this.getUsersList();
        this.closeModalPopup();
      } else if (res.statusCode === 404 && res.data === -3) {
        this.commonService.setLoader(false);
        this.toastr.error(MESSAGE.UNABLE_TO_DEACTIVATE, "", { timeOut: 10000 });
        this.closeModalPopup();
      }
    }
    },
    (error) => {
      console.log(error);
    }
  );
}

// lock unlock account
dolockUnlockConfirmation(event,dataItem, template: TemplateRef<any>) {
  event.preventDefault();
  event.stopPropagation();
  dataItem.locked = !dataItem.locked;
  this.openPopup(dataItem,template);
}

public lockUnlockUser(user) {
  const bodyParams = {
    id: user.id,
    locked: !user.locked,
    updatedBy: this.loggedInUserService.getUserData('id')
  };

  this.mobileUserService.lockUnlockUser(bodyParams).subscribe(
    (res) => {
      if (res) {
        this.commonService.setLoader(false);
        if (res.statusCode === 200 && res.data > 0) {
          if(user.locked) {
            this.toastr.success(MESSAGE.UNLOCKED__DONE_SUCCESSFULLY, "");
          } else {
            this.toastr.success(MESSAGE.LOCKED__DONE_SUCCESSFULLY, "");
          }
          this.getUsersList();
          this.closeModalPopup();
        } else if (res.statusCode === 404 && res.data === -3) {
          this.toastr.error(MESSAGE.REC_UPDATING_FAILED, "", { timeOut: 10000 });
          this.closeModalPopup();
        }
      }
    },
    (error) => {
      console.log(error);
    }
  );
}

//Edit User Detail
public editMobileUser(user, template: TemplateRef<any>) {
  this.userIsEdit = true;
  this.selectedUser = Object.assign({}, user);
  this.openModal(template);
}

private openModal(template: TemplateRef<any>) {
  this.modalRef = this.modalService.show(template, {
    class: 'modal-dialog modal-dialog-centered',
    ignoreBackdropClick: true
  });
}

public openPopup(data, template: TemplateRef<any>) {
  this.selectedUser =  data;
   this.openModal(template);
}

//change user Password
public changePassword(newPassword) {
  const params = {
    id: this.selectedUser.id,
    password: newPassword,
    updatedBy: this.loggedInUserService.getUserData('id')
  }
  this.mobileUserService.changeAccountPassword(params).subscribe(res => {
    this.commonService.setLoader(false);
    if(res && res.statusCode === 200 && res.data > 0) {
      this.toastr.success(MESSAGE.PASSWORD_CHANGED_SUCCESSFULLY)
      this.getUsersList();
      this.closeModalPopup();
    } else {
      this.toastr.error(MESSAGE.RECORD_DOES_NOT_EXIST)
      this.closeModalPopup();
    }
  });
 }

 // Final Submit After first Modal i.e SAVE API_CALL
public submitModalPopup(template: TemplateRef<any>) {
  this.getUsersList();
  if (!this.modalRef) {
    return;
  }
  this.modalRef.hide();
  this.modalRef = null;
}

}
