import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';

@Injectable()
export class VisitService {

  constructor(private restAPI: RestApi) { }

  // get list of visits
  getVisits(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.VISITS.GET_VISITS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

    // get list of history
    getHistory(bodyParams) {
      const url = RestApi.buildUrl(`${ENDPOINTS.VISITS.GET_HISTORY}`);
      return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
    }

    // get list of cleans
    getCleans(bodyParams) {
      // console.log('bodyParams', bodyParams);
      
      const url = RestApi.buildUrl(`${ENDPOINTS.VISITS.GET_CLEANS}`);
      return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
    }

    // get clean detail by id 
    getCleanDetail(bodyParams) {
      const url = RestApi.buildUrl(`${ENDPOINTS.VISITS.GET_CLEAN_BY_ID}`);
      return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
    }

    // get  syncLogs
    getSyncLogs(bodyParams) {
      const url = RestApi.buildUrl(`${ENDPOINTS.VISITS.GET_SYNC_LOGS}`);
      return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
    }
}