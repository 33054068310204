import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';

@Injectable()
export class WebAccountService {

  constructor(private restAPI: RestApi) { }

  // get list of accounts
  getWebAccList(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.WEB_ACCOUNTS.GET_WEB_ACC}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // edit or add Web Account
  saveWebAcc(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.WEB_ACCOUNTS.SAVE_WEB_ACC}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // change account password
   changeAccountPassword(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.WEB_ACCOUNTS.CHANGE_ACCOUNT_PASSWORD}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // archive account
  archiveWebAcc(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.WEB_ACCOUNTS.ARCHIVE_WEB_ACC}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  //reset 2FA
  reset2FA(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.WEB_ACCOUNTS.RESET_2FA}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // lock Unlock web account
  lockUnlockWebAcc(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.WEB_ACCOUNTS.LOCK_UNLOCK_WEB_ACC}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }
}
