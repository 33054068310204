import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UploadFileComponent } from './upload-file.component';
import { SharedModule } from '../shared/shared.module';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { UploadFileOfflineComponent } from './upload-file-offline/upload-file-offline.component';
import { TranslateModule } from '@ngx-translate/core';
import { UploadHistoryPopupComponent } from './upload-history-popup/upload-history-popup.component';
import { FileCorrectionPopupComponent } from './file-correction-popup/file-correction-popup.component';

@NgModule({
  declarations: [UploadFileComponent, UploadFileOfflineComponent, UploadHistoryPopupComponent, FileCorrectionPopupComponent],
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    ExcelModule,
    SharedModule,
    TranslateModule
  ]
})
export class UploadFileModule { }
