import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class CommonService {

    private loader = new Subject<Boolean>();
    browserRefresh$ =  new BehaviorSubject<Boolean>(false);
    private isMobile = new BehaviorSubject<Boolean>(false);

    private currentCookieConsentValBs = new BehaviorSubject<any>({});
    currentCookieConsentValObs = this.currentCookieConsentValBs.asObservable();

    constructor() {}

    setBrowserRefresh(flag : Boolean) {
        this.browserRefresh$.next(flag);
    }

    setLoader(flag : Boolean) {
        this.loader.next(flag);
    }

    getloader(): Observable<Boolean> {
        return this.loader.asObservable();
    }

    setIsMobile(flag) {
        this.isMobile.next(flag);
    }

    getIsMobile() {
       return this.isMobile.asObservable();
    }

    onResize = (event?) => {
        var width = window.innerWidth;
        if (width <= 768) {
            this.setIsMobile(true);
            // console.log('mobile device detected')
        } else if (width > 768 && width <= 992) {
          this.setIsMobile(false);
          // console.log('tablet detected')
        } else {
          this.setIsMobile(false);
          // console.log('desktop detected')
    }}

    setCurrentCookieConsentVal(showConsent: any) {
        this.currentCookieConsentValBs.next(showConsent);
    }

    // updateShowCookieConsent(showConsent: boolean) {
    //     this.showCookieConsentBs.next(showConsent);
    // }


}

