import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { OutletModel } from 'src/app/models/outlet.model';
import { NgForm, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { OutletService } from 'src/app/services/outlet.service';
import { SharedService } from 'src/app/services/shared.service';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { Role } from 'src/app/models/roles.enum';
import { OrganizationModel } from 'src/app/models/organization.model';
import { ToastrService } from 'ngx-toastr';
import { MESSAGE } from 'src/app/shared/messages';
import { GroupModel } from 'src/app/models/groups.model';
import { TerritoryModel } from 'src/app/models/territories.model';
import { RegionModel } from 'src/app/models/regions.model';
import { AssetTypeService } from 'src/app/services/asset-type.service';
import { AssetTypeId, AssetTypeModel } from 'src/app/models/assetType.model';
import { constants } from 'src/app/shared/directives/constants';


@Component({
  selector: 'app-manage-outlet',
  templateUrl: './manage-outlet.component.html',
  styleUrls: ['./manage-outlet.component.css']
})
export class ManageOutletComponent implements OnInit {
  readonly MESSAGE = MESSAGE;
  @Input() isEdit = false;
  @Input() outletDetail: OutletModel;
  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter();
  outlet: OutletModel;
  organization: {
    orgId: number;
    orgName: string;
  };
  orgList: OrganizationModel[] = [];
  assetList = [];
  showAsset = false;
  groupList: GroupModel[] = [];
  regionList: RegionModel[] = [];
  territoryList: TerritoryModel[] = [];
  submitError: string;
  role: string;
  roleEnum = Role;
  selectedAsset: object = null;
  quantity: number = null;
  volume: number = null;
  selectedAssetList: any[] = [];
  readonly constants = constants;
  todayDate = new Date();
  theDayAfterTomDate;
  fromDate;
  toDate;
  frequency: string;
  outletForm: FormGroup;
  notificationMethod: number;
  showFrequency: boolean;
  datesArray = Array.from({ length: 31 }, (_, index) => index + 1);
  saveDisabled = true;
  dueDate = null;
  frequencyError = '';
  @ViewChild('freqBasedCheck', { static: false }) freqBasedCheck: ElementRef;
  @ViewChild('dueBasedCheck', { static: false }) dueBasedCheck: ElementRef;
  assetQuantityError = '';
  assetVolumeError = '';
  assetDueDateError = '';
  assetDateRangeError = '';
  tempAsset = {} as any;

  constructor(
    private toastr: ToastrService,
    private loggedInUserService: LoggedInUserService,
    private sharedService: SharedService,
    private commonService: CommonService,
    private outletService: OutletService,
    private assetTypeService: AssetTypeService
  ) { }

  ngOnInit() {

    this.theDayAfterTomDate = new Date();
    this.theDayAfterTomDate.setDate(this.todayDate.getDate() + 2);
    // this.theDayAfterFromDate = this.

    this.role = this.loggedInUserService.getUserData('role');
    if (this.outletDetail) {
      this.outlet = this.outletDetail;
    }

    this.fromDate = this.theDayAfterTomDate;
    this.toDate = this.theDayAfterTomDate;

    if ([Role[2], Role[4], Role[5]].includes(this.role)) {
      // organizationAdmin, superOrgUser, orgSuperAdmin
      this.organization = {
        orgId: this.loggedInUserService.getUserData('organisationId'),
        orgName: this.loggedInUserService.getUserData('organisationName')
      };
      this.outlet.organisationId = this.organization.orgId;
      this.fetchData();
    } else {
      // superAdmin
      this.sharedService.getOrgList().then((res: OrganizationModel[]) => {
        this.orgList = res;
      });
      this.fetchData();
    }
  }

  onLongLatChange(type: boolean) {
    if (type) {
      this.outlet.latitude = this.outlet.latitude.toString().match(/^-?\d+(?:\.\d{0,6})?/)[0];
    } else {
      this.outlet.longitude = this.outlet.longitude.toString().match(/^-?\d+(?:\.\d{0,6})?/)[0];
    }
  }

  private fetchData() {
    if (!this.isEdit && this.outlet.organisationId > 0 && this.outlet.groupId && this.outlet.regionId > 0
      && this.outlet.territoryId > 0) {
      // from previous table add
      this.fetchMasterData();
      this.getAssetList(this.outlet.organisationId);
    } else if (!this.isEdit) {
      // from page Add button
      if (!this.organization) {
        this.outlet.organisationId = null;
      }
      this.outlet.groupId = null;
      this.outlet.regionId = null;
      this.outlet.territoryId = null;
      if (this.outlet.organisationId) {
        this.sharedService.getGrpList(this.outlet.organisationId).then((res: GroupModel[]) => {
          this.groupList = res;
        });

        this.getAssetList(this.outlet.organisationId);
      }
    } else {
      // from edit button
      this.outletService.getOutletsById(this.outletDetail.id).subscribe((res) => {
        this.commonService.setLoader(false);
        if (res && res.statusCode === 200) {
          res.data.latitude = res.data.latitude.toString().match(/^-?\d+(?:\.\d{0,6})?/)[0];
          res.data.longitude = res.data.longitude.toString().match(/^-?\d+(?:\.\d{0,6})?/)[0]

          this.outlet = res.data;

          if (this.outlet.assets && this.outlet.assets.length > 0) {
            this.selectedAssetList = res.data.assets;

            this.selectedAssetList.map((item) => {
              item.startDate ? item.startDate = new Date(item.startDate) : item.startDate = new Date();
              item.nextCleanDue ? item.nextCleanDue = new Date(item.nextCleanDue) : item.nextCleanDue = new Date();
              item.endDate ? item.endDate = new Date(item.endDate) : item.endDate = new Date();
              item.dueBasedDate ? item.dueBasedDate = new Date(item.dueBasedDate) : item.dueBasedDate = new Date();
              //item.endDate = new Date(item.endDate);
              item.disabledField = true;
              item.disabledEdit = false;

            });
          }
        }
        this.fetchMasterData();
        this.getAssetList(this.outlet.organisationId);
      });
    }
    this.commonService.setLoader(false);

  }

  private fetchMasterData() {
    if (this.outlet.organisationId) {
      this.sharedService.getGrpList(this.outlet.organisationId).then((res: GroupModel[]) => {
        this.groupList = res;
      });
    }
    if (this.outlet.groupId === null) {
      this.outlet.groupId = null;
      this.outlet.regionId = null;
      this.outlet.territoryId = null;
    } else {
      this.sharedService
        .getRegionList(this.outlet.organisationId, this.outlet.groupId)
        .then((res: RegionModel[]) => {
          this.regionList = res;
        });

      this.sharedService
        .getTerritoryList(
          this.outlet.organisationId,
          this.outlet.groupId,
          this.outlet.regionId
        )
        .then((res: TerritoryModel[]) => {
          this.territoryList = res;
        });
    }
  }

  public getAssetList(organisationId): void {
    const bodyParams: AssetTypeId = {
      organisationId: this.role === Role[2] ? this.loggedInUserService.getUserData('organisationId') : organisationId,
      userId: 0
    };
    this.assetTypeService.getAssetTypeList(bodyParams).subscribe(
      (res) => {
        this.commonService.setLoader(false);
        if (res && res.statusCode === 200) {
          this.assetList = res.data.filter(asset => !asset.archived);
          this.assetList.forEach(asset => asset['hide'] = false);
          this.setAssetList();
        } else if (res && res.statusCode === 404) {
          this.assetList = [];
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  setAssetList(): void {
    if (this.assetList.length && this.selectedAssetList.length) {
      this.assetList.forEach((asset, idx) => {
        this.selectedAssetList.forEach(selectedAsset => {
          if (asset.id === selectedAsset.assetTypeId) {
            asset.hide = true;
          }
        });
      });
    }
  }

  public addAsset(): void {
    //isFrequencyOrDueBased : true-- Means Frequency Based => 0
    //isFrequencyOrDueBased : false-- Means Due Date Based => 1

    const error = this.checkDatesWithFrequency();

    if (error && error.message) {
      this.frequencyError = error.message;
      return;
    }

    let isFrequencyOrDueBased: boolean;

    if (this.notificationMethod === 0) {
      isFrequencyOrDueBased = true;
      this.dueDate = null;
    } else if (this.notificationMethod === 1) {
      isFrequencyOrDueBased = false;
      this.frequency = null;
      this.fromDate = null;
      this.toDate = null;
    }

    //if (this.selectedAsset !== null && this.quantity > 0) {
    this.selectedAsset = {
      ...this.selectedAsset, quantity: this.quantity, volume: this.volume, assetTypeId: this.selectedAsset['id'], setFrequency: this.frequency, isFrequencyOrDueBased: isFrequencyOrDueBased, dueBasedDate: this.dueDate, startDate: this.fromDate, endDate: this.toDate, disabledField: true, disabledEdit: false, assetDateRangeError: '',
      assetDueDateError: '', assetQuantityError: '', assetVolumeError: '', assetNotificationError: '', assetFrequencyError: ''
    };
    this.selectedAssetList.push({ ...this.selectedAsset });
    const idx = this.assetList.findIndex(asset => asset.id === this.selectedAsset['id']);
    this.assetList[idx].hide = true;
    this.selectedAsset = null;
    this.quantity = null;
    this.volume = null;
    this.toDate = new Date();
    this.frequency = '';
    this.notificationMethod = undefined;
    this.freqBasedCheck.nativeElement.checked = false;
    this.dueBasedCheck.nativeElement.checked = false;
    this.dueDate = '';
    this.showFrequency = false;
    this.saveDisabled = true;
    //}
  }

  public cancelAsset(): void {
    this.selectedAsset = null;
    this.quantity = null;
    this.volume = null;
    this.showAsset = false;
    this.showFrequency = undefined;
    this.notificationMethod = undefined;
  }

  public removeAsset(idx: number, asset): void {
    const selectedAsset = this.selectedAssetList[idx];
    const id = this.assetList.findIndex(asset => asset.id === selectedAsset.assetTypeId);
    this.assetList[id].hide = false;
    if (asset.disabledField === false) {
      this.selectedAssetList.map((item) => {
        item.disabledEdit = false;
      });
    }
    this.selectedAssetList.splice(idx, 1);
  }

  changedSelection(fieldId: number) {
    if (fieldId === 1) {
      // change in organisation
      this.outlet.regionId = null;
      this.outlet.territoryId = null;
      this.sharedService.getGrpList(this.outlet.organisationId).then((res: GroupModel[]) => {
        this.outlet.groupId = null;
        this.groupList = res;
      });
      this.selectedAssetList = [];
      this.getAssetList(this.outlet.organisationId);
    } else if (fieldId === 2) {
      // change in group
      this.sharedService
        .getRegionList(this.outlet.organisationId, this.outlet.groupId)
        .then((res: RegionModel[]) => {
          this.outlet.regionId = null;
          this.regionList = res;
        });
    } else if (fieldId === 3) {
      // change in region
      this.outlet.territoryId = null;
      this.sharedService
        .getTerritoryList(
          this.outlet.organisationId,
          this.outlet.groupId,
          this.outlet.regionId
        )
        .then((res: TerritoryModel[]) => {
          this.territoryList = res;
        });
    }
  }

  checkAssetQuantity(form: NgForm) {
    if (this.selectedAssetList && this.selectedAssetList.length > 0) {
      const assetQuantity = this.selectedAssetList.map(asset => asset.quantity);

      if (assetQuantity.filter(val => val > 0).length <= 0) {
        form.controls['assetQuantity'].setErrors({ required: true });
        return true;
      } else {
        form.controls['assetQuantity'].setErrors(null);
        return false;
      }
    }
  }


  saveOutlet(event, form: NgForm) {

    event.stopPropagation();
    if (form.form.valid) {
      let customError = false;
      if (this.outlet.name.trim() === '') {
        form.controls['name'].setErrors({ required: true });
        customError = true;
      }
      if (this.outlet.city.trim() === '') {
        form.controls['city'].setErrors({ required: true });
        customError = true;
      }

      // customError = this.checkAssetQuantity(form);
      // if (customError) {
      //   return;
      // }
    }
    if (form.form.invalid) {
      event.preventDefault();
      return;
    }
    if (this.selectedAssetList.length > 0) {
      this.outlet.assets = this.selectedAssetList.map(asset => {
        return {
          name: asset.name,
          assetTypeId: asset.assetTypeId,
          quantity: +asset.quantity,
          volume: +asset.volume,
          isFrequencyOrDueBased: asset.isFrequencyOrDueBased,
          dueBasedDate: asset.dueBasedDate,
          setFrequency: asset.setFrequency,
          startDate: asset.startDate,
          endDate: asset.endDate
        };
      });
    }
    const params: OutletModel = {
      id: this.isEdit ? this.outlet.id : 0,
      shipTo: this.outlet.shipTo,
      name: this.outlet.name.trim(),
      city: this.outlet.city.trim(),
      organisationId: this.role !== Role[1]
        ? this.organization.orgId
        : +this.outlet.organisationId,
      groupId: +this.outlet.groupId,
      territoryId: +this.outlet.territoryId,
      userId: this.loggedInUserService.getUserData('id'),
      assets: this.isEdit ? this.outlet.assets : null,
      latitude: this.outlet.latitude,
      longitude: this.outlet.longitude,
      cycleNumber: this.outlet.cycleNumber,
      isEmail: this.outlet.isEmail,
      isSMS: this.outlet.isSMS
    }

    this.outletService.saveOutlet(params).subscribe(res => {
      if (res.statusCode === 200 && res.data > 0) {
        this.submit.emit();
        this.isEdit ? this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
        this.commonService.setLoader(false);
      } else if (res.statusCode === 404) {
        this.commonService.setLoader(false);
        if (res.data === -1) {
          this.submitError = MESSAGE.OUTLET_SHIPTO_ALREADY_EXIST;
        } else if (res.data === -3) {
          this.submitError = MESSAGE.OUTLET_NAME_EXIST_IN_ORG;
        }
      }
    });
  }

  dismissModal() {
    this.close.emit();
  }


  isItemSelected(selectedArray, id) {
    return selectedArray && selectedArray.length > 0 ? selectedArray.some(item => item.id == id) : false;
  }

  checkDatesWithFrequency(asset?) {
    this.frequencyError = '';
    const currentFrequency = asset ? asset.setFrequency : this.frequency;
    switch (currentFrequency) {
      case 'weekly':
        return this.checkForWeek(asset);

      case 'bi-weekly':
        return this.checkForBiWeek(asset);

      default:
        return this.checkForMonths(asset);
    }
  }

  checkForWeek(asset?) {
    const fromDate = asset ? asset.startDate : this.fromDate;

    const toDate = asset ? new Date(asset.endDate.getFullYear(), asset.endDate.getMonth(), asset.endDate.getDate()) : new Date(this.toDate.getFullYear(), this.toDate.getMonth(), this.toDate.getDate());

    const nextWeekDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + 7);

    const isLessThanWeek = toDate.getTime() < nextWeekDate.getTime();
    if (isLessThanWeek) {
      const error = {
        message: 'For weekly frequency date range should be atleast a week'
      }
      return error;
    }
  }

  checkForBiWeek(asset?) {
    const fromDate = asset ? asset.startDate : this.fromDate;

    const toDate = asset ? new Date(asset.endDate.getFullYear(), asset.endDate.getMonth(), asset.endDate.getDate()) : new Date(this.toDate.getFullYear(), this.toDate.getMonth(), this.toDate.getDate());

    const nextBiWeekDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + 14);

    const isLessThanBiWeek = toDate.getTime() < nextBiWeekDate.getTime();

    if (isLessThanBiWeek) {
      const error = {
        message: 'For Bi-Weekly frequency date range should be atleast a 15 days'
      }
      return error;
    }
  }

  checkForMonths(asset?) {
    let months = 0;
    const fromDate = asset ? asset.startDate : this.fromDate;

    const toDate = asset ? asset.endDate : this.toDate;

    const currentFrequency = asset ? asset.setFrequency : this.frequency;

    months = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
    months -= fromDate.getMonth();
    months += toDate.getMonth();
    months = months <= 0 ? 0 : months;


    if (months < 1 && currentFrequency === 'monthly') {
      const error = {
        message: 'For Montlhy frequency date range should be atleast a month'
      }
      return error;
    }

    if (months < 4 && currentFrequency === 'quarterly') {
      const error = {
        message: 'For quarterly frequency date range should be more than 4 months'
      }
      return error;
    }

    if (months < 6 && currentFrequency === 'half-yearly') {
      const error = {
        message: 'For  half yearly frequency date range should be more than 6 months'
      }
      return error;
    }

    if (months < 12 && currentFrequency === 'yearly') {
      const error = {
        message: 'For yearly frequency date range should be atleast a year'
      }
      return error;
    }
  }

  methodSelected(event) {
    this.assetDueDateError = '';
    this.assetDateRangeError = '';
    // 0 means frequency based, 1 means due date based
    this.notificationMethod = +event;
    if (+event === 0) {
      this.showFrequency = true;
    } else {
      this.showFrequency = false;
    }
  }

  assetFormCheck() {
    if (this.selectedAsset && this.quantity && this.volume && this.notificationMethod !== undefined) {
      if (this.notificationMethod === 0) {
        if (this.frequency && this.fromDate && this.toDate) {
          this.saveDisabled = false;
        }
      } else {

        this.frequencyError = '';

        if (this.dueDate !== null) {
          this.saveDisabled = false;
        } else {
          this.saveDisabled = true;
        }
      }
    } else {
      this.saveDisabled = true;
    }
  }

  editAsset(asset) {
    this.tempAsset = { ...asset };
    asset.disabledField = false;

    if (asset.isFrequencyOrDueBased === null) {
      asset.assetNotificationError = 'Select any notification type';
      return;
    }

    this.selectedAssetList.map((item) => {
      if (!(item.id === asset.id)) {
        item.disabledEdit = true;
      }

    });
  }

  saveAsset(asset) {

    asset.quantity = asset.quantity ? +asset.quantity : '';
    asset.volume = asset.volume ? +asset.volume : '';

    if (asset.isFrequencyOrDueBased) {
      asset.dueBasedDate = null;
    }

    if (!asset.isFrequencyOrDueBased) {
      asset.setFrequency = null;
      asset.startDate = null;
      asset.endDate = null;
    }

    this.selectedAssetList.map((item) => {
      item.disabledEdit = false;
      item.disabledField = true;
    });

  }

  assetMethodChange(methodValue, asset) {
    asset.assetDueDateError = '';
    asset.assetDateRangeError = '';
    asset.assetNotificationError = '';
    if (+methodValue === 0) {
      asset.isFrequencyOrDueBased = true;
      this.checkFrequency(asset);
    } else {
      asset.isFrequencyOrDueBased = false;
      asset.assetFrequencyError = '';
      this.checkDueDate(asset);
    }
  }

  checkFrequency(asset) {
    if ((asset.isFrequencyOrDueBased === true) && (asset.setFrequency === null)) {
      asset.assetFrequencyError = 'Select frequency';
    } else {
      asset.assetFrequencyError = '';
    }
  }

  checkQuantity(asset) {
    if (!asset.quantity) {
      asset.assetQuantityError = 'Quantity is required.';
    } else {
      asset.quantity = +asset.quantity;
      asset.assetQuantityError = '';
    }
  }

  checkVolume(asset) {
    if (!asset.volume) {
      asset.assetVolumeError = 'Line Volume is required.';
    } else {
      asset.volume = +asset.volume;
      asset.assetVolumeError = '';
    }
  }

  checkDueDate(asset) {
    if (!asset.dueBasedDate && (asset.isFrequencyOrDueBased === false)) {
      asset.assetDueDateError = 'Due Date is required.';
    } else {
      asset.assetDueDateError = '';
    }
  }

  checkDateRange(asset) {

    this.checkFrequency(asset);

    const result = this.checkDatesWithFrequency(asset);

    if (result) {
      asset.assetDateRangeError = result.message;
    } else {
      asset.assetDateRangeError = '';
    }
  }

  cancelAssetAction(asset) {
    this.selectedAssetList.map((item) => {
      if (item.id === this.tempAsset.id) {
        item.quantity = this.tempAsset.quantity;
        item.volume = this.tempAsset.volume;
        item.dueBasedDate = this.tempAsset.dueBasedDate;
        item.startDate = this.tempAsset.startDate;
        item.endDate = this.tempAsset.endDate;
        item.setFrequency = this.tempAsset.setFrequency;
        item.isFrequencyOrDueBased = this.tempAsset.isFrequencyOrDueBased;
      }
      item.disabledEdit = false;
      item.disabledField = true;
      item.assetDateRangeError = '';
      item.assetDueDateError = '';
      item.assetDateRangeError = '';
    });

    this.tempAsset = {};
  }

  identify(index, item) {
    return item.name;
  }



}
