import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { CommonService } from 'src/app/services/common.service';
import { constants } from 'src/app/shared/directives/constants';
import { DashboardRequestModel } from 'src/app/models/dashboardRequest.model';
import { IKendoColumnModel } from 'src/app/models/kendoColumn.model';
import { process, State, GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, GroupableSettings, PageChangeEvent } from '@progress/kendo-angular-grid';
import { getFormatedDate, setCurrentTime, getFormattedTime, convertTimeZone } from 'src/app/shared/handler.functions';
import { ObjectResponseModel } from 'src/app/models/reponse.model';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { SharedService } from 'src/app/services/shared.service';
import { Subscription } from 'rxjs';
import { CleanParamsService } from 'src/app/services/clean-params.service';

@Component({
    selector: 'app-dashboard-detail',
    templateUrl: './dashboard-detail.component.html',
    styleUrls: ['./dashboard-detail.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardDetailComponent implements OnInit {
    readonly constants = constants;
    graphData;
    filters;
    graphNumber: number;
    detailList;
    gridView: GridDataResult;
    manualReasonGraphData = [];
    refusalReasonGraphData = [];
    state: State = {
        skip: 0,
        take: 50
    }
    columns: IKendoColumnModel[];
    cdFlag = false;
    groupableSettings: GroupableSettings;
    timeZone: string;
    cleanType: any;
    sub: Subscription;
    constructor(private router: Router, private toastr: ToastrService, private route: ActivatedRoute, private dashboardService: DashboardService, private commonService: CommonService, private translate: TranslateService, private cd: ChangeDetectorRef, private loggedInUserService: LoggedInUserService, private shared: SharedService, private clearService: CleanParamsService) {
        console.log(localStorage.getItem('lang'));
        this.allData = this.allData.bind(this);
        this.route.queryParams.subscribe(queryparams => {
            this.graphData = JSON.parse(queryparams.data);
            // this.cleanType = queryparams.cleanType
            console.log('this.graphData..', this.graphData);
            this.graphNumber = +queryparams.graph;
            this.filters = JSON.parse(queryparams.filters);
            if (this.graphNumber === 2) {
                this.getManualAndRefusalReasonGraphData();
            }
        });
    }

    ngOnInit() {
        this.timeZone = this.loggedInUserService.getUserData('timezone');
        this.translate.onLangChange.subscribe((res) => {
            this.translationChanges();
        });

        this.getDashboardDetail();
        this.sub = this.shared.getCleanType().subscribe((data) => {
            this.cleanType = data;
        })
        this.columns = [
            {
                field: 'territory',
                title: 'Territory',
                groupable: true
            },
            {
                field: 'date',
                title: 'Date',
                cellTextCenter: true,
                groupable: false,
                cellFormat: data => data.date || '-'
            },
            {
                field: 'startTime',
                title: 'Start Time',
                cellTextCenter: true,
                groupable: false,
                cellFormat: data => data.startTime || '-'
            },
            {
                field: 'endTime',
                title: 'End Time',
                cellTextCenter: true,
                groupable: false,
                cellFormat: data => data.endTime || '-'
            },
            {
                field: 'outletShipTo',
                title: 'Ship To',
                groupable: true

            },
            {
                field: 'outletName',
                title: 'Outlet',
                groupable: true

            },
            {
                field: 'userName',
                title: 'User',
                groupable: true
            },
            {
                field: 'typeOfClean',
                title: 'Type',
                groupable: true,
                cellTextCenter: true,
                hidden: this.graphNumber === 1
            },
            {
                field: 'noOfLines',
                title: 'No. Of lines',
                cellTextCenter: true,
                groupable: true,
                hidden: this.graphNumber === 1,
                cellFormat: data => data.noOfLines || '-'
            },
            {
                field: 'score',
                title: 'Score',
                cellTextCenter: true,
                groupable: true,
                hidden: this.graphNumber === 1,
                cellFormat: data => data.score ? Number(data.score).toFixed(2) : '-'
            },
            {
                field: 'rating',
                title: 'Rating',
                cellTextCenter: true,
                groupable: true,
                hidden: this.graphNumber === 1,
                cellFormat: data => data.rating || '-'
            }
        ];

        if (this.graphNumber === 2) {
            this.columns.push(
                {
                    field: 'manualReasonName',
                    title: 'Manual Reason',
                    groupable: true,
                    cellFormat: data => data.manualReasonName || '-'
                },
                {
                    field: 'refusalReasonName',
                    title: 'Not Cleaned Reason',
                    groupable: true,
                    cellFormat: data => data.refusalReasonName || '-'
                }
            );
        }
    }

    getDashboardDetail() {
        const params: DashboardRequestModel = {
            skip: this.state.skip,
            take: this.state.take,
            organisationId: +this.filters.organisationId,
            from: setCurrentTime(this.filters.fromDate),
            to: setCurrentTime(this.filters.toDate),
            group: this.filters.group.map(grp => grp.id),
            region: this.filters.region.map(rgn => rgn.id),
            area: this.filters.area.map(area => area.id),
            territory: this.filters.territory.map(terr => terr.id),
            user: this.filters.user.map(user => user.id) || undefined,
            typeofClean: this.filters.typeofClean.map(typeofClean => typeofClean.id) || undefined,
            manualReason: this.filters.manualReason.map(manualReason => manualReason.name) || [],
            refusalReason: this.filters.refusalReason.map(refusalReason => refusalReason.name) || [],
            methodIds: this.filters.methodIds.map(method => method.id) || [],
            typeOfCleanIds: this.filters.methodIds.reduce((acc, method) => {
                method.typeOfCleanId !== null ? acc.push(method.typeOfCleanId) : method
                return acc;
            }, []) || [],
        }
        if (this.graphNumber === 1) {
            params.graphName = 'visit';
        } else if ([2, 4, 5].includes(this.graphNumber)) {
            params.graphName = 'clean';
        } else if (this.graphNumber === 3) {
            params.graphName = 'merit';
        }



        this.dashboardService.getDashboardDetail(params).subscribe(res => {
            if (res) {
                this.commonService.setLoader(false);
                if (res.statusCode === constants.success) {
                    convertTimeZone(res.data.dashboardDetailResponse, ['endDate', 'startDate'], this.timeZone);
                    this.detailList = res.data;
                    this.loadData();
                }
            }
        })

    }

    // REALTED to grid Functionality paging , sorting , groupping
    pageChange(page: PageChangeEvent): void {
        this.state.skip = page.skip;
        this.getDashboardDetail();
    }

    groupChange(group: GroupDescriptor[]) {
        this.state.group = group;
        this.loadData();
    }

    sortChange(sort: SortDescriptor[]) {
        this.state.sort = sort;
        this.loadData();
    }

    private loadData() {
        this.detailList.dashboardDetailResponse.forEach(element => {
            element.date = getFormatedDate(element.startDate, constants.dateTimeFormat);
            element.startTime = getFormattedTime(element.startDate);
            element.endTime = getFormattedTime(element.endDate);
        });
        this.gridView = {
            data: process(this.detailList.dashboardDetailResponse, { group: this.state.group, sort: this.state.sort }).data,
            total: this.detailList.totalCount
        }

        this.cd.detectChanges();
    }

    // export all data
    allData() {
        const params: DashboardRequestModel = {
            skip: 0,
            take: this.detailList.totalCount,
            organisationId: +this.filters.organisationId,
            from: setCurrentTime(this.filters.fromDate),
            to: setCurrentTime(this.filters.toDate),
            group: this.filters.group.map(grp => grp.id),
            region: this.filters.region.map(rgn => rgn.id),
            area: this.filters.area.map(area => area.id),
            territory: this.filters.territory.map(terr => terr.id),
            user: this.filters.user.map(user => user.id) || undefined,
            typeofClean: this.filters.typeofClean.map(typeofClean => typeofClean.id) || undefined,
            manualReason: this.filters.manualReason.map(manualReason => manualReason.name) || [],
            refusalReason: this.filters.refusalReason.map(refusalReason => refusalReason.name) || [],
            methodIds: this.filters.methodIds.map(method => method.id) || [],
            typeOfCleanIds: this.filters.methodIds.reduce((acc, method) => {
                method.typeOfCleanId !== null ? acc.push(method.typeOfCleanId) : method
                return acc;
            }, []) || [],
        }

        if (this.graphNumber === 1) {
            params.graphName = 'visit';
        } else if ([2, 4, 5].includes(this.graphNumber)) {
            params.graphName = 'clean';
        } else if (this.graphNumber === 3) {
            params.graphName = 'merit';
        }
        let resData = [];
        return new Promise((resolve, reject) => {
            this.dashboardService.getDashboardDetail(params).subscribe((res: ObjectResponseModel) => {

                if (res) {
                    if (res.statusCode === constants.success) {
                        this.commonService.setLoader(false);
                        if (res.data && res.data.dashboardDetailResponse && res.data.dashboardDetailResponse.length > 0) {
                            resData = res.data.dashboardDetailResponse;
                            // debugger;;
                            resData.forEach(rec => {
                                rec.date = getFormatedDate(rec.startDate, constants.dateTimeFormat);
                                rec.startTime = getFormattedTime(rec.startDate);
                                rec.endTime = getFormattedTime(rec.endDate);
                            })
                            const result: ExcelExportData = {
                                data: resData,
                                group: []
                            };
                            resolve(result);
                        } else {
                            this.toastr.error('No. records to Export Data')
                            return false;
                        }
                    } else if (res.statusCode === constants.error) {
                        this.commonService.setLoader(false);
                        reject();
                    }
                }
            });
        });
    }

    getManualAndRefusalReasonGraphData() {
        const params: DashboardRequestModel = {
            organisationId: +this.filters.organisationId,
            from: setCurrentTime(this.filters.fromDate),
            to: setCurrentTime(this.filters.toDate),
            group: this.filters.group.map(grp => grp.id),
            region: this.filters.region.map(rgn => rgn.id),
            area: this.filters.area.map(area => area.id),
            territory: this.filters.territory.map(terr => terr.id),
            user: this.filters.user.map(user => user.id) || undefined,
            typeofClean: this.filters.typeofClean.map(typeofClean => typeofClean.id) || undefined,
            manualReason: this.filters.manualReason.map(manualReason => manualReason.name) || [],
            refusalReason: this.filters.refusalReason.map(refusalReason => refusalReason.name) || [],
            graphName: 'clean',
            methodIds: this.filters.methodIds.map(method => method.id) || [],
            typeOfCleanIds: this.filters.methodIds.reduce((acc, method) => {
                method.typeOfCleanId !== null ? acc.push(method.typeOfCleanId) : method
                return acc;
            }, []) || [],
        }
        this.commonService.setLoader(true);
        this.dashboardService.getGraphDataForDetailsPage(params).subscribe((res) => {
            // this.commonService.setLoader(false);
            if (res)
                // debugger;;
                if (res.statusCode === constants.success) {
                    this.manualReasonGraphData = res.data.manualReasonChartResponse;
                    this.refusalReasonGraphData = res.data.refusalReasonChartResponse;
                    this.cd.detectChanges();
                } else if (res.statusCode === constants.error) {
                    this.toastr.error(res.message);
                }
        });
    }

    backToDashboard() {
        this.router.navigate(['app/dashboard'], { queryParams: { filters: JSON.stringify(this.filters) }, skipLocationChange: true });
            this.clearService.visibleField(true);
    }

    translationChanges() {
        this.translate.get('Drag a column header and drop it here to group by that column').subscribe((res) => {
            this.groupableSettings = { enabled: true, emptyText: res, showFooter: false };
        });
        if (!this.cdFlag) {
            this.cd.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.cdFlag = true;
        this.cd.markForCheck();
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.translationChanges();
    }

}
