import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { State } from "@progress/kendo-data-query";
import {
  CancelEvent,
  EditEvent,
  GridComponent,
  SaveEvent,
} from "@progress/kendo-angular-grid";
import { BsModalService } from "ngx-bootstrap/modal";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IKendoColumnModel } from "src/app/models/kendoColumn.model";
import { OutletService } from "src/app/services/outlet.service";
import { CommonService } from "src/app/services/common.service";
import { LoggedInUserService } from "src/app/services/loggedInUser.service";
import { getFormatedDate } from "src/app/shared/handler.functions";
import { constants } from "src/app/shared/directives/constants";

@Component({
  selector: "app-file-correction-popup",
  templateUrl: "./file-correction-popup.component.html",
  styleUrls: ["./file-correction-popup.component.css"],
})
export class FileCorrectionPopupComponent implements OnInit {
  public view: any;
  public gridState: State = { sort: [], skip: 0, take: 5 };
  public formGroup: FormGroup;
  private editedRowIndex: number;
  public uploadFileType: string;
  public logId: number;

  @Output() close = new EventEmitter();
  columns: IKendoColumnModel[];

  outlet = [];

  assetNotification = [];

  adminOutlet = [];

  constructor(
    private modalService: BsModalService,
    private outletService: OutletService,
    private commonService: CommonService,
    private loggedInUserService: LoggedInUserService
  ) {}
  private outletCounter: number = this.outlet.length;
  private assetNotificationCounter: number = this.assetNotification.length;
  private adminOutletCounter: number = this.adminOutlet.length;

  public ngOnInit() {
    this.uploadFileType = this.modalService.config.initialState["filename"];
    this.logId = this.modalService.config.initialState["id"];
    if (this.uploadFileType === "Outlet") {
      this.columns = [
        {
          field: "ship_to",
          title: "SAP Ship To Customer No",
          width: 190,
          sortable: true,
          locked: true,
        },
        {
          field: "outlet_name",
          title: "Customer Name",
          width: 140,
          sortable: true,
        },
        {
          field: "city",
          title: "City",
          width: 100,
          sortable: true,
        },
        {
          field: "geolocation_latitude",
          title: "Geolocation (Latitude)",
          width: 180,
          sortable: true,
        },
        {
          field: "geolocation_longitude",
          title: "Geolocation (Longitude)",
          width: 170,
          sortable: true,
        },
        {
          field: "cycle_number",
          title: "Cycle Number",
          width: 130,
          sortable: true,
        },
        {
          field: "organization",
          title: "Organisation",
          width: 150,
          sortable: true,
        },
        {
          field: "group",
          title: "Group",
          width: 110,
          sortable: true,
        },
        {
          field: "territory",
          title: "Territory",
          width: 110,
          sortable: true,
        },
        {
          field: "active",
          title: "Active YN",
          width: 120,
          sortable: true,
        },
      ];
      this.view = this.outlet;
    } else if (this.uploadFileType === "Asset-Notification") {
      this.columns = [
        {
          field: "ship_to",
          title: "SAP Ship To Customer No",
          width: 190,
          sortable: true,
          locked: true,
        },
        {
          field: "email_YN",
          title: "Email YN",
          width: 150,
          sortable: true,
        },
        {
          field: "sms_YN",
          title: "SMS YN",
          width: 100,
          sortable: true,
        },
        {
          field: "asset_name",
          title: "Asset Name",
          width: 120,
          sortable: true,
        },
        {
          field: "quantity",
          title: "Quantity",
          width: 140,
          sortable: true,
        },
        {
          field: "notification_method",
          title: "Notification Method",
          width: 150,
          sortable: true,
        },
        {
          field: "set_frequency",
          title: "Set Frequency",
          width: 130,
          sortable: true,
        },
        {
          field: "start_date",
          title: "Start Date",
          width: 140,
          sortable: true,
          // cellFormat: (data) => {
          //   const myDate = Date.parse(data.start_date);
          //   console.log(myDate);
          //   if (myDate) {
          //     return getFormatedDate(data.start_date, constants.dateFormat) || "-";
          //   }
          // },
        },
        {
          field: "to_date",
          title: "To Date",
          width: 110,
          sortable: true,
          // cellFormat: (data) => {
          //   const myDate = Date.parse(data.to_date);
          //   console.log(myDate);
          //   if (myDate) {
          //     return getFormatedDate(data.to_date, constants.dateFormat) || "-";
          //   }
          // },
        },
        {
          field: "due_date",
          title: "Due Date",
          width: 110,
          sortable: true,
          // cellFormat: (data) => {
          //   const myDate = Date.parse(data.due_date);
          //   console.log(myDate);
          //   if (myDate) {
          //     return getFormatedDate(data.due_date, constants.dateFormat) || "-";
          //   }
          // },
        },
      ];
      this.view = this.assetNotification;
    } else {
      this.columns = [
        {
          field: "email_id",
          title: "Email ID",
          width: 200,
          sortable: true,
          locked: true,
        },
        {
          field: "ship_to",
          title: "Outlet",
          width: 460,
          sortable: true,
        },
        {
          field: "active",
          title: "Active YN",
          width: 120,
          sortable: true,
        },
      ];
      this.view = this.adminOutlet;
    }
    this.getAllUploadHistoryLogs();
  }

  private getAllUploadHistoryLogs(): void {
    this.outletService.getAllUploadHistoryLogs({ logId: this.logId }).subscribe(
      (res) => {
        this.commonService.setLoader(false);
        if (res && res.statusCode === 200) {
          res.data.map((data) => {
            if (data.typeId === "1") {
              this.outlet.push(data);
            } else if (data.typeId === "2") {
              this.assetNotification.push(data);
            } else {
              this.adminOutlet.push(data);
            }
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public onStateChange(state: State): void {
    this.gridState = state;
    // if (this.uploadFileType === "Outlet") {
    //   this.view = this.outlet;
    // } else if (this.uploadFileType === "Asset-Notification") {
    //   this.view = this.assetNotification;
    // } else {
    //   this.view = this.adminOutlet;
    // }
  }

  public editHandler(args: EditEvent): void {
    const { dataItem } = args;
    this.closeEditor(args.sender);
    if (this.uploadFileType === "Outlet") {
      this.formGroup = new FormGroup({
        id: new FormControl(dataItem.id),
        ship_to: new FormControl(dataItem.ship_to.trim(), Validators.required),
        outlet_name: new FormControl(dataItem.outlet_name, Validators.required),
        city: new FormControl(dataItem.city, Validators.required),
        geolocation_latitude: new FormControl(
          dataItem.geolocation_latitude,
          Validators.required
        ),
        geolocation_longitude: new FormControl(
          dataItem.geolocation_longitude,
          Validators.required
        ),
        cycle_number: new FormControl(
          dataItem.cycle_number,
          Validators.required
        ),
        organization: new FormControl(
          dataItem.organization,
          Validators.required
        ),
        group: new FormControl(dataItem.group, Validators.required),
        typeId: new FormControl(dataItem.typeId),
        territory: new FormControl(dataItem.territory, Validators.required),
        active: new FormControl(dataItem.active, Validators.required),
        // error: new FormControl(dataItem.error),
      });
    } else if (this.uploadFileType === "Asset-Notification") {
      // console.log(dataItem);
      
      this.formGroup = new FormGroup({
        id: new FormControl(dataItem.id),
        ship_to: new FormControl(dataItem.ship_to.trim(), Validators.required),
        email_YN: new FormControl(dataItem.email_YN),
        sms_YN: new FormControl(dataItem.sms_YN, Validators.required),
        asset_name: new FormControl(dataItem.asset_name, Validators.required),
        quantity: new FormControl(dataItem.quantity, Validators.required),
        notification_method: new FormControl(dataItem.notification_method),
        typeId: new FormControl(dataItem.typeId),
        set_frequency: new FormControl(dataItem.set_frequency),
        start_date: new FormControl(dataItem.start_date),
        to_date: new FormControl(dataItem.to_date),
        due_date: new FormControl(dataItem.due_date),
        // error: new FormControl(dataItem.error),
      });
    } else {
      this.formGroup = new FormGroup({
        id: new FormControl(dataItem.id),
        email_id: new FormControl(dataItem.email_id, Validators.required),
        ship_to: new FormControl(dataItem.ship_to.trim(), Validators.required),
        typeId: new FormControl(dataItem.typeId),
        active: new FormControl(dataItem.active, Validators.required),
        error: new FormControl(dataItem.error),
      });
    }

    this.editedRowIndex = args.rowIndex;
    // put the row in edit mode, with the `FormGroup` build above
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  public cancelHandler(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditor(args.sender, args.rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    const product = formGroup.value;
    let sentRequest;
    let updateRecord;
    sender.closeRow(rowIndex);

    if (this.uploadFileType === "Outlet") {
      updateRecord = this.outlet.splice(rowIndex, 1, product);
      sentRequest = {
        id: updateRecord[0].id,
        ship_to: product.ship_to.trim(),
        outlet_name: product.outlet_name.trim(),
        city: product.city.trim(),
        geolocation_latitude: product.geolocation_latitude.trim(),
        geolocation_longitude: product.geolocation_longitude.trim(),
        cycle_number: product.cycle_number.trim(),
        organization: product.organization.trim(),
        group: product.group.trim(),
        territory: product.territory.trim(),
        active: product.active.trim(),
        typeId: updateRecord[0].typeId,
        upload_log_id: this.logId,
        created_by: this.loggedInUserService.getUserData("id"),
      };
      // console.log(sentRequest);
    } else if (this.uploadFileType === "Asset-Notification") {
      updateRecord = this.assetNotification.splice(rowIndex, 1, product);
      sentRequest = {
        id: updateRecord[0].id,
        ship_to: product.ship_to.trim(),
        email_YN: product.email_YN.trim(),
        sms_YN: product.sms_YN.trim(),
        asset_name: product.asset_name.trim(),
        quantity: product.quantity.trim(),
        notification_method: product.notification_method.trim(),
        set_frequency: product.set_frequency.trim(),
        start_date: product.start_date.trim(),
        to_date: product.to_date.trim(),
        due_date: product.due_date.trim(),
        typeId: updateRecord[0].typeId,
        upload_log_id: this.logId,
        created_by: this.loggedInUserService.getUserData("id"),
      };
    } else {
      updateRecord = this.adminOutlet.splice(rowIndex, 1, product);
      sentRequest = {
        id: updateRecord[0].id,
        ship_to: product.ship_to.trim(),
        email_id: product.email_id.trim(),
        active: product.active.trim(),
        typeId: updateRecord[0].typeId,
        upload_log_id: this.logId,
        created_by: this.loggedInUserService.getUserData("id"),
      };
    }

    this.outletService.UpdateUploadHistoryLogs(sentRequest).subscribe((res) => {
      this.commonService.setLoader(false);
      const isErorHistory = res.data.isError;
      const resoneHistory = res.data.reason;
      if (isErorHistory && resoneHistory) {
        if (this.uploadFileType === "Outlet") {
          this.outlet[rowIndex].isError = isErorHistory;
          this.outlet[rowIndex].reason = resoneHistory;
        } else if (this.uploadFileType === "Asset-Notification") {
          this.assetNotification[rowIndex].isError = isErorHistory;
          this.assetNotification[rowIndex].reason = resoneHistory;
        } else {
          this.adminOutlet[rowIndex].isError = isErorHistory;
          this.adminOutlet[rowIndex].reason = resoneHistory;
        }
      }
    });
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    // close the editor
    grid.closeRow(rowIndex);
    // reset the helpers
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  dismissModal() {
    this.close.emit();
  }
}
