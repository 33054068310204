import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { RequestHelperService } from './network/RequestHelper';
import { CommonService } from './services/common.service';
import { LoggedInUserService } from './services/loggedInUser.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'Surge20X';
    loader = false;
    subscription: Subscription;

    constructor(private renderer: Renderer2, private router: Router, private commonService: CommonService, private requestHelperService: RequestHelperService, private loggedInUserService: LoggedInUserService, private translateService: TranslateService) {
         this.translateService.setDefaultLang('en');
         this.translateService.use(localStorage.getItem('lang') || 'en');
        if (localStorage.getItem('userData') && localStorage.getItem('userData') != null)
            this.loggedInUserService.setUserData(JSON.parse(localStorage.getItem('userData')));
        else {
            this.router.navigate(["/"]);
            return;
        }

        var expiry;
        if (localStorage.getItem('expiry') && localStorage.getItem('expiry') != null)
            expiry = Number(localStorage.getItem('expiry'));
        else {
            this.router.navigate(["/"]);
            return;
        }

        window.onresize = this.commonService.onResize;
        this.commonService.getIsMobile().subscribe((res: boolean) => {
            const isMobile = res;
            if (isMobile) {
                this.renderer.removeClass(document.body, "show-sidebar")
            }
        });
        this.subscription = router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                const browserRefresh = !router.navigated;
                if (browserRefresh) {
                    this.commonService.setBrowserRefresh(true);
                    this.requestHelperService.toggleLocaRefUpdated(true);
                }
            }
        });

    }
    ngOnInit(): void {
    }

}


