import { Injectable } from '@angular/core';
import { generateQueryParams } from '../shared/handler.functions';
import { METHODS, RestApi } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';
import { DefaultCleanParams } from '../models/cleanParam.model';
import { Clean } from '../models/clean.model';
import { LoggedInUserService } from './loggedInUser.service';
import { Role } from '../models/roles.enum';

@Injectable()
export class CleanParamsService {
  selectDeviceType: number;
  constructor(private restAPI: RestApi, private loggedInUserService: LoggedInUserService) {

  }
  roleType = this.loggedInUserService.getUserData('role');
  enableField: boolean;
  allowed = this.roleType === Role[1] || Role[4];
  childPath = [
    {
      id: 2,
      name: 'verx',
      title: 'Auto',
      display: this.allowed,
      path: 'auto',
    },
    {
      id: 3,
      name: 'verx',
      title: 'Manual',
      display: this.allowed,
      path: 'manual',
    },
    {
      id: 4,
      name: 'PLCS',
      title: 'Auto',
      display: this.allowed,
      path: 'auto',
    },
    {
      id: 5,
      name: 'PLCS',
      title: 'Manual',
      display: this.allowed,
      path: 'manual',
    },
    {
      id: 6,
      name: 'vortexi',
      title: 'Auto',
      display: this.allowed,
      path: 'auto',
    },
    {
      id: 7,
      name: 'vortexi',
      title: 'Manual',
      display: this.allowed,
      path: 'manual',
    },
    {
      id: 9,
      name: 'vortexn',
      title: 'Auto',
      display: this.allowed,
      path: 'auto',
    },
    {
      id: 10,
      name: 'vortexn',
      title: 'Manual',
      display: this.allowed,
      path: 'manual',
    },
  ];

  visibleField(data) {
    this.enableField = data;
  }
  getSelectedRoutes() {
    return this.selectDeviceType;
  }
  setSelectedRoutes(value) {
    this.selectDeviceType = value
  }
  getCleanParams(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ClEAN_PARAMS.GET_CLEAN_PARAMS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  setCleanParams(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ClEAN_PARAMS.SET_CLEAN_PARAMS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }
  getAllCleanParams(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ClEAN_PARAMS.GET_ALL_CLEAN_PARAMS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  // Clean param gen 1 Services

  getAllDefaultParam(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DEFAULT.GET_DEFAULT_LIST}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  setDefaultParamSettings(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DEFAULT.ADD_DEFAULT_PARAM_SETTINGS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  updateDefaultParamSettings(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DEFAULT.UPDATE_DEFAULT_PARAM_SETTINGS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  SetToDefaultCleanParameter(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DEFAULT.SET_TO_DEFAULT_PARAM_FOR_DEVICES}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  getCleanParamsById(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ClEAN_PARAMS_GEN1.GET_CLEAN_PARAMS_BY_ID}`);
    return this.restAPI.handleRequest(url, METHODS.Get, generateQueryParams(params));
  }

  saveCleanParams(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ClEAN_PARAMS_GEN1.SAVE_CLEAN_PARAMS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }
  getAllGen1CleanParams() {
    const url = RestApi.buildUrl(`${ENDPOINTS.ClEAN_PARAMS_GEN1.GET_ALL_CLEAN_PARAMS}`);
    return this.restAPI.handleRequest(url, METHODS.Get);
  }

  // set defaults params to selected device
  setDefaults(params: DefaultCleanParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ClEAN_PARAMS_GEN1.SET_DEFAULT_CLEAN_PARAMS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  // Save edit clean
  saveClean(params: Clean) {
    const url = RestApi.buildUrl(`${ENDPOINTS.VISITS.SAVE_CLEAN}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }
  saveverxCleanStatus(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ClEAN_PARAMS_GEN1.SET_ARCHIVEDEVICE_CLEAN_PARAMS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  // Need to check for vertexi
  savevortexiCleanStatus(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ClEAN_PARAMS_GEN1.SET_ARCHIVEDEVICE_CLEAN_PARAMS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  // For vertexn
  savevortexnCleanStatus(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ClEAN_PARAMS_GEN1.SET_ARCHIVEDEVICE_CLEAN_PARAMS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  changeDefaulSettingStatus(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DEFAULT.CHNAGE_STATUS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  getDefaultSettingById(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DEFAULT.GET_DEFAULT_BY_ID}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }
}
