import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardRequestModel } from '../models/dashboardRequest.model';
import { CommonService } from '../services/common.service';
import { LoggedInUserService } from '../services/loggedInUser.service';
import { SharedService } from '../services/shared.service';
import { Role } from '../models/roles.enum';
import { OrganizationModel } from '../models/organization.model';
import { AreaModel } from '../models/areas.model';
import { RegionModel } from '../models/regions.model';
import { GroupModel, GroupOption } from '../models/groups.model';
import { TerritoryModel } from '../models/territories.model';
import { DashboardService } from '../services/dashboard.service';
import { constants } from '../shared/directives/constants';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from '../models/mobile-user.model';
import { ActivatedRoute } from '@angular/router';
import { setCurrentTime } from '../shared/handler.functions';
import { TypeOfCleanOptions } from '../models/typeOfClean.enum';
import { ManualReasonModel } from '../models/manualReason.model';
import { RefusalReasonModel } from '../models/refusalReason.model';
import { Method } from '../models/method.model';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { forkJoin, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CleanParamsService } from '../services/clean-params.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
    readonly Role = Role;
    defaultOptionsCount = 2;
    defaultOptionsStatusList = [];
    readonly constants = constants;
    dashboard: DashboardRequestModel;
    dashboardResponse: {};
    userRole: string;
    orgList: OrganizationModel[] = [];
    grpList: GroupModel[] = [];
    regionList: RegionModel[] = [];
    areaList: AreaModel[] = [];
    territoryList: TerritoryModel[] = [];
    mobileUserList: UserModel[] = [];
    copyMobileUserList: UserModel[] = [];
    manualReasonList: ManualReasonModel[] = [];
    refusalReasonList: RefusalReasonModel[] = [];
    typeOfCleanList = TypeOfCleanOptions;
    cleanTypesList: Method[] = [];
    orgSelected = false;
    groupSelected = false;
    regionSelected = false;
    areaSelected = false;
    cleanTypeSelected = false;
    manualReasonSelected = false;
    @ViewChild('users', { static: true }) users;
    today = new Date();
    pieChartResponse = [];
    selectedCleanList = [];
    constructor(private route: ActivatedRoute, private toastr: ToastrService, private loggedInUserService: LoggedInUserService, private commonService: CommonService, private sharedService: SharedService, private dashboardService: DashboardService, private translateService: TranslateService, private clearService: CleanParamsService) {

        this.route.queryParams.subscribe(queryparams => {
            if (queryparams.filters) {
                const filtersApplied = JSON.parse(queryparams.filters);
                this.dashboard = new DashboardRequestModel();
                this.dashboard = filtersApplied;
                this.dashboard.fromDate = new Date(this.dashboard.fromDate);
                this.dashboard.toDate = new Date(this.dashboard.toDate);
                this.changeOrganisation(false);
            } else {
                this.dashboard = new DashboardRequestModel();
                this.dashboard.organisationId = null;
                this.dashboard.fromDate = new Date();
                this.dashboard.toDate = new Date();
            }
        });
        this.dashboard.group = this.dashboard.group;
    }

    ngOnInit() {
        if (this.groupSelected) {
            this.changeRegion(this.dashboard.region);
            this.changeGroup(this.dashboard.group);
            this.changeArea(this.dashboard.area)
        }
        this.userRole = this.loggedInUserService.getUserData('role');
        if (this.userRole === Role[1]) {
            this.sharedService.getOrgList(true).then((res: OrganizationModel[]) => {

                this.orgList = res;
                this.refreshReport();
            });
        } else {
            this.dashboard.organisationId = this.loggedInUserService.getUserData('organisationId');
            this.changeOrganisation();
            this.refreshReport();
        }
    }

    ngAfterViewInit(): void {
        const contains = (value) => (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;

        this.users.filterChange.asObservable().pipe(
            switchMap(value => from([this.mobileUserList]).pipe(
                map((data) => data.filter(contains(value)))
            ))
        )
            .subscribe(x => {
                this.copyMobileUserList = x;
            });
    }


    hideLoader() {
        this.commonService.setLoader(false);
    }

    changeOrganisation(updateAllFilters = true) {
        // debugger;
        if (updateAllFilters == true) {
            this.dashboard.group.length = 0;
            this.dashboard.region.length = 0;
            this.dashboard.area.length = 0;
            this.dashboard.territory.length = 0;
            this.dashboard.user.length = 0;
            this.dashboard.typeofClean.length = 0;
            this.dashboard.manualReason.length = 0;
            this.dashboard.refusalReason.length = 0;
            this.dashboard.methodIds.length = 0;
            this.dashboard.typeOfCleanIds.length = 0;
        }

        let userId = 0;
        if (this.userRole === Role[3]) {
            userId = this.loggedInUserService.getUserData('id');
        }

        if (!!(+this.dashboard.organisationId)) {
            this.orgSelected = true;

            this.grpList.length = 0;
            this.regionList.length = 0;
            this.areaList.length = 0;

            if (this.clearService.enableField) {
                this.groupSelected = true;
                this.regionSelected = true;
                this.areaSelected = true;
            } else {
                this.groupSelected = false;
                this.regionSelected = false;
                this.areaSelected = false;
            }

            this.getOrgRelatedData();

        } else {
            this.orgSelected = false;
            if (this.clearService.enableField) {
                this.groupSelected = true;
                this.regionSelected = true;
                this.areaSelected = true;
            } else {
                this.groupSelected = false;
                this.regionSelected = false;
                this.areaSelected = false;
            }
        }
    }

    getOrgRelatedData() {
        const type = { type: "dashboardcleantype", organisationId: +this.dashboard.organisationId, fromDashboard: 1 };
        // debugger;
        const orgId = { organisationId: +this.dashboard.organisationId, fromDashboard: 1 };
        const bodyParamGroups = {
            organisationId: +this.dashboard.organisationId,
            fromDashboard: 1
        };
        const bodyParamUsers = {
            organisationId: +this.dashboard.organisationId,
            fromDashboard: 0
        };
        // const bodyParams = {
        //     groupIds: selectedGroups.map((group) => group.id),
        //     fromDashboard: 1,
        //     organisationId: +this.dashboard.organisationId
        // }

        const cleanTypes = this.dashboardService.getCleanTypes(type);
        const manualReasons = this.dashboardService.getManualReasons(orgId);
        const notCleanedReasons = this.dashboardService.getNotCleanedReasons(orgId);
        const groups = this.dashboardService.getGroups(bodyParamGroups);
        // const regions = this.dashboardService.getRegions()
        const users = this.dashboardService.getUsers(bodyParamUsers);

        forkJoin([groups, cleanTypes, manualReasons, notCleanedReasons, users]).subscribe((result: any) => {
            this.commonService.setLoader(false);
            this.grpList = result[0].data || [];
            this.cleanTypesList = result[1].data || [];
            this.manualReasonList = result[2].data || [];
            this.refusalReasonList = result[3].data || [];
            this.mobileUserList = result[4].data || [];
            // if(this.dashboard.user.length > 0) {
            //  const groupIds = this.dashboard.user.map((group) => group.id);
            //  this.getUsers(bodyParamUsers);
            //  }
        });
    }

    changeGroup(selectedGroups: GroupOption[]) {
        if (selectedGroups.length > 0) {
            this.groupSelected = true;
            const bodyParams = {
                groupIds: selectedGroups.map((group) => group.id),
                fromDashboard: 1,
                organisationId: +this.dashboard.organisationId
            }

            this.dashboardService.getRegions(bodyParams).subscribe((res: any) => {
                this.commonService.setLoader(false);
                this.regionList = res.data;
                //this.getUsers(bodyParams.groupIds);
            });
        } else {
            // this.regionList.length = 0;
            this.areaList.length = 0;
            //this.mobileUserList.length = 0;
            this.territoryList.length = 0;
            //this.dashboard.user.length = 0;
            this.dashboard.area.length = 0;
            this.dashboard.region.length = 0;
            this.dashboard.territory.length = 0;
            this.groupSelected = false;
            this.regionSelected = false;
            this.areaSelected = false;
        }
    }

    getUsers(groupIds) {
        const bodyParams = {
            fromDashboard: 0,
            organisationId: +this.dashboard.organisationId
        };
        this.dashboardService.getUsers(bodyParams).subscribe((res: any) => {
            this.commonService.setLoader(false);
            this.mobileUserList = res.data;
            this.copyMobileUserList = this.mobileUserList;
        });
    }

    changeRegion(selectedRegions: RegionModel[]) {
        if (!!selectedRegions.length) {

            this.regionSelected = true;
            const bodyParams = {
                groupIds: this.dashboard.group.map((group) => group.id),
                regionIds: selectedRegions.map((region) => region.id),
                fromDashboard: 1,
                organisationId: +this.dashboard.organisationId
            }

            this.dashboardService.getAreas(bodyParams).subscribe((res: any) => {
                this.commonService.setLoader(false);
                this.areaList = res.data;
            });

        } else {
            this.areaList.length = 0;
            this.regionSelected = false;
            this.areaSelected = false;
            this.territoryList.length = 0;
        }
    }

    changeArea(selectedAreas: RegionModel[]) {
        if (!!selectedAreas.length) {

            this.areaSelected = true;

            const bodyParams = {
                groupIds: this.dashboard.group.map((group) => group.id),
                regionIds: this.dashboard.region.map((region) => region.id),
                areaIds: selectedAreas.map((region) => region.id),
                fromDashboard: 1,
                organisationId: +this.dashboard.organisationId
            }

            this.dashboardService.getTerritories(bodyParams).subscribe((res: any) => {
                this.commonService.setLoader(false);
                this.territoryList = res.data;
            });

        } else {
            this.areaSelected = false;
            this.territoryList.length = 0;
        }
    }

    refreshReport(form?: NgForm) {
        if (form && form.invalid) {
            return;
        }

        this.dashboardResponse = null;

        const params: DashboardRequestModel = {
            organisationId: +this.dashboard.organisationId,
            from: setCurrentTime(this.dashboard.fromDate),
            to: setCurrentTime(this.dashboard.toDate),
            group: this.dashboard.group.map(grp => grp.id),
            region: this.dashboard.region.map(rgn => rgn.id),
            area: this.dashboard.area.map(area => area.id),
            territory: this.dashboard.territory.map(terr => terr.id),
            user: this.dashboard.user.map(user => user.id) || undefined,
            typeofClean: this.dashboard.typeofClean.map(typeofClean => typeofClean.id) || undefined,
            manualReason: this.dashboard.manualReason.map(manualReason => manualReason.name) || [],
            refusalReason: this.dashboard.refusalReason.map(refusalReason => refusalReason.name) || [],
            methodIds: this.dashboard.methodIds.map(method => method.id) || [],
            typeOfCleanIds: this.dashboard.methodIds.reduce((acc, method) => {
                method.typeOfCleanId !== null ? acc.push(method.typeOfCleanId) : method
                return acc;
            }, []) || [],
        }

        if (this.userRole === Role[3]) {
            params.userId = this.loggedInUserService.getUserData('id');
        }

        this.dashboardService.getDashboardData(params).subscribe((res) => {
            this.commonService.setLoader(false);
            if (res)
                if (res.statusCode === constants.success) {
                    //for piechart labels
                    this.selectedCleanList = this.dashboard.methodIds.length === 0 ? [...this.cleanTypesList] : [...this.dashboard.methodIds];
                    this.sharedService.updateCleanType(this.selectedCleanList);
                    if (res.data.piChartResponse) {
                        const pieChartResponse = res.data.piChartResponse.filter((element) => {
                            return (this.selectedCleanList.find(curr => element.name === curr.name) || (element.name === 'Manual' || element.name === 'Not Cleaned' || element.name === 'Vortex-N Auto' || element.name === 'Vortex-N Manual'));
                        })

                        this.dashboardResponse = res.data;
                        this.dashboardResponse['piChartResponse'] = pieChartResponse;
                    }
                } else if (res.statusCode === constants.error) {
                    this.toastr.error(res.message);
                }
        });
    }

    // lineChartDataMapping(data) {
    //     debugger;
    //     const lineTest = data.filter((obj: any) => {
    //         for (var prop in obj) {
    //           if (obj[prop] === 0) {
    //             delete obj[prop];
    //           }
    //       }
    //       return obj;
    //     })
    //     const lineChartResponse =
    //       [
    //         {
    //           data: lineTest.map(x => x['manualCount']),
    //           name: 'Manual'
    //         }, {
    //           data: lineTest.map(x => x['refusalCount']),
    //           name: 'Not Cleaned'
    //         },
    //         {
    //           data: lineTest.map(x => x['vortexSoakCount']),
    //           name: 'Vortex Soak'
    //         }, {
    //           data: lineTest.map(x => x['vortexRecircCount']),
    //           name: 'Vortex Recirc'
    //         }, {
    //           data: lineTest.map(x => x['verxAutoCount']),
    //           name: 'Verx Auto'
    //         }, {
    //           data: lineTest.map(x => x['verxManualCount']),
    //           name: 'Verx Manual'
    //         },
    //         {
    //           data: lineTest.map(x => x['plcsAutoCount']),
    //           name: 'PLCS Auto'
    //         },
    //         {
    //           data: lineTest.map(x => x['plcsManualCount']),
    //           name: 'PLCS Manual'
    //         },
    //         {
    //           data: lineTest.map(x => x['vortexIAutoCount']),
    //           name: 'Vortex-I Auto'
    //         },
    //         {
    //           data: lineTest.map(x => x['vortexIManualCount']),
    //           name: 'Vortex-I Manual'
    //         },];

    //         return lineChartResponse;
    // }

    selectAllFilters() {
        this.dashboard.group = this.grpList;
        this.dashboard.region = this.regionList;
        this.dashboard.area = this.areaList;
        this.dashboard.territory = this.territoryList;
        this.dashboard.user = this.mobileUserList;
        this.dashboard.typeofClean = this.typeOfCleanList;
        this.dashboard.manualReason = this.manualReasonList;
        this.dashboard.refusalReason = this.refusalReasonList;
        this.dashboard.methodIds = this.cleanTypesList;
        this.refreshReport();
    }

    deSelectAllFilters() {
        this.dashboard.group = [];
        this.dashboard.region = [];
        this.dashboard.area = [];
        this.dashboard.territory = [];
        this.dashboard.user = [];
        this.dashboard.typeofClean = [];
        this.dashboard.manualReason = [];
        this.dashboard.refusalReason = [];
        this.dashboard.methodIds = [];
        this.dashboard.typeOfCleanIds = [];
        this.refreshReport();
    }

    selectAllOptions(target: string, destination: string) {
        (this.dashboard[target].length === this[destination].length) ? this.dashboard[target] = [] : this.dashboard[target] = this[destination];
        if (target === 'group') {
            this.changeGroup(this.dashboard[target]);
        }
        if (target === 'region') {
            this.changeRegion(this.dashboard[target]);
        }
        if (target === 'area') {
            this.changeArea(this.dashboard[target]);
        }
    }

    isItemSelected(selectedArray, id) {
        return selectedArray.some(item => item.id === id);
    }

    isItemSelectedByName(selectedArray, name: string) {
        return selectedArray.some(item => item.name === name);
    }

    doReportSearch() {
        this.defaultOptionsCount === this.defaultOptionsStatusList.length ? this.refreshReport() : undefined;
    }
    isItemSelectedByIndex(selectedArray, id) {
        return selectedArray.some(item => item.index === id);
    }
}
