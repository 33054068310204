export enum DeviceType {
    'Vortex' = 1,
    'VerxAuto',
    'VerxManual',
    'PLCSAuto',
    'PLCSManual',
    'VortexIAuto',
    'VortexIManual',
    'Verxlite',
    'VortexNAuto',
    'VortexNManual'
}