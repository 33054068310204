import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { process, State } from "@progress/kendo-data-query";
import { DataStateChangeEvent } from "@progress/kendo-angular-grid";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { IKendoColumnModel } from "src/app/models/kendoColumn.model";
import { LoggedInUserService } from "src/app/services/loggedInUser.service";
import { CommonService } from "src/app/services/common.service";
import { SearchDataPipe } from "src/app/pipes/search-data.pipe";
import { UploadHistoryModel } from "src/app/models/upload-history.modal";
import { OutletService } from "src/app/services/outlet.service";
import { getFormatedDate } from "src/app/shared/handler.functions";
import { constants } from "src/app/shared/directives/constants";

@Component({
  selector: "app-upload-history-popup",
  templateUrl: "./upload-history-popup.component.html",
  styleUrls: ["./upload-history-popup.component.css"],
})

export class UploadHistoryPopupComponent implements OnInit {
  @Output() close = new EventEmitter();
  @ViewChild("fileCorrectionPopup", { static: true }) fileCorrectionPopup: any;
  public searchKeyword: string;
  public organizationId: string;
  public  searchableColumns = ["templateName"];
  public errorLogData: UploadHistoryModel[] = [];
  public gridData: any = [];
  public columns: IKendoColumnModel[];
  public modalRef: BsModalRef;
  public state: State = {
    skip: 0,
    take: 5,
  };

  constructor(
    private loggedInUserService: LoggedInUserService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private outletService: OutletService
  ) {}

  ngOnInit() {
    this.organizationId =
      this.loggedInUserService.getUserData("organisationId");
    this.bindColoumns();
    this.getAllUploadLogs();
  }

  // Set the grid coloumns
  bindColoumns(): void {
    this.columns = [
      {
        field: "filename",
        title: "File Name",
        width: 90,
      },
      {
        field: "username",
        title: "Uploaded By",
        width: 90,
      },
      {
        field: "created_at",
        title: "Uploaded On",
        width: 120,
        cellFormat: (data) =>
          getFormatedDate(data.created_at, constants.dateTimeFormat) || "-",
      },
      {
        field: "organization_name",
        title: "Organisation",
        width: 100,
      },
      {
        field: "group_name",
        title: "Group",
        width: 100,
      },
    ];
  }

  private getAllUploadLogs(): void {
    this.outletService
      .getAllUploadLogs({
        organizationId: this.loggedInUserService.getUserData("organisationId"),
      })
      .subscribe(
        (res) => {
          this.commonService.setLoader(false);
          if (res && res.statusCode === 200) {
            // res.data[0].filename = 'Asset-Notification';
            // res.data[1].filename = 'Outlet';
            this.errorLogData = res.data;
            this.loadItems();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  // REALTED to grid Functionality paging , sorting , groupping
  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadItems();
  }

  // Load the grid with proper data
  private loadItems(): void {
    const searchedRecords = new SearchDataPipe().transform(
      this.errorLogData,
      this.searchKeyword,
      this.searchableColumns
    );
    this.gridData = process(searchedRecords, this.state);
  }

  dismissModal() {
    if (!this.modalRef) {
      this.close.emit();
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
    this.getAllUploadLogs();  
  }

  checkUploadHistoryStatus(dataItem) {
    this.modalRef = this.modalService.show(this.fileCorrectionPopup, {
      class: "modal-dialog-centered modal-xl",
      ignoreBackdropClick: true,
      initialState: {filename: dataItem.filename, id: dataItem.id},
    });
  }
}
