import { Component, Input, OnInit, OnChanges } from '@angular/core';
import * as moment from 'moment';
declare var atlas: any;
declare var SpiderClusterManager: any;

@Component({
  selector: 'app-azure-map',
  templateUrl: './azure-map.component.html',
  styleUrls: ['./azure-map.component.css'],
})
export class AzureMapComponent implements OnInit, OnChanges {
  @Input() data = [];

  constructor() { }

  ngOnInit() {
    this.createMap(this.data);
  }

  ngOnChanges() {
    this.createMap(this.data);
  }

  createMap(mapData) {
    var map = new atlas.Map('myMap', {
        language: 'en-US',
        view: 'Auto',
        showBuildingModels: true,
        showLogo: false,
        showFeedbackLink: false,
        style: "road",
        zoom: 2,
        center: new atlas.data.Position(-6.7598417601704455, 53.415471161092256),
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: 'sAnuidVDjn8k0YEAhhRLH2oOg0enL3cdZqXsanCnYe8'
        }
      });
      
    // add all controls
    map.controls.add([
        new atlas.control.ZoomControl(),
        new atlas.control.CompassControl(),
        new atlas.control.PitchControl(),
        new atlas.control.StyleControl()
    ], {
        position: atlas.ControlPosition.TopRight
  });

  var popup;

  //Colors for each EntityType property in point data
  var entityTypes = [
  'Vortex Recirc',
  'Vortex Soak',
  'Verx Manual',
  'Verx Auto',
  'Verx Lite Auto',
  'PLCS Auto',
  'PLCS Manual',
  'Vortex-I Manual',
  'Vortex-I Auto',
  'Manual',
  'Not Cleaned'
];

  map.events.add('ready', function (e) {
    //Create a reusable popup.
    popup = new atlas.Popup();
    
      //Create a data source and add it to the map.
      var dataSource = new atlas.source.DataSource(null, {
          //Tell the data source to cluster point data.
          cluster: true,
          //The radius in pixels to cluster points together.
          clusterRadius: 25,
          //The maximum zoom level in which clustering occurs.
          //If you zoom in more than this, all points are rendered as symbols.
          clusterMaxZoom: 30,

          //Calculate counts for each entity type in a cluster as custom aggregate properties.
          clusterProperties: {
            'Vortex Recirc':
            ['+', ['case',['all',['==',['get', 'typeOfClean'],'Vortex Recirc']],1,0]],

            'Vortex Soak':
            ['+', ['case',['all',['==',['get', 'typeOfClean'],'Vortex Soak']],1,0]],

            'Verx Manual':
            ['+', ['case',['all',['==',['get', 'typeOfClean'],'Verx Manual']],1,0]],

            'Verx Auto':
            ['+', ['case',['all',['==',['get', 'typeOfClean'],'Verx Auto']],1,0]],

            'PLCS Auto':
            ['+', ['case',['all',['==',['get', 'typeOfClean'],'PLCS Auto']],1,0]],

            'Verx Lite Auto':
            ['+', ['case',['all',['==',['get', 'typeOfClean'],'Verx Lite Auto']],1,0]],

            'PLCS Manual':
            ['+', ['case',['all',['==',['get', 'typeOfClean'],'PLCS Manual']],1,0]],

            'Vortex-I Manual':
            ['+', ['case',['all',['==',['get', 'typeOfClean'],'Vortex-I Manual']],1,0]],

            'Vortex-I Auto':
            ['+', ['case',['all',['==',['get', 'typeOfClean'],'Vortex-I Auto']],1,0]],

            'Manual':
            ['+', ['case',['all',['==',['get', 'type'],'Manual']],1,0]],

            'Not Cleaned':
            ['+', ['case',['all',['==',['get', 'type'],'Not Cleaned']],1,0]],
          }
      });

      map.sources.add(dataSource);

      //Create a bubble layer for rendering clustered data points.
      var clusterBubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
          radius: [
            'step',
            ['get', 'point_count'],
            20,         //Default of 20 pixel radius.
            100, 30,    //If point_count >= 100, radius is 30 pixels.
            750, 40     //If point_count >= 750, radius is 40 pixels.
        ],
          color: '#6a00ff',
          strokeWidth: 1,
          filter: ['has', 'point_count'] //Only rendered data points which have a point_count property, which clusters do.
      });

      //Load the custom image icon into the map resources.
      map.imageSprite.add('map-green-marker', '../assets/images/map-green-marker_new.png')

      //Load the custom image icon into the map resources.
      map.imageSprite.add('map-grey-marker', '../assets/images/map-grey-marker.png')

      //Create a layer to render the individual locations.
      var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          filter: ['!', ['has', 'point_count']],  //Filter out clustered points from this layer.
          iconOptions: {
            //Use a match expression to select the image icon based on the EntityType property of the data point.
            image: [
              'match',
              ['get', 'rating'],

              //For each entity type, specify the icon name to use.
              1, 'map-green-marker',
              2, 'marker-yellow',
              3, 'marker-red',
              //Default fallback icon.
              4, 'marker-blue',
              5, 'map-grey-marker',
              'marker-blue'
          ]
          }
      });


      //Add the clusterBubbleLayer and two additional layers to the map.
      map.layers.add([
          clusterBubbleLayer,
          //Create a symbol layer to render the count of locations in a cluster.
          new atlas.layer.SymbolLayer(dataSource, null, {
              iconOptions: {
                  image: 'none' //Hide the icon image.
              },
              textOptions: {
                  textField: ['get', 'point_count_abbreviated'],
                  offset: [0, 0.4],
                  color: 'white'
              }
          }),

          //Create a layer to render the individual locations.
          symbolLayer

      ]);

      var spiderManager = new atlas.SpiderClusterManager(map, clusterBubbleLayer, symbolLayer);

      //Add event handler for when a feature is selected.
      map.events.add('featureSelected', spiderManager, function(e) {
        if (e.cluster) {
        const coordinates = e.cluster.geometry.coordinates;
        const data = e.shape.data.properties;
        showFeaturePopup(coordinates, data);
        }
    });


    //Add a click event to the symbol layer.
    map.events.add('click', symbolLayer, function (e) {
      //Make sure that the point exists.
      if (e && e.shapes && e.shapes.length > 0) {
        //Get the clustered point from the event.
        const coordinates = e.shapes[0]['data']['geometry'].coordinates;
        const data = e.shapes[0]['data']['properties'];
        showFeaturePopup(coordinates, data);
      }
    });

    function showFeaturePopup(coordinates, data) {
      var html = ['<div style="padding:10px;">'];
      html.push(`Rating: ${data.rating || '-'}<br/>`);
      html.push(`Method: ${data.type}<br/>`);
      html.push(`Type of Clean: ${data.typeOfClean || '-'}<br/>`);
      html.push(`Outlet ID: ${data.outletId || '-'}<br/>`);
      html.push(`Outlet Name: ${data.outletName || '-'}<br/>`);
      html.push(`Clean Start Date: ${moment(data.cleanStartDate).format('DD/MM/YYYY, h:mm a') || '-'}<br/>`);
      html.push(`Clean End Date: ${moment(data.cleanEndDate).format('DD/MM/YYYY, h:mm a') || '-'}<br/>`);
      html.push('</div>');

      popup.setOptions({
        position: coordinates,
        content: html.join('')
      });

      popup.open(map);
    }

    // map.events.add('click', clusterBubbleLayer, function (e) {
    //   if (e && e.shapes && e.shapes.length > 0) {
    //     //Get the clustered point from the event.
    //     var cluster = e.shapes[0];

    //     var html = ['<div style="padding:10px; max-height:200px; overflow-y:auto">'];

    //     html.push(`<b>Cluster size: ${e.shapes[0]['properties'].point_count_abbreviated} Records.</b><br/><br/>`);
        
    //     //Loop though each entity type get the count from the clusterProperties of the cluster.
    //     entityTypes.forEach(et => {
    //       html.push(`${et}: ${e.shapes[0]['properties'][et]}<br/>`);
    //     })

    //     html.push('</div>');

    //     //Update the options of the popup and open it on the map.
    //     popup.setOptions({
    //       position: cluster['geometry'].coordinates,
    //       content: html.join('')
    //     });

    //     popup.open(map);
    //   }
    // });

      if (mapData) {
          mapData.forEach(element => {
            var long = element.geolocationLong ? element.geolocationLong : element.outletLongitude;
            var lat = element.geolocationLat ? element.geolocationLat : element.outletLatitude;

            if(element.rating){  
              dataSource.add(new atlas.data.Feature(new atlas.data.Point([long, lat]), {
                rating : element.rating,
                type : element.type,
                typeOfClean : element.typeOfClean,
                outletId : element.outletId,
                outletName : element.outletName,
                cleanStartDate : element.cleanStartDate,
                cleanEndDate : element.cleanEndDate,
              }));
            } else {
              if (element.type == "Manual") {
                element.rating = 4;
              } else if (element.type == "Not Cleaned") {
                element.rating = 5;
              }

              dataSource.add(new atlas.data.Feature(new atlas.data.Point([long, lat]), {
                rating : element.rating,
                type : element.type,
                typeOfClean : element.typeOfClean ? element.typeOfClean : '-',
                outletId : element.outletId,
                outletName : element.outletName,
                cleanStartDate : element.cleanStartDate,
                cleanEndDate : element.cleanEndDate
              }));
            }
          });
      }

  });
  
}

}
