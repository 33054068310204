import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';

@Injectable()
export class ManualReasonService {

  constructor(private restAPI: RestApi) { }

  // get list of manual reasons
  getManualReasonList(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.MANUAL_REASON.GET_MANUAL_REASONS}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // edit or add manual reason
  saveManualReason(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.MANUAL_REASON.SAVE_MANUAL_REASON}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // archive manual reason
  archiveManualReason(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.MANUAL_REASON.ARCHIVE_MANUAL_REASON}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }
}
