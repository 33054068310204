import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/services/common.service';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.css']
})
export class CookieConsentComponent implements OnInit {
  modalRef: BsModalRef;
  cookieConsentId: number;
  sub: Subscription;
  currentConsentStatus: boolean;
  @Output() emitshowConsent = new EventEmitter<boolean> ();
  constructor(private modalService: BsModalService, private commonService: CommonService, private loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.sub = this.commonService.currentCookieConsentValObs.subscribe((res) => {
      this.currentConsentStatus = res.termsAgree;
      this.cookieConsentId = res.id;
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  reject() {
    const payload = {
      useId: this.loggedInUserService.getUserData('id'),
      termsAgree: false
    } as any;

    if(this.cookieConsentId) {
      payload.id = this.cookieConsentId
    }
    
      this.loggedInUserService.setCookieConsentStatus(payload).subscribe((res: any) => {
        this.commonService.setLoader(false);
        if(res && res.statusCode === 200) {
        localStorage.setItem('rejectedCookieOnce', 'true');
        this.setCookieIdAndStatus();
        }
      });
  }

  accept() {
    const payload = {
      useId: this.loggedInUserService.getUserData('id'),
      termsAgree: true
    } as any;

    if(this.cookieConsentId) {
      payload.id = this.cookieConsentId
    }

    this.loggedInUserService.setCookieConsentStatus(payload).subscribe((res: any) => {
      this.commonService.setLoader(false);
      if(res && res.statusCode === 200) {
      localStorage.removeItem('rejectedCookieOnce');
      this.setCookieIdAndStatus();

      }
    });
  }

  setCookieIdAndStatus() {
    const useId = this.loggedInUserService.getUserData('id');
    this.loggedInUserService.getCookieConsentStatus({useId}).subscribe((res) => {
    this.commonService.setLoader(false);
        this.commonService.setCurrentCookieConsentVal({termsAgree: res.data.termsAgree, id: res.data.id});
    });
}

}
