import { Component, OnInit } from '@angular/core';
import { MESSAGE } from 'src/app/shared/messages';
import { Router, ActivatedRoute } from '@angular/router';
import { CleanParamsService } from 'src/app/services/clean-params.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { NgForm } from '@angular/forms';
import { CleanParamGen1 } from 'src/app/models/cleanparamGen1.model';
import { constants } from 'src/app/shared/directives/constants';

@Component({
  selector: 'app-manage-clean-gen',
  templateUrl: './manage-clean-gen.component.html',
  styleUrls: ['./manage-clean-gen.component.css']
})
export class ManageCleanGenComponent implements OnInit {
  cleanParam: CleanParamGen1;
  cleanParamId: number;
  submitError: string;

  constructor(private router: Router, private route: ActivatedRoute, private cleanParamService: CleanParamsService, private loggedInUserService: LoggedInUserService, private commonService: CommonService, private toastr: ToastrService) {
    this.route.queryParams.subscribe(queryparams => {
      this.cleanParamId = +queryparams.cleanParamId;
    });
  }

  genArray(length: number) {
    return Array.from(Array(length).keys());
  }
  ngOnInit() {
    this.cleanParam = new CleanParamGen1();
    this.getCleanParamsById();
  }
  getCleanParamsById() {
    const params = {
      id: this.cleanParamId
    }
    this.cleanParamService.getCleanParamsById(params).subscribe(res => {
      this.commonService.setLoader(false);
      if (res) {

        if (res.statusCode === constants.success) {
          this.cleanParam = res.data;
        } else if (res.statusCode === constants.error && res.data === null) {
          this.toastr.info('Please Set Default Clean Params');
          this.cleanParam = new CleanParamGen1();
        }
      }
    });
  }
  saveCleanParams(form: NgForm) {
    if (form.form.invalid) {
      this.submitError = MESSAGE.FORM_CONATINS_ERROR;
      return;
    }
    this.submitError = null;
    const params: CleanParamGen1 = {
      ...this.cleanParam
    }
    this.cleanParamService.saveCleanParams(params).subscribe(res => {
      this.commonService.setLoader(false);
      if (res && res.statusCode === constants.success && res.data > 0) {
        if(this.cleanParamId === 0 ) {
          this.toastr.success(MESSAGE.DEFAULT_CLEAN_PARAMS_UPDATED);
        } else {
           this.toastr.success(MESSAGE.CLEAN_PARAMS_UPDATED);
        }
        this.router.navigate(['app/cleanParamsGen1']);
      } else {
        this.toastr.error(MESSAGE.ERROR_CLEAN_PARAMS_UPDATED);
      }
    });
  }

  cancelForm() {
    this.router.navigate(['app/cleanParamsGen1']);
  }
}
