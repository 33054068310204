import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';
import { GroupOrgId, ArchiveGroup } from '../models/groups.model';

@Injectable()
export class GroupsService {

  constructor(private restAPI: RestApi) { }

  // get list of groups
  getGroupsList(bodyParams: GroupOrgId) {
    const url = RestApi.buildUrl(`${ENDPOINTS.GROUPS.GET_GROUPS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // archive group
  archiveGroup(bodyParams: ArchiveGroup) {
    const url = RestApi.buildUrl(`${ENDPOINTS.GROUPS.ARCHIVE_GROUP}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // edit or add group
  saveGroup(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.GROUPS.SAVE_GROUP}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

}
