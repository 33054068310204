import  {RequestHelperService } from './RequestHelper';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENDPOINTS } from './endpoints';
import { CommonService } from '../services/common.service';

// tslint:disable:indent
export const METHODS = {
	Get: 'get',
	GetBlob: 'getBlob',
	Post: 'post',
	Delete: 'delete',
	Put: 'put',
	Patch: 'patch',
};

/**
* Rest Api networking class.
* All API requests must be called from here.
*/
@Injectable()
export class RestApi {
	constructor(private commonService: CommonService, private requestHelperService: RequestHelperService, private http: HttpClient) { }

	static buildUrl(url) {
		return `${this.getBaseUrl()}${url}`;
	}

	private static getBaseUrl() {
		// Fallback API URL
		return ENDPOINTS.BASE_URL;
	}

	static buildUrlIP(url) {
		return `${this.getBaseUrl()}/${url}`;
	}

	handleRequest(url, method = METHODS.Get, data?, auth?) {
			this.commonService.setLoader(true);
			const response = this.requestHelperService[method](url, data, auth);
			return response;
	}

    // handleError(error) {
	// 	if (error && error.response && error.response.status === 401) {
	// 		window.console.error('This is an error', error.response.headers);
	// 		localStorage.clear();
	// 		window.location.href = '/login';
	// 		const authLocation = error.response.headers['auth-location'];
	// 		if (typeof authLocation !== 'undefined' && authLocation !== null) {
	// 			return null;
	// 		}
	// 	} else if (error && error.response && error.response.status === 301) {
	// 		return null;
	// 	} else if (error && error.response && error.response.status === 404) {
	// 		if (error.response.data && error.response.data.message) {
	// 			return error.response.data;
	// 		}
	// 		return null;
	// 	} else if (error && error.response && error.response.status === 500) {
	// 		return null;
	// 	}

	// }


}

