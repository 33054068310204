import { Component, OnInit } from '@angular/core';
import { syncLogList } from '../models/synclogResponse.model';
import { LoggedInUserService } from '../services/loggedInUser.service';
import { Role } from '../models/roles.enum';
import { VisitService } from '../services/visit.service';
import { constants } from '../shared/directives/constants';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { MESSAGE } from '../shared/messages';
import { GridDataResult, GroupableSettings, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State, GroupDescriptor, SortDescriptor, process } from '@progress/kendo-data-query';
import { IKendoColumnModel } from '../models/kendoColumn.model';
import { convertTimeZone, getFormatedDate } from '../shared/handler.functions';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ObjectResponseModel } from '../models/reponse.model';
import { OrganizationModel } from '../models/organization.model';
import { SharedService } from '../services/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sync-logs',
  templateUrl: './sync-logs.component.html',
  styleUrls: ['./sync-logs.component.css']
})
export class SyncLogsComponent implements OnInit {
  readonly Role = Role;
  searchKeyword: string;
  syncLogList: syncLogList;
  role: string;
  // Grid
  gridView: GridDataResult;
  state: State = {
    skip: 0,
    take: 50
  };
  organisationId: number;
  orgList: OrganizationModel[] = [];
  columns: IKendoColumnModel[];
  searchableColumns = ['userName' , 'deviceId' ,'syncDate'];
  groupableSettings: GroupableSettings;
  timeZone: string;
  constructor(private toastr: ToastrService, private loggedInUserService: LoggedInUserService, private sharedService: SharedService, private visitService: VisitService, private commonService: CommonService, private translate: TranslateService) {
    this.allData = this.allData.bind(this);
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe( (res) => {
      this.translationChanges();
    });
    this.role = this.loggedInUserService.getUserData('role');
    this.timeZone = this.loggedInUserService.getUserData('timezone');
    this.columns = [
      {
        field: 'userName',
        title: 'Mobile User Name'
      },
      {
        field: 'deviceId',
        title: 'Device Id',
        cellFormat: (data) => data.deviceId || '-'
      },
      {
        field: 'syncDate',
        title: 'Last Sync Date',
        width: 180,
        cellFormat: (data) => getFormatedDate(data.syncDate, constants.dateTimeFormat)
      },
      {
        field: 'version',
        title: 'Version',
        width: 110,
        cellTextCenter: true,
        cellFormat: (data) => data.version || '-'
      }
    ];
    if (this.role === Role[1]) {
      this.sharedService.getOrgList(true).then((res: OrganizationModel[]) => {
        this.orgList = res;
        if (!this.organisationId) {
          this.organisationId = this.orgList[0].id;
          this.getSyncLogs();
        }
      });
    } else {
      this.organisationId = this.loggedInUserService.getUserData('organisationId');
      this.getSyncLogs();
    }
  }

  filterSearch() {
    this.state.skip = 0;
    this.getSyncLogs();
  }

  changeOrganisation() {
    this.getSyncLogs();
  }

  // REALTED to grid Functionality paging , sorting , groupping
  pageChange(page: PageChangeEvent): void {
    this.state.skip = page.skip;
    this.getSyncLogs();
  }

  groupChange(group: GroupDescriptor[]) {
    this.state.group = group;
    this.loadData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.state.sort = sort;
    this.loadData();
  }

  loadData() {
    // const searchedRecords = new SearchDataPipe().transform(
    //   this.syncLogList.synclogsResponse,
    //   this.searchKeyword,
    //   this.searchableColumns
    // );
    // this.gridView = process(this.visitList.visitResponse, this.state);
    this.gridView = {
      data: process(this.syncLogList.synclogsResponse, { group: this.state.group, sort: this.state.sort }).data,
      total: this.syncLogList.totalCount
    }
  }

  getSyncLogs() {
    const params = {
      skip: this.state.skip,
      take: this.state.take,
      organisationId: +this.organisationId,
      searchtext :this.searchKeyword
    }
    if (this.role === Role[3]) {
      params['userId'] = this.loggedInUserService.getUserData('id');
    }

    this.visitService.getSyncLogs(params).subscribe((res: ObjectResponseModel) => {
      if (res) {
        this.commonService.setLoader(false);
        if (res.statusCode === constants.success && res.data) {
          convertTimeZone(res.data.synclogsResponse, ['syncDate'], this.timeZone);
          this.syncLogList = res.data;
          this.loadData();
        } else if (res.statusCode === constants.failed) {
          this.toastr.error(MESSAGE.OPERATION_FAILED);
        } else if (res.statusCode === constants.error) {
          this.syncLogList = res.data;
        }
      }
    })
  }

  // export all data
  allData() {
    const params = {
      skip: 0,
      take: this.syncLogList.totalCount,
      organisationId: +this.organisationId,
      searchtext :this.searchKeyword
    }

    if (this.role === Role[3]) {
      params['userId'] = this.loggedInUserService.getUserData('id');
    }

    let resData = [];
    return new Promise((resolve, reject) => {
      this.visitService.getSyncLogs(params).subscribe((res: ObjectResponseModel) => {
        if (res) {
          if (res.statusCode === constants.success) {
            this.commonService.setLoader(false);
            if (res.data.synclogsResponse.length > 0) {
              resData = res.data.synclogsResponse;
              const result: ExcelExportData = {
                data: resData,
                group: []
              };
              resolve(result);
            } else {
              this.toastr.error('No. records to Export Data')
              return false;
            }
          } else if (res.statusCode === constants.error) {
            console.log('error');
            this.commonService.setLoader(false);
            reject();
          }
        }
      });
    });
  }

  translationChanges() {
    this.translate.get('Drag a column header and drop it here to group by that column').subscribe((res) => {
    this.groupableSettings = { enabled: true, emptyText: res, showFooter: false};
    });
  }

}
