import { WebAccountService } from '../services/web-account.service';
import { NgModule  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account.component';
import { WebAccountsComponent } from './web-accounts/web-accounts.component';
import { RouterModule } from '@angular/router';
import { MobileUsersComponent } from './mobile-users/mobile-users.component';
import { ManageWebAccountsComponent } from './web-accounts/manage-web-accounts/manage-web-accounts.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MobileUserService } from '../services/mobile-user.service';
import { ManageMobileUsersComponent } from './mobile-users/manage-mobile-users/manage-mobile-users.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AccountComponent,
    WebAccountsComponent,
    MobileUsersComponent,
    ManageWebAccountsComponent,
    ManageMobileUsersComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DropDownsModule,
    ReactiveFormsModule,
    InputsModule,
    GridModule,
    FormsModule,
    SharedModule,
    TranslateModule
  ],
  providers: [WebAccountService,MobileUserService],
})
export class AccountModule { }
