import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[numbersOnly]'
})
export class OnlyNumber {


  constructor(private _el: ElementRef) { }

  @HostListener('keydown', ['$event']) onInputChange(event) {
    // const initalValue = this._el.nativeElement.value;
    if (event.which == 64 || event.which == 16) {  
      // to allow numbers  
      return false;  
  } else if (event.which >= 48 && event.which <= 57) {  
      // to allow numbers  
      return true;  
  } else if (event.which >= 96 && event.which <= 105) {  
      // to allow numpad number  
      return true;  
  } else if ([8,9, 13, 27, 37, 38, 39, 40, 46].indexOf(event.which) > -1 ||
  (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+C
        (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+V
        (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+X
        (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
        // Allow: home, end, left, right
        (event.keyCode >= 35 && event.keyCode <= 39)) {
          // let it happen, don't do anything
          // to allow backspace, enter, escape, arrows  
      return true;  
  } else {  
      event.preventDefault();  
      // to stop others  
      return false;  
  }  
  }

}
