import { Component, OnInit } from '@angular/core';
import { LoggedInUserService } from '../services/loggedInUser.service';
import { Role } from '../models/roles.enum';

@Component({
  selector: 'app-clean-params',
  templateUrl: './clean-params.component.html',
})
export class CleanParamsComponent implements OnInit {
  roleType;
  routes;
  constructor(private loggedInUserService: LoggedInUserService) {
  }

  ngOnInit() {
    this.roleType = this.loggedInUserService.getUserData('role');

    const superAdmin = this.roleType === Role[1];
    this.routes = [{
      title: 'Vortex',
      display: superAdmin,
      path: 'vortex'
    },
    {
      title: 'Verx',
      display: superAdmin,
      path: 'verx'
    },
    {
      title: 'PLCS',
      display: superAdmin,
      path: 'plcs'
    },
    {
      title: 'Vortex - I',
      display: superAdmin,
      path: 'vortexi'
    },
    {
      title: 'Verx Lite',
      display: superAdmin,
      path: 'verx-lite'
    },
    ]
  }
}