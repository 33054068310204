export enum  Role  {
    'superAdmin' = 1,
    'organisationAdmin',
    'webUser',
    'superOrgUser',
    'orgSuperAdmin',
    'serviceUser',
    'outletAdmin'
}

export const roles = [
    // {id: 1, name: 'Super Admin'},
    {id: 4, name: 'Organisation Super User'},
    {id: 5, name: 'Organisation Super Admin'},
    {id: 2, name: 'Organisation Admin'},
    {id: 3, name: 'Web User'},
    {id: 6, name: 'Equipment Admin'},
    {id: 7, name: 'Outlet Admin'}
]