import { environment } from 'src/environments/environment';

export const API_URL = environment.apiEndpoint // UAT
// export const API_URL = "http://172.16.7.99:8102/"  // UAT

export const ENDPOINTS = {
  // BASE_URL : 'http://localhost:3000',
  // BASE_URL : 'http://172.16.5.122:7010/api/', // Local
  // BASE_URL : "http://172.16.5.122:7030/api/", // Local
  // BASE_URL: API_URL+ "api/", // UAT
  BASE_URL: API_URL, // UAT

  AUTHENTICATION: {
    LOGIN: "Account/AuthenticateAccount",
    VALIDATE_OTP: "Account/ValidateTOTP"
  },
  ORGANIZATION: {
    GET_ORGANIZATION: "Organisation/GetOrganisations",
    SAVE_ORGANIZATION: "Organisation/SaveOrganisation",
    GET_ORGANIZATION_By_Id: "Organisation/GetOrganisationById",
    ARCHIVE_ORGANIZATION: "Organisation/ArchiveOrganisation"
  },
  METHOD: {
    GET_METHOD: "Clean/GetDeviceType",
    GET_METHOD_AND_CLEAN_TYPES: "Clean/methodandtypeofclean "
  },
  GROUPS: {
    GET_GROUPS: "Group/GetGroups",
    SAVE_GROUP: "Group/SaveGroup",
    ARCHIVE_GROUP: "Group/ArchiveGroup"
  },
  REGIONS: {
    GET_REGIONS: "Region/GetRegions",
    SAVE_REGION: "Region/SaveRegion",
    ARCHIVE_REGION: "Region/ArchiveRegion"
  },
  AREAS: {
    GET_AREAS: "Area/GetAreas",
    SAVE_AREA: "Area/SaveArea",
    ARCHIVE_AREA: "Area/ArchiveArea"
  },
  OUTLETS: {
    GET_OUTLETS: 'Outlet/GetOutlets',
    SAVE_OUTLET: 'Outlet/SaveOutlet',
    ARCHIVE_OUTLET: 'Outlet/ArchiveOutlet',
    GET_OUTLETS_BY_ID: 'Outlet/GetOutletById',
    UPLOAD_OUTLET: 'Outlet/UploadOutlet',
    UPLOAD_OUTLET_OFFLINE: 'Outlet/UploadOutletOffline',
    UPLOAD_OUTLET_ASSET: 'Outlet/UploadOutletAsset',
    GET_OUTLETS_FOR_WEBUSER: 'Outlet/GetOutletForWebUserCreation',
    GET_ALL_UPLOAD_LOGS: 'Outlet/GetAllUploadLogs',
    GET_ALL_UPLOAD_HISTORY_LOGS: 'Outlet/GetAllUploadHistoryLogs',
    UPDATEUPLOADHISTORYLOGS: 'Outlet/UpdateUploadHistoryLogs'
  },
  ASSET_TYPE: {
    GET_ASSET_TYPE: 'Outlet/GetAssetType',
    SAVE_ASSET_TYPE: 'Outlet/SaveAssetType',
    ARCHIVE_ASSET_TYPE: 'Outlet/ArchiveAssetType'
  },
  TERRITORIES: {
    GET_TERRITORIES: "Territory/GetTerritories",
    SAVE_TERRITORY: "Territory/SaveTerritory",
    ARCHIVE_TERRITORY: "Territory/ArchiveTerritory"
  },
  DEVICE: {
    GET_DEVICE: "Clean/GetDevices",
    SAVE_DEVICE: "Clean/SaveDevice",
    ARCHIVE_DEVICE: "Clean/ArchiveDevice"
  },
  ClEAN_PARAMS: {
    GET_ALL_CLEAN_PARAMS: "Clean/GetAllCleanParameters",
    GET_CLEAN_PARAMS: "Clean/GetCleanParameter",
    SET_CLEAN_PARAMS: "Clean/SetCleanParameter"
  },
  ClEAN_PARAMS_GEN1: {
    GET_ALL_CLEAN_PARAMS: "Clean/GetAllCleanParams",
    GET_CLEAN_PARAMS_BY_ID: "Clean/GetCleanParamsById",
    SAVE_CLEAN_PARAMS: "Clean/SaveCleanParams",
    SET_DEFAULT_CLEAN_PARAMS: 'Clean/SetToDefaultCleanParameter',
    SET_ARCHIVEDEVICE_CLEAN_PARAMS: 'Clean/ArchiveDeviceCleanParameter',
  },
  MANUAL_REASON: {
    GET_MANUAL_REASONS: "Clean/GetManualReasons",
    SAVE_MANUAL_REASON: "Clean/SaveManualReason",
    ARCHIVE_MANUAL_REASON: "Clean/ArchiveManualReason"
  },
  REFUSAL_REASON: {
    GET_REFUSAL_REASONS: "Clean/GetRefusalReasons",
    SAVE_REFUSAL_REASON: "Clean/SaveRefusalReasons",
    ARCHIVE_REFUSAL_REASON: "Clean/ArchiveRefusalReasons"
  },
  WEB_ACCOUNTS: {
    SAVE_WEB_ACC: "Account/SaveAccount",
    GET_WEB_ACC: "Account/GetAccounts",
    CHANGE_ACCOUNT_PASSWORD: 'Account/ChangeAccountPassword',
    ARCHIVE_WEB_ACC: "Account/ArchiveAccount",
    RESET_2FA: "Account/Reset2fa",
    LOCK_UNLOCK_WEB_ACC: "Account/LockUnlockAccount",
    GET_COOKIE_CONSENT_STATUS: 'Account/GetCookie',
    SET_COOKIE_CONSENT_STATUS: 'Account/SaveCookie'
  },
  EMAIL_CONFIGURATION: {
    GET_EMAIL_RECIPIENTS: 'Account/GetEmailRecipients',
    GET_ORGANIZATION_ADMINS: 'Account/GetOrganisationAdmins',
    SAVE_EMAIL_RECIPIENTS: 'Account/SaveEmailRecipient'
  },
  USERS: {
    SAVE_USER: "User/SaveUser",
    GET_USERS: "User/GetUsers",
    CHANGE_USER_PASSWORD: 'User/ChangeUserPassword',
    ARCHIVE_USER: "User/ArchiveUser",
    LOCK_UNLOCK_USER: "User/LockUnlockUser"
  },
  VISITS: {
    GET_VISITS: 'Visit/GetVisits',
    GET_HISTORY: 'Visit/GetHistory',
    GET_CLEANS: 'Visit/GetCleanItems',
    GET_CLEAN_BY_ID: 'Visit/GetCleanItemById',
    GET_SYNC_LOGS: 'Visit/GetSyncLogs',
    SAVE_CLEAN: 'Visit/EditCleanItem'
  },
  DASHBOARD: {
    GET_DASHBOARD_DATA: 'DashBoard/GetDashboardData',
    GET_DASHBOARD_DETAIL: 'DashBoard/GetDashboardDetail',
    MANUAL_REASON_LIST: 'DashBoard/GetAllManualReasonList',
    REFUSAL_REASON_LIST: 'DashBoard/GetAllRefusalReasonList',
    MANUAL_REFUSAL_GRAPH_DATA: 'DashBoard/GetCleanReasonGraphDetail'
  },
  DEFAULT: {
    GET_DEFAULT_LIST : 'Clean/GetAllDefaultCleanParameterByDeviceCleanTypeId',
    CHNAGE_STATUS : 'Clean/ArchiveDefaultCleanParameters',
    GET_DEFAULT_BY_ID : 'Clean/GetDefaultCleanParameterById',
    ADD_DEFAULT_PARAM_SETTINGS : 'Clean/CreateDefaultCleanParameters',
    UPDATE_DEFAULT_PARAM_SETTINGS : 'Clean/UpdateDefaultCleanParameters',
    SET_TO_DEFAULT_PARAM_FOR_DEVICES : 'Clean/SetToDefaultCleanParameter'
  }
};
