import {
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
    OnDestroy
} from "@angular/core";
import { SearchDataPipe } from "src/app/pipes/search-data.pipe";
import { process, State } from "@progress/kendo-data-query";
import { DataStateChangeEvent } from "@progress/kendo-angular-grid";
import { Getterbodyparam, TerritoryModel } from "src/app/models/territories.model";
import { CommonService } from "src/app/services/common.service";
import { LoggedInUserService } from "src/app/services/loggedInUser.service";
import { TerritoriesService } from "src/app/services/territories.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { MESSAGE } from "../../shared/messages";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { IKendoColumnModel } from "src/app/models/kendoColumn.model";
import { convertTimeZone, getFormatedDate } from "src/app/shared/handler.functions";
import { Role } from 'src/app/models/roles.enum';

@Component({
    selector: "app-territories",
    templateUrl: "./territories.component.html"
})

export class TerritoriesComponent implements OnInit, OnDestroy {
    territoryList: TerritoryModel[] = [];
    gridData: any = [];
    searchKeyword: string;
    searchableColumns = ["name", "organisationName", "groupName", "regionName", "areaName"];
    state: State = {
        skip: 0,
        take: 50
    };
    columns: IKendoColumnModel[];

    bodyParamTerrotory: Getterbodyparam = {
        organisationId: this.loggedInUserService.getUserData("organisationId"),
        groupId: 0,
        regionId: 0,
        areaId: 0
    };

    timeZone: string;

    // Modal Popup
    modalRef: BsModalRef;
    confirmModalRef: BsModalRef;
    selectedTerritory;
    territoryIsEdit: boolean = false;
    territorySubscription: Subscription;

    @ViewChild("territoryDetail", { static: true }) territoryDetailModal: TemplateRef<any>;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private trtService: TerritoriesService,
        private commonService: CommonService,
        private loggedInUserService: LoggedInUserService,
        private modalService: BsModalService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {

        this.timeZone = this.loggedInUserService.getUserData('timezone');

        this.GetTerritories();
        const role = this.loggedInUserService.getUserData('role');
        this.territorySubscription = this.activeRoute.queryParams.subscribe(
            params => {
                if (Object.keys(params).length !== 0) {
                    this.router.navigate([], { replaceUrl: true });
                    this.selectedTerritory = {
                        organisationId: params.orgId,
                        groupId: params.grpId,
                        regionId: params.rgnId,
                        areaId: params.areaId
                    };
                    this.openAreaModal(this.territoryDetailModal);
                    this.commonService.setLoader(false);
                }
            }
        );
        this.columns = [
            {
                field: "name",
                title: "Name",
                width: 200,
                groupable: false,
                locked: true
            },
            {
                field: "organisationName",
                title: "Organisation",
                width: 200,
                display: role !== Role[1],
                groupable: true
            },
            {
                field: "groupName",
                title: "Group",
                width: 200,
                groupable: true
            },
            {
                field: "regionName",
                title: "Region",
                width: 200,
                groupable: true
            },
            {
                field: "areaName",
                title: "Area",
                width: 200,
                groupable: true
            },
            {
                field: "createdAt",
                title: "Created On",
                width: 120,
                cellTextCenter: true,
                groupable: false,
                cellFormat: data => getFormatedDate(data.createdAt)
            },
            {
                field: "createdByName",
                title: "Created By",
                width: 200,
                groupable: false
            },
            {
                field: "updatedAt",
                title: "Updated On",
                width: 130,
                cellTextCenter: true,
                groupable: false,
                cellFormat: data => getFormatedDate(data.updatedAt) || "-"
            },
            {
                field: "updatedByName",
                title: "Updated By",
                width: 200,
                cellTextCenter: false,
                groupable: false,
                cellFormat: data => data.updatedByName || "-"
            }
        ];
    }

    private loadItems() {
        const searchedRecords = new SearchDataPipe().transform(
            this.territoryList,
            this.searchKeyword,
            this.searchableColumns
        );
        this.gridData = process(searchedRecords, this.state);
    }

    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.loadItems();
    }

    // Get Territories List
    private GetTerritories(): void {
        const bodyParams = this.bodyParamTerrotory;
        this.trtService.getTerritoriesList(bodyParams).subscribe(
            res => {
                this.commonService.setLoader(false);
                if (res && res.statusCode === 200 && res.data) {

                    convertTimeZone(res.data, ['createdAt', 'updatedAt'], this.timeZone);
                    
                    this.territoryList = res.data;
                    this.loadItems();
                }
            },
            error => { }
        );
    }

    // for search
    public filterSearch() {
        this.loadItems();
        this.state.skip = 0;
    }

    // edit territory
    public editTerritory(territory, template: TemplateRef<any>) {
        this.territoryIsEdit = true;
        this.selectedTerritory = Object.assign({}, territory);
        this.openModal(template);
    }

    // add new Territory
    public addTerritory(template: TemplateRef<any>) {

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.dismissActiveInactiveConfirmModal();
            this.router.navigate(["/"]);
            return;
        }

        this.selectedTerritory = {
            id: 0,
            userId: JSON.parse(localStorage.getItem("userData")).id
        };
        this.openAreaModal(template);
    }

    private openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: "modal-dialog-centered",
            ignoreBackdropClick: true
        });
    }

    public openAreaModal(template: TemplateRef<any>) {
        this.territoryIsEdit = false;
        this.openModal(template);
    }

    // Close Modal popup
    public closeModalPopup() {
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // Final Submit
    public submitModalPopup(template: TemplateRef<any>) {
        this.GetTerritories();
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // confirmation for archive
    public askForConfirmation(territory, template: TemplateRef<any>) {
        this.selectedTerritory = territory;
        this.confirmModalRef = this.modalService.show(template, {
            class: "modal-confirm modal-dialog-centered second",
            ignoreBackdropClick: true
        });
    }

    public dismissActiveInactiveConfirmModal() {
        this.confirmModalRef.hide();
        this.confirmModalRef = null;
    }

    public finallyCloseStatusConfirmModal(territory) {
        this.archivedTerritory(territory);
        this.dismissActiveInactiveConfirmModal();
    }

    // archive group or change status
    public archivedTerritory(territory) {

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.dismissActiveInactiveConfirmModal();
            this.router.navigate(["/"]);
            return;
        }

        const bodyParams = {
            id: territory.id,
            archived: !territory.archived,
            updatedBy: JSON.parse(localStorage.getItem("userData")).id
        };

        this.trtService.archiveTerritory(bodyParams).subscribe(
            res => {
                if (res.statusCode === 200 && res.data > 0) {
                    this.toastr.success(MESSAGE.ARCHIVED_DONE_SUCCESSFULLY, "");
                    this.GetTerritories();
                    this.commonService.setLoader(false);
                } else if (res.statusCode === 404) {
                    this.commonService.setLoader(false);
                    if (res.data === -3) {
                        this.toastr.error(MESSAGE.UNABLE_TO_DEACTIVATE_TERRITORY, "", { timeOut: 10000 });
                    } else if (res.data === -4) {
                        this.toastr.error(MESSAGE.UNABLE_TO_ACTIVATE_TERRITORY, "", { timeOut: 10000 });
                    }
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    goToOutlet(territory) {
        this.router.navigate(["app/outlets"], {
            queryParams: { orgId: territory.organisationId, grpId: territory.groupId, rgnId: territory.regionId, territoryId: territory.id, territoryName: territory.name },
            skipLocationChange: true
        });
    }

    ngOnDestroy(): void {
        this.territorySubscription && this.territorySubscription.unsubscribe();
    }
}
