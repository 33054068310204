import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GroupsService } from 'src/app/services/groups.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { OrganizationModel } from 'src/app/models/organization.model';
import { CommonService } from 'src/app/services/common.service';
import { MESSAGE } from 'src/app/shared/messages';
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { Role } from 'src/app/models/roles.enum';
import { GroupModel } from 'src/app/models/groups.model';
import { NgForm } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-manage-groups',
    templateUrl: './manage-groups.component.html'
})

export class ManageGroupsComponent implements OnInit {
    @Input() isEdit: boolean = false;
    @Input() groupDetail: GroupModel;
    @Output() close = new EventEmitter();
    @Output() submit = new EventEmitter();
    submitError: string;
    group: GroupModel;
    orgList: OrganizationModel[];
    fromParent;
    grpsubscription: Subscription;
    grpIdFrmPar;
    role: string;
    roleEnum = Role;
    onlyViewPermission = false;
    organization: {
        orgId: number;
        orgName: string;
    };
    constructor(private sharedService: SharedService, private grpService: GroupsService, private orgService: OrganizationService, private commonService: CommonService, private toastr: ToastrService, private loggedInUserService: LoggedInUserService, private router: Router) {
    }

    ngOnInit() {
        if (this.groupDetail) {
            this.group = this.groupDetail;
        }
        this.role = this.loggedInUserService.getUserData('role');

        this.getOrgList();

        if ([Role[2],Role[4],Role[5]].includes(this.role)) {
            // organizationAdmin, superOrgUser, orgSuperAdmin
            this.organization = {
                orgId: this.loggedInUserService.getUserData("organisationId"),
                orgName: this.loggedInUserService.getUserData("organisationName")
            };
            this.group.organisationId = this.organization.orgId;
        } 
        else {
            //superAdmin
            if (!this.isEdit && !this.group.organisationId) {
                this.group.organisationId = null;
            }
            this.getOrgList();
        }
        
        this.commonService.setLoader(false);
    }

    private getOrgList() {
        this.sharedService.getOrgList()
            .then(
                (resData: OrganizationModel[]) => {
                    this.orgList = resData;
                }
            );
    }

    saveGroup(event, form: NgForm) {
        event.stopPropagation();
        if (this.group.name.trim() === '') {
            form.controls['GroupName'].setErrors({ required: true });
            return
        }
        if (form.form.invalid) {
            return;
        }

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.close.emit();
            this.router.navigate(["/"]);
            return;
        }

        const params = {
            id: this.group.id,
            name: this.group.name.trim(),
            organisationId: this.role !== Role[1] ? this.loggedInUserService.getUserData('organisationId') : Number(this.group.organisationId),
            userId: JSON.parse(localStorage.getItem('userData')).id
        }

        this.grpService.saveGroup(params).subscribe(
            (res) => {
                if (res) {
                    if (res.statusCode === 200 && res.data > 0) {
                        this.submit.emit();
                        this.isEdit ? this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
                    } else if (res.statusCode === 404) {
                        this.commonService.setLoader(false);
                        if (res.data === -1) {
                            this.submitError = MESSAGE.RECORD_ALREADY_EXIST;
                        } else if (res.data === 0) {
                            this.toastr.error(MESSAGE.RECORD_SAVING_FAILED)
                        }
                    }
                }
            }
        );

    }

    dismissModal() {
        this.close.emit();
    }

}
