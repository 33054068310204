import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';

@Injectable({
  providedIn: 'root'
})
export class OutletService {

  constructor(private restAPI: RestApi) { }

  // get list of outlets
  getOutletsList(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.OUTLETS.GET_OUTLETS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  // get outlet by Id
  getOutletsById(outletId) {
    const url = RestApi.buildUrl(`${ENDPOINTS.OUTLETS.GET_OUTLETS_BY_ID}?id=${outletId}`);
    return this.restAPI.handleRequest(url, METHODS.Get);
  }


  // archive outlet
  archiveOutlet(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.OUTLETS.ARCHIVE_OUTLET}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  // add/edit outlet
  saveOutlet(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.OUTLETS.SAVE_OUTLET}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  // Upload outlet
  uploadFile(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.OUTLETS.UPLOAD_OUTLET}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  // Upload offline file 
  uploadOfflineFile(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.OUTLETS.UPLOAD_OUTLET_OFFLINE}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }
  // CR5 upload file
  uploadOutletAsset(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.OUTLETS.UPLOAD_OUTLET_ASSET}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  getOutletsForWebUser(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.OUTLETS.GET_OUTLETS_FOR_WEBUSER}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  // Get all upload logs
  getAllUploadLogs(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.OUTLETS.GET_ALL_UPLOAD_LOGS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  // Get all upload history logs
  getAllUploadHistoryLogs(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.OUTLETS.GET_ALL_UPLOAD_HISTORY_LOGS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }

  // Get all upload history logs
  UpdateUploadHistoryLogs(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.OUTLETS.UPDATEUPLOADHISTORYLOGS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, params);
  }
}
