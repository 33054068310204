import { Subscription } from 'rxjs';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GroupsService } from "../../services/groups.service";
import { CommonService } from "../../services/common.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { GroupOrgId, GroupModel } from 'src/app/models/groups.model';
import { MESSAGE } from "../../shared/messages";
import { process, State } from "@progress/kendo-data-query";

import { DataStateChangeEvent } from "@progress/kendo-angular-grid";
import { ActivatedRoute, Router } from '@angular/router';
import { SearchDataPipe } from "src/app/pipes/search-data.pipe";
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { Role } from 'src/app/models/roles.enum';
import { IKendoColumnModel } from 'src/app/models/kendoColumn.model';
import { convertTimeZone, getFormatedDate } from 'src/app/shared/handler.functions';

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html'
})
export class GroupsComponent implements OnInit {

    groupOrgId: GroupOrgId = {
        "organisationId": this.loggedInUserService.getUserData('organisationId')
    };

    userOrgId: GroupOrgId;

    selectedGroup;
    grpIsEdit: boolean = false;

    // Modal Popup
    modalRef: BsModalRef;
    confirmModalRef: BsModalRef;

    public gridData: any = [];
    groupList: GroupModel[] = [];
    fromParent;
    grpsubscription: Subscription;
    searchableColumns = ["name", "organisationName"];
    columns: IKendoColumnModel[];
    searchKeyword: string;
    state: State = {
        skip: 0,
        take: 50
    };
    role: string;
    timeZone: string;

    @ViewChild('groupDetail', { static: true }) groupDetailModal: TemplateRef<any>;

    constructor(
        private modalService: BsModalService,
        private grpService: GroupsService,
        private commonService: CommonService,
        private toastr: ToastrService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private loggedInUserService: LoggedInUserService
    ) { }

    ngOnInit() {
        this.getGroups();
        this.timeZone = this.loggedInUserService.getUserData('timezone');
        this.grpsubscription = this.activeRoute.queryParams.subscribe(
            (params) => {
                if (Object.keys(params).length !== 0) {
                    this.router.navigate([], { replaceUrl: true });
                    this.grpIsEdit = false;
                    this.selectedGroup = { organisationId: params.orgId }
                    this.openGroupModal(this.groupDetailModal);
                    this.commonService.setLoader(false);
                }
            }
        );
        this.role = this.loggedInUserService.getUserData('role');

        this.columns = [
            {
                field: "name",
                title: "Name",
                width: 300,
                groupable: false,
                locked: true
            },
            {
                field: "organisationName",
                title: "Organisation",
                width: 300,
                groupable: true,
                display: this.role !== Role[1]
            },
            {
                field: "createdAt",
                title: "Created On",
                width: 120,
                cellTextCenter: true,
                groupable: false,
                cellFormat: data => getFormatedDate(data.createdAt)
            },
            {
                field: "createdBy",
                title: "Created By",
                width: 230,
                groupable: false
            },
            {
                field: "updatedAt",
                title: "Updated On",
                width: 130,
                cellTextCenter: true,
                groupable: false,
                cellFormat: data => getFormatedDate(data.updatedAt) || '-'
            },
            {
                field: "updatedBy",
                title: "Updated By",
                width: 230,
                groupable: false,
                cellFormat: (data) => data.updatedBy || "-"
            }
        ];


    }

    // REALTED to grid Functionality paging , sorting , groupping
    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.loadItems();
    }

    private loadItems() {
        const searchedRecords = new SearchDataPipe().transform(
            this.groupList,
            this.searchKeyword,
            this.searchableColumns
        );
        this.gridData = process(searchedRecords, this.state);
    }

    // confirmation for archive
    public askForConfirmation(group, template: TemplateRef<any>) {
        this.selectedGroup = group;
        this.confirmModalRef = this.modalService.show(template, {
            class: "modal-confirm modal-dialog-centered second",
            ignoreBackdropClick: true
        });
    }

    public dismissActiveInactiveConfirmModal() {
        this.confirmModalRef.hide();
        this.confirmModalRef = null;
    }

    public finallyCloseStatusConfirmModal(group) {
        this.archivedGroup(group);
        this.dismissActiveInactiveConfirmModal();
    }

    // archive group or change status
    private archivedGroup(group) {

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.dismissActiveInactiveConfirmModal();
            this.router.navigate(["/"]);
            return;
        }

        const bodyParams = {
            id: group.id,
            archived: !group.archived,
            updatedBy: JSON.parse(localStorage.getItem("userData")).id
        };

        this.grpService.archiveGroup(bodyParams).subscribe(
            (res) => {
                if (res) {
                    if (res.statusCode === 200 && res.data > 0) {
                        this.toastr.success(MESSAGE.ARCHIVED_DONE_SUCCESSFULLY, "");
                        this.getGroups();
                        this.commonService.setLoader(false);
                    } else if (res.statusCode === 404) {
                        this.commonService.setLoader(false);
                        if (res.data === -3) {
                            this.toastr.error(MESSAGE.UNABLE_TO_DEACTIVATE_GROUP, "", { timeOut: 10000 });
                        } else if (res.data === -4) {
                            this.toastr.error(MESSAGE.UNABLE_TO_ACTIVATE_GROUP, "", { timeOut: 10000 });
                        }
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    private openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered',
            ignoreBackdropClick: true
        });
    }

    // Close first add/edit Modal popup
    public closeModalPopup() {
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // Final Submit After first Modal i.e SAVE API_CALL
    public submitModalPopup(template: TemplateRef<any>) {
        this.getGroups();
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }


    // edit group
    public editGroup(group, template: TemplateRef<any>) {
        this.grpIsEdit = true;
        this.selectedGroup = Object.assign({}, group);
        this.openModal(template);
    }

    // Get Group List
    private getGroups(): void {
        const bodyParams = this.groupOrgId;
        this.grpService.getGroupsList(bodyParams).subscribe(
            (res) => {
                this.commonService.setLoader(false);
                if (res && res.statusCode === 200) {
                    convertTimeZone(res.data, ['createdAt', 'updatedAt'], this.timeZone);
                    this.groupList = res.data;
                    this.loadItems();
                }
            },
            (error) => {
            }
        );
    }

    public filterSearch() {
        this.loadItems();
        this.state.skip = 0;
    }

    public openGroupModal(template: TemplateRef<any>) {
        // this.selectedGroup = { id: 0, userId: JSON.parse(localStorage.getItem('userData')).id };
        // this.selectedGroup = new GroupDatum();
        this.grpIsEdit = false;
        this.openModal(template);
    }

    // add new group
    public addGroup(template: TemplateRef<any>) {

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.router.navigate(["/"]);
            return;
        }

        this.selectedGroup = { id: 0, userId: JSON.parse(localStorage.getItem('userData')).id };
        this.openGroupModal(template);
    }

    goToRegion(orgId, grpId) {
        this.router.navigate(['app/organisationDetail/regions'], { queryParams: { 'grpId': grpId, 'orgId': orgId }, skipLocationChange: true });
    }

    ngOnDestroy(): void {
        this.grpsubscription && this.grpsubscription.unsubscribe();
    }

}
