import { Injectable } from '@angular/core';
import { RegionsService } from './regions.service';
import { GroupsService } from './groups.service';
import { OrganizationService } from './organization.service';
import { CommonService } from './common.service';
import { AreasService } from './areas.service';
import { TerritoriesService } from './territories.service';
import { MobileUserService } from './mobile-user.service';
import { MethodService } from './method.service';
import { DashboardService } from './dashboard.service';
import { Subject, Observable,BehaviorSubject } from 'rxjs';
@Injectable()
export class SharedService {
  role: number;
   private cleanTypes = new BehaviorSubject<any>([]);
  methodTypeData = {
    "message": "Data fetched successfully.",
    "statusCode": 200,
    "data": [
      {
        "index": 1,
        "id": 1,
        "name": "Vortex Soak",
        "deviceTypeId": 1,
        "typeOfCleanId": 1,
        archived: false
      },
      {
        "index": 2,
        "id": 1,
        "name": "Vortex Recirc",
        "deviceTypeId": 1,
        "typeOfCleanId": 2,
        archived: false
      },
      {
        "index": 3,
        "id": 5,
        "name": "Verx Auto",
        "deviceTypeId": 2,
        "typeOfCleanId": 3,
        archived: false
      },
      {
        "index": 4,
        "id": 5,
        "name": "Verx Manual",
        "deviceTypeId": 2,
        "typeOfCleanId": 4,
        archived: false
      },
      {
        "index": 5,
        "id": 3,
        "name": "Not Cleaned",
        "deviceTypeId": null,
        "typeOfCleanId": null,
        archived: false
      },
      {
        "index": 6,
        "id": 2,
        "name": "Manual",
        "deviceTypeId": null,
        "typeOfCleanId": null,
        archived: false
      }
    ]
  }

  constructor(private orgService: OrganizationService, private grpService: GroupsService, private regionService: RegionsService, private areaService: AreasService, private territoryService: TerritoriesService, private commonService: CommonService, private mobileUserService: MobileUserService, private methodService: MethodService, private dashboardService: DashboardService) {
  }

  getOrgList(all = false) {
    return new Promise((resolve, reject) =>
      this.orgService.getOrganizationList()
        .subscribe(
          (resData) => {
            this.commonService.setLoader(false);
            if (resData?.data) {
              const data = all ? resData.data : resData.data.filter(res => !res.archived);
              resolve(data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
            }
          }
        ))
  }
  getMethod(bodyParam, all = false) {
    return new Promise((resolve, reject) =>
      this.methodService.getMethod(bodyParam)
        .subscribe(
          (resData) => {
            this.commonService.setLoader(false);
            if (resData.data) {
              const data = all ? resData.data : resData.data.filter(res => !res.archived);
              resolve(data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
            }
          }
        ))
  }

  getMethodAndCleanTypes(bodyParam, all = false) {
    return new Promise((resolve, reject) =>
     {
       const data = all ? this.methodTypeData.data : this.methodTypeData.data.filter(res => !res.archived);
       resolve(data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
     }
      // this.methodService.getMethodAndTypeOfCleans(bodyParam)
      //   .subscribe(
      //     (resData) => {
      //       this.commonService.setLoader(false);
      //       if (resData.data) {
      //         // const data = all ? resData.data : resData.data.filter(res => !res.archived);
      //         const data = all ? this.methodTypeData.data : this.methodTypeData.data.filter(res => !res.archived);
      //         resolve(data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
      //       }
      //     }
      //   )
        )
  }

  getGrpList(organisationId: number, all = false, userId = 0) {
    const bodyParams = {
      organisationId
    };
    if (userId > 0) {
      bodyParams['userId'] = userId;
    }
    return new Promise((resolve, reject) =>
      this.grpService.getGroupsList(bodyParams).subscribe(
        (resData) => {
          this.commonService.setLoader(false);
          if (resData && resData.data) {
            const data = all ? resData.data : resData.data.filter(res => !res.archived);
            resolve(data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
          }
        }
      ));
  }

  getRegionList(organisationId: number, groupId = 0, all = false, userId = 0) {
    const bodyParams = {
      organisationId,
      groupId
    };
    if (userId > 0) {
      bodyParams['userId'] = userId;
    }
    return new Promise((resolve, reject) =>
      this.regionService.getRegionsList(bodyParams).subscribe(
        (resData) => {
          this.commonService.setLoader(false);
          if (resData && resData.data) {
            const data = all ? resData.data : resData.data.filter(res => !res.archived);
            resolve(data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
          }
        }
      ));
  }

  getAreaList(organisationId: number, groupId = 0, regionId = 0, all = false, userId = 0) {
    const bodyParams = {
      organisationId,
      groupId,
      regionId
    };
    if (userId > 0) {
      bodyParams['userId'] = userId;
    }
    return new Promise((resolve, reject) =>
      this.areaService.getAreasList(bodyParams).subscribe(
        (resData) => {
          this.commonService.setLoader(false);
          if (resData && resData.data) {
            const data = all ? resData.data : resData.data.filter(res => !res.archived);
            resolve(data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
          }
        }
      ));
  }

  getTerritoryList(organisationId: number, groupId = 0, regionId = 0, areaId = 0, all = false, userId = 0) {
    const bodyParams = {
      organisationId,
      groupId,
      regionId,
      areaId
    };
    if (userId > 0) {
      bodyParams['userId'] = userId;
    }
    return new Promise((resolve, reject) =>
      this.territoryService.getTerritoriesList(bodyParams).subscribe(
        (resData) => {
          this.commonService.setLoader(false);
          if (resData && resData.data) {
            const data = all ? resData.data : resData.data.filter(res => !res.archived);
            resolve(data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
          } else {
            resolve([]);
          }
        }
      ));
  }

  getMobileUsersList(organisationId: number, all = false, userId = 0) {
    const bodyParams = {
      organisationId,
    };
    if (userId > 0) {
      bodyParams['userId'] = userId;
    }
    return new Promise((resolve, reject) =>
      this.mobileUserService.getUserList(bodyParams).subscribe(
        (resData) => {
          this.commonService.setLoader(false);
          if (resData && resData.data) {
            const data = all ? resData.data : resData.data.filter(res => !res.archived);
            resolve(data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
          } else {
            resolve([]);
          }
        }
      ));
  }

  manualReasonList(organisationId: number, userId = 0) {
    const bodyParams = {
      organisationId,
      userId
    };
    // if (userId > 0) {
    //   bodyParams['userId'] = userId;
    // }
    return new Promise((resolve, reject) =>
      this.dashboardService.getManualReasonsList(bodyParams).subscribe(
        (resData) => {
          this.commonService.setLoader(false);
          if (resData.data) {
            const data = resData.data;
            resolve(data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
          } else {
            resolve([]);
          }
        }
      ));
  }

  refusalReasonList(organisationId: number, userId = 0) {
    const bodyParams = {
      organisationId,
      userId
    };
    // if (userId > 0) {
    //   bodyParams['userId'] = userId;
    // }
    return new Promise((resolve, reject) =>
      this.dashboardService.getRefusalReasonsList(bodyParams).subscribe(
        (resData) => {
          this.commonService.setLoader(false);
          if (resData.data) {
            const data = resData.data;
            resolve(data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
          } else {
            resolve([]);
          }
        }
      ));
  }

  public getCleanType(): Observable<any> {
    return this.cleanTypes.asObservable();
  }
  public updateCleanType(message: any): void {
    
    this.cleanTypes.next(message);
  }

}

