import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { CleanModel, Clean } from 'src/app/models/clean.model';
import { constants } from '../../shared/directives/constants';
import { Role } from 'src/app/models/roles.enum';
import { NgForm } from '@angular/forms';
import { CleanParamsService } from 'src/app/services/clean-params.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { MESSAGE } from 'src/app/shared/messages';


@Component({
    selector: 'app-manage-clean',
    templateUrl: './manage-clean.component.html',
})
export class ManageCleanComponent implements OnInit {
    readonly constants = constants;
    readonly Role = Role;
    @Input() cleanDetail: CleanModel;
    @Output() close = new EventEmitter();
    @Output() submit = new EventEmitter();
    cleanData: CleanModel;
    submitError: string;


    constructor(
        private cleanParamsService: CleanParamsService,
        private commonService: CommonService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        if (this.cleanDetail) {
            this.cleanData = this.cleanDetail;
            this.cleanData.visitStartDateTimeEdit = moment(this.cleanDetail.visitStart.split('+')[0]).toDate();
            this.cleanData.visitEndDateTimeEdit = moment(this.cleanDetail.visitEnd.split('+')[0]).toDate();
            this.cleanData.cleanStartDateTimeEdit = moment(this.cleanDetail.cleanStartDateTime.split('+')[0]).toDate();
            this.cleanData.cleanFinishDateTimeEdit = moment(this.cleanDetail.cleanFinishDateTime.split('+')[0]).toDate();
        }
    }

    // save clean
    saveClean(event, form: NgForm) {
        event.stopPropagation();
        if (form.form.invalid) {
            return;
        }
        const params: Clean = {
            cleanItemId: this.cleanDetail.cleanItemId,
            visitStart: moment(this.cleanDetail.visitStartDateTimeEdit).format('YYYY-MM-DDTHH:mm:ss'),
            visitEnd: moment(this.cleanDetail.visitEndDateTimeEdit).format('YYYY-MM-DDTHH:mm:ss'),
            cleanStartDateTime: moment(this.cleanDetail.cleanStartDateTimeEdit).format('YYYY-MM-DDTHH:mm:ss'),
            cleanFinishDateTime:  moment(this.cleanDetail.cleanFinishDateTimeEdit).format('YYYY-MM-DDTHH:mm:ss'),
            score: +this.cleanDetail.score ? +this.cleanDetail.score : null,
            rating: +this.cleanDetail.rating ? +this.cleanDetail.rating : null
        };
        this.cleanParamsService.saveClean(params).subscribe(res => {
            if (res) {
                if (res.statusCode === 200 && res.data > 0) {
                    this.submit.emit();
                    this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS);
                } else if (res.statusCode === 404) {
                    this.commonService.setLoader(false);
                    if (res.data === -1) {
                        this.submitError = MESSAGE.RECORD_ALREADY_EXIST;
                    } else if (res.data === 0) {
                        this.toastr.error(MESSAGE.RECORD_SAVING_FAILED);
                    }
                }
            }
        });
    }

    dismissModal() {
        this.close.emit();
    }
}
