import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DeviceType } from 'src/app/models/deviceType.enum';
import { SearchDataPipe } from 'src/app/pipes/search-data.pipe';
import { CleanParamsService } from 'src/app/services/clean-params.service';
import { CommonService } from 'src/app/services/common.service';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { MESSAGE } from 'src/app/shared/messages';

@Component({
  selector: 'app-multiple-default-setting',
  templateUrl: './multiple-default-setting.component.html',
  styleUrls: ['./multiple-default-setting.component.css']
})
export class MultipleDefaultSettingComponent implements OnInit {
  sub: any;
  childPath;
  selectDeviceType: number;
  defaultSettingList: any;
  selectedDefaultSettings = [];
  allSelected = false;
  selectedDevice: string;
  modalRef: BsModalRef;
  confirmModalRef: BsModalRef;
  //manageState: Observable<object>;
  verxCleanParams: any = {};

  columns = [
    {
      field: "defaultName", title: "Default Name",
      width: 130, forDevice: [2, 3]

    },
    {
      field: "organizationName", title: "Organisation name",
      width: 130, forDevice: [2, 3]

    },
    {
      field: "isMaster", title: "Is Master",
      width: 130, forDevice: [2, 3]

    },
    {
      field: "archieved", title: "Default Status",
      width: 130, forDevice: [4, 5]

    }
  ];
  searchableColumns = this.columns.map(col => col.field);
  searchKeyword: string;
  public state: State = {
    skip: 0,
    take: 50
  };
  gridData: any = [];
  organisation_Id: number;

  constructor(private router: Router, private route: ActivatedRoute,
    private service: CleanParamsService,
    private commonService: CommonService,
    private toastr: ToastrService, private modalService: BsModalService,
    private loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.organisation_Id = this.loggedInUserService.getUserData("organisationId"),
      this.sub = this.route
        .data
        .subscribe(v => {
          console.log(v.device);
          this.childPath = this.service.childPath.filter(obj => obj.name === v.device);
          this.selectedDevice = v.device;
          this.setSelectedItem(v.device);
        });
  }

  isSelectedItem(item) {
    return item === this.service.getSelectedRoutes();
  }

  setSelectedItem(device) {
    switch (device) {
      case 'verx': this.service.setSelectedRoutes(DeviceType.VerxAuto);
        this.getDefaulSettings({ deviceCleanTypeId: DeviceType.VerxAuto, organizationId: this.organisation_Id });
        this.selectDeviceType = DeviceType.VerxAuto;
        break;
      case 'PLCS': this.service.setSelectedRoutes(DeviceType.PLCSAuto);
        this.getDefaulSettings({ deviceCleanTypeId: DeviceType.PLCSAuto, organizationId: this.organisation_Id });
        this.selectDeviceType = DeviceType.PLCSAuto;
        break;
      case 'vortex': this.service.setSelectedRoutes(DeviceType.Vortex);
        this.getDefaulSettings({ deviceCleanTypeId: DeviceType.Vortex, organizationId: this.organisation_Id });
        this.selectDeviceType = DeviceType.Vortex;
        break;
      case 'vortexi': this.service.setSelectedRoutes(DeviceType.VortexIAuto);
        this.getDefaulSettings({ deviceCleanTypeId: DeviceType.VortexIAuto, organizationId: this.organisation_Id });
        this.selectDeviceType = DeviceType.VortexIAuto;
        break;
      case 'verx-lite': this.service.setSelectedRoutes(DeviceType.Verxlite);
        this.getDefaulSettings({ deviceCleanTypeId: DeviceType.Verxlite, organizationId: this.organisation_Id });
        this.selectDeviceType = DeviceType.Verxlite;
        break;
      case 'vortexn': this.service.setSelectedRoutes(DeviceType.VortexNAuto);
        this.getDefaulSettings({ deviceCleanTypeId: DeviceType.VortexNAuto, organizationId: this.organisation_Id });
        this.selectDeviceType = DeviceType.VortexNAuto;
        break;

      default:
        break;
    }
  }

  deviceSelect(value) {
    // this.allSelected = false
    this.service.setSelectedRoutes(value);
    this.selectDeviceType = this.service.getSelectedRoutes();
    this.getDefaulSettings({ deviceCleanTypeId: value, organizationId: this.organisation_Id });
  }

  getDefaulSettings(devideID: any) {
    this.defaultSettingList = [];
    this.service.getAllDefaultParam(devideID).subscribe((res) => {
      this.commonService.setLoader(false);
      if (res && res.statusCode === 200 && res.data) {
        this.defaultSettingList = res.data;
        this.loadItems();
      }
    })
  }

  private loadItems() {
    const searchedRecords = new SearchDataPipe().transform(
      this.defaultSettingList,
      this.searchKeyword,
      this.searchableColumns
    );
    this.gridData = process(searchedRecords, this.state);
  }

  // REALTED to grid Functionality paging , sorting
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadItems();
  }

  cellCheck(defaultId) {
    const index = this.selectedDefaultSettings.indexOf(defaultId);
    if (index === -1) {
      this.selectedDefaultSettings.push(defaultId);
    } else {
      this.selectedDefaultSettings.splice(index, 1);
    }
    this.allSelected = this.selectedDefaultSettings.length === this.defaultSettingList.length;
  }

  checkAll() {
    if (!this.allSelected) {
      this.selectedDefaultSettings = this.defaultSettingList.map(item => item.defaultId);
      this.allSelected = true;
    } else {
      this.selectedDefaultSettings = [];
      this.allSelected = false;
    }
  }

  public filterSearch() {
    this.loadItems();
    this.state.skip = 0;
  }

  changeStatusConfirmation(event, dataItem, template: TemplateRef<any>) {
    event.preventDefault();
    event.stopPropagation();
    //dataItem.archieved = !dataItem.archieved;
    this.openPopup(dataItem, template);
  }

  public openPopup(data, template: TemplateRef<any>) {
    this.verxCleanParams = data;
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog modal-dialog-centered', ignoreBackdropClick: true });
  }

  public closeModalPopup() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  public setStatus(verxCleanParams) {
    // this.closeModalPopup();
    const bodyParams = {
      defaultSettingId: verxCleanParams.defaultSettingId,
      //archived: verxCleanParams.archieved
      archived: verxCleanParams.archieved == true ? false : true
    };
    this.service.changeDefaulSettingStatus(bodyParams).subscribe(
      (res) => {
        if (res) {
          this.commonService.setLoader(false);
          if (res.statusCode === 200 && res.data) {
            this.toastr.success(MESSAGE.DEFAULT_SETTING_STATUS_UPDATED, "");
            // this.getAllCleanParams(this.device);
            this.getDefaulSettings({ deviceCleanTypeId: this.selectDeviceType, organizationId: this.organisation_Id });
            this.closeModalPopup();
          } else if (res.statusCode === 404 && res.data === -3) {
            this.toastr.error(MESSAGE.DEFAULT_SETTING_STATUS_ERROR, "", { timeOut: 10000 });
            this.closeModalPopup();
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  editDefault(dataItem) {
    console.log("dataItem..", dataItem);
    this.router.navigate(['/app/defaultcleanparams/manage'], { queryParams: { deviceIds: [], serialNos: [], deviceCleanTypeId: this.selectDeviceType, isAddDefault: 'true' } });
  }
}
