import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';

@Injectable()
export class DashboardService {

  constructor(private restAPI: RestApi) { }

  // get dashboard data
  getDashboardData(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DASHBOARD.GET_DASHBOARD_DATA}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // get dashboard detail
  getDashboardDetail(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DASHBOARD.GET_DASHBOARD_DETAIL}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // Get list of manual reasons dropdown
  getManualReasonsList(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DASHBOARD.MANUAL_REASON_LIST}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // Get list of refusal reasons dropdown
  getRefusalReasonsList(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DASHBOARD.REFUSAL_REASON_LIST}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // Get graph data for manaul refusal and reason refusal
  getGraphDataForDetailsPage(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DASHBOARD.MANUAL_REFUSAL_GRAPH_DATA}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // get groups for dashboard dropdown
  getGroups(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.GROUPS.GET_GROUPS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // get regions for dashboard dropdown
  getRegions(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.REGIONS.GET_REGIONS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // get users for dashboard dropdown
  getUsers(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.USERS.GET_USERS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // get area for dashboard dropdown
  getAreas(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.AREAS.GET_AREAS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // get area for dashboard dropdown
  getTerritories(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.TERRITORIES.GET_TERRITORIES}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // get cleanTypes for dashboard dropdown
  getCleanTypes(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.METHOD.GET_METHOD}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // get manual reasons for dashboard dropdown
  getManualReasons(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DASHBOARD.MANUAL_REASON_LIST}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // get manual reasons for dashboard dropdown
  getNotCleanedReasons(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DASHBOARD.REFUSAL_REASON_LIST}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

}