import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MESSAGE } from 'src/app/shared/messages';
import { AccountLogin } from '../../models/accountLogin.model';
import { RequestHelperService } from 'src/app/network/RequestHelper';
import { CommonService } from 'src/app/services/common.service';
import { constants } from 'src/app/shared/directives/constants';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {

    setup2FA = false;
    verify = false;
    // cancel = $scope.$dismiss;
    authError: string;
    verifyAccount = false;
    // code
    key;
    qrImage;
    credentials: AccountLogin;

    constructor(private auth: AuthService, private router: Router, private commonService: CommonService, private requestHelperService: RequestHelperService) {

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.router.navigate(["/"]);
            return;
        }

        const userToken = localStorage.getItem('token');
        if (userToken) {
            this.router.navigate(['app/dashboard']);
        }
    }

    ngOnInit() {
        this.resetCredentials();
    }
    resetCredentials() {
        this.credentials = {
            email: '',
            password: ''
        };
    }
    login(credentials) {
        if (!(RegExp(constants.emailRegex).test(credentials.email))) {
            this.authError = MESSAGE.EMAIL_INVALID;
            return false;
        }
        this.authError = '';
        if (this.verify || this.setup2FA) {
            this.auth.loginOTP(credentials).subscribe((res) => {

                this.commonService.setLoader(false);
                if (res && res.statusCode === 200 && res.data) {
                    if (res.data.loginFlag === -1) {
                        this.authError = MESSAGE.OTP_ERROR;
                        this.credentials.totp = null;
                    } else if (res.data.loginFlag === 0) {
                        this.authError = MESSAGE.EMAIL_PWD_INVALID;
                    } else if (res.data.token) {
                        this.authError = '';
                        localStorage.setItem('expiry', JSON.stringify(new Date().valueOf() + constants.sessionTimeOut));
                        localStorage.setItem('userData', JSON.stringify(res.data));
                        localStorage.setItem('token', JSON.stringify(res.data.token));
                        this.requestHelperService.toggleLocaRefUpdated(true);
                        // location.reload();
                        window.location.href = window.location.origin;
                        // this.router.navigate('/']);
                    }
                }
            });
        } else {
            this.auth.login(credentials).subscribe((res) => {
                this.commonService.setLoader(false);
                if (res && res.statusCode === 200 && res.data) {
                    if (res.data.loginFlag === 0) {
                        this.authError = MESSAGE.EMAIL_PWD_INVALID;
                    } else if (res.data.loginFlag === -2) {
                        this.authError = MESSAGE.ACOOUNT_IS_LOCKED;
                    } else if (!res.data.enabled2fa) {
                        this.setup2FA = true;
                        this.key = res.data.manualEntrykey;
                        this.qrImage = res.data.qrcodeImageurl;
                    } else {
                        this.verify = true;
                    }
                }
            });
        }
    }
}
