import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { State, process } from '@progress/kendo-data-query';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { LoggedInUserService } from '../services/loggedInUser.service';
import { SearchDataPipe } from '../pipes/search-data.pipe';
import { OutletService } from '../services/outlet.service';
import { CommonService } from '../services/common.service';
import { Role } from '../models/roles.enum';
import { DataStateChangeEvent, GroupableSettings } from '@progress/kendo-angular-grid';
import { OutletModel } from '../models/outlet.model';
import { MESSAGE } from '../shared/messages';
import { ToastrService } from 'ngx-toastr';
import { IKendoColumnModel } from '../models/kendoColumn.model';
import { convertTimeZone, getFormatedDate } from '../shared/handler.functions';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { constants } from '../shared/directives/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
})
export class OutletComponent implements OnInit {
  outletList: OutletModel[] = [];
  gridData: any = [];
  searchKeyword: string;
  state: State = {
    skip: 0,
    take: 50
  };
  searchableColumns = ["shipTo", "name", "city", "organisationName", "groupName", "regionName", "territoryName"];
  columns: IKendoColumnModel[];
  role: string;
  outletIsEdit: boolean = false;
  selectedOutlet: OutletModel;
  modalRef: BsModalRef;
  confirmModalRef: BsModalRef;
  outletSubscription: Subscription;
  isMobile: boolean = false;
  isMobileSubscriber: Subscription;
  timeZone: string;
  orgId: number;

  @ViewChild('outletDetail', { static: true }) outletDetailModal: TemplateRef<any>;
  groupableSettings: GroupableSettings;

  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private loggedInUserService: LoggedInUserService, private modalService: BsModalService, private outletService: OutletService, private commonService: CommonService, private translate: TranslateService) {
    this.allData = this.allData.bind(this);
    this.isMobileSubscriber = this.commonService.getIsMobile().subscribe((res: boolean) => {
      this.isMobile = res;
      this.bindColumns();
    });
  }

  bindColumns() {
    this.columns = [
      {
        field: "shipTo",
        title: "ID",
        width: 120,
        groupable: false,
        locked: true
      },
      {
        field: "name",
        title: "Outlet Name",
        width: 300,
        groupable: false,
        locked: !this.isMobile
      },
      {
        field: "city",
        title: "City",
        width: 200,
        groupable: true,
        cellFormat: data => data.city || '-'
      },
      {
        field: "latitude",
        title: "Latitude",
        width: 200,
        groupable: true,
        cellFormat: data => data.latitude || '-'
      },
      {
        field: "longitude",
        title: "Longitude",
        width: 200,
        groupable: true,
        cellFormat: data => data.longitude || '-'
      },
      {
        field: "cycleNumber",
        title: "Cycle Number",
        width: 200,
        groupable: true,
        cellFormat: data => data.cycleNumber || '-'
      },
      {
        field: "organisationName",
        title: "Organisation",
        width: 200,
        groupable: true,
        hidden: this.role !== Role[1]
      },
      {
        field: "groupName",
        title: "Group",
        width: 200,
        groupable: true,
      },
      {
        field: "regionName",
        title: "Region",
        width: 200,
        cellTextCenter: false,
        groupable: true,
      },
      {
        field: "territoryName",
        title: "Territory",
        width: 300,
        cellTextCenter: false,
        groupable: false,
      },
      {
        field: "outlet_admin",
        title: "Outlet Admin",
        width: 120,
        cellTextCenter: false,
        groupable: true,
      },
      {
        field: "createdAt",
        title: "Created On",
        width: 120,
        cellTextCenter: true,
        groupable: false,
        cellFormat: data => getFormatedDate(data.createdAt, constants.dateTimeFormat) || '-'
      },
      {
        field: "createdBy",
        title: "Created By",
        width: 230,
        cellTextCenter: false,
        groupable: false
      },
      {
        field: "updatedAt",
        title: "Updated On",
        width: 130,
        cellTextCenter: true,
        groupable: false,
        cellFormat: data => getFormatedDate(data.updatedAt, constants.dateTimeFormat) || '-'
      },
      {
        field: "updatedBy",
        title: "Updated By",
        width: 230,
        cellTextCenter: false,
        groupable: false,
        cellFormat: (data) => data.updatedBy || "-"
      },
      {
        field: "status",
        title: "Status",
        width: 100,
        cellTextCenter: true,
        hidden: true
      },
    ];
  }

  ngOnInit() {

    this.timeZone = this.loggedInUserService.getUserData('timezone');

    this.translate.onLangChange.subscribe((res) => {
      this.translationChanges();
    });

    this.role = this.loggedInUserService.getUserData('role');
    this.orgId = this.loggedInUserService.getUserData('organisationId');
    this.bindColumns();
    this.getOutlet();
    this.outletSubscription = this.route.queryParams.subscribe(
      (params) => {
        if (Object.keys(params).length !== 0) {
          this.router.navigate(['app/outlets'], { replaceUrl: true });
          this.selectedOutlet = {
            organisationId: params.orgId, groupId: params.grpId, regionId: params.rgnId,
            territoryId: +params.territoryId
          };
          this.openModal(this.outletDetailModal);
          this.commonService.setLoader(false);
        }
      }
    );
  }

  getOutlet() {
    const params = {
      organisationId: this.role === Role[2] ? this.loggedInUserService.getUserData('organisationId') : 0,
      groupId: 0,
      regionId: 0,
      territoryId: 0
    }
    this.outletService.getOutletsList(params).subscribe(res => {
      res.data.forEach(outlet => {
        outlet.latitude = outlet.latitude.toString().match(/^-?\d+(?:\.\d{0,6})?/)[0];
        outlet.longitude = outlet.longitude.toString().match(/^-?\d+(?:\.\d{0,6})?/)[0];
      })
      
      this.commonService.setLoader(false);
      if (res.statusCode === 200 && res.data.length > 0) {

        convertTimeZone(res.data, ['createdAt', 'updatedAt'], this.timeZone);
        if (this.role !== "superAdmin") {
          this.outletList = res.data.filter((curr) => {
            return curr.organisationId === this.orgId;
          });
        } else {
          this.outletList = res.data;
        }
        this.outletList.forEach(outlet => {
          outlet.status = outlet.archived ? 'Inactive' : 'Active';
        });
        this.loadItems();
      }
    });
  }

  // REALTED to grid Functionality paging , sorting , groupping
  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadItems();
  }

  loadItems() {
    const searchedRecords = new SearchDataPipe().transform(
      this.outletList,
      this.searchKeyword,
      this.searchableColumns
    );
    this.gridData = process(searchedRecords, this.state);
  }

  // for search
  filterSearch() {
    this.loadItems();
    this.state.skip = 0;
  }

  addOutlet(template: TemplateRef<any>) {
    this.outletIsEdit = false;
    this.selectedOutlet = new OutletModel();
    this.openModal(template);
  }
  // Modal popup functionalities 

  private openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      ignoreBackdropClick: true
    });
  }


  // Close Modal popup
  public closeModalPopup() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  // Final Submit
  public submitModalPopup(template: TemplateRef<any>) {
    this.getOutlet();
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }



  // edit outlet
  public editOutlet(outlet, template: TemplateRef<any>) {
    this.outletIsEdit = true;
    this.selectedOutlet = Object.assign({}, outlet);
    this.openModal(template);
  }


  // confirmation for archive
  public askForConfirmation(outlet, template: TemplateRef<any>) {
    this.selectedOutlet = outlet;
    this.confirmModalRef = this.modalService.show(template, {
      class: "modal-confirm modal-dialog-centered second",
      ignoreBackdropClick: true
    });
  }

  public dismissActiveInactiveConfirmModal() {
    this.confirmModalRef.hide();
    this.confirmModalRef = null;
  }

  public finallyCloseStatusConfirmModal() {
    this.archivedOutlet();
    this.dismissActiveInactiveConfirmModal();
  }

  // archive/change status
  private archivedOutlet() {
    const bodyParams = {
      id: this.selectedOutlet.id,
      archived: !this.selectedOutlet.archived,
      updatedBy: this.loggedInUserService.getUserData('id')
    };

    this.outletService.archiveOutlet(bodyParams).subscribe(
      (res) => {
        if (res) {
          if (res.statusCode === 200 && res.data > 0) {
            this.toastr.success(MESSAGE.ARCHIVED_DONE_SUCCESSFULLY, "");
            this.getOutlet();
            this.commonService.setLoader(false);
          } else if (res.statusCode === 404 && res.data === -3) {
            this.commonService.setLoader(false);
            this.toastr.error(MESSAGE.UNABLE_TO_DEACTIVATE, "", { timeOut: 10000 });
          }
        }
      },
      (error) => {
      }
    );
  }

  // export all data
  allData() {
    return {
      data: this.outletList || [],
      group: []
    }
  }

  ngOnDestroy(): void {
    this.isMobileSubscriber.unsubscribe();
  }

  translationChanges() {
    this.translate.get('Drag a column header and drop it here to group by that column').subscribe((res) => {
      this.groupableSettings = { enabled: true, emptyText: res, showFooter: false };
    });
  }

}
