import { AccountComponent } from './account/account.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthCodeComponent } from "./auth/auth-code/auth-code.component";
import { LoginComponent } from "./auth/login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AuthService } from "./services/auth.service";
import { CleanParamsComponent } from "./clean-params/clean-params.component";
import { ManageCleanParamsComponent } from "./clean-params/manage-clean-params/manage-clean-params.component";
import { LayoutComponent } from "./layout/layout.component";
import { OrganizationDetailComponent } from "./organization-detail/organization-detail.component";
import { OrganizationComponent } from "./organization-detail/organization/organization.component";
import { GroupsComponent } from "./organization-detail/groups/groups.component";
import { RegionsComponent } from './organization-detail/regions/regions.component';
import { DeviceComponent } from './device/device.component';
import { AreasComponent } from './organization-detail/areas/areas.component';
import { ErrorPageComponent } from './shared/components/error-page.component';
import { OutletComponent } from './outlet/outlet.component';
import { TerritoriesComponent } from './organization-detail/territories/territories.component';
import { WebAccountsComponent } from './account/web-accounts/web-accounts.component';
import { MobileUsersComponent } from './account/mobile-users/mobile-users.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { ReasonsComponent } from './reasons/reasons.component';
import { ManualReasonsComponent } from './reasons/manual-reasons/manual-reasons.component';
import { RefusalReasonsComponent } from './reasons/refusal-reasons/refusal-reasons.component';
import { VisitComponent } from './visit/visit.component';
import { HistoryComponent } from './history/history.component';
import { CleansComponent } from './cleans/cleans.component';
import { CleanDetailsComponent } from './cleans/clean-details/clean-details.component';
import { DashboardDetailComponent } from './dashboard/dashboard-detail/dashboard-detail.component';
import { CleanGenComponent } from './clean-gen/clean-gen.component';
import { ManageCleanGenComponent } from './clean-gen/manage-clean-gen/manage-clean-gen.component';
import { SyncLogsComponent } from './sync-logs/sync-logs.component';
import { AssetTypeComponent } from './asset-type/asset-type.component';
import { UploadFileOfflineComponent } from './upload-file/upload-file-offline/upload-file-offline.component';
import { EmailConfigurationComponent } from './email-configuration/email-configuration.component';
import { VortexCleanParamsComponent } from './clean-params/vortex/vortex-clean-params/vortex-clean-params.component';
import { VerxCleanParamsComponent } from './clean-params/verx/verx-clean-params/verx-clean-params.component';
import { ToggleDeviceComponent } from './device/toggleDevice/toggle-device/toggle-device.component';
import { PlcsCleanParamsComponent } from './clean-params/plcs/plcs-clean-params/plcs-clean-params.component';
import { TabLayoutComponent } from './layout/tab-layout/tab-layout.component';
import { MultipleDefaultSettingComponent } from './device/multiple-default-setting/multiple-default-setting.component';
import { ManageDefaultCleanParamsComponent } from './device/multiple-default-setting/manage-default-clean-params/manage-default-clean-params.component';
import { VortexiCleanParamsComponent } from './clean-params/vertexi/vortexi-clean-params/vortexi-clean-params.component';
import { VerxLiteCleanParamsComponent } from './clean-params/verx-lite/verx-lite-clean-params/verx-lite-clean-params.component';
import { VortexnCleanParamsComponent } from './clean-params/vortexn-clean-params/vortexn-clean-params.component';




const routes: Routes = [
  {
    path: "",
    component: LoginComponent
  },
  {
    path: "login",
    component: LoginComponent,
    data: { roles: ['superAdmin', 'organisationAdmin', 'webUser', 'superOrgUser', 'orgSuperAdmin', 'serviceUser'] }
  },
  {
    path: "auth",
    component: AuthCodeComponent,
    canActivate: [AuthService],
    data: { roles: ['superAdmin', 'organisationAdmin', 'webUser', 'superOrgUser', 'orgSuperAdmin', 'serviceUser'] }

  },
  {
    path: "app",
    component: LayoutComponent,
    canActivate: [AuthService],
    data: { roles: ['superAdmin', 'organisationAdmin', 'webUser', 'superOrgUser', 'orgSuperAdmin', 'serviceUser'] },
    children: [
      {
        path: "",
        component: DashboardComponent,
        // canActivate: [AuthService],
        data: { title: "dashboard", roles: ['superAdmin', 'organisationAdmin', 'webUser', 'superOrgUser', 'orgSuperAdmin', 'serviceUser'] }
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        // canActivate: [AuthService],
        data: { title: "Dashboard", roles: ['superAdmin', 'organisationAdmin', 'webUser', 'superOrgUser', 'orgSuperAdmin', 'serviceUser'] }
      },
      {
        path: "dashboard/detail",
        component: DashboardDetailComponent,
        // canActivate: [AuthService],
        data: { title: "Report", roles: ['superAdmin', 'organisationAdmin', 'webUser', 'superOrgUser', 'orgSuperAdmin', 'serviceUser'] }
      },
      {
        path: 'assetType',
        component: AssetTypeComponent,
        canActivate: [AuthService],
        data: { title: 'Asset Type', roles: ['superAdmin', 'superOrgUser', 'orgSuperAdmin'] }
      },
      {
        path: "organisationDetail",
        component: OrganizationDetailComponent,
        canActivate: [AuthService],
        data: { title: "Organisation Details", roles: ['superAdmin', 'organisationAdmin', 'superOrgUser', 'orgSuperAdmin'] },
        children: [
          {
            path: "organisations",
            component: OrganizationComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'orgSuperAdmin'] }
          },
          {
            path: "groups",
            component: GroupsComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'organisationAdmin', 'superOrgUser', 'orgSuperAdmin'] }
          },
          {
            path: "regions",
            component: RegionsComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'organisationAdmin', 'superOrgUser', 'orgSuperAdmin'] }
          },
          {
            path: "areas",
            component: AreasComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'organisationAdmin', 'superOrgUser', 'orgSuperAdmin'] }
          },
          {
            path: "territories",
            component: TerritoriesComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'organisationAdmin', 'superOrgUser', 'orgSuperAdmin'] }
          }
        ]
      },
      {
        path: "users",
        component: AccountComponent,
        canActivate: [AuthService],
        data: { title: "Manage Accounts/ Users", roles: ['superAdmin', 'organisationAdmin', 'superOrgUser', 'orgSuperAdmin'] },
        children: [
          {
            path: "web-accounts",
            component: WebAccountsComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'organisationAdmin', 'superOrgUser', 'orgSuperAdmin'] }
          },
          {
            path: "mobile-users",
            component: MobileUsersComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'organisationAdmin', 'superOrgUser', 'orgSuperAdmin'] }
          }
        ]
      },
      {
        path: "reasons",
        component: ReasonsComponent,
        canActivate: [AuthService],
        data: { title: "Reasons", roles: ['superAdmin', 'superOrgUser', 'orgSuperAdmin'] },
        children: [
          {
            path: "manual",
            component: ManualReasonsComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'orgSuperAdmin'] }
          },
          {
            path: "refusal",
            component: RefusalReasonsComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'orgSuperAdmin'] }
          }
        ]
      },
      {
        path: "vortex",
        component: VortexCleanParamsComponent,
        canActivate: [AuthService],
        data: { title: "Vortex BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "cleanparams",
            component: CleanParamsComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          }
        ]

      },
      {
        path: "plcs",
        component: PlcsCleanParamsComponent,
        canActivate: [AuthService],
        data: { title: "PLCS BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "cleanparams",
            component: CleanParamsComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          }
        ]

      },
      {
        path: "verx",
        component: VerxCleanParamsComponent,
        canActivate: [AuthService],
        data: { title: "Verx BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "cleanparams",
            component: CleanParamsComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          }
        ]

      },
      {
        path: "verx-lite",
        component: VerxLiteCleanParamsComponent,
        canActivate: [AuthService],
        data: { title: "Verx Lite BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "cleanparams",
            component: CleanParamsComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          }
        ]

      },
      {
        path: "vortexi",
        component: VortexiCleanParamsComponent,
        canActivate: [AuthService],
        data: { title: "VortexI BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "cleanparams",
            component: CleanParamsComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          }
        ]

      },
      {
        path: "vortexn",
        component: VortexnCleanParamsComponent,
        canActivate: [AuthService],
        data: { title: "VortexN BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "cleanparams",
            component: CleanParamsComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          }
        ]

      },
      {
        path: "cleanParamsGen1",
        component: CleanGenComponent,
        canActivate: [AuthService],
        data: { title: "Clean Parameters Gen 1", roles: ['superAdmin'] }
      },
      {
        path: "cleanparams/manage",
        component: ManageCleanParamsComponent,
        canActivate: [AuthService],
        data: { title: 'Manage Clean Params', roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
      },
      {
        path: "defaultcleanparams/manage",
        component: ManageDefaultCleanParamsComponent,
        canActivate: [AuthService],
        data: { title: 'Manage Default Clean Params', roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
      },
      {
        path: "cleanParamsGen1/view",
        component: ManageCleanGenComponent,
        canActivate: [AuthService],
        data: { title: 'Manage Gen1 Clean Params', roles: ['superAdmin'] }
      },
      {
        path: 'emailConfiguration',
        component: EmailConfigurationComponent,
        canActivate: [AuthService],
        data: { title: 'Email Configuration', roles: ['superAdmin', 'superOrgUser', 'orgSuperAdmin', 'organisationAdmin'] }
      },
      {
        path: "vortex",
        component: ToggleDeviceComponent,
        canActivate: [AuthService],
        data: { title: "Vortex BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "manageDevices",
            component: DeviceComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          },
          {
            path: "multipleDefaultSetting",
            component: MultipleDefaultSettingComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'], device: 'vortex' },
          }
        ]

      },
      {
        path: "plcs",
        component: ToggleDeviceComponent,
        canActivate: [AuthService],
        data: { title: "PLCS BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "manageDevices",
            component: DeviceComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          },
          {
            path: "multipleDefaultSetting",
            component: MultipleDefaultSettingComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'], device: 'PLCS' },
          }
        ]

      },
      {
        path: "verx",
        component: ToggleDeviceComponent,
        canActivate: [AuthService],
        data: { title: "Verx BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "manageDevices",
            component: DeviceComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          },
          {
            path: "multipleDefaultSetting",
            component: MultipleDefaultSettingComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'], device: 'verx' },
          }
        ]

      },
      {
        path: "verx-lite",
        component: ToggleDeviceComponent,
        canActivate: [AuthService],
        data: { title: "Verx Lite BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "manageDevices",
            component: DeviceComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          },
          {
            path: "multipleDefaultSetting",
            component: MultipleDefaultSettingComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'], device: 'verx-lite' },
          }
        ]

      },
      {
        path: "vortexi",
        component: ToggleDeviceComponent,
        canActivate: [AuthService],
        data: { title: "VortexI BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "manageDevices",
            component: DeviceComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          },
          {
            path: "multipleDefaultSetting",
            component: MultipleDefaultSettingComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'], device: 'vortexi' },
          }
        ]
      },
      {
        path: "vortexn",
        component: ToggleDeviceComponent,
        canActivate: [AuthService],
        data: { title: "VortexN BT", roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
        children: [
          {
            path: "manageDevices",
            component: DeviceComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'] },
          },
          {
            path: "multipleDefaultSetting",
            component: MultipleDefaultSettingComponent,
            canActivate: [AuthService],
            data: { roles: ['superAdmin', 'superOrgUser', 'serviceUser'], device: 'vortexn' },
          }
        ]
      },
      {
        path: "outlets",
        component: OutletComponent,
        canActivate: [AuthService],
        data: { title: "Outlets", roles: ['superAdmin', 'organisationAdmin', 'orgSuperAdmin', 'superOrgUser'] }
      },
      {
        path: "visits",
        component: VisitComponent,
        canActivate: [AuthService],
        data: { title: "Session", roles: ['superAdmin', 'organisationAdmin', 'webUser', 'superOrgUser', 'orgSuperAdmin', 'serviceUser'] }
      },
      {
        path: "cleans",
        component: CleansComponent,
        canActivate: [AuthService],
        data: { title: "Cleans", roles: ['superAdmin', 'organisationAdmin', 'webUser', 'superOrgUser', 'orgSuperAdmin', 'serviceUser'] }
      },
      {
        path: "cleans/detail",
        component: CleanDetailsComponent,
        canActivate: [AuthService],
        data: { title: "Clean Details", roles: ['superAdmin', 'organisationAdmin', 'webUser', 'superOrgUser', 'orgSuperAdmin', 'serviceUser'] }
      },
      {
        path: "history",
        component: HistoryComponent,
        canActivate: [AuthService],
        data: { title: "History", roles: ['superAdmin', 'organisationAdmin', 'webUser', 'superOrgUser', 'orgSuperAdmin', 'serviceUser'] }
      },
      // Commented for Veify Clean
      {
        path: "uploadFile",
        component: UploadFileComponent,
        canActivate: [AuthService],
        data: { title: "Upload Real Time", roles: ['superAdmin', 'organisationAdmin'] }
      },
      // Updated the title to Upload File and path to uploadFile for VerifyClean
      {
        path: 'uploadBackground',
        component: UploadFileOfflineComponent,
        canActivate: [AuthService],
        data: { title: 'Upload Background', roles: ['superAdmin', 'superOrgUser', 'orgSuperAdmin'] }
      },
      {
        path: "syncLogs",
        component: SyncLogsComponent,
        canActivate: [AuthService],
        data: { title: "Sync Logs", roles: ['superAdmin', 'superOrgUser', 'orgSuperAdmin', 'organisationAdmin'] }
      }
    ]
  },
  {
    path: "**",
    component: ErrorPageComponent,
    canActivate: [AuthService],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
