import { Component, OnInit, TemplateRef } from "@angular/core";
import { PageChangeEvent, DataStateChangeEvent } from "@progress/kendo-angular-grid";
import {
    SortDescriptor,
    orderBy,
    State,
    process
} from "@progress/kendo-data-query";
import { DeviceService } from "../services/device.service";
import { CommonService } from "../services/common.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { MESSAGE } from "../shared/messages";
import { Device } from '../models/deviceRequest.model';
import { IKendoColumnModel } from '../models/kendoColumn.model';
import { SearchDataPipe } from '../pipes/search-data.pipe';
import { Router } from '@angular/router';
import { Role } from 'src/app/models/roles.enum';
import { LoggedInUserService } from "../services/loggedInUser.service";
@Component({
    selector: 'app-device',
    templateUrl: './device.component.html',
})
export class DeviceComponent implements OnInit {
    public gridData: any = [];
    role: string;
    readonly Role = Role;
    deviceList = [];
    selectedDevice: Device;
    deviceIsEdit: boolean = false
    searchableColumns = ["serialNumber"];
    serialNumberPrifix: string;
    currentDevice: string;
    columns: IKendoColumnModel[] = [
        {
            field: "serialNumber",
            title: "Serial Number",
            width: 500,
        },
        {
            field: "deviceOrganizationName",
            title: "Organisation",
            width: 300
        }
    ];
    searchKeyword: string;

    public state: State = {
        skip: 0,
        take: 50
        // group: [{ field: 'userId' }]
    };

    gridOptions = {
        filterable: false,
        // groupable: true,
        sortable: true,
        reorderable: true,
        resizable: true,
        pageable: {
            refresh: true,
            pageSizes: true,
            buttonCount: 5
        }
    };

    // Modal Popup
    modalRef: BsModalRef;
    confirmModalRef: BsModalRef;
    orgId: any;


    constructor(
        private modalService: BsModalService,
        private deviceService: DeviceService,
        private commonService: CommonService,
        private toastr: ToastrService,
        private router: Router,
        private loggedInUserService: LoggedInUserService
    ) {
        this.getDevices(this.currentDevice);
    }

    ngOnInit() {
        this.currentDevice = this.router.url.split('/')[2];
        this.role = this.loggedInUserService.getUserData('role');
        this.orgId = this.loggedInUserService.getUserData('organisationId');
        this.gridData = [];
        this.getDevices(this.currentDevice);
    }

    public addDevice(template: TemplateRef<any>) {
        this.selectedDevice = { id: 0, serialNumber: '', uuid: '', serialNumberPrifix: this.serialNumberPrifix };
        this.deviceIsEdit = false;
        this.openModal(template);
    }

    private openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered',
            ignoreBackdropClick: true
        });
    }

    // Cnacel the first add modal &  ask for confirmation
    public cancelModal(event, template: TemplateRef<any>) {
        this.modalRef.hide();
        this.selectedDevice = event.device;
        this.deviceIsEdit = event.edit;
        if (!this.deviceIsEdit) {
            this.confirmModalRef = this.modalService.show(template, {
                class: 'modal-confirm modal-dialog-centered second',
                ignoreBackdropClick: true
            });
        }
    }

    // Close first add/edit Modal popup
    public closeModalPopup() {
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // Final Submit After first Modal i.e SAVE API_CALL
    public submitModalPopup(template: TemplateRef<any>) {


        this.getDevices(this.currentDevice);
        if (!this.modalRef) {
            this.modalRef.hide();
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }
    submitDeviceChange() {

        this.getDevices(this.currentDevice);
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // Reject Cancel confirm Modal &  open add/edit modal again
    public dismissConfirmModal(template) {
        if (!this.confirmModalRef) {
            return;
        }
        this.confirmModalRef.hide();
        if (this.modalRef) {
            this.modalRef = this.modalService.show(template, {
                class: 'modal-dialog-centered',
                ignoreBackdropClick: true
            });
        }
    }

    // Finally confirm cancel modal
    public finallyCloseModal() {
        this.confirmModalRef.hide();
    }

    public editDevice(device, template: TemplateRef<any>) {
        const newDevice = { serialNumberPrifix: this.serialNumberPrifix, ...device }

        this.deviceIsEdit = true;
        this.selectedDevice = Object.assign({}, newDevice);
        this.selectedDevice.serialNumber = this.selectedDevice.serialNumber.split('-')[1]
        this.openModal(template);
    }

    public askForConfirmation(device, template: TemplateRef<any>) {

        this.selectedDevice = device;
        this.confirmModalRef = this.modalService.show(template, {
            class: 'modal-confirm modal-dialog-centered second',
            ignoreBackdropClick: true
        });
    }

    public dismissActiveInactiveConfirmModal() {
        this.confirmModalRef.hide();
        this.confirmModalRef = null;
    }

    public finallyCloseStatusConfirmModal(device) {
        this.archivedDevice(device);
        this.dismissActiveInactiveConfirmModal();
    }
    public archivedDevice(device) {

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.dismissActiveInactiveConfirmModal();
            this.router.navigate(["/"]);
            return;
        }

        const params = {
            id: device.id,
            archived: !device.archived,
            updatedBy: JSON.parse(localStorage.getItem("userData")).id
        };

        this.deviceService.archiveDevice(params).subscribe(res => {
            if (res) {

                if (res.statusCode === 200 && res.data > 0) {
                    this.toastr.success(MESSAGE.ARCHIVED_DONE_SUCCESSFULLY, "");
                    this.getDevices(this.currentDevice);
                } else if (res.statusCode === 404 && res.data === -3) {
                    this.commonService.setLoader(false);
                    this.toastr.error(MESSAGE.UNABLE_TO_DEACTIVATE, "", { timeOut: 10000 });
                }
            }
        });
    }

    // REALTED to grid Functionality paging , sorting 
    public pageChange(event: PageChangeEvent): void {
        this.state.skip = event.skip;
        this.loadItems();
    }

    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.loadItems();
    }

    private loadItems() {
        const searchedRecords = new SearchDataPipe().transform(
            this.deviceList,
            this.searchKeyword,
            this.searchableColumns
        );
        this.gridData = process(searchedRecords, this.state);
    }

    public filterSearch() {
        this.loadItems();
        this.state.skip = 0;
    }

    // Organization List API_CALL
    private getDevices(device): void {
        switch (device) {
            case 'vortex': {
                this.getDeviceByType({ deviceTypeId: 1, deviceOrganizationId: this.orgId });
                this.serialNumberPrifix = '005'
                break;
            }
            case 'verx': {
                this.getDeviceByType({ deviceTypeId: 2, deviceOrganizationId: this.orgId });
                this.serialNumberPrifix = '007'
                break;
            }
            case 'plcs': {
                this.getDeviceByType({ deviceTypeId: 3, deviceOrganizationId: this.orgId });
                this.serialNumberPrifix = '008'
                break;
            }
            case 'vortexi': {
                this.getDeviceByType({ deviceTypeId: 4, deviceOrganizationId: this.orgId });
                this.serialNumberPrifix = '009'
                break;
            }
            case 'verx-lite': {
                this.getDeviceByType({ deviceTypeId: 5, deviceOrganizationId: this.orgId });
                this.serialNumberPrifix = '010'
                break;
            }
            case 'vortexn': {
                this.getDeviceByType({ deviceTypeId: 6, deviceOrganizationId: this.orgId });
                this.serialNumberPrifix = '011'
                break;
            }
        }
    }
    private getDeviceByType(deviceTypeId): void {
        this.deviceService.getDeviceList(deviceTypeId).subscribe(res => {
            this.commonService.setLoader(false);
            if (res && res.statusCode === 200 && res.data) {
                // this.gridData = process(this.data, this.state);
                this.deviceList = res.data;
                this.loadItems();
            }
        });
    }

}
