import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CleanParamsComponent } from './clean-params.component';
import { LayoutModule } from '../layout/layout.module';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { CleanParamsService } from '../services/clean-params.service';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation'
import { RouterModule } from '@angular/router';
import { ManageCleanParamsComponent } from './manage-clean-params/manage-clean-params.component';
import { VortexCleanParamsComponent } from './vortex/vortex-clean-params/vortex-clean-params.component';
import { VerxCleanParamsComponent } from './verx/verx-clean-params/verx-clean-params.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PlcsCleanParamsComponent } from './plcs/plcs-clean-params/plcs-clean-params.component';
import { TranslateModule } from '@ngx-translate/core';
import { DefaultCleanParamsPopupComponent } from './default-clean-params-popup/default-clean-params-popup.component';
import { VortexiCleanParamsComponent } from './vertexi/vortexi-clean-params/vortexi-clean-params.component';
import { VerxLiteCleanParamsComponent } from './verx-lite/verx-lite-clean-params/verx-lite-clean-params.component';
import { VortexnCleanParamsComponent } from './vortexn-clean-params/vortexn-clean-params.component';
// import {TooltipModule} from "ngx-tooltip";


@NgModule({
  declarations: [CleanParamsComponent, ManageCleanParamsComponent, VortexCleanParamsComponent, VerxCleanParamsComponent, PlcsCleanParamsComponent, DefaultCleanParamsPopupComponent, VortexiCleanParamsComponent, VerxLiteCleanParamsComponent, VortexnCleanParamsComponent],
  imports: [
    CommonModule,
    LayoutModule,
    FormsModule,
    CustomFormsModule,
    GridModule,
    InputsModule,
    SharedModule,
    RouterModule,
    ExcelModule,
    ExcelExportModule,
    TranslateModule
  ],
  providers: [CleanParamsService]
})
export class CleanParamsModule { }
