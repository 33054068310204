import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { CleansComponent } from './cleans.component';
import {NgxMaskModule, IConfig} from 'ngx-mask'
import { FormsModule } from '@angular/forms';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { VisitService } from '../services/visit.service';
import { CleanDetailsComponent } from './clean-details/clean-details.component';
import { ManageCleanComponent } from './manage-clean/manage-clean.component';
import 'hammerjs';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CleansComponent, CleanDetailsComponent, ManageCleanComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    GridModule,
    SharedModule,
    ExcelModule,
    ExcelExportModule,
    NgxMaskModule.forRoot(),
    TranslateModule
   // DateInputsModule
  ],
  providers: [VisitService],
})
export class CleansModule { }
