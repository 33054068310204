import { StepSettings } from './stepSetting.model';

export class CleanParam {
  deviceId: number | [];
  uuid: null;
  serialNumber: null;
  runSettingId: null;
  id: number;
  processType: number;
  numberOfSteps: number;
  flowmeterCalibration: number;
  flowrateAverage: number;
  conductivityAverage: number;
  temperatureAverage: number;
  errorClearVol: number;
  errorDetectVol: number;
  tempMaxWater: number;
  tempMinWater: number;
  flowrateScalingledsTarget: number;
  flowrateScalingledsMax: number;
  flowrateScalingledsMin: number;
  r0: number;
  r1: number;
  s0: number;
  s1: number;
  maxLineVolume: number;
  minLineVolume: number;
  maxLineNumber: number;
  minLineNumber: number;
  stepSettings: StepSettings[];
  constructor() {}
}

export class Devices {
  deviceId: number;
}

export class DefaultCleanParams {
  userId: number;
  deviceCleanTypeId:number;
  devices: Devices[];
  selectedOrg?: string;
}
