export const constants = {
    passwordRegex : /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!£@#$%^&*])[a-zA-Z0-9!£@#$%^&*]{8,50}$/,
    emailRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
    dateFormatGraph: 'dd MMM yyyy',
    dateFormat: 'dd/MM/yyyy', // kendo 
    dateTimeFormat: 'dd MMM yyyy, HH:mm:ss',
    dateWithoutTimeFormat: 'dd MMM yyyy',
    kendogridDateFormat: '{0:dd MMM yyyy, HH:mm:ss}',
    longDateFormat: 'EEEE, MMM d, y',
    success: 200,
    failed: 500,
    error: 404,
    duplicateEntry: -1,
    invalid: -1,
    reordNotExist: -2,
    unableToPerform: -3,
    savingFailed: 0,
    fiveMB: 5242880,
    sessionTimeOut:1800000,
    numberWithPlusSign:/^[0-9 ()+]+$/,
    onlyNumbers: /^\d+$/
   //sessionTimeOut:180000000
}