import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

import { AuthService } from 'src/app/services/auth.service';
import { LoginComponent } from './login/login.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    DialogModule,
    SharedModule,
    TranslateModule
  ],
  providers: [AuthService],
})
export class AuthModule { }
