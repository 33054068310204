import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { DialogModule } from "@progress/kendo-angular-dialog";
import { ChartsModule } from "@progress/kendo-angular-charts";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";

import { CommonModule } from "@angular/common";
import { BsModalService, ModalModule } from "ngx-bootstrap/modal";

import { ToastrModule } from "ngx-toastr";

import { CommonService } from "../services/common.service";
import { SearchDataPipe } from "../pipes/search-data.pipe";
import { OnlyNumber } from "./directives/only-number.directive";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { LoaderComponent } from "./components/loader/loader.component";
import { ConfirmationPopupComponent } from "./components/confirmation-popup/confirmation-popup.component";
import { ErrorPageComponent } from "./components/error-page.component";
import { AllowOneDecimalPoint } from "./directives/allow-one-decimal-point.directive";
import { OneDigitDecimaNumberDirective } from "./directives/one-digit-decima-number.directive";
import { SharedService } from "../services/shared.service";
import { ChangePasswordComponent } from "../auth/change-password/change-password.component";
import { FormsModule } from "@angular/forms";
import { AutofocusDirective } from "./directives/auto-focus.directive";
import { AuthCodeComponent } from '../auth/auth-code/auth-code.component';
import { ToNumberPipe } from '../pipes/toNumber.pipe';
import { DatePickerModule, DateTimePickerModule } from '@progress/kendo-angular-dateinputs';
import { TranslateModule } from "@ngx-translate/core";
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
@NgModule({
  declarations: [
    AuthCodeComponent,
    ChangePasswordComponent,
    LoaderComponent,
    ConfirmationPopupComponent,
    ErrorPageComponent,
    SearchDataPipe,
    ToNumberPipe,
    OnlyNumber,
    AllowOneDecimalPoint,
    OneDigitDecimaNumberDirective,
    AutofocusDirective,
    CookieConsentComponent
  ],
  imports: [

    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot(), // ToastrModule added
    FormsModule,
    CommonModule,
    ChartsModule,
    DialogModule,
    DropDownsModule,
    DatePickerModule,
    DateTimePickerModule,
    TranslateModule
  ],
  providers: [CommonService, BsModalService, SharedService],
  exports: [
    AuthCodeComponent,
    ChangePasswordComponent,
    DropDownsModule,
    TooltipModule,
    LoaderComponent,
    ConfirmationPopupComponent,
    ErrorPageComponent,
    SearchDataPipe,
    ToNumberPipe,
    OnlyNumber,
    AllowOneDecimalPoint,
    OneDigitDecimaNumberDirective,
    AutofocusDirective,
    DatePickerModule,
    DateTimePickerModule,
    TranslateModule,
    CookieConsentComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
