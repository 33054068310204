import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { SharedModule } from '../shared/shared.module';
import { SyncLogsComponent } from './sync-logs.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SyncLogsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    SharedModule,
    ExcelModule,
    TranslateModule
  ]
})
export class SyncLogsModule { }
