import { AreasService } from "src/app/services/areas.service";
import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { Saveareasparams, AreaModel } from "src/app/models/areas.model";
import { CommonService } from "src/app/services/common.service";
import { ToastrService } from "ngx-toastr";
import { LoggedInUserService } from "src/app/services/loggedInUser.service";
import { OrganizationModel } from "src/app/models/organization.model";
import { GroupOrgId, GroupModel } from "src/app/models/groups.model";
import { MESSAGE } from "src/app/shared/messages";
import { Role } from "src/app/models/roles.enum";
import { SharedService } from 'src/app/services/shared.service';
import { RegionModel } from 'src/app/models/regions.model';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: "app-manage-areas",
    templateUrl: "./manage-areas.component.html"
})
export class ManageAreasComponent implements OnInit {
    readonly MESSAGE = MESSAGE;
    @Output() close = new EventEmitter();
    submitError: string;
    @Input() areaDetail: AreaModel;
    role: string;
    area: AreaModel;
    grpList: GroupModel[] = [];
    regionList: RegionModel[] = [];
    orgList: OrganizationModel[] = [];
    groupOrgId: GroupOrgId = {
        organisationId: this.loggedInUserService.getUserData("organisationId")
    };
    @Output() submit = new EventEmitter();
    @Input() isEdit: boolean = false;
    regionId: number;
    roleEnum = Role;
    organization: {
        orgId: number;
        orgName: string;
    };

    constructor(
        private toastr: ToastrService,
        private loggedInUserService: LoggedInUserService,
        private sharedService: SharedService,
        private commonService: CommonService,
        private areasServices: AreasService,
        private router: Router
    ) { }

    ngOnInit() {
        this.role = this.loggedInUserService.getUserData("role");
        if (this.areaDetail) {
            this.area = this.areaDetail;
        }

        if ([Role[2],Role[4],Role[5]].includes(this.role)) {
            // organizationAdmin, superOrgUser, orgSuperAdmin
            this.organization = {
                orgId: this.loggedInUserService.getUserData("organisationId"),
                orgName: this.loggedInUserService.getUserData("organisationName")
            };
            this.area.organisationId = this.organization.orgId;
            this.fetchData();
        } else {
            // superAdmin
            this.sharedService.getOrgList().then((res: OrganizationModel[]) => {
                this.orgList = res;
            });
            this.fetchData();
        }

    }

    private fetchData() {
        if (!this.isEdit && this.area.organisationId > 0 && this.area.groupId > 0 && this.area.regionId > 0) {
            //from previous table add
            this.fetchMasterData();
        } else if (!this.isEdit) {
            // from page Add button
            if (!this.organization) {
                this.area.organisationId = null;
            }
            this.area.groupId = null;
            this.area.regionId = null;
            if (this.area.organisationId) {
                this.sharedService.getGrpList(this.organization.orgId).then((res: GroupModel[]) => {
                    this.grpList = res;
                });
            }
        } else {
            // edit
            this.fetchMasterData();
        }
        this.commonService.setLoader(false);
    }

    private fetchMasterData() {
        this.sharedService
            .getGrpList(this.area.organisationId)
            .then((res: GroupModel[]) => {
                this.grpList = res;
            });
        this.sharedService
            .getRegionList(this.area.organisationId, this.area.groupId)
            .then((res: RegionModel[]) => {
                this.regionList = res;
            });
    }




    changedSelection(fieldId: number) {
        if (fieldId === 1) {
            this.area.regionId = null;
            this.sharedService.getGrpList(this.area.organisationId).then((res: GroupModel[]) => {
                this.area.groupId = null;
                this.grpList = res;
            });
        } else if (fieldId === 2) {
            this.sharedService
                .getRegionList(this.area.organisationId, this.area.groupId)
                .then((res: RegionModel[]) => {
                    this.area.regionId = null;
                    this.regionList = res;
                });
        }
    }

    saveArea(event, form: NgForm) {
        event.stopPropagation();
        if (this.area.name.trim() === '') {
            form.controls['AreaName'].setErrors({ required: true });
            return
        }
        if (form.form.invalid) {
            return;
        }

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.close.emit();
            this.router.navigate(["/"]);
            return;
        }

        const params: Saveareasparams = {
            id: this.areaDetail.id,
            name: this.areaDetail.name.trim(),
            organisationId:
                this.role !== Role[1]
                    ? +this.loggedInUserService.getUserData("organisationId")
                    : +this.areaDetail.organisationId,
            regionId: +this.areaDetail.regionId,
            userId: JSON.parse(localStorage.getItem("userData")).id
        };

        this.areasServices.saveArea(params).subscribe(res => {
            if (res) {
                if (res.statusCode === 200 && res.data > 0) {
                    this.submit.emit();
                    this.isEdit ? this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
                } else if (res.statusCode === 404) {
                    this.commonService.setLoader(false);
                    if (res.data === -1) {
                        this.submitError = MESSAGE.RECORD_ALREADY_EXIST;
                    } else if (res.data === 0) {
                        this.toastr.error(MESSAGE.RECORD_SAVING_FAILED)
                    }
                }
            }
        });
    }

    dismissModal() {
        this.close.emit();
    }
}
