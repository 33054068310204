import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AssetTypeModel } from 'src/app/models/assetType.model';
import { OrganizationModel } from 'src/app/models/organization.model';
import { Role } from 'src/app/models/roles.enum';
import { SharedService } from 'src/app/services/shared.service';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { CommonService } from 'src/app/services/common.service';
import { AssetTypeService } from 'src/app/services/asset-type.service';
import { MESSAGE } from 'src/app/shared/messages';
import { Router } from '@angular/router';

@Component({
    selector: 'app-manage-asset-type',
    templateUrl: './manage-asset-type.component.html'
})

export class ManageAssetTypeComponent implements OnInit {
    @Input() isEdit = false;
    @Input() assetTypeDetail: AssetTypeModel;
    @Output() close = new EventEmitter();
    @Output() submit = new EventEmitter();
    submitError: string;
    asset: AssetTypeModel;
    orgList: OrganizationModel[];
    fromParent;
    assetsubscription: Subscription;
    role: string;
    roleEnum = Role;
    organization: {
        orgId: number;
        orgName: string;
    };

    constructor(
        private sharedService: SharedService,
        private loggedInUserService: LoggedInUserService,
        private commonService: CommonService,
        private assetTypeService: AssetTypeService,
        private toastr: ToastrService,
        private router: Router
    ) { }

    ngOnInit() {
        if (this.assetTypeDetail) {
            this.asset = this.assetTypeDetail;
        }
        this.role = this.loggedInUserService.getUserData('role');

        if ([Role[4], Role[5]].includes(this.role)) {
            this.organization = {
                orgId: this.loggedInUserService.getUserData('organisationId'),
                orgName: this.loggedInUserService.getUserData('organisationName')
            };
            this.asset.organisationId = this.organization.orgId;
        } else {
            //superAdmin
            if (!this.isEdit && !this.asset.organisationId) {
                this.asset.organisationId = null;
            }
            this.getOrgList();
        }
        this.commonService.setLoader(false);
    }

    private getOrgList() {
        this.sharedService.getOrgList()
            .then(
                (resData: OrganizationModel[]) => {
                    this.orgList = resData;
                }
            );
    }

    saveAsset(event, form: NgForm) {
        event.stopPropagation();
        if (this.asset.name.trim() === '') {
            form.controls['AssetTypeName'].setErrors({ required: true });
            return
        }
        if (form.form.invalid) {
            return;
        }
        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.close.emit();
            this.router.navigate(["/"]);
            return;
        }
        const params = {
            id: this.asset.id,
            name: this.asset.name.trim(),
            organisationId: this.role !== Role[1] ? this.loggedInUserService.getUserData('organisationId') :
                Number(this.asset.organisationId),
            userId: JSON.parse(localStorage.getItem('userData')).id
        };

        this.assetTypeService.saveAssetType(params).subscribe(
            (res) => {
                if (res) {
                    if (res.statusCode === 200 && res.data > 0) {
                        this.submit.emit();
                        this.isEdit ? this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
                    } else if (res.statusCode === 404) {
                        this.commonService.setLoader(false);
                        if (res.data === -1) {
                            this.submitError = MESSAGE.RECORD_ALREADY_EXIST;
                        } else if (res.data === 0) {
                            this.toastr.error(MESSAGE.RECORD_SAVING_FAILED);
                        }
                    }
                }
            }
        );

    }

    dismissModal() {
        this.close.emit();
    }
}
