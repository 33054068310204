import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../layout/layout.module';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { EmailConfigurationComponent } from './email-configuration.component';
import { EmailConfigurationService } from '../services/email-configuration.service';
import { ManageEmailConfigurationComponent } from './manage-email-configuration/manage-email-configuration.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [EmailConfigurationComponent, ManageEmailConfigurationComponent],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        RouterModule,
        LayoutModule,
        GridModule,
        ExcelModule,
        DialogModule,
        TranslateModule
    ],
    providers: [EmailConfigurationService],
})

export class EmailConfigurationModule { }
