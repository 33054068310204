import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';
import { Bodyparamarea, Saveareasparams, ArchiveArea } from '../models/areas.model';


@Injectable()
export class AreasService {
  constructor(private restAPI: RestApi) { }

    // get list of ares
    getAreasList(bodyParams: Bodyparamarea) {
      const url = RestApi.buildUrl(`${ENDPOINTS.AREAS.GET_AREAS}`);
      return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
    }

    // edit or add area
  saveArea(bodyParams: Saveareasparams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.AREAS.SAVE_AREA}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // archive group
  archiveArea(bodyParams: ArchiveArea) {
    const url = RestApi.buildUrl(`${ENDPOINTS.AREAS.ARCHIVE_AREA}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }


}
