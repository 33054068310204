import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-page',
  template: `<div>404 error</div> `,
})
export class ErrorPageComponent implements OnInit {

  constructor() {}
    ngOnInit() {
  }

}
