import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrganizationService } from 'src/app/services/organization.service';
import { MESSAGE } from 'src/app/shared/messages';
import { CommonService } from 'src/app/services/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OrganizationModel } from 'src/app/models/organization.model';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { ActionResponseModel } from 'src/app/models/reponse.model';
import { constants } from 'src/app/shared/directives/constants';
import { CleanTypes } from 'src/app/models/clean.model';

@Component({
  selector: 'app-manage-organization',
  templateUrl: './manage-organization.component.html',
})
export class ManageOrganizationComponent implements OnInit {
  @Output() submit = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() close = new EventEmitter();
  @Input() organizationDetail: OrganizationModel;
  @Input() isEdit: boolean ;
  organization: OrganizationModel;
  logoUpdated : boolean = false;
  submitError: string;
  imageExist : boolean =  false;
  allCleanTypesList: CleanTypes[] | any;

  constructor(private toastr:ToastrService, private orgService: OrganizationService, private loggedInUserService: LoggedInUserService, private commonService: CommonService, private modalService: BsModalService) {
    
  }

  ngOnInit() {
    this.allCleanTypesList = this.modalService.config.initialState;
    this.allCleanTypesList = this.allCleanTypesList.filter((item) => item.deviceTypeId);

    this.logoUpdated = false;
    this.imageExist = false;
    if (this.organizationDetail) {
      
      this.imageExist = this.organizationDetail.logo ? true : false;
      this.logoUpdated = this.organizationDetail.logo ? true : false;
      this.organization = this.organizationDetail;
      
      if(this.isEdit) {
        const cleanTypes = [];

        this.organizationDetail.organisations_Type_Of_Clean_Responses.forEach((element) => {
          const obj = {} as CleanTypes;
          obj.index = element.type_of_clean_id;
          obj.typeOfCleanId = element.type_of_clean_id;
          obj.name = element.clean_type;
          cleanTypes.push(obj);
        });
        
        this.organization.selectedCleanTypes = cleanTypes;
        
      } else {
        this.organization.selectedCleanTypes = [];
      }
      
    }
  }


  convertFileToByteArray(file, form: NgForm) {
    if(!file.type.match(/(image\/)/)){
      this.organization.logo = null;
      this.logoUpdated = false;
      this.imageExist = false;
      form.controls['organizationLogo'].setErrors({invalidFile: 'File with type ' + file.type + ' is invalid'});
      return;
    }
    if(file.size > constants.fiveMB) {
      this.organization.logo = null;
      this.logoUpdated = false;
      this.imageExist = false;
      form.controls['organizationLogo'].setErrors({invalidFile: 'File size is greater than 5MB'});
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result;
      this.logoUpdated = true;
      this.imageExist = false;
      this.organization.logo = base64data.toString().split(',')[1];
    };
    reader.onerror = err => {
    };
  }

  saveOrganization(event, form: NgForm) {
    event.stopPropagation();
    if(this.organization.name.trim() === '') {
      form.controls['OrganizationName'].setErrors({required: true});
      return 
    }
    if(form.form.invalid) {
      return;
    }
    const params = {
      id: this.organization.id,
      name: this.organization.name.trim(),
      logo: !this.imageExist ? this.organization.logo : undefined,
      userId: this.loggedInUserService.getUserData('id'),
      cleanTypes: this.organization.selectedCleanTypes.map((i) => i.typeOfCleanId)
    }
    
    if (!params.logo) {
      delete params.logo;
    }

    this.orgService.saveOrganization(params).subscribe((res: ActionResponseModel) => {
      if(res) {
      if (res.statusCode === constants.success && res.data > 0) {
        this.submit.emit();
        this.isEdit ? this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
      } else if (res.statusCode === constants.error) {
        this.commonService.setLoader(false);
        if (res.data === constants.duplicateEntry) {
          this.submitError = MESSAGE.ORG_NAME_EXIST;
        } else if (res.data === constants.reordNotExist) {
          this.submitError = MESSAGE.RECORD_DOES_NOT_EXIST;
          this.close.emit();
        } else if( res.data === constants.savingFailed ) {
          this.toastr.error(MESSAGE.RECORD_SAVING_FAILED)
        }
      }
    }
    });
  }

  cancelOrganization() {
    if( this.organization.name === '' && this.organization.logo === '') {
      this.dismissModal();
      return;
    }
    this.cancel.emit({ edit: this.isEdit, organization: this.organization });

  }

  dismissModal() {
    this.close.emit();
  }

  checkRegex(string) {
    return new RegExp(/^(https?):/g).test(string);
  }

  toggleSelectAll() {
    if(this.organization.selectedCleanTypes.length === this.allCleanTypesList.length) {
      this.organization.selectedCleanTypes = [];
    } else {
      this.organization.selectedCleanTypes = this.allCleanTypesList.slice();
    }
}

isItemSelectedByIndex(selectedArray, id) {
  return selectedArray.some(item => item.index === id);
}

}
