import { Component, OnInit, SimpleChanges, TemplateRef } from "@angular/core";
import {
    Router,
    RoutesRecognized,
    ActivatedRoute
} from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { Role } from 'src/app/models/roles.enum';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { changePasswordModel } from 'src/app/models/changePassword.model';
import { MESSAGE } from 'src/app/shared/messages';
import { ToastrService } from 'ngx-toastr';
import { WebAccountService } from 'src/app/services/web-account.service';
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { Subscription } from 'rxjs';

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
    lang;
    userame;
    pageTitle;
    orgName: string;
    role: string;
    modalRef: BsModalRef;
    changePasswordError: string
    langArray=[{id:'en',text:'English'},{id:'fr',text:'French'},{id:'de',text:'German'},{id:'es',text:'Spanish'}, {id:'it',text:'Italian'}];
    consentSub: Subscription;
    sub: Subscription;
    cookieConsentStatusText: string;
    currentConsentStatus: boolean;
    cookieConsentStatusId: number;
    rejectedCookieOnce: boolean;
    showConsent = false;
    defaultLanguage: string;

    constructor(
        private toastr: ToastrService,
        private webAccService: WebAccountService,
        private router: Router,
        private commonService: CommonService,
        private loginUserService: LoggedInUserService,
        private activatedRoute: ActivatedRoute,
        private modalService: BsModalService,
        private translateService: TranslateService,
        private http: HttpClient
    ) {

        this.commonService.browserRefresh$.subscribe(res => {
            if (res) {
                this.getTitle();
            }
        });
        this.role = this.loginUserService.getUserData('role');
        this.defaultLanguage = this.loginUserService.getUserData('default_language') || localStorage.getItem('lang') || 'en';
        

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.router.navigate(["/"]);
            return;
        }

        if (localStorage.getItem("userData")) {
            this.userame = this.loginUserService.getUserData("name");
            this.orgName = this.role !== Role[1] ? this.loginUserService.getUserData("organisationName") : null;
        }
    }
    ngOnInit() {
        this.changeLang(this.defaultLanguage);

        this.getCookieConsent();

        //this.lang=localStorage.getItem('lang') || 'en';

        this.getTitle();
        this.getCookieConsentStatus();

    }

    getCookieConsentStatus() {
        this.consentSub = this.commonService.currentCookieConsentValObs.subscribe((res) => {
            
            const accepted = Boolean(res.termsAgree);
            
            this.cookieConsentStatusId = res.id || 0;
            this.currentConsentStatus = res.termsAgree || false;

            this.showConsent = res.termsAgree ? !res.termsAgree : true;
            this.rejectedCookieOnce = Boolean(JSON.parse(localStorage.getItem('rejectedCookieOnce')));
            
            if(accepted) {
                this.cookieConsentStatusText = 'You have accepted cookie consent already. Would you like to reject it now?'
            } else {
                this.cookieConsentStatusText = 'You have not accepted cookie consent yet. Would you like to accept it now?'
            }
        });
    }

    getCookieConsent() {
        const payload = {
            useId: this.loginUserService.getUserData('id')
        }
        if(payload.useId) {
            this.loginUserService.getCookieConsentStatus(payload).subscribe((res) => {
                this.commonService.setLoader(false);
                const rejectedOnce = !!JSON.parse(localStorage.getItem('rejectedCookieOnce'));
                if(res && res.statusCode === 200) {

                    this.commonService.setCurrentCookieConsentVal({termsAgree:res.data.termsAgree, id: res.data.id});

                    if((res.data.termsAgree === false)) {
                        if(rejectedOnce === false) {
                            this.showConsent = true;
                        }
                    }
                    if((res.data.termsAgree === true)) {
                        this.showConsent = false;
                        localStorage.removeItem('rejectedCookieOnce');
                    }
                } else if(res && res.statusCode === 404) {
                    this.showConsent = true;
                    localStorage.setItem('rejectedCookieOnce', 'false');
                    this.rejectedCookieOnce = Boolean(JSON.parse(localStorage.getItem('rejectedCookieOnce')));
                    //Case for when termsAgree is not set ever.
                    //this.commonService.setCurrentCookieConsentVal({termsAgree: false, id: null}); 
                }
                
            });
        }
    }

    getTitle() {
        this.pageTitle = this.activatedRoute.snapshot.firstChild.data.title;
        this.router.events.subscribe(data => {
            if (data instanceof RoutesRecognized) {
                this.pageTitle = data.state.root.firstChild.children.length > 0 && data.state.root.firstChild.children[0].data.title;
            }
        });
    }

    doLogout() {
        localStorage.clear();
        this.loginUserService.setUserData(null);
        this.router.navigate(["/"]);
        return;
    }

    openPopup(template: TemplateRef<any>, centered?) {
        this.changePasswordError = '';
        if (centered) {
            this.modalRef = this.modalService.show(template, { class: 'modal-dialog modal-dialog-centered', ignoreBackdropClick: true });
        } else {
            this.modalRef = this.modalService.show(template, { class: 'modal-dialog', ignoreBackdropClick: true });
        }
    }

    changePassword(event) {
        const userId = this.loginUserService.getUserData('id');
        const params: changePasswordModel = {
            id: userId,
            password: event.password,
            oldPassword: event.oldPassword,
            updatedBy: userId
        }
        this.webAccService.changeAccountPassword(params).subscribe(res => {
            this.commonService.setLoader(false);
            if (res && res.statusCode === 200) {
                if (res.data > 0)
                    this.toastr.success(MESSAGE.PASSWORD_CHANGED_SUCCESSFULLY)
                this.closeModalPopup();
            } else if (res && res.statusCode === 404) {
                if (res.data === -1) {
                    this.changePasswordError = '';
                    this.changePasswordError = res.message;
                } else if (res.data === -3) {
                    this.changePasswordError = '';
                    this.changePasswordError = MESSAGE.NEW_PASSWORD_IS_SAME_AS_OLD;
                }
            }
        });
    }

    public closeModalPopup() {
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    changeStatus() {
        const payload = {
            useId: this.loginUserService.getUserData('id'),
            termsAgree: !this.currentConsentStatus,
        } as any;
        if(this.cookieConsentStatusId) {
            payload.id = this.cookieConsentStatusId
        };

        this.loginUserService.setCookieConsentStatus(payload).subscribe((res) => {
            this.commonService.setLoader(false);
            this.closeModalPopup();
            if(res && res.statusCode === 200) {

            if(!this.currentConsentStatus) {
                localStorage.setItem('rejectedCookieOnce', 'true');
                
            }

            }
        });

        this.setCookieIdAndStatus();
    }

    setCookieIdAndStatus() {
        const useId = this.loginUserService.getUserData('id');
        this.loginUserService.getCookieConsentStatus({useId}).subscribe((res) => {
            this.commonService.setLoader(false);
            this.commonService.setCurrentCookieConsentVal({termsAgree: res.data.termsAgree, id: res.data.id});
        });
    }

    changeLang(lang){
        if(lang !== this.defaultLanguage){
            window.location.reload();
        }
        localStorage.setItem('lang',lang);
        this.translateService.setDefaultLang(lang);
        this.translateService.use(lang);
        this.lang=lang;
    }

    ngOnDestroy(): void {
        if(this.consentSub) {
            this.consentSub.unsubscribe();
        }
        if(this.sub) {
            this.sub.unsubscribe();
        }
    }
}
