import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TerritoryModel } from "src/app/models/territories.model";
import { OrganizationModel } from "src/app/models/organization.model";
import { LoggedInUserService } from "src/app/services/loggedInUser.service";
import { Role } from "src/app/models/roles.enum";
import { SharedService } from "src/app/services/shared.service";
import { CommonService } from "src/app/services/common.service";
import { TerritoriesService } from "src/app/services/territories.service";
import { ToastrService } from "ngx-toastr";
import { MESSAGE } from "src/app/shared/messages";
import { GroupModel } from 'src/app/models/groups.model';
import { RegionModel } from 'src/app/models/regions.model';
import { AreaModel } from 'src/app/models/areas.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: "app-manage-territories",
  templateUrl: "./manage-territories.component.html"
})
export class ManageTerritoriesComponent implements OnInit {
  readonly MESSAGE = MESSAGE;
  @Input() isEdit: boolean = false;
  @Input() territoryDetail: TerritoryModel;
  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter();
  submitError: string;
  territory: TerritoryModel;
  orgList: OrganizationModel[] =[];
  grpList: GroupModel[] = [];
  rgnList: RegionModel[] = [];
  areaList: AreaModel[] =[];
  role: string;
  organization: {
    orgId: number;
    orgName: string;
  };
  roleEnum = Role;
  constructor(
    private toastr: ToastrService,
    private loggedInUserService: LoggedInUserService,
    private sharedService: SharedService,
    private commonService: CommonService,
    private territoryService: TerritoriesService
  ) {}

  ngOnInit() {
    this.role = this.loggedInUserService.getUserData("role");
    if (this.territoryDetail) {
      this.territory = this.territoryDetail;
    } else {
      this.territory = new TerritoryModel();
    }

    if ([Role[2],Role[4],Role[5]].includes(this.role)) {
      // organizationAdmin, superOrgUser, orgSuperAdmin
      this.organization = {
        orgId: this.loggedInUserService.getUserData("organisationId"),
        orgName: this.loggedInUserService.getUserData("organisationName")
      };
      this.territory.organisationId = this.organization.orgId;
      this.fetchData();
     } else {
      // superAdmin
      this.sharedService.getOrgList().then((res: OrganizationModel[]) => {
        this.orgList = res;
      });
      this.fetchData();
    }
  }

  private fetchData() {
    if (!this.isEdit && this.territory.organisationId > 0 && this.territory.groupId > 0  && this.territory.regionId > 0  && this.territory.areaId > 0 ) {
      //from previous table add
      this.fetchMasterData();
    } else if (!this.isEdit) {
      // from page Add button
      if (!this.organization) {
        this.territory.organisationId = null;
      }
      this.territory.groupId = null;
      this.territory.regionId = null;
      this.territory.areaId = null;
      if(this.territory.organisationId) {
        this.sharedService.getGrpList(this.organization.orgId).then((res: GroupModel[]) => {
          this.grpList = res;
        });
      }
    } else {
      // edit
      this.fetchMasterData();
    }
    this.commonService.setLoader(false);
  }

  private fetchMasterData() {
    this.sharedService.getGrpList(this.territory.organisationId).then((res: GroupModel[]) => {
      this.grpList = res;
    });
    this.sharedService
    .getRegionList(this.territory.organisationId, this.territory.groupId)
    .then((res: RegionModel[]) => {
      this.rgnList = res;
    });
  this.sharedService
    .getAreaList(
      this.territory.organisationId,
      this.territory.groupId,
      this.territory.regionId
    )
    .then((res: AreaModel[]) => {
      this.areaList = res;
    });
  }

  changedSelection(fieldId: number) {
    if (fieldId === 1) {
      this.territory.regionId = null;
      this.territory.areaId = null;
      this.sharedService.getGrpList(this.territory.organisationId).then((res: GroupModel[]) => {
        this.territory.groupId = null;
        this.grpList = res;
      });
    } else if (fieldId === 2) {
      this.territory.areaId = null;
      this.sharedService
        .getRegionList(this.territory.organisationId, this.territory.groupId)
        .then((res: RegionModel[]) => {
          this.territory.regionId = null;
          this.rgnList = res;
        });
    } else if (fieldId === 3) {
      this.sharedService
        .getAreaList(
          this.territory.organisationId,
          this.territory.groupId,
          this.territory.regionId
        )
        .then((res: AreaModel[]) => {
          this.territory.areaId = null;
          this.areaList = res;
        });
    }
  }

  saveTerritory(event,form: NgForm) {
    event.stopPropagation();
    if(this.territory.name.trim() === '') {
      form.controls['TerritoryName'].setErrors({required: true});
      return 
    }
    if(form.form.invalid) {
      return;
    }
    const params = {
      id: this.isEdit ? this.territory.id : 0,
      name: this.territory.name.trim(),
      organisationId:
        this.role !== Role[1]
          ? this.organization.orgId
          : +this.territory.organisationId,
      areaId: +this.territory.areaId,
      userId: this.loggedInUserService.getUserData("id")
    };

    this.territoryService.saveTerritory(params).subscribe(res => {
      if(res) {
      if (res.statusCode === 200 && res.data > 0) {
        this.submit.emit();
        this.isEdit ? this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
      } else if (res.statusCode === 404) {
        this.commonService.setLoader(false);
        if (res.data === -1) {
          this.submitError = MESSAGE.RECORD_ALREADY_EXIST;
        }else if( res.data === 0) {
          this.toastr.error(MESSAGE.RECORD_SAVING_FAILED)
        }
      }
    }
    });
  }

  dismissModal() {
    this.close.emit();
  }
}
