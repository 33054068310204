import {
    Component,
    OnInit,
    TemplateRef,
} from "@angular/core";
import { DataStateChangeEvent } from "@progress/kendo-angular-grid";
import { SortDescriptor, process, State } from "@progress/kendo-data-query";
import { OrganizationService } from "../../services/organization.service";
import { CommonService } from "../../services/common.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { OrganizationModel } from "../../models/organization.model";
import { ToastrService } from "ngx-toastr";
import { MESSAGE } from "../../shared/messages";
import { Router } from '@angular/router';
import { SearchDataPipe } from "src/app/pipes/search-data.pipe";
import { getFormatedDate } from 'src/app/shared/handler.functions';
import { IKendoColumnModel } from 'src/app/models/kendoColumn.model';
import { ListResponseModel, ActionResponseModel } from 'src/app/models/reponse.model';
import { constants } from 'src/app/shared/directives/constants';
import { Subscription } from 'rxjs';
import { convertTimeZone } from './../../shared/handler.functions';
import { LoggedInUserService } from "src/app/services/loggedInUser.service";
import { Role } from "src/app/models/roles.enum";

@Component({
    selector: "app-organization",
    templateUrl: "./organization.component.html"
})
export class OrganizationComponent implements OnInit {
    gridData: any = [];
    organizationList: OrganizationModel[] = [];
    selectedOrganization: OrganizationModel;
    orgIsEdit: boolean = false;
    selectedOrgId: number;
    hasPermission = false;

    public multiple = false;
    public allowUnsort = true;
    public sort: SortDescriptor[] = [
        {
            field: "",
            dir: "asc"
        }
    ];

    searchableColumns = ["name"];

    columns: IKendoColumnModel[] = [];
    searchKeyword: string;
    timeZone: string;

    public state: State = {
        skip: 0,
        take: 50
        // group: [{ field: 'userId' }]
    };

    gridOptions = {
        filterable: false,
        // groupable: true,
        sortable: true,
        reorderable: true,
        resizable: true,
        pageable: {
            refresh: true,
            pageSizes: true,
            buttonCount: 5
        }
    };

    // Modal Popup
    modalRef: BsModalRef;
    confirmModalRef: BsModalRef;
    isMobile: boolean = false;
    isMobileSubscriber: Subscription;
    cleanTypesList: any;
    roleId: number;

    constructor(
        private modalService: BsModalService,
        private orgService: OrganizationService,
        private commonService: CommonService,
        private toastr: ToastrService,
        private router: Router,
        private loggedInUserService: LoggedInUserService
    ) {
        this.isMobileSubscriber = this.commonService.getIsMobile().subscribe((res: boolean) => {
            this.isMobile = res;
            this.bindColumns();
        });
    }

    bindColumns() {
        this.columns = [
            {
                field: "name",
                title: "Name",
                width: 300,
                locked: !this.isMobile
            },
            {
                field: "createdAt",
                title: "Created On",
                width: 120,
                cellTextCenter: true,
                cellFormat: data => getFormatedDate(data.createdAt, constants.dateTimeFormat) || '-'
            },
            {
                field: "createdByName",
                title: "Created By",
                width: 230,
            },
            {
                field: "updatedAt",
                title: "Updated On",
                width: 130,
                cellTextCenter: true,
                cellFormat: data => getFormatedDate(data.updatedAt, constants.dateTimeFormat) || '-'
            },
            {
                field: "updatedByName",
                title: "Updated By",
                width: 230,
                cellFormat: data => data.updatedByName || "-"
            }
        ];
    }
    ngOnInit() {
        this.timeZone = this.loggedInUserService.getUserData('timezone');
        this.roleId = +Role[this.loggedInUserService.getUserData('role')];
        if(this.roleId === 1) {
            this.hasPermission = true;
        }
        this.gridData = [];
        this.bindColumns();
        this.getOrganizations();
        this.getAllCleanTypes();
    }

    public addOrganization(template: TemplateRef<any>) {
        this.selectedOrganization = { id: 0, name: ""};
        this.orgIsEdit = false;
        this.openModal(template);
    }

    private openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: "modal-dialog-centered",
            ignoreBackdropClick: true,
            initialState: this.cleanTypesList
        });
    }

    getAllCleanTypes() {
        const bodyParams = { type: "methodandtypeofclean" };
        this.orgService.getCleanTypes(bodyParams).subscribe((res) => {
          this.commonService.setLoader(false);
          this.cleanTypesList = res.data;
        });
      }

    // Cnacel the first add modal &  ask for confirmation
    public cancelModal(event, template: TemplateRef<any>) {
        this.modalRef.hide();
        this.selectedOrganization = event.organization;
        this.orgIsEdit = event.edit;
        if (!this.orgIsEdit) {
            this.confirmModalRef = this.modalService.show(template, {
                class: "modal-confirm modal-dialog-centered second",
                ignoreBackdropClick: true
            });
        }
    }

    // Close first add/edit Modal popup
    public closeModalPopup() {
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // Final Submit After first Modal i.e SAVE API_CALL
    public submitModalPopup(template: TemplateRef<any>) {
        this.getOrganizations();
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // Reject Cancel confirm Modal &  open add/edit modal again
    public dismissConfirmModal(template) {
        if (!this.confirmModalRef) {
            return;
        }
        this.confirmModalRef.hide();
        if (this.modalRef) {
            this.modalRef = this.modalService.show(template, {
                class: "modal-dialog-centered",
                ignoreBackdropClick: true
            });
        }
    }

    // Finally confirm cancel modal
    public finallyCloseModal() {
        this.confirmModalRef.hide();
    }

    public editOrganization(organization, template: TemplateRef<any>) {
        this.orgIsEdit = true;
        this.selectedOrganization = Object.assign({}, organization);
        this.openModal(template);
    }

    public askForConfirmation(organisation, template: TemplateRef<any>) {
        this.selectedOrganization = organisation;
        this.confirmModalRef = this.modalService.show(template, {
            class: "modal-confirm modal-dialog-centered second",
            ignoreBackdropClick: true
        });
    }

    public dismissActiveInactiveConfirmModal() {
        this.confirmModalRef.hide();
        this.confirmModalRef = null;
    }

    public finallyCloseStatusConfirmModal(organization) {
        this.archivedOganization(organization);
        this.dismissActiveInactiveConfirmModal();
    }

    public archivedOganization(organization) {

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.dismissActiveInactiveConfirmModal();
            this.router.navigate(["/"]);
            return;
        }

        const params = {
            id: organization.id,
            archived: !organization.archived,
            updatedBy: JSON.parse(localStorage.getItem("userData")).id
        };

        this.orgService.archiveOrganisation(params).subscribe((res: ActionResponseModel) => {
            if (res) {
                if (res.statusCode === constants.success && res.data > 0) {
                    this.toastr.success(MESSAGE.ARCHIVED_DONE_SUCCESSFULLY, "");
                    this.getOrganizations();
                } else if (res.statusCode === constants.error && res.data === constants.unableToPerform) {
                    this.commonService.setLoader(false);
                    this.toastr.error(MESSAGE.UNABLE_TO_DEACTIVATE_ORGANISATION, "", { timeOut: 10000 });
                }
            }
        });
    }

    // REALTED to grid Functionality paging , sorting ,
    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.loadItems();
    }

    private loadItems() {
        const searchedRecords = new SearchDataPipe().transform(
            this.organizationList,
            this.searchKeyword,
            this.searchableColumns
        );
        this.gridData = process(searchedRecords, this.state);
    }

    public filterSearch() {
        this.loadItems();
        this.state.skip = 0;
    }
    // Organization List API_CALL
    private getOrganizations(): void {
        this.orgService.getOrganizationList().subscribe((res: ListResponseModel) => {
            this.commonService.setLoader(false);
            if (res && res.statusCode === constants.success && res.data) {
                
                convertTimeZone(res.data, ['createdAt', 'updatedAt'], this.timeZone);
                this.organizationList = res.data;
                this.organizationList.forEach(el => {
                    el.logo = el.logo + '?' + new Date().getTime()
                })
                this.loadItems();
            }
        });
    }

    goToGroup(orgId) {
        this.router.navigate(['app/organisationDetail/groups'], { queryParams: { 'orgId': orgId }, skipLocationChange: true });
    }

    ngOnDestroy(): void {
        this.isMobileSubscriber.unsubscribe();
    }
}
