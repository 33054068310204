import { Archiveregion, RegionModel } from './../../models/regions.model';
import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from "@progress/kendo-data-query";

import { CommonService } from 'src/app/services/common.service';
import { RegionsService } from 'src/app/services/regions.service';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';

import { MESSAGE } from "../../shared/messages";
import { Bodyparamregion } from 'src/app/models/regions.model';
import { SearchDataPipe } from 'src/app/pipes/search-data.pipe';
import { Role } from 'src/app/models/roles.enum';
import { convertTimeZone, getFormatedDate } from 'src/app/shared/handler.functions';
import { IKendoColumnModel } from 'src/app/models/kendoColumn.model';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html'
})
export class RegionsComponent implements OnInit, OnDestroy {

  regionList : RegionModel[] = [];
  searchKeyword: string;
  selectedRegion;
  rgnIsEdit: boolean = false;
  modalRef: BsModalRef;
  public gridData: any = [];
  state: State = {
    skip: 0,
    take: 50
  };

  bodyParamRegion: Bodyparamregion = {
    "organisationId": this.loggedInUserService.getUserData('organisationId'),
    "groupId": 0
  };

  fromParent;
  rgnsubscription: Subscription;
  searchableColumns = ["name", "organisationName", "groupName"];
  columns: IKendoColumnModel[];

  role: string;
  confirmModalRef: BsModalRef;

  @ViewChild('regionDetail', { static: true }) regionDetailModal: TemplateRef<any>;
  timeZone: string;

  constructor(
    private modalService: BsModalService,
    private rgnService: RegionsService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute,
    private loggedInUserService: LoggedInUserService,
    private router: Router

  ) { }

  ngOnInit() {

    this.getRegions();

    this.timeZone = this.loggedInUserService.getUserData('timezone');

    this.rgnsubscription = this.activeRoute.queryParams.subscribe(
      (params) => {
        if (Object.keys(params).length !== 0) {
          this.router.navigate([], { replaceUrl: true});
          this.selectedRegion = { organisationId: params.orgId, groupId: params.grpId }
          this.openRegionModal(this.regionDetailModal);
          this.commonService.setLoader(false);
        }
      }
    );
    this.role = this.loggedInUserService.getUserData('role');

    this.columns = [
      {
        field: "name",
        title: "Name",
        width: 200,
        groupable: false,
        locked: true
      },
      {
        field: "organisationName",
        title: "Organisation",
        width: 200,
        display: this.role !== Role[1],
        groupable: true
      },
      {
        field: "groupName",
        title: "Group",
        width: 200,
        groupable: true
      },
      // {
      //   field: "value",
      //   title: "Value",
      //   width: 70,
      //   groupable: false
      // },
      {
        field: "createdAt",
        title: "Created On",
        width: 120,
        cellTextCenter: true,
        groupable: false,
        cellFormat: data => getFormatedDate(data.createdAt) || '-'
      },
      {
        field: "createdBy",
        title: "Created By",
        width: 230,
        groupable: false
      },
      {
        field: "updatedAt",
        title: "Updated On",
        width: 130,
        cellTextCenter: true,
        groupable: false,
        cellFormat: data => getFormatedDate(data.updatedAt) || '-'
      },
      {
        field: "updatedBy",
        title: "Updated By",
        width: 200,
        groupable: false,
        cellFormat: (data) => data.updatedBy || "-"
      },
    ];

  }

  // REALTED to grid Functionality paging , sorting , groupping
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadItems();
  }

  private loadItems() {
    const searchedRecords = new SearchDataPipe().transform(
      this.regionList,
      this.searchKeyword,
      this.searchableColumns
    );
    this.gridData = process(searchedRecords, this.state);
  }

  private openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
      ignoreBackdropClick: true
    });
  }

  // edit group
  public editGroup(group, template: TemplateRef<any>) {
    this.rgnIsEdit = true;
    this.selectedRegion = Object.assign({}, group);
    this.openModal(template);
  }

  // Close Modal popup
  public closeModalPopup() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  // Final Submit
  public submitModalPopup(template: TemplateRef<any>) {
    this.getRegions();
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  public openRegionModal(template: TemplateRef<any>) {
    this.rgnIsEdit = false;
    this.openModal(template);
  }

  // add new group
  public addRegion(template: TemplateRef<any>) {
    this.selectedRegion = { id: 0, userId: this.loggedInUserService.getUserData('id') };
    this.openRegionModal(template);
  }


  // confirmation for archive
  public askForConfirmation(region, template: TemplateRef<any>) {
    this.selectedRegion = region;
    this.confirmModalRef = this.modalService.show(template, {
      class: "modal-confirm modal-dialog-centered second",
      ignoreBackdropClick: true
    });
  }

  public dismissActiveInactiveConfirmModal() {
    this.confirmModalRef.hide();
    this.confirmModalRef = null;
  }

  public finallyCloseStatusConfirmModal(region) {
    this.archivedRegion(region);
    this.dismissActiveInactiveConfirmModal();
  }

  // archive/change status
  private archivedRegion(archiveRegion) {
    const bodyParams: Archiveregion = {
      id: archiveRegion.id,
      archived: !archiveRegion.archived,
      updatedBy: this.loggedInUserService.getUserData('id')
    };

    this.rgnService.archiveRegion(bodyParams).subscribe(
      (res) => {
        if(res){
        if (res.statusCode === 200 && res.data > 0) {
          this.toastr.success(MESSAGE.ARCHIVED_DONE_SUCCESSFULLY, "");
          this.getRegions();
          this.commonService.setLoader(false);
        }  else if (res.statusCode === 404) {
          this.commonService.setLoader(false);
          if (res.data === -3) {
            this.toastr.error(MESSAGE.UNABLE_TO_DEACTIVATE_REGION, "", { timeOut: 10000 });
          } else if (res.data === -4) {
            this.toastr.error(MESSAGE.UNABLE_TO_ACTIVATE_REGION, "", { timeOut: 10000 });
          }
        }
      }
      },
      (error) => {
      }
    );
  }

  // Get Region List
  private getRegions(): void {
    const bodyParams = this.bodyParamRegion;
    this.rgnService.getRegionsList(bodyParams).subscribe(
      (res) => {
        this.commonService.setLoader(false);
        if (res && res.statusCode === 200 && res.data) {
          
          convertTimeZone(res.data, ['createdAt', 'updatedAt'], this.timeZone);

          this.regionList = res.data;
          this.loadItems();
        }
        else {

        }
      },
      (error) => {

      }
    );
  }

  // edit region
  public editRegion(region, template: TemplateRef<any>) {
    this.rgnIsEdit = true;
    this.selectedRegion = Object.assign({}, region);
    this.openModal(template);
  }

  // for search
  public filterSearch() {
    this.loadItems();
    this.state.skip = 0;
  }

  goToArea(orgId, grpId, rgnId) {
    this.router.navigate(['app/organisationDetail/areas'], { queryParams: { 'orgId': orgId, 'grpId': grpId, 'rgnId': rgnId }, skipLocationChange: true });
  }

  ngOnDestroy(): void {
    this.rgnsubscription && this.rgnsubscription.unsubscribe();
  }

}
