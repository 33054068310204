import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {
    transform(value: string): any {
        if (value === '-') {
            return value ;
        }
        if (value === undefined || value === '' || value === null) {
            return null;
        }
        let retNumber = Number(value.toString().replace(/,/g, ''));
        return isNaN(retNumber) ? 0 : retNumber;
    }
}