import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { RegionsService } from "src/app/services/regions.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrService } from "ngx-toastr";
import { OrganizationModel } from "src/app/models/organization.model";
import { MESSAGE } from "src/app/shared/messages";
import { LoggedInUserService } from "src/app/services/loggedInUser.service";
import { Role } from "src/app/models/roles.enum";
import { SharedService } from "src/app/services/shared.service";
import { RegionModel } from 'src/app/models/regions.model';
import { GroupModel } from 'src/app/models/groups.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: "app-manage-regions",
  templateUrl: "./manage-regions.component.html"
})
export class ManageRegionsComponent implements OnInit {
  readonly MESSAGE = MESSAGE;
  @Input() isEdit: boolean = false;
  @Input() regionDetail: RegionModel;
  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter();
  submitError: string;
  region: RegionModel;
  orgList: OrganizationModel[] = [];
  grpList: GroupModel[] = [];
  role: string;
  organization: {
    orgId: number;
    orgName: string;
  };
  roleEnum = Role;
  constructor(
    private toastr: ToastrService,
    private loggedInUserService: LoggedInUserService,
    private sharedService: SharedService,
    private commonService: CommonService,
    private rgnService: RegionsService
  ) { }

  ngOnInit() {
    this.role = this.loggedInUserService.getUserData("role");
    if (this.regionDetail) {
      this.region = this.regionDetail;
    }
    
    if ([Role[2],Role[4],Role[5]].includes(this.role)) {
      // organizationAdmin, superOrgUser, orgSuperAdmin
      this.organization = {
        orgId: this.loggedInUserService.getUserData("organisationId"),
        orgName: this.loggedInUserService.getUserData("organisationName")
      };
      this.region.organisationId = this.organization.orgId;
      this.fetchData();
    } else {
      // superAdmin
      this.sharedService.getOrgList().then((res: OrganizationModel[]) => {
        this.orgList = res;
      });
     this.fetchData();
    }
  }

  private fetchData() {
      if (!this.isEdit && this.region.organisationId > 0 && this.region.groupId > 0) {
        //from previous table add
        this.fetchMasterData();
      } else if (!this.isEdit) {
      // from page Add button
      if (!this.organization) {
        this.region.organisationId = null;
      }
      this.region.groupId = null;
      if(this.region.organisationId) {
        this.sharedService.getGrpList(this.organization.orgId).then((res: GroupModel[]) => {
          this.grpList = res;
        });
      }
    } else {
      // edit
       this.fetchMasterData();
    }
    this.commonService.setLoader(false);
  }

  private fetchMasterData() {
    this.sharedService.getGrpList(this.region.organisationId).then((res: GroupModel[]) => {
      this.grpList = res;
    });
  }

  changedSelection() {
      this.sharedService.getGrpList(this.region.organisationId).then((res: GroupModel[]) => {
        this.region.groupId = null;
        this.grpList = res;
      });
  }

  saveRegion(event,form: NgForm) {
    event.stopPropagation();
    if(this.region.name.trim() === '') {
      form.controls['RegionName'].setErrors({required: true});
      return 
    }
    if(form.form.invalid) {
      return;
    }
    const params = {
      id: this.region.id,
      name: this.region.name.trim(),
      // value: this.region.value && this.region.value.trim() || null,
      organisationId:
        this.role !== Role[1]
          ? this.organization.orgId
          : +this.region.organisationId,
      groupId: +this.region.groupId,
      userId: this.loggedInUserService.getUserData("id")
    };

    this.rgnService.saveRegion(params).subscribe(res => {
      if(res) {
      if (res.statusCode === 200 && res.data > 0) {
        this.submit.emit();
        this.isEdit ? this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
      } else if (res.statusCode === 404) {
        this.commonService.setLoader(false);
        if (res.data === -1) {
          this.submitError = MESSAGE.RECORD_ALREADY_EXIST;
        } else if( res.data === 0) {
          this.toastr.error(MESSAGE.RECORD_SAVING_FAILED)
        }
      }
    }
    });
  }

  dismissModal() {
    this.close.emit();
  }
}
