export interface Getterbodyparam {
  organisationId: number;
  groupId:        number;
  regionId:       number;
  areaId:         number;
}

export interface Territoriesres {
  message:    string;
  statusCode: number;
  data:       TerritoryModel[];
}

export class TerritoryModel {
  id:               number;
  name:             string;
  organisationId:   number;
  archived:         boolean;
  organisationName: string;
  createdByName:    string;
  updatedByName:    null;
  regionId:         number;
  regionName:       string;
  groupId:          number;
  groupName:        string;
  areaId:           number;
  areaName:         string;
  constructor() {}
}

export interface Arcterbodyparam {
  id:        number;
  archived:  boolean;
  updatedBy: number;
}
