import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';

@Injectable()
export class EmailConfigurationService {
    constructor(private restAPI: RestApi) { }

    // get list of Email Templates
    getEmailRecipients(params) {
        const url = RestApi.buildUrl(`${ENDPOINTS.EMAIL_CONFIGURATION.GET_EMAIL_RECIPIENTS}`);
        return this.restAPI.handleRequest(url, METHODS.Post, params);
    }

    // get list of Asset Type
    getOrganizationAdmins(params) {
        const url = RestApi.buildUrl(`${ENDPOINTS.EMAIL_CONFIGURATION.GET_ORGANIZATION_ADMINS}`);
        return this.restAPI.handleRequest(url, METHODS.Post, params);
    }

    // Save/Edit email configuration
    saveEmailRecipient(params) {
        const url = RestApi.buildUrl(`${ENDPOINTS.EMAIL_CONFIGURATION.SAVE_EMAIL_RECIPIENTS}`);
        return this.restAPI.handleRequest(url, METHODS.Post, params);
    }
}
