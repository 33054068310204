import { Component, OnInit } from '@angular/core';
import { PageChangeEvent, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, State, process } from '@progress/kendo-data-query';
import { SearchDataPipe } from '../pipes/search-data.pipe';
import { CommonService } from '../services/common.service';
import { CleanParamsService } from '../services/clean-params.service';
import { IKendoColumnModel } from '../models/kendoColumn.model';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-clean-gen',
  templateUrl: './clean-gen.component.html',
  styleUrls: ['./clean-gen.component.css']
})
export class CleanGenComponent implements OnInit {

  cleanParamsList = [];
  gridData: any = [];
  columns: IKendoColumnModel[] = [
   {
      field: "unit",
      title: "Unit",
      width:130,
      locked: true
    }, {
      field: "cleanCal",
      title: "Clean",
      width:80

    }, {
      field: "recircCal",
      title: "Recirc",
      width:90
    }, {
      field: "doseRinse", title: "Rinse",
      width:80
    }, {
      field: "rinseMin", title: "Rinse Min.",
      width:80
    }, {
      field: "maxFlow", title: "Max. Flow",
      width:80
    }, {
      field: "maxLinesSlope", title: "Slope",
      width:80
    }, {
      field: "maxLinesConstant", title: "Constants",
      width:90
    }, {
      field: "minLines",
      title: "Min. Lines",
      width:80
    }, {
      field: "maxInputlines", title: "Max. Input Lines",
      width:90
    },
    {
      field: "r0", title: "RO"
    },
    {
      field: "r1", title: "R1"
    },
    {
      field: "r2", title: "R2"
    },
    {
      field: "r3", title: "R3"
    },
    {
      field: "r4", title: "R4"
    },
    {
      field: "r5", title: "R5"
    },
    {
      field: "s0", title: "S0"
    },
    {
      field: "s1", title: "S1"
    }, {
      field: "s2", title: "S2"
    },
    {
      field: "s3", title: "S3"
    }, {
      field: "s4", title: "S4"
    }, {
      field: "s5", title: "S5"
    }, {
      field: "s6", title: "S6"
    },
    {
      field: "s7", title: "S7"
    }];

  searchableColumns = this.columns.map(col => col.field);
  searchKeyword: string;
  public state: State = {
    skip: 0,
    take: 50
  };

  constructor(private commonService: CommonService, private service: CleanParamsService) {
    this.allData = this.allData.bind(this);
   }

  ngOnInit() {
    this.searchableColumns.push('userName')
    this.getAllCleanParams();
  }

  // REALTED to grid Functionality paging , sorting , grouping
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadItems();
  }

  private loadItems() {
    const searchedRecords = new SearchDataPipe().transform(
      this.cleanParamsList,
      this.searchKeyword,
      this.searchableColumns
    );
    this.gridData = process(searchedRecords, this.state);
  }

  public filterSearch() {
    this.loadItems();
    this.state.skip = 0;
  }

  getAllCleanParams() {
    this.service.getAllGen1CleanParams().subscribe(res => {
      this.commonService.setLoader(false);
      if (res && res.statusCode === 200 && res.data) {
        this.cleanParamsList = res.data;
        this.loadItems();
      }
    })
  }

  refreshGrid() {
    this.getAllCleanParams();
  }

   // export all data
   allData() {
    const result: ExcelExportData = {
      data: this.cleanParamsList,
      group: []
    };
    return result;
  }
}
