import { Injectable, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';

export interface Headers {
    headers: HttpHeaders;
}
@Injectable()
export class RequestHelperService {
    private localRefUpdated: Subject<boolean> = new Subject<boolean>();
    private localRef;
    httpHeaders: Headers;
    authHttpHeaders: Headers;
    constructor(private commonService: CommonService, private http: HttpClient, private router: Router) {
        this.localRefUpdated.subscribe(res => {
            if (res) {

                if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
                    this.router.navigate(["/"]);
                    return;
                }

                this.localRef = JSON.parse(localStorage.getItem('userData')) || null;
                if (this.localRef) {
                    this.authHttpHeaders = {
                        headers: new HttpHeaders({
                            'Content-Type': 'application/json',
                            Authorization: 'bearer ' + this.localRef.token || '',
                            userId: this.localRef.id
                        })
                    };
                }
                this.httpHeaders = {
                    headers: new HttpHeaders({
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, PUT, POST',
                        'Content-Type': 'application/json'
                    })
                };
            }
        });
    }

    toggleLocaRefUpdated(flag) {
        this.localRefUpdated.next(flag);
    }

    get(url, params?, dynamicHeaders?: object, isAuth = true) {
        const headers = isAuth ? this.authHttpHeaders : this.httpHeaders;
        return from(this.http.get(`${url}${params || ''}`, headers).pipe(
            map(res => res)).toPromise().catch(this.handleError));
    }

    post(url, params?, dynamicHeaders?: object, isAuth = true) {
        const headers = isAuth ? this.authHttpHeaders : this.httpHeaders;
        return from(this.http.post(url, params, headers).pipe(
            map(res => res)).toPromise().catch(this.handleError));
    }

    resetHeaders() {
        this.authHttpHeaders = null;
        this.httpHeaders = null;
    }

    handleError(error) {
        if (error && error.status === 401) {
            window.console.info('This is an error', error.headers);
            localStorage.clear();
            window.location.href = '/#/login';
            // const authLocation = error.response.headers['auth-location'];
            // if (typeof authLocation !== 'undefined' && authLocation !== null) {
            // 	return null;
            // }
        } else if (error && error.status === 301) {
            return null;
        } else if (error && error.status === 404) {
            if (error.data && error.data.message) {
                this.commonService.setLoader(false);
                return error.data;
            }
            return null;
        } else if (error && error.status === 500) {
            return null;
        } else if (error && error.status === 200) {
            const errorObj = {
                statusCode: 500
            }
            return errorObj;
        } else {
            return;
        }
    }
}
