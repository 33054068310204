import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CleanParam } from "src/app/models/cleanParam.model";
import { StepSettings } from "src/app/models/stepSetting.model";
import { CleanParamsService } from 'src/app/services/clean-params.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { MESSAGE } from 'src/app/shared/messages';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { DeviceType } from '../../models/deviceType.enum'
import { NgForm } from "@angular/forms";
@Component({
  selector: "app-manage-clean-params",
  templateUrl: "./manage-clean-params.component.html",
  styleUrls: ['./manage-clean-params.component.css']
})
export class ManageCleanParamsComponent implements OnInit {
  cleanParam: CleanParam;
  cleanParamDeviceId = [];
  selectedDeviceSerialNos = [];
  selectedDeviceType: number;
  deviceTypeEnum = DeviceType;
  defaultCleanParam: boolean = false;
  noOfSteps: number;
  currentDevice: string;
  stepSettingConfig: any[];
  submitError: string;
  // change for step Names
  stepNames: string[];
  constructor(private router: Router, private route: ActivatedRoute, private cleanParamService: CleanParamsService, private loggedInUserService: LoggedInUserService, private commonService: CommonService, private toastr: ToastrService) {
    this.route.queryParams.subscribe(queryparams => {
      this.cleanParamDeviceId = queryparams.deviceIds;
      this.selectedDeviceSerialNos = queryparams.serialNos;
      this.selectedDeviceType = parseInt(queryparams.deviceCleanTypeId);
      // **Here need to be improve the reuse code
      if (this.selectedDeviceType === this.deviceTypeEnum.Vortex) {
        this.stepNames = [
          "Detergent",
          "Detergent Extra",
          "Recirc",
          "Recirc Boost",
          "Dose Unit Rinse",
          "Bypass Rinse",
          "Rinse Target",
          "Rinse Extra"
        ];
      } else if (this.selectedDeviceType === this.deviceTypeEnum.VerxManual) {
        this.stepNames = [
          "Detergent",
          "Detergent Extra",
          "Dose Rinse",
          "Bypass Rinse",
          "Rinse Target",
          "Rinse Extra"
        ];
      } else if (this.selectedDeviceType === this.deviceTypeEnum.VerxAuto) {

        this.stepNames = [
          "R1 Pre Flush",
          "R2 Pre Flush",
          "R1 Flush",
          "R2 Flush", "R1 Charge1", "R2 Charge1",
          "R1 Soak1", "R2 Soak1", "R1 Charge2", "R2 Charge2", "R1 Soak2",
          "R2 Soak2", "R1 Dose Rinse", "R2 Dose Rinse", "R1 Rinse", "R2 Rinse"
        ];
      } else if (this.selectedDeviceType === this.deviceTypeEnum.PLCSManual) {
        this.stepNames = [
          "Detergent",
          "Detergent Extra",
          "Dose Rinse",
          "Bypass Rinse",
          "Rinse Target",
          "Rinse Extra"
        ];
      } else if (this.selectedDeviceType === this.deviceTypeEnum.PLCSAuto) {

        this.stepNames = [
          "R1 Pre Flush",
          "R2 Pre Flush",
          "R1 Flush",
          "R2 Flush", "R1 Charge1", "R2 Charge1",
          "R1 Soak1", "R2 Soak1", "R1 Charge2", "R2 Charge2", "R1 Soak2",
          "R2 Soak2", "R1 Dose Rinse", "R2 Dose Rinse", "R1 Rinse", "R2 Rinse"
        ];
      } else if (this.selectedDeviceType === this.deviceTypeEnum.VortexIAuto) {

        this.stepNames = [
          "Flush to Sensor",
          "Flush Target",
          "Detergent to Sensor",
          "Detergent to Target",
          "Recirc",
          "Recirc Boost",
          "Dose Unit Rinse",
          "Bypass Rinse",
          "Rinse to Sensor",
          "Rinse Target"
        ];
      } else if (this.selectedDeviceType === this.deviceTypeEnum.VortexIManual) {

        this.stepNames = [
          "Flushto Target",
          "Flush Extra",
          "Detergent",
          "Detergent Extra",
          "Dose Rinse",
          "Bypass Rinse",
          "Rinse Target",
          "Rinse Extra",
        ];
      } else if (this.selectedDeviceType === this.deviceTypeEnum.Verxlite) {
        this.stepNames = [
          "Flush",
          "Flill Tank 1",
          "Recirc Tank 1",
          "Fill Tank 2",
          "Recirc Tank 2",
          "Charge 1",
          "Soak 1",
          "Charge 2",
          "Soak 2",
          "Drain Tank",
          "Rinse Fill Tank",
          "Ricirc Tank 3",
          "Empty Tank",
          "Main Rinse",
          "Partial Fill Tank",
          " "
        ];
      } else if (this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto) {
        this.stepNames = [
          "Vent Carbonator",
          "Deternent Fill Carb",
          "Detergent Extra Carb",
          "Detergent Fill Ambient",
          "Detergent Extra Ambient",
          "Detergent Fill Chilled",
          "Detergent Extra Chilled",
          "Recirc Carbonator",
          "Recirc Ambient",
          "Recirc Chilled",
          "Detergent RInse",
          "Bypass Rinse",
          "Rinse Buffer",
          "Main Rinse Ambient",
          "Rinse Extra Ambient",
          "Main Rinse Chilled",
          "Rinse Extra Chilled",
          "Main RInse Carbonator",
          "Rinse Extra Carbonator",
          "Vent Carbonator"
        ];
      } else if (this.selectedDeviceType === this.deviceTypeEnum.VortexNManual) {
        this.stepNames = [
          "Flush to Target",
          "Flush Extra",
          "Detergent",
          "Detergent Extra",
          "Dose Rinse",
          "Bypass Rinse",
          "Rinse Target",
          "Rinse Extra"
        ];
      }

      this.stepSettingConfig = [
        {
          field: "stepNumber",
          title: 'Step Number',
          range: [0, 20],
          steps: (this.selectedDeviceType === this.deviceTypeEnum.Vortex || this.selectedDeviceType === this.deviceTypeEnum.VortexNManual) ? [1, 2, 3, 4, 5, 6, 7, 8] : (this.selectedDeviceType === this.deviceTypeEnum.VerxManual || this.selectedDeviceType === this.deviceTypeEnum.PLCSManual) ? [1, 2, 3, 4, 5, 6] : this.selectedDeviceType === this.deviceTypeEnum.Verxlite ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15] : this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          numbersOnly: true,
          disabled: true,
          required: true,
          forDevice: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        {
          field: "stepName",
          title: 'Step Name',
          steps: this.selectedDeviceType === this.deviceTypeEnum.Verxlite ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15] : this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          required: true,
          text: true,
          forDevice: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        {
          field: "stepType",
          title: 'Step Type',
          range: [0, 254],
          steps: this.selectedDeviceType === this.deviceTypeEnum.Verxlite ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15] : this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          numbersOnly: true,
          required: true,
          forDevice: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        {
          field: "liquidTimeout1",
          title: 'Liquid Timeout 1',
          range: [0, 65535],
          unit: 's',
          numbersOnly: true,
          steps: this.selectedDeviceType === this.deviceTypeEnum.Vortex ? [1, 3, 4] : (this.selectedDeviceType === this.deviceTypeEnum.VerxManual || this.selectedDeviceType === this.deviceTypeEnum.PLCSManual) ? [1] : this.selectedDeviceType === this.deviceTypeEnum.VortexIAuto ? [5, 6] : this.selectedDeviceType === this.deviceTypeEnum.VortexIManual ? [3] : this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [1, 8, 9, 10, 20] : this.selectedDeviceType === this.deviceTypeEnum.VortexNManual ? [3] : this.selectedDeviceType === this.deviceTypeEnum.Verxlite ? [1, 3, 5, 6, 7, 8, 9, 10, 12, 13, 14] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          required: true,
          forDevice: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        {
          field: "liquidTimeNextStep",
          title: 'Liquid Time Next step',
          subTitle: '(once Time exceeded)',
          numbersOnly: true,
          range: [0, 254],
          steps: this.selectedDeviceType === this.deviceTypeEnum.Vortex ? [1, 3, 4] : (this.selectedDeviceType === this.deviceTypeEnum.VerxManual || this.selectedDeviceType === this.deviceTypeEnum.PLCSManual) ? [1] : this.selectedDeviceType === this.deviceTypeEnum.VortexIAuto ? [5, 6] : this.selectedDeviceType === this.deviceTypeEnum.VortexIManual ? [3] : this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [1, 8, 9, 10, 20] : this.selectedDeviceType === this.deviceTypeEnum.VortexNManual ? [3] : this.selectedDeviceType === this.deviceTypeEnum.Verxlite ? [1, 3, 5, 6, 7, 8, 9, 10, 12, 13, 14] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          required: true,
          forDevice: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        {
          field: "liquidVol",
          title: 'Liquid Vol',
          range: [0, 6553.5],
          steps: this.selectedDeviceType === this.deviceTypeEnum.Vortex ? [5, 6, 7] : (this.selectedDeviceType === this.deviceTypeEnum.VerxManual || this.selectedDeviceType === this.deviceTypeEnum.PLCSManual) ? [3, 4, 5] : this.selectedDeviceType === this.deviceTypeEnum.VortexIAuto ? [1, 2, 3, 4, 7, 8, 9, 10] : this.selectedDeviceType === this.deviceTypeEnum.VortexIManual || this.selectedDeviceType === this.deviceTypeEnum.VortexNManual ? [1, 5, 6, 7] : this.selectedDeviceType === this.deviceTypeEnum.Verxlite ? [1, 2, 4, 6, 8, 11, 14, 15] : this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [1, 2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20] : [1, 2, 3, 4, 5, 6, 9, 10, 13, 14, 15, 16],
          unit: 'l',
          withDecimal: true,
          decimal: 1,
          required: true,
          forDevice: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        {
          field: "liquidVolNextStep",
          title: 'Liquid Vol Next step',
          subTitle: '(once Vol exceeded)',
          range: [0, 254],
          steps: this.selectedDeviceType === this.deviceTypeEnum.Vortex ? [5, 6, 7] : (this.selectedDeviceType === this.deviceTypeEnum.VerxManual || this.selectedDeviceType === this.deviceTypeEnum.PLCSManual) ? [3, 4, 5] : this.selectedDeviceType === this.deviceTypeEnum.VortexIAuto ? [1, 2, 3, 4, 7, 8, 9, 10] : this.selectedDeviceType === this.deviceTypeEnum.VortexIManual || this.selectedDeviceType === this.deviceTypeEnum.VortexNManual ? [1, 5, 6, 7] : this.selectedDeviceType === this.deviceTypeEnum.Verxlite ? [1, 2, 4, 6, 8, 11, 14, 15] : this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [1, 2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20] : [1, 2, 3, 4, 5, 6, 9, 10, 13, 14, 15, 16],
          numbersOnly: true,
          required: true,
          forDevice: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        {
          field: "inputButton",
          title: 'Input/Button',
          range: [0, 254],
          steps: this.selectedDeviceType === this.deviceTypeEnum.Vortex ? [1, 2, 4, 8] : (this.selectedDeviceType === this.deviceTypeEnum.VerxManual || this.selectedDeviceType === this.deviceTypeEnum.PLCSManual) ? [2, 6] : this.selectedDeviceType === this.deviceTypeEnum.VortexIAuto ? [6] : this.selectedDeviceType === this.deviceTypeEnum.VortexIManual || this.selectedDeviceType === this.deviceTypeEnum.VortexNManual ? [2, 4, 8] : this.selectedDeviceType === this.deviceTypeEnum.Verxlite ? [2, 4, 10, 11, 12, 13, 15] : [],
          numbersOnly: true,
          required: true,
          forDevice: [1, 3, 5, 6, 7, 8, 9, 10]
        },
        {
          field: "inputNextStep",
          title: 'Input Next Step',
          range: [0, 254],
          steps: this.selectedDeviceType === this.deviceTypeEnum.Vortex ? [1, 2, 4, 8] : (this.selectedDeviceType === this.deviceTypeEnum.VerxManual || this.selectedDeviceType === this.deviceTypeEnum.PLCSManual) ? [2, 6] : this.selectedDeviceType === this.deviceTypeEnum.VortexIAuto ? [6] : this.selectedDeviceType === this.deviceTypeEnum.VortexIManual || this.selectedDeviceType === this.deviceTypeEnum.VortexNManual ? [2, 4, 8] : this.selectedDeviceType === this.deviceTypeEnum.Verxlite ? [2, 4, 10, 11, 12, 13, 15] : [],
          numbersOnly: true,
          required: true,
          forDevice: [1, 3, 5, 6, 7, 8, 9, 10]
        },
        {
          field: "liquidTimeout2",
          title: 'Liquid Timeout 2',
          range: [0, 65535],
          steps: this.selectedDeviceType === this.deviceTypeEnum.VortexIAuto ? [5] : [3],
          unit: 's',
          numbersOnly: true,
          required: true,
          forDevice: [1, 6]
        },
        // {
        //   field: "spareNextStep",
        //   title: 'Spare Next Step',
        //   range: [0, 254],
        //   steps: null,
        //   numbersOnly: true,
        //   required: true,
        //   forDevice: [1, 2, 3]
        // },
        {
          field: "liquidVolSensor",
          title: 'Liquid Vol Sensor',
          range: [0.0, 6553.5],
          steps: this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [2, 4, 6, 14, 16, 18] : [1, 3, 9],
          withDecimal: true,
          decimal: 1,
          required: true,
          forDevice: [6, 9]
        },
        {
          field: "liquidVolSensorNextStep",
          title: 'Liquid Vol Sensor Next Step',
          subTitle: '(once Vol exceeded)',
          range: [0, 254],
          steps: this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [2, 4, 6, 14, 16, 18] : [1, 3, 9],
          withDecimal: true,
          decimal: 1,
          required: true,
          forDevice: [6, 9]
        },
        {
          field: "maxConductivity",
          title: 'Max Conductivity',
          range: [0, 655.36],
          steps: this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          withDecimal: true,
          unit: 'ms',
          decimal: 2,
          required: true,
          forDevice: [1, 2, 3, 4, 5, 6, 7, 9, 10]
        },
        {
          field: "minConductivity",
          title: 'Min Conductivity',
          range: [0, 655.36],
          steps: this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          withDecimal: true,
          unit: 'ms',
          decimal: 2,
          required: true,
          forDevice: [1, 2, 3, 4, 5, 6, 7, 9, 10]
        },
        {
          field: "volumeConstant",
          title: 'Volume constant',
          range: [0, 655.36],
          steps: this.selectedDeviceType === this.deviceTypeEnum.Verxlite ? [1, 2, 4, 6, 8, 11, 14, 15] : [1, 2, 3, 4, 5, 6, 9, 10, 13, 14, 15, 16],
          withDecimal: true,
          unit: 'ms',
          decimal: 1,
          required: true,
          forDevice: [2, 4, 8]
        },
        {
          field: "targetTemp",
          title: 'Target Temp',
          range: [0, 254],
          steps: this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [2, 3, 4, 5, 6, 7, 8, 9, 10] : [3, 4, 5, 6],
          forDevice: [6, 9],
          numbersOnly: true,
        },
        {
          field: "pValue",
          title: 'P value',
          range: [0, 254],
          steps: this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [2, 3, 4, 5, 6, 7, 8, 9, 10] : [3, 4, 5, 6],
          forDevice: [6, 9],
          numbersOnly: true,
        },
        {
          field: "iValue",
          title: 'I value',
          range: [0, 254],
          steps: this.selectedDeviceType === this.deviceTypeEnum.VortexNAuto ? [2, 3, 4, 5, 6, 7, 8, 9, 10] : [3, 4, 5, 6],
          forDevice: [6, 9],
          numbersOnly: true,
        }
      ];
    });
  }

  genArray(length: number) {
    return Array.from(Array(length).keys());
  }
  ngOnInit() {
    if (this.cleanParamDeviceId.length === 0) {
      this.defaultCleanParam = true;
      // **This condition will not excute in this case
      this.getCleanParams(0, this.selectedDeviceType);
    } else if (this.cleanParamDeviceId.length === 1) {
      this.getCleanParams(this.cleanParamDeviceId[0], this.selectedDeviceType)
    }
    if (this.selectedDeviceType === 1 || this.selectedDeviceType === 7 || this.selectedDeviceType === 10) {
      this.noOfSteps = 8
    } else if (this.selectedDeviceType === 2 || this.selectedDeviceType === 4) {
      this.noOfSteps = 16
    } else if (this.selectedDeviceType === 6) {
      this.noOfSteps = 10
    } else if (this.selectedDeviceType === 8) {
      this.noOfSteps = 15
    } else if (this.selectedDeviceType === 9) {
      this.noOfSteps = 20
    } else {
      this.noOfSteps = 6
    }
    this.cleanParam = new CleanParam();
    this.cleanParam.stepSettings = Array(this.noOfSteps);
    const stepArray = this.genArray(this.noOfSteps);
    stepArray.forEach(index => {
      const obj = new StepSettings();
      obj.stepNumber = index + 1;
      obj.stepType = null;
      obj.liquidTimeout1 = null;
      obj.liquidTimeNextStep = null;
      obj.liquidVol = null;
      obj.liquidVolNextStep = null;
      obj.inputButton = null;
      obj.inputNextStep = null;
      obj.liquidTimeout2 = null;
      obj.spareNextStep = null;
      obj.maxConductivity = null;
      obj.minConductivity = null;
      this.cleanParam.stepSettings[index] = obj;
    });
    // this.cleanParam.stepSettings.fill({...obj},0,this.noOfSteps);
    // new Array(this.noOfStepSettings).forEach(() => {
    //   const stepSettings = new StepSettings();
    //   this.cleanParam.stepSettings.push(stepSettings);
    // });
    this.currentDevice = window.location.href.split('/')[5];

  }
  getCleanParams(deviceId, deviceCleanTypeId) {
    const params = {
      deviceId,
      deviceCleanTypeId
    }

    this.cleanParamService.getCleanParams(params).subscribe(res => {
      if (res) {
        this.commonService.setLoader(false);
        if (res.statusCode === 200 && res.data) {

          if (res.data.runSetting) {
            res.data.runSetting.stepSettings.sort((a, b) => a.stepNumber - b.stepNumber);
            this.cleanParam = res.data.runSetting;
          } else {
            this.toastr.info('Please Set Default Clean Params');
            // this.cleanParam = new CleanParam();
          }
        }
      }
    });
  }
  saveCleanParams(form: NgForm) {
    if (form.form.invalid) {
      window.scroll(0, 0);
      this.submitError = MESSAGE.FORM_CONATINS_ERROR;
      return;
    }
    delete this.cleanParam.deviceId;
    delete this.cleanParam.uuid;
    delete this.cleanParam.serialNumber;
    delete this.cleanParam.runSettingId;
    this.cleanParam.stepSettings.forEach(step => {
      step.stepName = step.stepName && step.stepName.trim();
      Object.entries(step).forEach(([key, value]) => {
        if (key !== 'stepName') {
          step[key] = (value === null || value === "") ? null : +value;
        }
      });
      delete step.id;
      delete step.guid;
      delete step.runSettingsId;
    });
    this.submitError = null;
    const params = {
      deviceCleanTypeId: this.selectedDeviceType,
      userId: this.loggedInUserService.getUserData('id'),
      runSetting: this.cleanParam
    }
    if (this.defaultCleanParam) {
      params['devices'] = [];
    } else {
      params['devices'] = this.cleanParamDeviceId.map(deviceId => ({ deviceId }));
    }

    this.cleanParamService.setCleanParams(params).subscribe(res => {
      this.commonService.setLoader(false);
      if (res && res.statusCode === 200 && res.data > 0) {
        if (this.defaultCleanParam) {
          this.toastr.success(MESSAGE.DEFAULT_CLEAN_PARAMS_UPDATED);
          this.router.navigate([`app/${this.currentDevice}/cleanparams/`]);
        } else {
          this.toastr.success(MESSAGE.CLEAN_PARAMS_UPDATED);
          this.router.navigate([`app/${this.currentDevice}/cleanparams/`]);
        }
      } else {
        this.toastr.error(MESSAGE.ERROR_CLEAN_PARAMS_UPDATED);
      }
    });
  }

  cancelForm() {
    this.router.navigate(['app/', this.currentDevice]);
  }
  navigateBack() {
    this.router.navigate(['app/' + this.currentDevice + '/cleanparams/']);
  }
}
