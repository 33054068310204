import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CleanParamsService } from 'src/app/services/clean-params.service';
import { CommonService } from 'src/app/services/common.service';
import { constants } from 'src/app/shared/directives/constants';
import { MESSAGE } from 'src/app/shared/messages';

@Component({
  selector: 'app-default-clean-params-popup',
  templateUrl: './default-clean-params-popup.component.html',
  styleUrls: ['./default-clean-params-popup.component.css']
})
export class DefaultCleanParamsPopupComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Output() saveDefaultSetting = new EventEmitter();
  @Output() close = new EventEmitter();
  @Input() defaultCleanParams;
  selectedDefaultSettingId = "";

  confirmModalRef: BsModalRef;
  defaultSettingList: any[];

  constructor(private toastr: ToastrService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService, 
    private router: Router, 
    private cleanParamService: CleanParamsService) { }

  ngOnInit() {
    this.defaultSettingList = [];

    const defaultParamRequest = {
      deviceCleanTypeId: this.defaultCleanParams.deviceCleanTypeId,
      organizationId: this.defaultCleanParams.selectedOrg
    }

    // Get list of default settings.
    this.cleanParamService.getAllDefaultParam( defaultParamRequest ).subscribe((res) => {
      if (res && res.statusCode === 200 && res.data) {
        this.defaultSettingList = res.data.filter(value => !value.archieved);
      }
    });
    this.commonService.setLoader(false);
  }

  /**
   * @function setDefaultSettingForDevices
   * @param form
   *
   * This function use to set default settings base on 
   * selected deviceIDs and selected deafult settings.
   */
  setDefaultSettingForDevices(form: NgForm) {
    if (form.form.invalid) {
      return;
    }

    const params = {
      ...this.defaultCleanParams,
      defaultSettingId: this.selectedDefaultSettingId
    }

    this.cleanParamService.SetToDefaultCleanParameter(params).subscribe(res => {
      if (res) {
        this.commonService.setLoader(false);
        if (res.statusCode === constants.success && res.data > 0) {
          this.saveDefaultSetting.emit();
          this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS)
        } else {
          this.toastr.success(res.message);
          this.dismissModal();
        }
      }
    });
  }

  dismissModal() {
    this.close.emit();
  }

}
