import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { MESSAGE } from 'src/app/shared/messages';
import { CommonService } from 'src/app/services/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DeviceService } from 'src/app/services/device.service';
import { Device } from 'src/app/models/deviceRequest.model';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { constants } from 'src/app/shared/directives/constants';
import { Router } from '@angular/router';
import { OrganizationModel } from 'src/app/models/organization.model';
import { SharedService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-manage-device',
    styleUrls: ['./manage-device.component.css'],
    templateUrl: './manage-device.component.html',
})
export class ManageDeviceComponent implements OnInit {

    @Output() submitNewDevice = new EventEmitter();
    @Output() deviceUpdate = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() close = new EventEmitter();
    @Input() deviceDetail: Device;
    @Input() isEdit: boolean = false;
    device: Device;
    submitError: string;
    selectedDevice: Device;
    confirmModalRef: BsModalRef;
    oldDevicetypeId: any;
    hideCancel = false;
    orgList: OrganizationModel[] = [];
    // Not Used
    deviceType = [{ id: 1, name: "VORTEX" }, { id: 2, name: "VERX" },{ id: 3, name: "PLCS" },{ id: 4, name: "VORTEXI" }];
    constructor(private toastr: ToastrService, private deviceService: DeviceService, private commonService: CommonService, private modalService: BsModalService, private router: Router, private sharedService: SharedService) {
    }

    ngOnInit() {
        this.device = { id: 0, serialNumber: '', uuid: '', deviceTypeId: 0, deviceOrganizationId: null};
        if (this.deviceDetail) {
            this.oldDevicetypeId = this.deviceDetail.deviceTypeId;
            this.device = this.deviceDetail;
        }
        this.getOrganisations();
    }

    saveDevice(form: NgForm) {
        if (form.form.invalid) {
            return;
        }
        
        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.close.emit();
            this.router.navigate(["/"]);
            return;
        }

        const params: Device = {
            id: this.device.id,
            serialNumber: `${this.device.serialNumberPrifix}-${this.device.serialNumber}`,
            userId: JSON.parse(localStorage.getItem('userData')).id,
            deviceTypeId: this.device.deviceTypeId,
            deviceOrganizationId: +this.device.deviceOrganizationId
        }

        this.deviceService.saveDevice(params).subscribe(res => {
            if (res) {
                this.commonService.setLoader(false);
                if (res.statusCode === constants.success && res.data > 0) {
                    this.submitNewDevice.emit();
                    this.isEdit ? this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
                } else if (res.statusCode === constants.error) {
                    if (res.data === -1) {
                        this.submitError = MESSAGE.DEVICE_SERIAL_NO_UUID_EXIST;
                    } else if (res.data === -2) {
                        this.submitError = MESSAGE.RECORD_DOES_NOT_EXIST;
                        this.close.emit();
                    }
                } else if (res.statusCode === constants.failed) {
                    this.toastr.error(MESSAGE.OPERATION_FAILED);
                }
            }
        });
    }

    cancelDevice() {
        if (!!this.device.uuid || !!this.device.serialNumber) {
            this.cancel.emit({ edit: this.isEdit, device: this.device });
        } else { this.dismissModal(); }
    }
    checkClick() {


    }

    public askForDeviceChange(device, template: TemplateRef<any>) {
        this.selectedDevice = device;
        this.confirmModalRef = this.modalService.show(template, {
            class: 'modal-confirm modal-dialog-centered second',
            ignoreBackdropClick: false
        });
    }
    public dismissActiveInactiveConfirmModal() {
        this.confirmModalRef.hide();
        this.confirmModalRef = null;
    }
    public finallyCloseStatusConfirmModal(device) {
        this.archivedDevice(device);
        this.dismissActiveInactiveConfirmModal();
    }
    public archivedDevice(device) {

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.dismissActiveInactiveConfirmModal;
            this.router.navigate(["/"]);
            return;
        }

        const params: Device = {
            id: device.id,
            serialNumber: device.serialNumber,
            // uuid: this.device.uuid ,
            userId: JSON.parse(localStorage.getItem('userData')).id,
            deviceTypeId: device.deviceTypeId
        }

        this.deviceService.saveDevice(params).subscribe(res => {
            if (res) {
                this.commonService.setLoader(false);
                if (res.statusCode === constants.success && res.data > 0) {
                    this.deviceUpdate.emit();

                    this.isEdit ? this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
                } else if (res.statusCode === constants.error) {
                    if (res.data === -1) {
                        this.submitError = MESSAGE.DEVICE_SERIAL_NO_UUID_EXIST;
                    } else if (res.data === -2) {
                        this.submitError = MESSAGE.RECORD_DOES_NOT_EXIST;
                        this.close.emit();
                    }
                } else if (res.statusCode === constants.failed) {
                    this.toastr.error(MESSAGE.OPERATION_FAILED);
                }
            }
        });
    }
    dismissModal() {
        this.close.emit();
    }

    getOrganisations() {
        this.sharedService.getOrgList(true).then((res: OrganizationModel[]) => {
            this.commonService.setLoader(false);
            this.orgList = res;
          });
    }
}
