import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchData'
})
export class SearchDataPipe implements PipeTransform {

  transform(data: any, searchInput?: any, searchableColumns?: string[]): any {
    if (searchInput) {
      return data &&  data.filter(item => {
        return Object.keys(item).some(
          k =>
          ( searchableColumns.includes(k) || searchableColumns.length === 0) &&
            item[k] != null &&
            item[k]
              .toString()
              .toLowerCase()
              .includes(searchInput.trim().toLowerCase())
        );
    });
  } else {
    return data;
  }
  }
}
