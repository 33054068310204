import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReasonsComponent } from './reasons.component';
import { ManualReasonsComponent } from './manual-reasons/manual-reasons.component';
import { ManageManualReasonsComponent } from './manual-reasons/manage-manual-reasons/manage-manual-reasons.component';
import { RefusalReasonsComponent } from './refusal-reasons/refusal-reasons.component';
import { ManageRefusalReasonsComponent } from './refusal-reasons/manage-refusal-reasons/manage-refusal-reasons.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ManualReasonService } from '../services/manual-reason.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { RefusalReasonService } from '../services/refusal-reason.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ReasonsComponent, ManualReasonsComponent, ManageManualReasonsComponent, RefusalReasonsComponent, ManageRefusalReasonsComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    GridModule,
    TranslateModule
  ],
  providers:[ManualReasonService,RefusalReasonService]
})
export class ReasonsModule { }
