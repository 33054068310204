import { Component, OnInit, SimpleChanges, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { constants } from 'src/app/shared/directives/constants';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styles: [
    `
      .legend {
        text-align: center;
        width: 18px;
        height: 2px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LineChartComponent implements OnInit {
  readonly constants = constants;
  @Input() data: [];
  @Input() cleanType = [];
  subscription;
  hoveredArea;
  public chartOptions = {
    title: {
      text: "Clean Details"
    },
    legend: {
      visible: true,
      position: "bottom"
    },
    seriesDefaults: {
      type: "line",
      markers:
      {
        visible: true,
        size: 2
      }
    },
    valueAxis: {
      title: {
        text: "No. of Cleans",
      },
      line: {
        visible: false,
      }
    },
    categoryAxis: {
      type: 'date',
      title: {
        text: "Dates",
      },
      categories: {},
      crosshair: {
        visible: true,
        tooltip: {
          visible: true
        }
      },
      majorGridLines: {
        visible: false
      },
      baseUnit: 'auto',
      maxDivisions: 5,
      labels: {
        position: "start",
        rotation: "auto",
        visible: true,
        content: e => new DatePipe('en').transform(e.value, constants.dateFormatGraph)
        // content:(e) => getFormatedDate(e.value, `MMM'yy`)
      }
    }
  };
  lineTest;
  lineChartResponse = [];
  cdFlag = false;
  constructor(private translate: TranslateService, private cd: ChangeDetectorRef, private shared: SharedService) {
   
  }
  
  ngOnInit() {
    this.translate.onLangChange.subscribe((res) => {
      this.translationChanges();
    });
 
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    //this.lineChartResponse = [];
    
    if (this.data && this.data.length > 0) {
      this.lineChartResponse = [];
      this.data.forEach((x: { date: number, count: number, dateField: Date }) => {

        x.dateField = new Date(x.date * 1000);
      });

      this.lineTest = this.data.filter((obj: any) => {

        for (var prop in obj) {
          if (obj[prop] === 0) {
            delete obj[prop];
          }
        }
        return obj;
      })
      this.lineChartResponse =
        [
          {
            data: this.lineTest.map(x => x['manualCount']),
            name: 'Manual'
          }, {
            data: this.lineTest.map(x => x['refusalCount']),
            name: 'Not Cleaned'
          },
          {
            data: this.lineTest.map(x => x['vortexSoakCount']),
            name: 'Vortex Soak'
          }, {
            data: this.lineTest.map(x => x['vortexRecircCount']),
            name: 'Vortex Recirc'
          }, {
            data: this.lineTest.map(x => x['verxAutoCount']),
            name: 'Verx Auto'
          }, {
            data: this.lineTest.map(x => x['verxManualCount']),
            name: 'Verx Manual'
          },
          {
            data: this.lineTest.map(x => x['verxLiteAutoCount']),
            name: 'Verx Lite Auto'
          },
          {
            data: this.lineTest.map(x => x['plcsAutoCount']),
            name: 'PLCS Auto'
          },
          {
            data: this.lineTest.map(x => x['plcsManualCount']),
            name: 'PLCS Manual'
          },
          {
            data: this.lineTest.map(x => x['vortexIAutoCount']),
            name: 'Vortex-I Auto'
          },
          {
            data: this.lineTest.map(x => x['vortexIManualCount']),
            name: 'Vortex-I Manual'
          },];
      if (this.cleanType.length > 0) {
        this.lineChartResponse = this.lineChartResponse.filter((element) => {
          return (this.cleanType.find(curr => element.name === curr.name) || (element.name === 'Manual' || element.name === 'Not Cleaned'));
        })
      }

      this.chartOptions.categoryAxis['categories'] = this.data.map(x => new Date(x['date'] * 1000));
    }
    this.translationChanges();
  }

  public onPlotAreaHover(args: any): void {
    this.hoveredArea = args;
  }

  translationChanges() {
    this.translate.get(['Clean Details', 'No. of Cleans', 'Dates']).subscribe((res) => {
      this.chartOptions.title = { text: `${res['Clean Details']}` };

      this.chartOptions.valueAxis = {
        title: {
          text: res['No. of Cleans'],
        },
        line: {
          visible: false,
        }
      }

      this.chartOptions.categoryAxis['categories'] = this.data.map(x => new Date(x['date'] * 1000));

      if (!this.cdFlag) {
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.cdFlag = true;
    this.cd.markForCheck();
    // this.subscription.unsubscribe();
  }

}
