import { Component, OnInit } from '@angular/core';
import { LoggedInUserService } from '../services/loggedInUser.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
})
export class AccountComponent implements OnInit {

  routes = [];
  roleType: string;

  constructor(private loggedInUserService: LoggedInUserService, private router: Router,private translateService: TranslateService) { }

  ngOnInit() {

    this.roleType = this.loggedInUserService.getUserData('role');
    const superAdmin = this.roleType === 'superAdmin';
    const organisationAdmin = this.roleType === 'organisationAdmin';
    const organisationSuperUser = this.roleType === 'superOrgUser'

    this.routes = [
      {
        title: 'Web accounts',
        display: superAdmin || organisationAdmin || organisationSuperUser,
        path: 'web-accounts'
      },
      {
        title: 'Mobile Users',
        display: superAdmin || organisationAdmin || organisationSuperUser,
        path: 'mobile-users'
      }
    ];
  }
}
