import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedInUserService } from '../services/loggedInUser.service';

@Component({
  selector: 'app-reasons',
  templateUrl: './reasons.component.html',
})
export class ReasonsComponent implements OnInit {

  routes = [];
  roleType: string;

  constructor(private loggedInUserService: LoggedInUserService, private router: Router) { }

  ngOnInit() {

    this.roleType = this.loggedInUserService.getUserData('role');

    const superAdmin = this.roleType === 'superAdmin';
    const superOrgUser = this.roleType === 'superOrgUser';
    const orgSuperAdmin = this.roleType === 'orgSuperAdmin';

    this.routes = [
      {
        title: 'Manual Reasons',
        display: superAdmin || superOrgUser || orgSuperAdmin,
        path: 'manual'
      },
      {
        title: 'Not Cleaned Reasons',
        display: superAdmin || superOrgUser || orgSuperAdmin,
        path: 'refusal'
      }
    ]
  }
}
