import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { constants } from 'src/app/shared/directives/constants';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { MobileUserService } from 'src/app/services/mobile-user.service';
import { ToastrService } from 'ngx-toastr';
import { MESSAGE } from 'src/app/shared/messages';
import { CommonService } from 'src/app/services/common.service';
import { Role } from 'src/app/models/roles.enum';
import { OrganizationModel } from 'src/app/models/organization.model';
import { UserModel } from 'src/app/models/mobile-user.model';
import { SharedService } from 'src/app/services/shared.service';
import { TerritoryModel } from 'src/app/models/territories.model';

@Component({
  selector: 'app-manage-mobile-users',
  templateUrl: './manage-mobile-users.component.html'
})
export class ManageMobileUsersComponent implements OnInit {
  readonly MESSAGE = MESSAGE;
  @Input() isEdit: boolean = false;
  @Input() userDetail : UserModel;
  @Output() submit = new EventEmitter();
  @Output() close = new EventEmitter();
  userForm: FormGroup;
  mobUserId: number = 0;
  submitError: string;
  currentRole: string;
  roleEnum = Role;
  selectedOrgId: number = 0;
  terrList : TerritoryModel[] = [];
  orgList: OrganizationModel[] = [];
  currentorg: string;

  constructor( private sharedService:SharedService, private loggedInUserService: LoggedInUserService, private mobileUserService: MobileUserService, private toastr: ToastrService, private commonService: CommonService ) { }

  ngOnInit() {

    this.currentRole = this.loggedInUserService.getUserData('role');

    this.userForm = new FormGroup({
      userName: new FormControl('', Validators.required),
      userEmail: new FormControl('', [Validators.required, Validators.email, Validators.pattern(constants.emailRegex)]),
      userPassword: new FormControl('', [Validators.required, Validators.pattern(constants.passwordRegex)]),
      userOrg: new FormControl(null,Validators.required),
      userTerritories: new FormControl({ value: '', disabled: true }, Validators.required),
      userStatus: new FormControl(true)
    });

    if(this.isEdit) {
      // Edit User

      this.mobUserId = this.userDetail.id;

      this.userForm.get('userName').setValue(this.userDetail.name);
      this.userForm.get('userEmail').setValue(this.userDetail.email);
      this.userForm.get('userOrg').setValue(this.userDetail.organisationId);
      this.userForm.get('userTerritories').setValue(this.userDetail.lstTerritoryUser);
      this.userForm.get('userTerritories').enable();
      this.userForm.get('userStatus').setValue(!this.userDetail.archived);
      this.userForm.get('userPassword').setValidators(null);

      this.getTerrList(this.userDetail.organisationId);
    }


    //get list of org
    if (this.currentRole === Role[1]) {
      // superadmin
      this.sharedService.getOrgList(true).then(
        (res: OrganizationModel[]) => {
          this.orgList = res;
        }
      );
    } else {
      //other than superAdmin
      const orgId = this.loggedInUserService.getUserData('organisationId');
      this.currentorg = this.loggedInUserService.getUserData('organisationName');
      this.userForm.get('userOrg').setValue(orgId);
      this.userForm.get('userTerritories').enable();
      this.getTerrList(orgId);
    }
  }

  dismissModal() {
    this.close.emit();
  }

  onSubmit(form: FormGroup) {
    event.stopPropagation();
    if(form.get('userName').value && form.get('userName').value.trim() === '') {
      form.get('userName').setErrors({required: true});
      return 
    }
    if (form.invalid) {
      return;
    }
    const userTerrList = form.get('userTerritories').value;
    let selectedTerr = userTerrList.map(({ id }) => ({id}));

    const params : UserModel = {
      id: this.mobUserId,
      name: form.get('userName').value.trim(),
      email: form.get('userEmail').value,
      password: !this.isEdit ? form.get('userPassword').value : undefined,
      organisationId: this.currentRole === Role[1] ? +form.get('userOrg').value : this.loggedInUserService.getUserData('organisationId'),
      userId: this.loggedInUserService.getUserData('id'),
      territoryUsers: selectedTerr,
      archived: !form.get('userStatus').value
    }

    this.mobileUserService.saveUser(params).subscribe(
      (res) => {
        if(res) {
        if (res.statusCode === 200) {
          this.submit.emit();
          this.isEdit ? this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
        } else if (res.statusCode === 404) {
          this.commonService.setLoader(false);
          if (res.data === -1) {
            this.submitError = MESSAGE.EMAILID_ALREADY_EXIST;
          }
        }
      }
    }
    );

  }

  sendSelectedOrg(orgId: number) {
    this.userForm.get('userTerritories').setValue([]);
    this.userForm.get('userTerritories').enable();
    this.selectedOrgId = orgId;
    this.getTerrList(orgId);
   }

   getTerrList(orgId) {
    this.sharedService.getTerritoryList(orgId,0,0,0,true).then((res: TerritoryModel[]) => {
      this.terrList = res;
    });
   }
   
  isItemSelected(selectedArray , id) {
    return selectedArray && selectedArray.length > 0 ? selectedArray.some(item => item.id === id) : false;
  }

}
