import { Component, OnInit, Input } from '@angular/core';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { Role } from 'src/app/models/roles.enum';

@Component({
  selector: 'app-tab-layout',
  templateUrl: './tab-layout.component.html',
  styleUrls: ['./tab-layout.component.css']
})
export class TabLayoutComponent implements OnInit {

  @Input() routes: [];
  currentRole: string;
  constructor(private loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.currentRole = this.loggedInUserService.getUserData('role');
    this.routes.map((route: any) => {
      if([Role[4], Role[6]].includes(this.currentRole)) {
        if((['manageDevices', 'multipleDefaultSetting', 'cleanparams'].includes(route.path))) {
          route.display = true;
        } else {
          route.display = false;
        }
      }
    });
  }

}
