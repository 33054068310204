import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutletComponent } from './outlet.component';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { SharedModule } from '../shared/shared.module';
import { ManageOutletComponent } from './manage-outlet/manage-outlet.component';
import { OutletService } from '../services/outlet.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';

@NgModule({
  declarations: [OutletComponent, ManageOutletComponent],
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    ExcelModule,
    SharedModule,
    TranslateModule,
    InputsModule
  ],
  providers: [OutletService]
})
export class OutletModule { }
