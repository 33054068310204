import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RefusalReasonModel } from 'src/app/models/refusalReason.model';
import { OrganizationModel } from 'src/app/models/organization.model';
import { Role } from 'src/app/models/roles.enum';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { SharedService } from 'src/app/services/shared.service';
import { RefusalReasonService } from 'src/app/services/refusal-reason.service';
import { MESSAGE } from 'src/app/shared/messages';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-manage-refusal-reasons',
  templateUrl: './manage-refusal-reasons.component.html'
})

export class ManageRefusalReasonsComponent implements OnInit {
  readonly Role = Role;
  @Input() isEdit: boolean = false;
  @Input() refusalReasonDetail : RefusalReasonModel;
  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter();

  orgList: OrganizationModel[] = [];
  refusalReason: RefusalReasonModel;
  organisationName: string;
  currentRole: string;
  submitError: string;
  constructor(private commonService: CommonService, private toastr:ToastrService, private loggedInUserService: LoggedInUserService,private SharedService: SharedService, private refusalReasonService: RefusalReasonService) { }

  ngOnInit() {
    this.refusalReason = new RefusalReasonModel();
    this.currentRole = this.loggedInUserService.getUserData('role');
    if(this.isEdit) {
      this.refusalReason =  Object.assign({},this.refusalReasonDetail);
    } else {
    this.refusalReason.organisationId = null;
    }
    if(this.currentRole === Role[1]) {
    this.SharedService.getOrgList().then((res: OrganizationModel[]) => {
      this.orgList = res;
    });
    } else {
      this.refusalReason.organisationId = this.loggedInUserService.getUserData('organisationId');
      this.organisationName= this.loggedInUserService.getUserData('organisationName');
    }
  }

  saveRefusalReason(event, form: NgForm) {
    event.stopPropagation();
    if(form.form.invalid) {
      return;
    }
    const params: RefusalReasonModel = {
      id: this.isEdit ? this.refusalReason.id : 0,
      name: this.refusalReason.name.trim(),
      organisationId: this.refusalReason.organisationId,
      userId: this.loggedInUserService.getUserData('id')
    }
    this.refusalReasonService.saveRefusalReason(params).subscribe(res => {
      if(res) {
        if (res.statusCode === 200 && res.data > 0) {
          this.submit.emit();
          this.isEdit ? this.toastr.success(MESSAGE.REFUSAL_REASON_UPDATED) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
        } else if (res.statusCode === 404) {
          this.commonService.setLoader(false);
          if (res.data === -1) {
            this.submitError = MESSAGE.RECORD_ALREADY_EXIST;
          } else  if (res.data === 0) {
            this.toastr.error(MESSAGE.RECORD_SAVING_FAILED)
          }
        }
      }
    });
  }

  dismissModal() {
    this.close.emit();
  }
}
