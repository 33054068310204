export class DashboardRequestModel {
    organisationId: number;
    fromDate?: Date;
    toDate?: Date;
    from: Date | string;
    to: Date | string;
    group = [];
    region = [];
    area = [];
    territory = [];
    user = [];
    userId?: number;
    graphName?: string;
    skip?: number;
    take?: number;
    typeofClean = [];
    manualReason?= [];
    refusalReason?= [];
    methodIds?= [];
    typeOfCleanIds?= [];
    constructor() { };
}