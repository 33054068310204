import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggedInUserService } from '../services/loggedInUser.service';
import { Role } from '../models/roles.enum';

@Component({
  selector: 'app-organization-detail',
  templateUrl: './organization-detail.component.html'
})
export class OrganizationDetailComponent implements OnInit {
  message: string;
  roleType: string;
  constructor(private loggedInUserService: LoggedInUserService, private router: Router, private route: ActivatedRoute) { }

  routes = []
  ngOnInit() {
    this.roleType = this.loggedInUserService.getUserData('role');
    const superAdmin = this.roleType === Role[1];
    const organisationAdmin = this.roleType === Role[2];
    const superOrgUser = this.roleType === Role[4];
    const orgSuperAdmin = this.roleType === Role[5];
    const serviceUser = this.roleType === Role[6];
    this.routes = [{
      title: 'Organisations',
      display: superAdmin,
      path: 'organisations'
    },
    {
      title: 'Groups',
      display: superAdmin || superOrgUser || orgSuperAdmin || organisationAdmin,
      path: 'groups'
    },
    {
      title: 'Regions',
      display: superAdmin || superOrgUser || orgSuperAdmin || organisationAdmin,
      path: 'regions'
    },
    {
      title: 'Areas',
      display: superAdmin || superOrgUser || orgSuperAdmin || organisationAdmin,
      path: 'areas'
    },
    {
      title: 'Territories',
      display: superAdmin || superOrgUser || orgSuperAdmin || organisationAdmin,
      path: 'territories'
    }
    ];
  }
}
