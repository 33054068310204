import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';
import { LoggedInUserService } from './loggedInUser.service';
import { Role } from '../models/roles.enum';
import { CommonService } from './common.service';

@Injectable()
export class AuthService {

    constructor(private restAPI: RestApi, public router: Router, private loggedInUserService: LoggedInUserService, private commonService: CommonService) { }

    login(params) {
        const url = RestApi.buildUrl(`${ENDPOINTS.AUTHENTICATION.LOGIN}`);
        return this.restAPI.handleRequest(url, METHODS.Post, params, false);
    }

    loginOTP(params) {
        const url = RestApi.buildUrl(`${ENDPOINTS.AUTHENTICATION.VALIDATE_OTP}`);
        return this.restAPI.handleRequest(url, METHODS.Post, params, false);
    }

    isAuthenticated = () => {
        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.router.navigate(["/"]);
            return;
        }
        const token = JSON.parse(localStorage.getItem('token'));
        return token ? true : false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentUserRole = this.loggedInUserService.getUserData('role');
        if (currentUserRole) {
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(currentUserRole) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/app/dashboard']);
                return false;
            }
            this.commonService.onResize();
            // authorised so return true
            return true;
        }

        if (!this.isAuthenticated()) {
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}

