import { Component, OnInit, TemplateRef } from '@angular/core';
import { IKendoColumnModel } from '../models/kendoColumn.model';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AssetTypeId, AssetTypeModel, ArchiveAssetType } from '../models/assetType.model';
import { AssetTypeService } from '../services/asset-type.service';
import { CommonService } from '../services/common.service';
import { SearchDataPipe } from 'src/app/pipes/search-data.pipe';
import { LoggedInUserService } from '../services/loggedInUser.service';
import { Role } from '../models/roles.enum';
import { convertTimeZone, getFormatedDate } from 'src/app/shared/handler.functions';
import { MESSAGE } from '../shared/messages';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { constants } from '../shared/directives/constants';


@Component({
    selector: 'app-asset-type',
    templateUrl: './asset-type.component.html'
})
export class AssetTypeComponent implements OnInit {

    // Global variables
    assetTypeList: AssetTypeModel[] = [];
    public gridData: any = [];
    columns: IKendoColumnModel[];
    searchKeyword: string;
    searchableColumns = ['name', 'organisationName'];
    state: State = {
        skip: 0,
        take: 50
    };
    role: string;
    selectedAsset: AssetTypeModel;
    assetTypeIsEdit = false;
    // Modal Refs
    modalRef: BsModalRef;
    confirmModalRef: BsModalRef;
    timeZone: string;
    orgId: number;

    constructor(
        private assetTypeService: AssetTypeService,
        private modalService: BsModalService,
        private commonService: CommonService,
        private loggedInUserService: LoggedInUserService,
        private toastr: ToastrService,
        private router: Router) { }

    ngOnInit() {
        this.role = this.loggedInUserService.getUserData('role');
        this.orgId = this.loggedInUserService.getUserData('organisationId');
        this.timeZone = this.loggedInUserService.getUserData('timezone');
        this.getAssetType();
        this.columns = [
            {
                field: 'name',
                title: 'Name',
                width: 300,
                groupable: false,
                locked: true
            },
            {
                field: 'organisationName',
                title: 'Organisation',
                width: 300,
                groupable: true,
                display: this.role !== Role[1]
            },
            {
                field: 'createdAt',
                title: 'Created On',
                width: 120,
                cellTextCenter: true,
                groupable: false,
                cellFormat: data => getFormatedDate(data.createdAt, constants.dateTimeFormat) || '-'
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 230,
                groupable: false
            },
            {
                field: 'updatedAt',
                title: 'Updated On',
                width: 130,
                cellTextCenter: true,
                groupable: false,
                cellFormat: data => getFormatedDate(data.updatedAt, constants.dateTimeFormat) || '-'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 230,
                groupable: false,
                cellFormat: (data) => data.updatedBy || '-'
            }
        ];
    }

    // Get Asset Type List
    private getAssetType(): void {
        const bodyParams: AssetTypeId = {
            organisationId: this.role === Role[2] ? 0 : this.loggedInUserService.getUserData('organisationId'),
            userId: 0
        };
        this.assetTypeService.getAssetTypeList(bodyParams).subscribe(
            (res) => {
                this.commonService.setLoader(false);
                if (res && res.statusCode === 200) {
                    convertTimeZone(res.data, ['createdAt', 'updatedAt'], this.timeZone);
                    if (this.role !== "superAdmin") {
                        this.assetTypeList = res.data.filter((curr) => {
                            return this.orgId === curr.organisationId;
                        })
                    } else {
                        this.assetTypeList = res.data;
                    }
                    this.loadItems();
                }
            },
            (error) => {
                console.error(error);
            }
        );
    }

    private loadItems(): void {
        const searchedRecords = new SearchDataPipe().transform(
            this.assetTypeList,
            this.searchKeyword,
            this.searchableColumns
        );
        this.gridData = process(searchedRecords, this.state);
    }

    public filterSearch() {
        this.loadItems();
        this.state.skip = 0;
    }

    // REALTED to grid Functionality paging , sorting , groupping
    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.loadItems();
    }

    // Add Asset Type
    public addAssetType(template: TemplateRef<any>) {
        this.selectedAsset = { id: 0, name: '' };
        this.assetTypeIsEdit = false;
        this.openModal(template);
    }

    // edit group
    public editAssetType(group, template: TemplateRef<any>) {
        this.assetTypeIsEdit = true;
        this.selectedAsset = Object.assign({}, group);
        this.openModal(template);
    }

    // Opens the modal popup with backdrop config
    private openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered',
            ignoreBackdropClick: true
        });
    }

    // Final Submit After first Modal i.e SAVE API_CALL
    public submitModalPopup(template: TemplateRef<any>) {
        this.getAssetType();
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // confirmation for archive
    public askForConfirmation(asset, template: TemplateRef<any>) {
        this.selectedAsset = asset;
        this.confirmModalRef = this.modalService.show(template, {
            class: 'modal-confirm modal-dialog-centered second',
            ignoreBackdropClick: true
        });
    }

    // Close first add/edit Modal popup
    public closeModalPopup() {
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    public finallyCloseStatusConfirmModal(asset) {
        this.archivedAsset(asset);
        this.dismissActiveInactiveConfirmModal();
    }

    // archive group or change status
    private archivedAsset(asset) {

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.dismissActiveInactiveConfirmModal();
            this.router.navigate(["/"]);
            return;
        }

        const bodyParams: ArchiveAssetType = {
            id: asset.id,
            archived: !asset.archived,
            updatedBy: JSON.parse(localStorage.getItem('userData')).id
        };

        this.assetTypeService.archiveGroup(bodyParams).subscribe(
            (res) => {
                if (res) {
                    if (res.statusCode === 200 && res.data > 0) {
                        this.toastr.success(MESSAGE.ARCHIVED_DONE_SUCCESSFULLY, '');
                        this.getAssetType();
                        this.commonService.setLoader(false);
                    } else if (res.statusCode === 404) {
                        this.commonService.setLoader(false);
                        if (res.data === -3) {
                            this.toastr.error(MESSAGE.UNABLE_TO_DEACTIVATE_GROUP, '', { timeOut: 10000 });
                        } else if (res.data === -4) {
                            this.toastr.error(MESSAGE.UNABLE_TO_ACTIVATE_GROUP, '', { timeOut: 10000 });
                        }
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    public dismissActiveInactiveConfirmModal() {
        this.confirmModalRef.hide();
        this.confirmModalRef = null;
    }

}
