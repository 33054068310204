import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { changePasswordModel } from 'src/app/models/changePassword.model';
import { constants } from 'src/app/shared/directives/constants';
import { generatePassword } from 'src/app/shared/handler.functions';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit {
  @Output() changePassword = new EventEmitter<{}>();
  @Output() close = new EventEmitter();
  @Input() fromProfile : boolean = false;
  @Input() error: string;
  model: changePasswordModel;
  submitError: string = '';
  passwordRegex = constants.passwordRegex;
  show: boolean = false;
  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.submitError = '';
    this.model = {
      oldPassword:'',
      newPassword: '',
      confirmPassword: ''
    }
  }

  ngOnChanges(): void {
    this.submitError = this.error;
  }

  generateNewPassword(event) {
    event.stopPropagation();
    const pwd = generatePassword();
    this.model.newPassword = pwd;
    this.model.confirmPassword = pwd;
  }
  doChangePassword(event) {
    event.stopPropagation();
    if (!(new RegExp(constants.passwordRegex)).test(this.model.newPassword)) {
      this.submitError = 'Password does not match with the rules';
      return;
    }
    if (this.model.newPassword === this.model.confirmPassword) {
      if(this.fromProfile) {
        this.changePassword.emit({oldPassword : this.model.oldPassword,password:  this.model.newPassword});
      } else {
        this.changePassword.emit(this.model.newPassword);
      }
    } else {
      this.submitError = "New password and confirm password does not match."  
      return;
    }
  }

  dismissModal() {
    this.submitError = '';
    this.close.emit()
  }

  password() {
    this.show = !this.show;
}
}
