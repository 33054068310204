import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryComponent } from './history.component';
import { FormsModule } from '@angular/forms';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { GridModule, ExcelModule, SharedModule } from '@progress/kendo-angular-grid';
import { VisitService } from '../services/visit.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HistoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    DatePickerModule,
    GridModule,
    ExcelModule,
    SharedModule,
    TranslateModule
  ],
  providers:[VisitService]
})
export class HistoryModule { }
