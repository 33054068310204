
import { Component, OnInit, ViewChild } from '@angular/core';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { OrganizationModel } from '../models/organization.model';
import { Role } from '../models/roles.enum';
import { LoggedInUserService } from '../services/loggedInUser.service';
import { VisitRequestModel } from '../models/visitRequest.model';
import { SharedService } from '../services/shared.service';
import { NgForm } from '@angular/forms';
import { VisitService } from '../services/visit.service';
import { ObjectResponseModel } from '../models/reponse.model';
import { constants } from '../shared/directives/constants';
import { CommonService } from '../services/common.service';
import { IKendoColumnModel } from '../models/kendoColumn.model';
import { convertTimeZone, getFormatedDate, setCurrentTime } from '../shared/handler.functions';
import { CleanListModel } from '../models/clean.model';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { stepMesurements } from './stepMeasurements.constants';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { isNumber } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { forkJoin, Subscription } from 'rxjs';
import { GroupModel, GroupOption } from '../models/groups.model';
import { RegionModel } from '../models/regions.model';
import { AreaModel } from '../models/areas.model';
import { UserModel } from '../models/mobile-user.model';
import { TerritoryModel } from '../models/territories.model';
import { Method } from '../models/method.model';
import { DashboardService } from '../services/dashboard.service';
import { CleanParamsService } from '../services/clean-params.service';

@Component({
    selector: 'app-cleans',
    templateUrl: './cleans.component.html',
    styleUrls: ['./cleans.component.css']
})
export class CleansComponent implements OnInit {

    readonly constants = constants;
    readonly Role = Role;
    defaultOptionsCount = 3;
    defaultOptionsStatusList = [];
    orgList: OrganizationModel[] = [];
    cleanTypesList: Method[] = [];
    typeOfClean: Method[] = [];
    grpList: GroupModel[] = [];
    regionList: RegionModel[] = [];
    areaList: AreaModel[] = [];
    territoryList: TerritoryModel[] = [];
    mobileUserList: UserModel[] = [];
    clean: VisitRequestModel;
    cleanList: CleanListModel;
    userRole: string;
    todaysDate = new Date();
    noOfSteps = 8;
    stepMesurements = stepMesurements;
    test: string;
    // Grid
    gridView: GridDataResult;
    state: State = {
        skip: 0,
        take: 50
    };
    columns: IKendoColumnModel[];
    isMobile = false;
    isMobileSubscriber: Subscription;
    ratingList = [
        {
            id: 1,
            color: 'green'
        },
        {
            id: 2,
            color: 'amber'
        },
        {
            id: 3,
            color: 'red'
        }
    ];

    orgSelected = false;
    groupSelected = false;
    regionSelected = false;
    areaSelected = false;
    @ViewChild('users', { static: false }) users;
    copyMobileUserList: UserModel[] = [];
    timeZone: string;
    
    constructor(
        private clearService: CleanParamsService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private loggedInUserService: LoggedInUserService,
        private commonService: CommonService,
        private sharedService: SharedService,
        private dashboardService: DashboardService,
        private cleanService: VisitService) {
            this.allData = this.allData.bind(this);
            this.route.queryParams.subscribe(queryparams => {
                if (queryparams.filters) {
                    this.clean = JSON.parse(queryparams.filters);
                    this.clean.from = new Date(this.clean.from);
                    this.clean.to = new Date(this.clean.to);
                    this.state = JSON.parse(queryparams.page);
                    this.sharedService.getOrgList(true).then((res: OrganizationModel[]) => {
                        
                        this.orgList = res;
                        this.commonService.setLoader(true);
                        this.changeOrganisation(false);
                        this.changeGroup(this.clean.group);
                        this.changeRegion(this.clean.region);
                        this.changeArea(this.clean.area);
                        this.refreshReport();
                    });
                    // this.sharedService.getMethod({ "type": "methodandtypeofclean" }, true).then((res: any) => {
                        //     this.methodList = res;
                        //     this.typeOfClean = [];
                        
                        //     this.commonService.setLoader(true);
                        
                        //     this.refreshReport();
                        // });
                        
                    } else {
                        this.clean = new VisitRequestModel();
                this.clean.organisationId = null;
                this.clean.from = new Date();
                this.clean.to = new Date();
                this.sharedService.getOrgList(true).then((res: OrganizationModel[]) => {
                    this.orgList = res;
                });
            }
        });
        this.isMobileSubscriber = this.commonService.getIsMobile().subscribe((res: boolean) => {
            this.isMobile = res;
            this.bindColumns();
        });
    }
    
    bindColumns() {
        this.columns = [
            {
                field: 'shipToNumber',
                title: 'Ship To',
                width: 120,
                locked: true
            },
            {
                field: 'outletName',
                title: 'Outlet',
                width: 150,
                locked: !this.isMobile
            },
            {
                field: 'organisationName',
                title: 'Organisation',
                width: 150,
            },
            {
                field: 'groupName',
                title: 'Group',
                width: 150,
            },
            {
                field: 'region',
                title: 'Region',
                width: 150,
            },
            {
                field: 'area',
                title: 'Area',
                width: 150,
            },
            {
                field: 'territory',
                title: 'Territory',
                width: 150,
            },
            {
                field: 'jobNumber',
                title: 'Job Number',
                width: 130,
                cellTextCenter: true,
                cellFormat: data => data.jobNumber || '-'
            },
            {
                field: 'city',
                title: 'City',
                width: 120,
            },
            {
                field: 'cycleNumber',
                title: 'Cycle Number',
                width: 130,
                cellTextCenter: true,
                cellFormat: data => data.cycleNumber || '-'
            },
            {
                field: 'userName',
                title: 'User',
                width: 150,
            },
            {
                field: 'deviceID',
                title: 'Device Id',
                width: 110,
                cellFormat: data => data.deviceID || '-'
            },
            {
                field: 'expectedDate',
                title: 'Expected Date',
                width: 180,
                cellFormat: data => {
                    if (data.expectedDate) {
                        return `${getFormatedDate(data.expectedDate.split('-')[0], constants.dateWithoutTimeFormat)} - ${getFormatedDate(data.expectedDate.split('-')[1], constants.dateWithoutTimeFormat)}`
                    }
                }
            },
            {
                field: 'visitStart',
                title: 'Visit Start',
                width: 180,
                cellFormat: data => getFormatedDate(data.visitStart, constants.dateTimeFormat) || '-'
            },
            {
                field: 'visitEnd',
                title: 'Visit End',
                width: 180,
                cellFormat: data => getFormatedDate(data.visitEnd, constants.dateTimeFormat) || '-'
            },
            {
                field: 'callDuration',
                title: 'Session Duration',
                width: 110,
                cellFormat: data => data.callDuration || '-'
            },
            {
                field: 'type',
                title: 'Method',
                width: 110,
                cellFormat: data => data.type || '-'
            },
            {
                field: 'assetName',
                title: 'Asset',
                width: 110,
                cellFormat: data => data.assetName || '-'
            },
            {
                field: 'assetQuantity',
                title: 'Asset Lines',
                width: 110,
                cellFormat: data => data.assetQuantity || '-'
            },
            {
                field: 'noOfLines',
                title: 'No. of Lines',
                width: 110,
                cellFormat: data => isNumber(data.noOfLines) ? data.noOfLines : '-'
            }, {
                field: 'volumeOfLines',
                title: 'Volume of Lines',
                width: 110,
                cellFormat: data => isNumber(data.volumeOfLines) ? data.volumeOfLines : '-'
            }
            , {
                field: 'manualReason',
                title: 'Manual Reason',
                width: 110,
                cellFormat: data => data.manualReason || '-'
            }
            , {
                field: 'refusalReason',
                title: 'Not Cleaned Reason',
                width: 110,
                cellFormat: data => data.refusalReason || '-'
            },
            {
                field: 'geolocationLat',
                title: 'Geolocation Lat',
                width: 170,
                cellFormat: data => data.geolocationLat || '-'
            }
            , {
                field: 'geolocationLong',
                title: 'Geolocation Long',
                width: 160,
                cellFormat: data => data.geolocationLong || '-'
            },
            {
                field: 'outletLatitude',
                title: 'Outlet Latitude',
                width: 160,
                cellFormat: data => data.outletLatitude || '-'
            }
            , {
                field: 'outletLongitude',
                title: 'Outlet Longitude',
                width: 160,
                cellFormat: data => data.outletLongitude || '-'
            }
            , {
                field: 'score',
                title: 'Score',
                width: 110,
                cellFormat: data => isNumber(data.score) ? data.score.toFixed(2) : '-',
                cellOptions: { format: '0.00' }
            }
            , {
                field: 'rating',
                title: 'Rating',
                width: 110,
                cellFormat: data => isNumber(data.rating) ? data.rating : '-'
            },
            {
                field: 'runNumber',
                title: 'Run Number',
                width: 120,
                cellFormat: data => isNumber(data.runNumber) ? data.runNumber : '-'
            },
            {
                field: 'typeOfClean',
                title: 'Clean Type',
                width: 120,
                cellFormat: data => data.typeOfClean || '-'
            },
            {
                field: 'numberofSteps',
                title: 'Number Of Steps',
                width: 110,
                cellFormat: data => data.numberofSteps || '-'
            },
            {
                field: 'cleanStartDateTime',
                title: 'Clean Start Date Time',
                width: 180,
                cellFormat: data => getFormatedDate(data.cleanStartDateTime, constants.dateTimeFormat) || '-'
            }, {
                field: 'cleanFinishDateTime',
                title: 'Clean Finish Date Time',
                width: 180,
                cellFormat: data => getFormatedDate(data.cleanFinishDateTime, constants.dateTimeFormat) || '-'
            }
            , {
                field: 'surgeCleanTime',
                title: 'Clean Time',
                width: 110,
                cellFormat: data => data.surgeCleanTime || '-'
            }
            , {
                field: 'numberOfErrors',
                title: 'Number of Errors',
                width: 110,
                cellFormat: data => isNumber(data.numberOfErrors) ? data.numberOfErrors : '-'
            }
        ];
    }
   
   
    ngOnInit() {
       
        this.userRole = this.loggedInUserService.getUserData('role');
        this.timeZone = this.loggedInUserService.getUserData('timezone');
        this.bindColumns();
        if (this.userRole === Role[1]) {
            if (!this.clean.organisationId) {
                this.sharedService.getOrgList(true).then((res: OrganizationModel[]) => {
                    this.orgList = res;
                    // this.changeOrganisation();
                    // this.refreshReport();
                });
            }
        } else {
            // For org. admin & web user
            if (!this.clean.organisationId) {
                this.clean.organisationId = this.loggedInUserService.getUserData('organisationId');
                this.changeOrganisation();
            }
        }
        
        // this.sharedService.getMethod({ "type": "methodandtypeofclean" }, true).then((res: any) => {
        //     this.methodList = res;
        //     if (!this.clean.methodId) {
        //         this.clean.methodId = this.methodList;
        //     }
        //     this.commonService.setLoader(true);
        //     this.changeOrganisation(false);
        //     this.refreshReport();
        // });
    }


    changeOrganisation(updateAllFilters = true) {
        if (updateAllFilters) {
            this.clean.group.length = 0;
            this.clean.region.length = 0;
            this.clean.area.length = 0;
            this.clean.territory.length = 0;
            this.clean.user.length = 0;
            this.clean.methodId = [];
        }
        let userId = 0;
        if (this.userRole === Role[3]) {
            userId = this.loggedInUserService.getUserData('id');
        }

        if (!!(+this.clean.organisationId)) {
            this.orgSelected = true;
            this.grpList.length = 0;
            this.regionList.length = 0;
            this.areaList.length = 0;

            if (this.clearService.enableField) {
                this.groupSelected = true;
                this.regionSelected = true;
                this.areaSelected = true;
            } else {
                this.groupSelected = false;
                this.regionSelected = false;
                this.areaSelected = false;
            }

            this.getOrgRelatedData();

        } else {
            this.orgSelected = false;
            if (this.clearService.enableField) {
                this.groupSelected = true;
                this.regionSelected = true;
                this.areaSelected = true;
            } else {
                this.groupSelected = false;
                this.regionSelected = false;
                this.areaSelected = false;
            }
        }
    }

    getOrgRelatedData() {
        const type = { type: "cleandashboardcleantype", organisationId: +this.clean.organisationId, fromDashboard: 1 };

        const orgId = { organisationId: +this.clean.organisationId, fromDashboard: 1 };

        const bodyParamGroups = {
            organisationId: +this.clean.organisationId,
            fromDashboard: 1
        };

        const bodyParamUsers = {
            organisationId: +this.clean.organisationId,
            fromDashboard: 0
        };

        const cleanTypes = this.dashboardService.getCleanTypes(type);

        const groups = this.dashboardService.getGroups(bodyParamGroups);

        const users = this.dashboardService.getUsers(bodyParamUsers);

        forkJoin([groups, cleanTypes, users]).subscribe((result:any) => {
            this.commonService.setLoader(false);
            this.grpList = result[0].data;
            this.cleanTypesList = result[1].data;
            this.mobileUserList = result[2].data;
            // if(this.clean.user.length > 0) {
            //  const groupIds = this.clean.user.map((group) => group.id);
            //  }
        });
    }

    changeGroup(selectedGroups: GroupOption[]) {
        if (selectedGroups.length > 0) {
            this.groupSelected = true;

            const bodyParams = {
                groupIds: selectedGroups.map((group) => group.id),
                fromDashboard: 1,
                organisationId: +this.clean.organisationId
            }

            this.dashboardService.getRegions(bodyParams).subscribe((res: any) => {
                this.commonService.setLoader(false);
                this.regionList = res.data;
                // this.getUsers(bodyParams.groupIds);
            });
        } else {
            // this.regionList.length = 0;
            this.areaList.length = 0;
            // this.mobileUserList.length = 0;
            this.territoryList.length = 0;
            // this.clean.user.length = 0;
            this.clean.area.length = 0;
            this.clean.region.length = 0;
            this.clean.territory.length = 0;
            this.regionSelected = false;
            this.groupSelected = false;
            this.areaSelected = false;
        }
    }

    getUsers(groupIds) {
        const bodyParams = {
            groupIds: groupIds,
            fromDashboard: 1,
            organisationId: +this.clean.organisationId
        };
        this.dashboardService.getUsers(bodyParams).subscribe((res: any) => {
            this.commonService.setLoader(false);
            this.mobileUserList = res.data;
            this.copyMobileUserList = this.mobileUserList;
        });
    }

    changeRegion(selectedRegions: RegionModel[]) {
        if (!!selectedRegions.length) {
            this.regionSelected = true;
            const bodyParams = {
                groupIds: this.clean.group.map((group) => group.id),
                regionIds: selectedRegions.map((region) => region.id),
                fromDashboard: 1,
                organisationId: +this.clean.organisationId
            }

            this.dashboardService.getAreas(bodyParams).subscribe((res: any) => {
                this.commonService.setLoader(false);
               
                this.areaList = res.data;
            });

        } else {
            this.areaList.length = 0;
            this.regionSelected = false;
            this.areaSelected = false;
            this.territoryList.length = 0;
        }
    }

    changeArea(selectedAreas: RegionModel[]) {
       
        if (!!selectedAreas.length) {

            this.areaSelected = true;

            const bodyParams = {
                groupIds: this.clean.group.map((group) => group.id),
                regionIds: this.clean.region.map((region) => region.id),
                areaIds: selectedAreas.map((region) => region.id),
                fromDashboard: 1,
                organisationId: +this.clean.organisationId
            }

            this.dashboardService.getTerritories(bodyParams).subscribe((res: any) => {
                this.commonService.setLoader(false);
                this.territoryList = res.data;
            });

        } else {
            this.areaSelected = false;
            this.territoryList.length = 0;
        }
    }

    // REALTED to grid Functionality paging , sorting , groupping
    pageChange(page: PageChangeEvent): void {
        this.state.skip = page.skip;
        this.getCleans();
    }

    groupChange(group: GroupDescriptor[]) {
        this.state.group = group;
        this.loadData();
    }

    sortChange(sort: SortDescriptor[]) {
        this.state.sort = sort;
        this.loadData();
    }

    loadData() {
        this.gridView = {
            data: process(this.cleanList.cleanListResponses, { group: this.state.group, sort: this.state.sort }).data,
            total: this.cleanList.totalCount
        }
    }

    refreshReport(event?, form?: NgForm) {
        if (event) {
            event.stopPropagation();
        }
        if (form && form.invalid) {
            return;
        }
        +this.clean.shipTo ? this.state.skip = 0 : this.state.skip = this.state.skip;
        this.getCleans();
    }

    selectAllFilters() {
        this.clean.group = this.grpList;
        this.clean.region = this.regionList;
        this.clean.area = this.areaList;
        this.clean.territory = this.territoryList;
        this.clean.user = this.mobileUserList;
        this.clean.rating = this.ratingList;
        this.clean.methodId = this.cleanTypesList;
        this.refreshReport();
    }

    deSelectAllFilters() {
        this.clean.group = [];
        this.clean.region = [];
        this.clean.area = [];
        this.clean.territory = [];
        this.clean.user = [];
        this.clean.rating = [];
        this.clean.methodId = [];
        this.clean.shipTo = null;
        this.clean.deviceId = '';
        this.clean.outletName = '';
        this.refreshReport();
    }

    getCleans() {
        const params = {
            skip: this.state.skip,
            take: this.state.take,
            organisationId: +this.clean.organisationId,
            from: setCurrentTime(this.clean.from),
            to: setCurrentTime(this.clean.to),
            group: this.clean.group.map(grp => grp.id),
            region: this.clean.region.map(rgn => rgn.id),
            area: this.clean.area.map(area => area.id),
            territory: this.clean.territory.map(terr => terr.id),
            user: this.clean.user.map(user => user.id) || undefined,
            rating: this.clean.rating.map(rating => rating.id),
            userId: 0,
            outletName: this.clean.outletName,
            deviceID: this.clean.deviceId,
            isExport: false,
            methodIds: this.clean.methodId.map(method => method.id) || [],
            typeOfCleanIds: this.clean.methodId.reduce((acc, method) => {
                method.typeOfCleanId !== null ? acc.push(method.typeOfCleanId) : method
                return acc;
            }, []) || [],
        }
        if (+this.clean.shipTo) {
            params['shipTo'] = +this.clean.shipTo;
        }

        if (this.userRole === Role[3]) {
            params['userId'] = this.loggedInUserService.getUserData('id');
        }

        this.cleanService.getCleans(params).subscribe((res: ObjectResponseModel) => {
            this.commonService.setLoader(false);
            if (res) {
                this.cleanList = res.data;
                convertTimeZone(res.data.cleanListResponses, ['visitStart', 'visitEnd', 'cleanRunFinishDateTime','cleanRunStartDateTime', 'cleanStartDateTime', 'cleanFinishDateTime'], this.timeZone);
                if (res.data.cleanListResponses.length !== 0) {
                    this.noOfSteps = res.data.cleanListResponses[0].numberofSteps
                }


                if (res.statusCode === constants.success) {
                    this.loadData();
                }

            }
        });
    }

    allData() {
        const params = {
            skip: 0,
            take: this.cleanList.totalCount,
            organisationId: +this.clean.organisationId,
            from: setCurrentTime(this.clean.from),
            to: setCurrentTime(this.clean.to),
            userId: 0,
            group: this.clean.group.map(grp => grp.id),
            region: this.clean.region.map(rgn => rgn.id),
            area: this.clean.area.map(area => area.id),
            territory: this.clean.territory.map(terr => terr.id),
            user: this.clean.user.map(user => user.id) || undefined,
            rating: this.clean.rating.map(rating => rating.id),
            outletName: this.clean.outletName,
            deviceID: this.clean.deviceId,
            isExport: true,
            methodIds: this.clean.methodId.map(method => method.id) || [],
            typeOfCleanIds: this.clean.methodId.reduce((acc, method) => {
                method.typeOfCleanId !== null ? acc.push(method.typeOfCleanId) : method
                return acc;
            }, []) || [],
        };

        if (+this.clean.shipTo) {
            params['shipTo'] = +this.clean.shipTo;
        }

        if (this.userRole === Role[3]) {
            params['userId'] = this.loggedInUserService.getUserData('id');
        }
        let resData = [];
        return new Promise((resolve, reject) => {
            this.cleanService.getCleans(params).subscribe((res: ObjectResponseModel) => {

                this.commonService.setLoader(false);
                if (res) {
                    if (res.statusCode === constants.success) {
                        this.commonService.setLoader(false);
                        if (res.data.cleanListResponses.length > 0) {
                            resData = res.data.cleanListResponses;
                            const result: ExcelExportData = {
                                data: resData,
                                group: []
                            };
                            resolve(result);
                        }
                        else {
                            this.toastr.error('No. records to Export Data')
                            return false;
                        }
                    } else if (res.statusCode === constants.error) {
                        this.commonService.setLoader(false);
                        reject();
                    }
                }
            });
        });
    }

    genArray(length: number) {
        return length > 0 ? Array.from(Array(length).keys()) : [];
    }

    isItemSelected(selectedArray, id) {
        return selectedArray.some(item => item.id === id);
    }

    isItemSelectedByIndex(selectedArray, id) {
        return selectedArray.some(item => item.index === id);
    }

    ngOnDestroy(): void {
        this.isMobileSubscriber.unsubscribe();
    }

    selectAllOptions(target: string, destination: string) {
        (this.clean[target].length === this[destination].length) ? this.clean[target] = [] : this.clean[target] = this[destination];

        if (target === 'group') {
            this.changeGroup(this.clean[target]);
        }
        if (target === 'region') {
            this.changeRegion(this.clean[target]);
        }
        if (target === 'area') {
            this.changeArea(this.clean[target]);
        }
    }

    doReportSearch() {
        this.defaultOptionsCount === this.defaultOptionsStatusList.length ? this.refreshReport() : undefined;
    }
}
