import { Component, OnInit, Input, SimpleChanges, AfterViewInit, OnChanges, DoCheck, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ViewRef } from "@angular/core";
import { constants } from 'src/app/shared/directives/constants';
import { DatePipe } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-bar-chart",
  templateUrl: "./bar-chart.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BarChartComponent implements OnInit, OnChanges {
  readonly constants = constants;
  @Input() data: [];
  chartTitle = 'Clean session Summary';

  totalCount: number;


  public chartOptions = {
    title: {
      text: "Clean session Summary"
    },
    legend:{
      visible: false
    },
    seriesDefaults: {
      type: "column",
    },
    valueAxis: {
      title: {
        text: "Clean session",
      },
      line: {
        visible: false
      }
    },
    categoryAxis: {
      type:'date',
      title: {
        text: "Dates",
      },
      majorGridLines:{
        visible: false
      },
      baseUnit: 'days',
      baseUnitStep: 1,
      maxDivisions: 5,
      labels: {
        position: "start",
        rotation: "auto",
        visible:true,
        content: e => new DatePipe('en').transform(e.value, constants.dateFormatGraph)
      }
    },
  };

  barChartResponse = [];
  cdFlag: boolean = false;
  constructor(private translate: TranslateService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
  this.translate.onLangChange.subscribe( (res) => {
    this.translationChanges();
  });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.barChartResponse = JSON.parse(JSON.stringify(this.data));
    this.totalCount = this.barChartResponse.map(x => x.count).reduce((a, b) => a + b, 0);
    this.barChartResponse.forEach((x : {date: number , count: number, dateField: Date}) => { 
      x.dateField =  new Date(x.date*1000); 
    });
    this.translationChanges();
  }

  translationChanges() {
    this.translate.get(['Total', 'Clean Session Summary', 'Clean Session', 'Dates']).subscribe((res) => {
      this.chartOptions.title = { text: `${res['Total']}: ${this.totalCount} ${res['Clean Session Summary']}`};

      this.chartOptions.valueAxis = {
        title: {
          text: res['Clean Session'],
        },
        line: {
          visible: false
        }
      }

      this.chartOptions.categoryAxis = {
        type:'date',
        title: {
          text: res['Dates'],
        },
        majorGridLines:{
          visible: false
        },
        baseUnit: 'days',
        baseUnitStep: 1,
        maxDivisions: 5,
        labels: {
          position: "start",
          rotation: "auto",
          visible:true,
          content: e => new DatePipe('en').transform(e.value, constants.dateFormatGraph)
        }
      }

      if(!this.cdFlag) {
        this.cd.detectChanges();
      }
      
    });
  }

  ngOnDestroy(): void {
    this.cdFlag = true;
    this.cd.markForCheck();
  }
}
