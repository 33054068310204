import { Component, OnInit, SimpleChanges, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SeriesDefaults, SeriesDefaultsLabels } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieChartComponent implements OnInit {
  @Input() data;
  @Input() isReason = false;
  invisibleLegendIndexes = [];
  invisibleNameIndexes = [];
  totalCount: number;
  public seriesColors: string[] = [
    "#ff6358",
    "#ffd246",
    "#e91e63",
    "#78e08f",
    "#d35400",
    "#aa46be",
    "#0275d8",
    "#5bc0de",
    "#5cb85c",
    "#f0ad4e",
  ];

  public chartOptions = {
    title: {
      text: 'Clean Types'
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        distance: 0
      }
    },
    seriesDefaults: {
      type: "pie",
      labels: {
        visible: true,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = `${e.category}: ${e.value} (${(e.percentage * 100).toFixed(1)}%)`;
          if (this.isReason) {
            return `${marker}`;
          } else {
            return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`
          }
        }
      }
    },
  };

  pieChartResponse = [];
  cdFlag = false;
  constructor(private translate: TranslateService, private cd: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((res) => {
      this.translationChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // debugger;
    if(this.data.length>0){
      this.pieChartResponse = this.data;
    }
      // debugger;
      this.totalCount = this.pieChartResponse.map(x => x.count).reduce((a, b) => a + b, 0);
      this.cd.detectChanges();
    this.translationChanges();
  }

  translationChanges() {
    this.translate.get(['Clean Types']).subscribe((res) => {
      this.chartOptions.title = { text: `Total: ${this.totalCount ? this.totalCount : 0} ${res['Clean Types']}` };
    });
    if (!this.cdFlag) {
      this.cd.detectChanges();
    }
  }

  doCalculation($event) {
    if ($event) {
      const data = $event;
      const dataList: Array<any> = data.series.data;
      const currentDataIndex = data.pointIndex;
      // If found then pop
      if (this.invisibleLegendIndexes.includes(currentDataIndex)) {
        const index = this.invisibleLegendIndexes.findIndex(x => x === currentDataIndex);
        const nameIndex = this.invisibleNameIndexes.findIndex(x => x === data.text);
        this.invisibleLegendIndexes.splice(index, 1);
        this.invisibleNameIndexes.splice(nameIndex, 1);
      } else {
        // else not found then push
        this.invisibleLegendIndexes.push(currentDataIndex);
        this.invisibleNameIndexes.push(data['text']);
      }
      const availableList = dataList.reduce((acc, cv) => {
        this.invisibleNameIndexes.includes(cv['name']) ? acc : acc.push(cv);
        return acc;
      }, []);
      // Re-calculate line percentage
      const totalNumberOfLines = availableList.reduce((acc, x) => acc += x['lineCount'], 0);
      availableList.forEach((element) => {
        const index = dataList.findIndex(x => x.name === element.name);
        if (index !== -1) {
          const result = totalNumberOfLines != 0 ? ((element['lineCount'] * 100) / totalNumberOfLines).toFixed(1) : 0;
          this.data[index]['linePercentage'] = result;
        }
      });
    }

  }

  ngOnDestroy(): void {
    this.cdFlag = true;
    this.cd.markForCheck();
  }

}
