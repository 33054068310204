export const  stepMesurements  = [ {
    field: 'stepNumber',
    label: 'Step Number',
  },
  {
    field: 'stepName',
    label: 'Step Name',
  },
  {
    field: 'liquidTime',
    label: 'Liquid Time',
    subText: 'step time'
  },
  {
    field: 'liquidVolTotal',
    label: 'Liquid Vol Total',
  },
  {
    field: 'liquidFlowRateAverage',
    label: 'Liquid Flow Rate average',
  },
  {
    field: 'maxLiquidFlowRate',
    label: 'Max Liquid Flow Rate',
  },
  {
    field: 'minLiquidFlowRate',
    label: 'Min Liquid Flow Rate',
  },{
    field: 'conductivityAvg',
    label: 'Conductivity Avg',
  },{
    field: 'maxConductivity',
    label: 'Max Conductivity',
  },{
    field: 'minConductivity',
    label: 'Min Conductivity',
  },{
    field: 'temperatureAvg',
    label: 'Temperature Avg',
  },{
    field: 'maxTemp',
    label: 'Max Temp',
  },{
    field: 'minTemp',
    label: 'Min Temp',
  }
];
  