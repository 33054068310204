import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { AreasService } from 'src/app/services/areas.service';
import { Bodyparamarea, AreaModel } from 'src/app/models/areas.model';
import { SearchDataPipe } from 'src/app/pipes/search-data.pipe';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { MESSAGE } from 'src/app/shared/messages';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from "@progress/kendo-data-query";
import { IKendoColumnModel } from 'src/app/models/kendoColumn.model';
import { convertTimeZone, getFormatedDate } from 'src/app/shared/handler.functions';
import { Role } from 'src/app/models/roles.enum';

@Component({
    selector: 'app-areas',
    templateUrl: './areas.component.html'
})
export class AreasComponent implements OnInit, OnDestroy {

    areaList: AreaModel[] = [];
    searchKeyword: string;
    selectedArea;
    areaIsEdit: boolean = false;
    public gridData: any = [];
    state: State = {
        skip: 0,
        take: 50
        //pageSize: 10
    };

    public multiple = false;
    public allowUnsort = true;
    public sort: SortDescriptor[] = [
        {
            field: "",
            dir: "asc"
        }
    ];

    // Modal Popup
    modalRef: BsModalRef;
    confirmModalRef: BsModalRef;

    fromParent;
    rgnsubscription: Subscription;

    bodyParamArea: Bodyparamarea = {
        "organisationId": this.loggedInUserService.getUserData('organisationId'),
        "groupId": 0,
        "regionId": 0
    };

    // archivearea: Archivearea;
    // testdata;

    areasubscription: Subscription;

    searchableColumns = ["name", "organisationName", "groupName", "regionName"];
    columns: IKendoColumnModel[];

    @ViewChild('areaDetail', { static: true }) areaDetailModal: TemplateRef<any>;
    timeZone: string;

    constructor(
        private modalService: BsModalService,
        private areasService: AreasService,
        private commonService: CommonService,
        private toastr: ToastrService,
        private activeRoute: ActivatedRoute,
        private loggedInUserService: LoggedInUserService,
        private router: Router
    ) { }

    ngOnInit() {
        this.getAreas();

        this.timeZone = this.loggedInUserService.getUserData('timezone');

        const role = this.loggedInUserService.getUserData('role');
        this.areasubscription = this.activeRoute.queryParams.subscribe(
            (params) => {
                if (Object.keys(params).length !== 0) {
                    this.router.navigate([], { replaceUrl: true });
                    this.selectedArea = { organisationId: params.orgId, groupId: params.grpId, regionId: params.rgnId }
                    this.openAreaModal(this.areaDetailModal);
                    this.commonService.setLoader(false);
                }
            }
        );

        this.columns = [
            {
                field: "name",
                title: "Name",
                width: 200,
                cellTextCenter: false,
                groupable: false,
                locked: true
            },
            {
                field: "organisationName",
                title: "Organisation",
                width: 200,
                display: role !== Role[1],
                groupable: true
            },
            {
                field: "groupName",
                title: "Group",
                width: 200,
                groupable: true
            },
            {
                field: "regionName",
                title: "Region",
                width: 200,
                groupable: true
            },
            {
                field: "createdAt",
                title: "Created On",
                width: 120,
                cellTextCenter: true,
                groupable: false,
                cellFormat: data => getFormatedDate(data.createdAt)
            },
            {
                field: "createdBy",
                title: "Created By",
                width: 200,
                groupable: false
            },
            {
                field: "updatedAt",
                title: "Updated On",
                width: 130,
                cellTextCenter: true,
                groupable: false,
                cellFormat: data => getFormatedDate(data.updatedAt) || '-'
            },
            {
                field: "updatedBy",
                title: "Updated By",
                width: 200,
                groupable: false,
                cellFormat: (data) => data.updatedBy || "-"
            },
        ];
    }

    // REALTED to grid Functionality paging , sorting , groupping
    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.loadItems();
    }

    // REALTED to grid Functionality paging , sorting
    public pageChange(event: PageChangeEvent): void {
        this.state.skip = event.skip;
        this.loadItems();
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.loadItems();
    }

    private loadItems() {
        const searchedRecords = new SearchDataPipe().transform(
            this.areaList,
            this.searchKeyword,
            this.searchableColumns
        );
        // this.gridData = {
        //   data: orderBy(searchedRecords, this.sort).slice(
        //     this.state.skip,
        //     this.state.skip + this.state.pageSize
        //   ),
        //   total: this.areaList.length
        // };

        this.gridData = process(searchedRecords, this.state);
    }

    private openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered',
            ignoreBackdropClick: true
        });
    }

    public openAreaModal(template: TemplateRef<any>) {
        this.areaIsEdit = false;
        this.openModal(template);
    }

    // add new area
    public addArea(template: TemplateRef<any>) {

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.router.navigate(["/"]);
            return;
        }

        this.selectedArea = { id: 0, userId: JSON.parse(localStorage.getItem('userData')).id };
        this.openAreaModal(template);
    }

    // for search
    public filterSearch() {
        this.loadItems();
        this.state.skip = 0;
    }

    // Final Submit
    public submitModalPopup(template: TemplateRef<any>) {
        this.getAreas();
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // Close Modal popup
    public closeModalPopup() {
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // edit area
    public editArea(area, template: TemplateRef<any>) {
        this.areaIsEdit = true;
        this.selectedArea = Object.assign({}, area);
        this.openModal(template);
    }

    // confirmation for archive
    public askForConfirmation(area, template: TemplateRef<any>) {
        this.selectedArea = area;
        this.confirmModalRef = this.modalService.show(template, {
            class: "modal-confirm modal-dialog-centered second",
            ignoreBackdropClick: true
        });
    }

    public dismissActiveInactiveConfirmModal() {
        this.confirmModalRef.hide();
        this.confirmModalRef = null;
    }

    public finallyCloseStatusConfirmModal(area) {
        this.archivedArea(area);
        this.dismissActiveInactiveConfirmModal();
    }

    // archive group or change status
    private archivedArea(area) {

        if (!(localStorage.getItem('userData') && localStorage.getItem('userData') != null)) {
            this.dismissActiveInactiveConfirmModal();
            this.router.navigate(["/"]);
            return;
        }

        const bodyParams = {
            id: area.id,
            archived: !area.archived,
            updatedBy: JSON.parse(localStorage.getItem("userData")).id
        };
        this.areasService.archiveArea(bodyParams).subscribe(
            (res) => {
                if (res) {
                    if (res.statusCode === 200 && res.data > 0) {
                        this.toastr.success(MESSAGE.ARCHIVED_DONE_SUCCESSFULLY, "");
                        this.getAreas();
                        this.commonService.setLoader(false);
                    } else if (res.statusCode === 404) {
                        this.commonService.setLoader(false);
                        if (res.data === -3) {
                            this.toastr.error(MESSAGE.UNABLE_TO_DEACTIVATE_AREA, "", { timeOut: 10000 });
                        } else if (res.data === -4) {
                            this.toastr.error(MESSAGE.UNABLE_TO_ACTIVATE_AREA, "", { timeOut: 10000 });
                        }
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    private getAreas(): void {
        const bodyParams = this.bodyParamArea;
        this.areasService.getAreasList(bodyParams).subscribe(
            (res) => {
                this.commonService.setLoader(false);
                if (res && res.statusCode === 200 && res.data) {

                    convertTimeZone(res.data, ['createdAt', 'updatedAt'], this.timeZone);
                    
                    this.areaList = res.data;

                    this.loadItems();
                }
                else {

                }
            },
            (error) => {
            }
        );
    }

    goToTerritory(orgId, grpId, rgnId, areaId) {
        this.router.navigate(['app/organisationDetail/territories'], { queryParams: { orgId, grpId, rgnId, areaId }, skipLocationChange: true });
    }

    ngOnDestroy(): void {
        this.areasubscription && this.areasubscription.unsubscribe();
    }

}
