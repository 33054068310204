export class StepSettings {
    id: number;
    guid: string;
    runSettingsId: number;
    stepNumber: number;
    stepName: string;
    stepType: number;
    liquidTimeout1: number;
    liquidTimeNextStep: number;
    liquidVol: number;
    liquidVolNextStep: number;
    inputButton: number;
    inputNextStep: number;
    liquidTimeout2: number;
    spareNextStep: number;
    maxConductivity: number;
    minConductivity: number;
    constructor() {}
  }
  