import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';

@Injectable()
export class MobileUserService {

  constructor(private restAPI: RestApi) { }

  // get list of users
  getUserList(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.USERS.GET_USERS}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // edit or add mobile users
  saveUser(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.USERS.SAVE_USER}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // change user password
   changeAccountPassword(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.USERS.CHANGE_USER_PASSWORD}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // archive user
  archiveUser(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.USERS.ARCHIVE_USER}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  //lock unlock user
  lockUnlockUser(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.USERS.LOCK_UNLOCK_USER}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }
}
