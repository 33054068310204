import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CleanGenComponent } from './clean-gen.component';
import { ManageCleanGenComponent } from './manage-clean-gen/manage-clean-gen.component';
import { FormsModule } from '@angular/forms';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CleanGenComponent, ManageCleanGenComponent],
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    ExcelModule,
    RouterModule,
    TranslateModule
  ]
})
export class CleanGenModule { }
