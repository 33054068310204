import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { Role } from 'src/app/models/roles.enum';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  changePassword: boolean = false;
  menus = [];
  role: string;
  isMobile: boolean = false;
  isMobileSubscriber: Subscription;
  status: boolean = false;
  currentRoute: string = '';
  selectedItem = "dashboard";

  constructor(private renderer: Renderer2, private router: Router, private loggedInUserService: LoggedInUserService, private commonService: CommonService, private translateService: TranslateService, private http: HttpClient) {
    this.role = this.loggedInUserService.getUserData('role');
    const superAdmin = this.role === Role[1];
    const organisationAdmin = this.role === Role[2];
    const webUser = this.role === Role[3];
    const superOrgUser = this.role === Role[4];
    const orgSuperAdmin = this.role === Role[5];
    const serviceUser = this.role === Role[6];
    this.isMobileSubscriber = this.commonService.getIsMobile().subscribe((res: boolean) => {
      this.isMobile = res;
    });
    this.menus = [
      {
        id: 'lineCleaning',
        title: 'Line Cleaning',
        display: superAdmin || organisationAdmin || webUser || superOrgUser || orgSuperAdmin || serviceUser,
        list: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            icon: 'la la-dashboard',
            display: superAdmin || organisationAdmin || webUser || superOrgUser || orgSuperAdmin || serviceUser,
            routePath: '/app/dashboard'
          },
          {
            id: 'cleans',
            title: 'Cleans',
            icon: 'la la-refresh',
            display: superAdmin || organisationAdmin || webUser || superOrgUser || orgSuperAdmin || serviceUser,
            routePath: '/app/cleans'
          },
          {
            id: 'sessions',
            title: 'Sessions',
            icon: 'la la-map-pin',
            display: superAdmin || organisationAdmin || webUser || superOrgUser || orgSuperAdmin || serviceUser,
            routePath: '/app/visits'
          },
          {
            id: 'history',
            title: 'History',
            icon: 'la la-history',
            display: superAdmin || organisationAdmin || webUser || superOrgUser || orgSuperAdmin || serviceUser,
            routePath: '/app/history'
          }
        ]
      },
      {
        id: 'organisationAdmin',
        title: 'Organisation Admin',
        display: superAdmin || superOrgUser || orgSuperAdmin || organisationAdmin,
        list: [
          {
            id: 'organisationDetails',
            title: 'Organisation Details',
            icon: 'la la-sitemap',
            display: superAdmin || superOrgUser || orgSuperAdmin || organisationAdmin,
            routePath: '/app/organisationDetail'
          },
          {
            id: 'manageAccountsUsers',
            title: 'Manage Accounts/ Users',
            icon: 'la la-user',
            display: superAdmin || superOrgUser || orgSuperAdmin || organisationAdmin,
            routePath: '/app/users'
          },
          {
            id: 'outlets',
            title: 'Outlets',
            // icon: 'la la-building',
            image: '../../../assets/images/outlet.svg',
            display: superAdmin || superOrgUser || orgSuperAdmin || organisationAdmin,
            routePath: '/app/outlets'
          },
          {
            id: 'assetType',
            title: 'Asset Type',
            // icon: 'la la-plug',
            image: '../../../assets/images/asset.svg',
            display: superAdmin || superOrgUser || orgSuperAdmin,
            routePath: '/app/assetType'
          },
          {
            id: 'uploadBackground',
            title: 'Upload Background',
            icon: 'la la-upload',
            display: superAdmin || superOrgUser || orgSuperAdmin,
            routePath: '/app/uploadBackground'
          },
          {
            id: 'reasons',
            title: 'Reasons',
            icon: 'la la-question-circle',
            display: superAdmin || superOrgUser || orgSuperAdmin,
            routePath: '/app/reasons'
          },
          {
            id: 'syncLog',
            title: 'Sync Logs',
            icon: 'la la-files-o',
            display: superAdmin || organisationAdmin || superOrgUser || orgSuperAdmin,
            routePath: '/app/syncLogs'
          },
          {
            title: 'Email Configuration',
            icon: 'la la-cog',
            display: superAdmin || organisationAdmin || superOrgUser || orgSuperAdmin,
            routePath: '/app/emailConfiguration'
          }
        ]
      },
      {
        id: 'deviceAdmin',
        title: 'Device Admin',
        display: superAdmin || superOrgUser || serviceUser,
        list: [
          {
            id: 'vortexBT',
            display: superAdmin || superOrgUser || serviceUser,
            routePath: '/app/vortex',
            icon: 'la la-laptop',
            title: 'Vortex - BT'
          },
          {
            id: 'verxBT',
            display: superAdmin || superOrgUser || serviceUser,
            routePath: '/app/verx',
            icon: 'la la-laptop',
            title: 'Verx - BT'
          },
          {
            id: "verxLite",
            display: superAdmin || superOrgUser || serviceUser,
            routePath: "/app/verx-lite",
            icon: "la la-laptop",
            title: "Verx Lite – BT",
          },
          {
            id: 'PLCSBT',
            display: superAdmin || superOrgUser || serviceUser,
            routePath: '/app/plcs',
            icon: 'la la-laptop',
            title: 'PLCS - BT'
          },
          {
            id: 'VortexI - BT',
            display: superAdmin || superOrgUser || serviceUser,
            routePath: '/app/vortexi',
            icon: 'la la-laptop',
            title: 'VortexI – BT'
          },
          {
            id: 'VortexN - BT',
            display: superAdmin || superOrgUser || serviceUser,
            routePath: '/app/vortexn',
            icon: 'la la-laptop',
            title: 'VortexN – BT'
          },
        ]
      }
    ]

  }

  ngOnInit() {
    let resp = this.http.get(`src/asset/i18n/en-US`);
  }

  doChangePassword() {
    this.changePassword = true;
  }

  changeRoute(routePath: string) {

    this.currentRoute = routePath;

    if (routePath === '/app/organisationDetail') {
      if ([Role[1]].includes(this.role)) {
        this.router.navigate(['/app/organisationDetail/organisations']);
      } else {
        this.router.navigate(['/app/organisationDetail/groups']);
      }
    }

    if (routePath === '/app/users') {
      this.router.navigate(['/app/users/web-accounts']);
    }
    if (routePath === '/app/reasons') {
      this.router.navigate(['/app/reasons/manual']);
    }

    if (routePath === '/app/vortex') {
      this.router.navigate(['/app/vortex/manageDevices']);
    }
    if (routePath === '/app/verx') {
      this.router.navigate(['/app/verx/manageDevices']);
    }
    if (routePath === '/app/plcs') {
      this.router.navigate(['/app/plcs/manageDevices']);
    }
    if (routePath === '/app/vortexi') {
      this.router.navigate(['/app/vortexi/manageDevices']);
    }
    if (routePath === '/app/vortexn') {
      this.router.navigate(['/app/vortexn/manageDevices']);
    }
    if (routePath === "/app/verx-lite") {
      this.router.navigate(["/app/verx-lite/manageDevices"]);
    }

    this.commonService.setIsMobile(false);
    this.commonService.onResize();
    if (this.isMobile) {
      this.renderer.removeClass(document.body, 'show-sidebar');
    }
  }

  clickEvent(event) {
    this.status = !this.status;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.isMobileSubscriber.unsubscribe();
  }
}
