import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';
import { ArchiveAssetType, SaveAssetType } from '../models/assetType.model';


@Injectable()
export class AssetTypeService {

    constructor(private restAPI: RestApi) { }

    // get list of Asset Type
    getAssetTypeList(params) {
        const url = RestApi.buildUrl(`${ENDPOINTS.ASSET_TYPE.GET_ASSET_TYPE}`);
        return this.restAPI.handleRequest(url, METHODS.Post, params);
    }

    // edit or add asset type
    saveAssetType(bodyParams: SaveAssetType) {
        const url = RestApi.buildUrl(`${ENDPOINTS.ASSET_TYPE.SAVE_ASSET_TYPE}`);
        return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
    }

    // archive group
    archiveGroup(bodyParams: ArchiveAssetType) {
        const url = RestApi.buildUrl(`${ENDPOINTS.ASSET_TYPE.ARCHIVE_ASSET_TYPE}`);
        return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
    }

}
