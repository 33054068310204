import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[allowOneDecimalPoint]"
})
export class AllowOneDecimalPoint {
  @Input() decimal: number;
  @Input() allowNegative?: boolean = false;

  constructor(private _el: ElementRef) { }

  @HostListener("keypress", ["$event"]) onInputChange(event) {
    if (this.allowNegative && event.which === 45) {
      if (this._el.nativeElement.value.indexOf('-') != -1) {
        return false;
      }
      return true;
    }
    if (event.which === 9) {
      return true;
    }
    if ((event.which != 46 || this._el.nativeElement.value.indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
      // alert(event.which);
      if (event.which != 46 && this._el.nativeElement.value.indexOf(".") !== -1) {
        // only minus / subtract , decimal  others to be neglect
        event.preventDefault();
        return true;
      } else {
        return false;
      }
      // event.preventDefault();
    }
    // if (this._el.nativeElement.value.indexOf(".") > -1 && (this._el.nativeElement.value.split('.')[1].length > this.decimal - 1)) {
    //   alert('specific number only allowed after decimal point');
    //   return false;
    // }
  }
}