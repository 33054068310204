import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';

@Injectable()
export class DeviceService {

  constructor(private restAPI: RestApi) {}

  getDeviceList(bodyParams) {
    
    const url = RestApi.buildUrl(`${ENDPOINTS.DEVICE.GET_DEVICE}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  saveDevice(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DEVICE.SAVE_DEVICE}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  archiveDevice(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.DEVICE.ARCHIVE_DEVICE}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

}
