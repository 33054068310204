import { Component, OnInit,TemplateRef } from '@angular/core';
import { LoggedInUserService } from '../../../services/loggedInUser.service';
import { Role } from '../../../models/roles.enum';

@Component({
  selector: 'app-toggle-device',
  templateUrl: './toggle-device.component.html',
  styleUrls: ['./toggle-device.component.css']
})
export class ToggleDeviceComponent implements OnInit {

  roleType;
  routes;
  constructor(private loggedInUserService: LoggedInUserService) {
  }
  ngOnInit() {
    this.roleType = this.loggedInUserService.getUserData('role');

    const superAdmin = this.roleType === Role[1];
    this.routes = [{
      title: 'Device',
      display: superAdmin,
      path: 'manageDevices'
    },
    {
      title: 'Clean Parameters',
      display: superAdmin,
      path: 'cleanparams'
    },
    {
      title: 'Default',
      display: superAdmin,
      path: 'multipleDefaultSetting'
    }
    ]
  }

}
