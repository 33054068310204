import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';
import { generateQueryParams } from '../shared/handler.functions';

@Injectable()
export class OrganizationService {

  constructor(private restAPI: RestApi) { }

  getOrganizationList() {
    const url = RestApi.buildUrl(`${ENDPOINTS.ORGANIZATION.GET_ORGANIZATION}`);
    return this.restAPI.handleRequest(url, METHODS.Get);
  }

  getOrganizationById(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ORGANIZATION.GET_ORGANIZATION_By_Id}`);
    return this.restAPI.handleRequest(url, METHODS.Get,generateQueryParams(params));
  }

  saveOrganization(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ORGANIZATION.SAVE_ORGANIZATION}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  archiveOrganisation(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.ORGANIZATION.ARCHIVE_ORGANIZATION}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // get cleanTypes for dashboard dropdown
  getCleanTypes(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.METHOD.GET_METHOD}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }
}
