import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '../auth/auth.module';
import { TabLayoutComponent } from './tab-layout/tab-layout.component';
import { TranslateModule , TranslateLoader} from '@ngx-translate/core';
import { config } from 'rxjs';

@NgModule({
  declarations: [HeaderComponent, SidebarComponent,LayoutComponent,TabLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    AuthModule,
    TranslateModule
  ],
  exports: [LayoutComponent,TabLayoutComponent]
})
export class LayoutModule { }
