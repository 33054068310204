import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationComponent } from './organization/organization.component';
import { LayoutModule } from '../layout/layout.module';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormsModule } from '@angular/forms';
import { OrganizationService } from '../services/organization.service';
import { ManageOrganizationComponent } from './organization/manage-organization/manage-organization.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { OrganizationDetailComponent } from './organization-detail.component';
import { GroupsComponent } from './groups/groups.component';
import { GroupsService } from '../services/groups.service';
import { ManageGroupsComponent } from './groups/manage-groups/manage-groups.component';
import { RegionsComponent } from './regions/regions.component';
import { RegionsService } from '../services/regions.service';
import { ManageRegionsComponent } from './regions/manage-regions/manage-regions.component';
import { AreasComponent } from './areas/areas.component';
import { ManageAreasComponent } from './areas/manage-areas/manage-areas.component';
import { AreasService } from '../services/areas.service';
import { TerritoriesComponent } from './territories/territories.component';
import { ManageTerritoriesComponent } from './territories/manage-territories/manage-territories.component';
import { TerritoriesService } from '../services/territories.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [OrganizationComponent, ManageOrganizationComponent,OrganizationDetailComponent, GroupsComponent, ManageGroupsComponent, RegionsComponent, ManageRegionsComponent, AreasComponent, ManageAreasComponent, TerritoriesComponent, ManageTerritoriesComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    RouterModule,
    LayoutModule,
    GridModule,
    ExcelModule,
    DialogModule,
    TranslateModule
  ],
  providers: [OrganizationService, GroupsService, RegionsService, AreasService, TerritoriesService],
})

export class OrganizationDetailModule { }
