import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LayoutModule } from '../layout/layout.module';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { DashboardService } from '../services/dashboard.service';
import 'hammerjs';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { DashboardDetailComponent } from './dashboard-detail/dashboard-detail.component';
import { RouterModule } from '@angular/router';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { TranslateModule } from '@ngx-translate/core';
import { AzureMapComponent } from './azure-map/azure-map.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DashboardComponent, BarChartComponent, PieChartComponent, LineChartComponent, DonutChartComponent, DashboardDetailComponent, AzureMapComponent],
  imports: [
    CommonModule,
    SharedModule,
    LayoutModule,
    RouterModule,
    ChartsModule,
    FormsModule,
    GridModule,
    ExcelModule,
    TranslateModule
  ],
  providers:[DashboardService]
})
export class DashboardModule { }
