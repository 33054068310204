import { Component, OnInit } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { OrganizationModel } from '../models/organization.model';
import { Role } from '../models/roles.enum';
import { LoggedInUserService } from '../services/loggedInUser.service';
import { HistoryRequestModel } from '../models/historyRequest.model';
import { SharedService } from '../services/shared.service';
import { NgForm } from '@angular/forms';
import { constants } from '../shared/directives/constants';
import { HistoryListModel } from '../models/history.model';
import { CommonService } from '../services/common.service';
import { IKendoColumnModel } from '../models/kendoColumn.model';
import { getFormatedDate, setCurrentTime } from '../shared/handler.functions';
import { VisitService } from '../services/visit.service';
import { ObjectResponseModel } from '../models/reponse.model';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
// import { AbstractEmitterVisitor } from '@angular/compiler/esm2020/src/output/abstract_emitter';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { convertTimeZone } from './../shared/handler.functions';

enum colorRating {
  green = 1,
  amber,
  red
}
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html'
})

export class HistoryComponent implements OnInit {

  readonly constants = constants;
  readonly Role = Role;
  readonly rating = colorRating;
  orgList: OrganizationModel[] = [];
  history: HistoryRequestModel;
  historyList: HistoryListModel;
  userRole: string;
  todaysDate = new Date();
  timeZone: string;

  // Grid
  gridView: GridDataResult;
  state: State = {
    skip: 0,
    take: 50
  };
  columns: IKendoColumnModel[];
  isMobile = false;
  isMobileSubscriber: Subscription;

  constructor(
    private toastr: ToastrService,
    private loggedInUserService: LoggedInUserService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private visitService: VisitService) {
    this.allData = this.allData.bind(this);
    this.isMobileSubscriber = this.commonService.getIsMobile().subscribe((res: boolean) => {
      this.isMobile = res;
      this.bindColumns();
    });
  }

  bindColumns() {
    this.columns = [
      {
        field: 'outletShipTo',
        title: 'Ship To',
        width: 120,
        locked: true
      },
      {
        field: 'outletName',
        title: 'Outlet',
        width: 150,
        locked: !this.isMobile
      },
      {
        field: 'territory',
        title: 'Territory',
        width: 150,
        cellFormat: (data) => data.territory || '-'
      },
      {
        field: 'assetName',
        title: 'Asset',
        width: 150,
      }
    ];
  }

  ngOnInit() {
    this.userRole = this.loggedInUserService.getUserData('role');
    this.timeZone = this.loggedInUserService.getUserData('timezone');
    this.history = new HistoryRequestModel();
    this.history.organisationId = null;
    this.history.from = new Date();
    this.history.to = new Date();
    this.bindColumns();
    if (this.userRole === Role[1]) {
      this.sharedService.getOrgList(true).then((res: OrganizationModel[]) => {
        this.orgList = res;
        if (!this.history.organisationId) {
          //this.history.organisationId = this.orgList[0].id;
          this.getHistory();
        }
      });
    } else {
      this.history.organisationId = this.loggedInUserService.getUserData('organisationId');
      this.getHistory();
    }
  }

  // REALTED to grid Functionality paging , sorting , groupping
  pageChange(page: PageChangeEvent): void {
    this.state.skip = page.skip;
    this.getHistory();
  }

  groupChange(group: GroupDescriptor[]) {
    this.state.group = group;
    this.loadData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.state.sort = sort;
    this.loadData();
  }

  loadData() {
    // this.gridView = process(this.historyList.historyResponse, this.state);
    this.gridView = {
      data: process(this.historyList.histories, { group: this.state.group, sort: this.state.sort }).data,
      total: this.historyList.filterCount
    }
  }

  refreshReport(event, form: NgForm) {
    event.stopPropagation();
    if (form.form.invalid) {
      return;
    }
    this.state.skip = 0;
    this.getHistory();
  }

  getHistory() {
    const params = {
      skip: this.state.skip,
      take: this.state.take,
      organisationId: +this.history.organisationId,
      from: setCurrentTime(this.history.from),
      to: setCurrentTime(this.history.to),
      outletName: this.history.outletName,
      territoryName: this.history.territoryName
    }
    if (+this.history.shipTo) {
      params['shipTo'] = +this.history.shipTo
    }

    if (this.userRole === Role[3]) {
      params['userId'] = this.loggedInUserService.getUserData('id');
    }

    this.visitService.getHistory(params).subscribe((res: ObjectResponseModel) => {
      this.commonService.setLoader(false);
      if (res) {

        //function to convert date as per timezone
        convertTimeZone(res.data.histories, ['firstVisitDate', 'secondVisitDate', 'thirdVisitDate'], this.timeZone);

        this.historyList = res.data;
        if (res.statusCode === constants.success) {
          this.loadData();
        }
      }
    });

  }

  // export all data
  allData() {
    const params = {
      skip: 0,
      take: this.historyList.filterCount,
      organisationId: +this.history.organisationId,
      from: setCurrentTime(this.history.from),
      to: setCurrentTime(this.history.to),
      outletName: this.history.outletName,
      territoryName: this.history.territoryName
    }
    let resData = [];
    return new Promise((resolve, reject) => {
      this.visitService.getHistory(params).subscribe((res: ObjectResponseModel) => {
        if (res) {
          if (res.statusCode === constants.success) {
            if (res.data.histories.length > 0) {
              this.commonService.setLoader(false);
              resData = res.data.histories;
              const result: ExcelExportData = {
                data: resData,
                group: []
              };
              resolve(result);
            } else {
              this.toastr.error('No. records to Export Data')
              return false;
            }
          } else if (res.statusCode === constants.error) {
            console.log('error');
            this.commonService.setLoader(false);
            reject();
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.isMobileSubscriber.unsubscribe();
  }
}