export enum Error {
    'Power Outage Recovery' = 1,
    'Temprature' = 4,
    'No Detergent' = 6,
    'No Detergent Timeout' = 7,
    'No FOB' = 9,
    'High Level Triggered (No Flow)' = 11,
    'Timeout Triggered(no LL)' = 12,
    'Vol Limit Triggered(no HL)' = 13,
    'No Flow Error' = 14,
    'Lid Open Error' = 15
}