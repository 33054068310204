import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { WebAccountService } from 'src/app/services/web-account.service';
import { CommonService } from 'src/app/services/common.service';
import { constants } from 'src/app/shared/directives/constants';

@Component({
  selector: 'app-auth-code',
  templateUrl: './auth-code.component.html'
})
export class AuthCodeComponent implements OnInit {
  @Output() close = new EventEmitter();

  manualEntryKey: string;
  imageUrl: string;
  constructor(private loggedInUserService : LoggedInUserService, private webAccountService: WebAccountService,private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.setLoader(true);
    this.get2FADetail();
  }

  get2FADetail() {
    const params = {
      userId: this.loggedInUserService.getUserData('id'),
      updatedBy: this.loggedInUserService.getUserData('id')
    }
    this.webAccountService.reset2FA(params).subscribe(res => {
      if(res) {
        this.commonService.setLoader(false);
        if(res.statusCode === constants.success && res.data) {
          this.manualEntryKey = res.data.manualEntrykey,
          this.imageUrl = res.data.qrcodeImageurl
        }
      }
    })
  }
  dismiss() {
    this.close.emit();
  }

}
