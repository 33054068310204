import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';
import { Bodyparamregion, Archiveregion, Saveregion } from '../models/regions.model';

@Injectable()
export class RegionsService {
  constructor(private restAPI: RestApi) { }

  // get list of regions
  getRegionsList(bodyParams: Bodyparamregion) {
    const url = RestApi.buildUrl(`${ENDPOINTS.REGIONS.GET_REGIONS}`);
    return this.restAPI.handleRequest(url, METHODS.Post, bodyParams);
  }

  // archive regions
  archiveRegion(bodyParams: Archiveregion) {
    const url = RestApi.buildUrl(`${ENDPOINTS.REGIONS.ARCHIVE_REGION}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // edit or add region
  saveRegion(bodyParams: Saveregion) {
    const url = RestApi.buildUrl(`${ENDPOINTS.REGIONS.SAVE_REGION}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

}
