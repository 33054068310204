import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { VisitService } from 'src/app/services/visit.service';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { constants } from 'src/app/shared/directives/constants';
import { CleanModel } from 'src/app/models/clean.model';
import { stepMesurements } from '../stepMeasurements.constants';
import { convertTimeZoneField, getFormatedDate } from 'src/app/shared/handler.functions';
import { Role } from 'src/app/models/roles.enum';
import { Error } from 'src/app/models/errorCode.enum';
import { CleanParamsService } from 'src/app/services/clean-params.service';

enum TypeOfClean {
    VortexSoak = 1,
    VortexRecirc,
    VerxAuto,
    VerxManual
}
@Component({
    selector: 'app-clean-details',
    templateUrl: './clean-details.component.html',
    styleUrls: ['./clean-details.component.css']
})
export class CleanDetailsComponent implements OnInit {
    readonly constants = constants;
    cleanId: number;
    cleanDetail: CleanModel;
    clean: CleanModel;
    noOfSteps = 8;
    noOfErrors = 6;
    stepMesurements = stepMesurements;
    filters;
    page;
    role = '';
    readonly Role = Role;
    readonly Error = Error;
    // Modal Refs
    modalRef: BsModalRef;
    // change for step Names
    stepNames: string[];
    selectedDeviceType: number;
    field : boolean;
    timeZone: string;

    constructor(
        private clearService : CleanParamsService,
        private router: Router,
        private route: ActivatedRoute,
        private cleanService: VisitService,
        private loggedInUserService: LoggedInUserService,
        private commonService: CommonService,
        private toastr: ToastrService,
        private modalService: BsModalService, ) {
        this.route.queryParams.subscribe(queryparams => {
            this.cleanId = queryparams.cleanId;
            this.filters = queryparams.filters;
            this.selectedDeviceType = parseInt(JSON.parse(this.filters).typeOfCleanId);
            this.page = queryparams.page;
        });


    }

    ngOnInit() {
        this.role = this.loggedInUserService.getUserData('role');
        this.timeZone = this.loggedInUserService.getUserData('timezone');
        this.getCleanDetail();
        if (this.selectedDeviceType === TypeOfClean.VortexSoak || this.selectedDeviceType === TypeOfClean.VortexRecirc) {
            this.stepNames = [
                "Detergent",
                "Detergent Extra",
                "Recirc",
                "Recirc Boost",
                "Dose Unit Rinse",
                "Recirc Rinse",
                "Rinse Target",
                "Rinse Extra"
            ];
        } else if (this.selectedDeviceType === TypeOfClean.VerxManual) {
            this.stepNames = [
                "Detergent",
                "Detergent Extra",
                "Dose Rinse",
                "Bypass Rinse",
                "Rinse Target",
                "Rinse Extra"
            ];
        } else if (this.selectedDeviceType === TypeOfClean.VerxAuto) {

            this.stepNames = [
                "R1 Pre Flush",
                "R2 Pre Flush",
                "R1 Flush",
                "R2 Flush", "R1 Charge1", "R2 Charge1",
                "R1 Soak1", "R2 Soak1", "R1 Charge2", "R2 Charge2", "R1 Soak2",
                "R2 Soak2", "R1 Dose Rinse", "R2 Dose Rinse", "R1 Rinse", "R2 Rinse"
            ];
        }
    }

    getCleanDetail() {
        const params = {
            cleanId: +this.cleanId
        };
        this.cleanService.getCleanDetail(params).subscribe((res) => {
            this.commonService.setLoader(false);
            if (res) {
                if (res.statusCode === constants.success) {
                    this.cleanDetail = res.data;
                    this.clean = { ...res.data };
                    this.cleanDetail.cleanRunStartDateTime = convertTimeZoneField(this.cleanDetail.cleanRunStartDateTime, this.timeZone)
                    this.cleanDetail.cleanRunFinishDateTime = convertTimeZoneField(this.cleanDetail.cleanRunFinishDateTime, this.timeZone);
                    this.cleanDetail.cleanStartDateTime = convertTimeZoneField(this.cleanDetail.cleanStartDateTime, this.timeZone);
                    this.cleanDetail.cleanFinishDateTime = convertTimeZoneField(this.cleanDetail.cleanFinishDateTime, this.timeZone);
                    this.cleanDetail.visitStart = convertTimeZoneField(this.cleanDetail.visitStart, this.timeZone);
                    this.cleanDetail.visitEnd = convertTimeZoneField(this.cleanDetail.visitEnd, this.timeZone);
                    this.cleanDetail.stepErrors = this.cleanDetail.stepErrors.filter(error => error.stepNumber !== 0);
                    this.noOfSteps = res.data.numberofSteps;
                } else if (res.statusCode === constants.error) {
                    this.router.navigate(['app/cleans']);
                }
            }
        });
    }

    backToCleans() {
        this.router.navigate(['app/cleans'], { queryParams: { filters: this.filters, page: this.page}, skipLocationChange: true });
        this.clearService.visibleField(true);
    }

    genArray(length: number) {
        return length > 0 ? Array.from(Array(length).keys()) : [];
    }

    isError(array, stepNumber, errorNumber) {
        return array.findIndex(error => error.errorNumber === errorNumber && error.stepNumber === stepNumber) !== -1;
    }

    // edit group
    public editClean(template: TemplateRef<any>) {
        this.openModal(template);
    }

    // Opens the modal popup with backdrop config
    private openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered',
            ignoreBackdropClick: true
        });
    }

    // Final Submit After first Modal i.e SAVE API_CALL
    public submitModalPopup() {
        this.getCleanDetail();
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // Close first add/edit Modal popup
    public closeModalPopup() {
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }
}
