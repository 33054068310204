import { Component, OnInit, TemplateRef } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { IKendoColumnModel } from '../models/kendoColumn.model';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EmailConfigId } from '../models/email-configuration.model';
import { LoggedInUserService } from '../services/loggedInUser.service';
import { Role } from '../models/roles.enum';
import { EmailConfigurationService } from '../services/email-configuration.service';
import { CommonService } from '../services/common.service';
import { SearchDataPipe } from '../pipes/search-data.pipe';
import { SharedService } from '../services/shared.service';
import { OrganizationModel } from '../models/organization.model';

@Component({
    selector: 'app-email-configuration',
    templateUrl: './email-configuration.component.html'
})

export class EmailConfigurationComponent implements OnInit {

    // Global variables
    readonly Role = Role;
    emailConfigList = [];
    public gridData: any = [];
    columns: IKendoColumnModel[];
    searchKeyword: string;
    role: string;
    emailConfigIsEdit = false;
    selectedEmailConfig: any;
    searchableColumns = ['templateName'];
    state: State = {
        skip: 0,
        take: 50
    };
    orgList = [];
    organisationId = 0;
    organization = {
        organisationId: 0,
        organisationName: ''
    };
    // Modal Refs
    modalRef: BsModalRef;

    constructor(
        private loggedInUserService: LoggedInUserService,
        private emailConfigSrv: EmailConfigurationService,
        private commonService: CommonService,
        private modalService: BsModalService,
        private sharedService: SharedService
    ) { }

    ngOnInit() {
        this.role = this.loggedInUserService.getUserData('role');
        this.bindColoumns();
        if (this.role === Role[1]) {
            this.sharedService.getOrgList(true).then((res: OrganizationModel[]) => {
                this.orgList = res;
                if (!this.organisationId) {
                    this.organization.organisationId = this.orgList[0].id;
                    this.organization.organisationName = this.orgList[0].name;
                    this.organisationId = this.orgList[0].id;
                    this.getEmailRecipients();
                }
            });
        } else {
            this.organisationId = this.loggedInUserService.getUserData('organisationId');
            this.organization.organisationId = this.loggedInUserService.getUserData('organisationId');
            this.organization.organisationName = this.loggedInUserService.getUserData('organisationName');
            this.getEmailRecipients();
        }
    }

    // Set the grid coloumns
    bindColoumns(): void {
        this.columns = [
            {
                field: 'templateName',
                title: 'Template',
                width: 300,
            },
            {
                field: 'emailRecipients',
                title: 'Users',
                width: 300,
                cellFormat: (data) => data.emailRecipients.length ? this.formatUser(data.emailRecipients) : '-',
            }
        ];
    }

    // Format the emails of user
    private formatUser(users: []): string {
        const userEmail = users.map((user: any) => user.email).join(', ');
        return userEmail;
    }

    // Get Email Template list
    private getEmailRecipients(): void {
        const bodyParams: EmailConfigId = {
            organisationId: +this.organisationId,
            userId: this.role === Role[1] ? 0 : this.loggedInUserService.getUserData('id')
        };

        this.emailConfigSrv.getEmailRecipients(bodyParams).subscribe(
            (res) => {
                this.commonService.setLoader(false);
                if (res && res.statusCode === 200) {
                    this.emailConfigList = res.data;
                    this.loadItems();
                }
            },
            (error) => {
                console.error(error);
            }
        );
    }

    changeOrganisation() {
        this.organization.organisationId = +this.organisationId;
        this.organization.organisationName = this.orgList.find(org => org.id === this.organization.organisationId).name;
        this.getEmailRecipients();
    }

    // REALTED to grid Functionality paging , sorting , groupping
    dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.loadItems();
    }

    // Load the grid with proper data
    private loadItems(): void {
        const searchedRecords = new SearchDataPipe().transform(
            this.emailConfigList,
            this.searchKeyword,
            this.searchableColumns
        );
        this.gridData = process(searchedRecords, this.state);
    }

    // Esit Email Configuraton
    editEmailConfig(config, template: TemplateRef<any>) {
        this.selectedEmailConfig = { ...config, ...this.organization };
        this.emailConfigIsEdit = true;
        this.openModal(template);
    }

    // Opens the modal popup with backdrop config
    private openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered',
            ignoreBackdropClick: true
        });
    }

    // Final Submit After first Modal i.e SAVE API_CALL
    public submitModalPopup(template: TemplateRef<any>) {
        this.getEmailRecipients();
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }

    // Close first add/edit Modal popup
    public closeModalPopup() {
        if (!this.modalRef) {
            return;
        }
        this.modalRef.hide();
        this.modalRef = null;
    }
}
