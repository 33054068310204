import { MESSAGE } from 'src/app/shared/messages';
import { Component, OnInit, EventEmitter, Output, Input, DebugNode } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { WebAccountService } from 'src/app/services/web-account.service';
import { CommonService } from 'src/app/services/common.service';
import { SharedService } from 'src/app/services/shared.service';
import { GroupModel } from 'src/app/models/groups.model';
import { OrganizationModel } from 'src/app/models/organization.model';
import { AccountModel } from 'src/app/models/web-accounts.model';
import { constants } from 'src/app/shared/directives/constants';
import { Role } from 'src/app/models/roles.enum';
import { OutletService } from 'src/app/services/outlet.service';

@Component({
    selector: 'app-manage-web-accounts',
    templateUrl: './manage-web-accounts.component.html',
})

export class ManageWebAccountsComponent implements OnInit {
    readonly MESSAGE = MESSAGE;
    @Input() isEdit: boolean = false;
    @Input() webAccDetail: AccountModel;
    @Output() close = new EventEmitter();
    @Output() submit = new EventEmitter();
    webAccForm: FormGroup;
    orgList: OrganizationModel[] = [];
    grpList: GroupModel[] = [];
    outletList: any[] = [];
    currentorg: string;
    currentRole: string;
    currentId: number;
    selectedOrgId: number = 0;
    selectedRoleId: number = 0;
    fieldDisable: boolean = true;
    showOutletAdminFields: boolean = false;
    roleList = [
        { name: 'Organisation Super User', roleId: 4 },
        { name: 'Organisation Super Admin', roleId: 5 },
        { name: 'Organisation Admin', roleId: 2 },
        { name: 'Web User', roleId: 3 },
        { name: 'Equipment Admin', roleId: 6 },
        { name: 'Outlet Admin', roleId: 7 }
    ];
    webAccId: number = 0;
    submitError: string;
    webAccGroup: Array<string> = [];
    grpListValue = [];
    roleEnum = Role;
    languageList = [{ id: 'en', text: 'English' }, { id: 'fr', text: 'French' }, { id: 'de', text: 'German' }, { id: 'es', text: 'Spanish' }, { id: 'it', text: 'Italian' }]
    timezoneList = [
        {
            code: 'GMT',
            countries: 'Ireland/UK'
        },
        {
            code: 'CET',
            countries: 'Europe(+1)'
        },
        {
            code: 'EET',
            countries: 'Eastern Europe(+2)'
        },
        {
            code: 'ART',
            countries: 'Argentina/Brazil'
        },
        {
            code: 'CST',
            countries: 'USA'
        }
    ]
    oldOutletList = [];

    constructor(private sharedService: SharedService, private loggedInUserService: LoggedInUserService, private webAccService: WebAccountService, private toastr: ToastrService, private commonService: CommonService, private outletService: OutletService) { }

    ngOnInit() {
        this.currentRole = this.loggedInUserService.getUserData('role');
        this.createRoleList();

        this.webAccForm = new FormGroup({
            webAccName: new FormControl('', Validators.required),
            webAccEmail: new FormControl('', [Validators.required, Validators.email, Validators.pattern(constants.emailRegex)]),
            webAccPassword: new FormControl('', [Validators.required, Validators.pattern(constants.passwordRegex)]),
            webAccRole: new FormControl(null, Validators.required),
            webAccOrg: new FormControl(null, Validators.required),
            webAccGroup: new FormControl({ value: '', disabled: true }, Validators.required),
            webAccStatus: new FormControl(true),
            webAccTimezone: new FormControl(this.timezoneList[0].code, Validators.required),
            webAccLang: new FormControl(this.languageList[0].id, Validators.required),
        });

        if (this.isEdit) {
            // Edit Account
            this.webAccId = this.webAccDetail.id;

            this.webAccForm.get('webAccName').setValue(this.webAccDetail.name);
            this.webAccForm.get('webAccEmail').setValue(this.webAccDetail.email);
            this.webAccForm.get('webAccOrg').setValue(this.webAccDetail.organisationId);
            this.webAccForm.get('webAccRole').setValue(this.webAccDetail.accountType);
            this.webAccForm.get('webAccGroup').setValue(this.webAccDetail.accountGroup);
            this.webAccForm.get('webAccGroup').enable();

            if (+this.webAccDetail.accountType === 7) {

                this.addRemoveOutletAdminFields('add');
                if (this.webAccDetail.account_outlet_id) {
                    this.oldOutletList = this.webAccDetail.account_outlet_id.map((item) => {
                        let obj = {
                            id: item.outletid,
                            name: item.outletname,
                            associaltedGroupId: item.associaltedGroupId
                        }
                        return obj;
                    });
                }
                //this.oldGroupList = this.webAccDetail.accountGroup.map((item) => item.id);

                this.webAccForm.get('webAccPhone').setValue(this.webAccDetail.telephone);
                this.webAccForm.get('webAccCountryCode').setValue(this.webAccDetail.countrycode);
                this.webAccForm.get('communicationType').setValue(this.webAccDetail.smswhatsapp);
                this.showOutletAdminFields = true;
            }

            if (+this.webAccForm.get('webAccRole').value === 3) {
                this.webAccForm.get('webAccGroup').enable();
            }

            this.webAccForm.get('webAccStatus').setValue(!this.webAccDetail.archived);
            this.webAccForm.get('webAccPassword').setValidators(null);

            this.webAccForm.get('webAccTimezone').setValue(this.webAccDetail.timezone || this.timezoneList[0].code);
            this.webAccForm.get('webAccTimezone').setValidators(null);

            this.webAccForm.get('webAccLang').setValue(this.webAccDetail.default_language || this.languageList[0].id);

            this.getGroupList(this.webAccDetail.organisationId);
        }


        if (this.currentRole === Role[1]) {
            // superadmin
            this.sharedService.getOrgList(true).then(
                (res: OrganizationModel[]) => {
                    this.orgList = res;
                }
            );
        } else {
            //orgadmin
            const orgId = this.loggedInUserService.getUserData('organisationId');
            this.currentorg = this.loggedInUserService.getUserData('organisationName');
            this.webAccForm.get('webAccOrg').setValue(orgId);
            this.webAccForm.get('webAccGroup').enable();
            this.getGroupList(orgId);
        }

    }

    createRoleList() {

        if (this.currentRole == "superAdmin") {
            return this.roleList;
        }
        else if (this.currentRole == "superOrgUser") {
            const allowedRoleIds = [5, 2, 3, 6, 7];
            this.roleList = this.roleList.filter((role) => {
                return allowedRoleIds.includes(role.roleId);
            })
        }
        else if (this.currentRole == "orgSuperAdmin") {
            const allowedRoleIds = [2, 3, 6, 7];
            this.roleList = this.roleList.filter((role) => {
                return allowedRoleIds.includes(role.roleId);
            })
        }
        else if (this.currentRole == "organisationAdmin") {
            const allowedRoleIds = [3, 6, 7];
            this.roleList = this.roleList.filter((role) => {
                return allowedRoleIds.includes(role.roleId);
            })
        } else {
            return null;
        }
    }

    dismissModal() {
        this.close.emit();
    }

    onSubmit(form: FormGroup) {

        event.stopPropagation();

        if (form.get('webAccName').value && form.get('webAccName').value.trim() === '') {
            form.get('webAccName').setErrors({ required: true });
            return;
        }

        console.log('before valid', form);

        if (form.invalid) {
            return;
        }

        console.log('valid', form);

        const accGrps = form.get('webAccGroup').value;

        let selectedGroups = accGrps.map(({ id, name }) => ({ id, name }));

        let userOutlets = [];

        if (this.showOutletAdminFields) {
            userOutlets = form.get('webAccOutlets').value.map((item) => {
                let obj = {
                    outletid: item.id,
                    outletname: item.name
                }
                return obj;
            });

            const userSelectedOutletsIds = userOutlets.map(item => item.outletid);

            if (this.isEdit) {
                this.oldOutletList.map((item) => {
                    if (!userSelectedOutletsIds.includes(item.id)) {

                        const obj = {
                            outletid: item.id,
                            outletname: item.name,
                            archived: true
                        }

                        userOutlets.push(obj);
                    }
                });
            }
        }

        const params: AccountModel = {
            id: this.webAccId,
            name: form.get('webAccName').value.trim(),
            email: form.get('webAccEmail').value,
            password: !this.isEdit ? form.get('webAccPassword').value : undefined,
            accountType: +form.get('webAccRole').value,
            organisationId: this.currentRole === Role[1] ? +form.get('webAccOrg').value : this.loggedInUserService.getUserData('organisationId'),
            userId: this.loggedInUserService.getUserData('id'),
            accountGroup: selectedGroups,
            archived: !form.get('webAccStatus').value,
            timezone: form.get('webAccTimezone').value,
            default_language: form.get('webAccLang').value
        }

        if (this.showOutletAdminFields) {
            params.outlet = userOutlets;
            params.countrycode = form.get('webAccCountryCode').value;
            params.telephone = form.get('webAccPhone').value;
            params.smswhatsapp = form.get('communicationType').value;
        }

        console.log('payload', params);

        this.webAccService.saveWebAcc(params).subscribe(
            (res) => {
                if (res) {
                    if (res.statusCode === 200 && res.data > 0) {
                        this.submit.emit();
                        this.isEdit ? this.toastr.success(MESSAGE.REC_UPDATED_SUCCESS) : this.toastr.success(MESSAGE.REC_ADD_SUCCESS);
                    } else if (res.statusCode === 404) {
                        this.commonService.setLoader(false);
                        if (res.data === -1) {
                            this.submitError = MESSAGE.EMAILID_ALREADY_EXIST;
                        }
                    }
                }
            }
        );
    }

    async sendSelectedOrg(orgId: number) {
        this.webAccForm.get('webAccGroup').setValue([]);
        this.webAccForm.get('webAccGroup').enable();
        this.selectedOrgId = orgId;
        await this.getGroupList(orgId);
        this.fieldDisable = false;
        this.groupSelection();
        if (this.showOutletAdminFields) {
            this.webAccForm.get('webAccOutlets').disable();
            this.webAccForm.get('webAccOutlets').setValue([]);
            this.webAccForm.get('webAccOutlets').setValidators(null);
        }
    }

    changeRole() {
        this.groupSelection();
        if (+this.webAccForm.get('webAccRole').value === 7) {
            this.webAccForm.get('webAccPassword').clearValidators();
            this.showOutletAdminFields = true;
            this.addRemoveOutletAdminFields('add');
        } else {
            this.showOutletAdminFields = false;
            this.addRemoveOutletAdminFields('remove');
        }
    }

    addRemoveOutletAdminFields(action) {
        if (action === 'add') {
            this.webAccForm.addControl('webAccPhone', new FormControl(null, [Validators.pattern(constants.onlyNumbers), Validators.maxLength(10), Validators.minLength(10)]));

            this.webAccForm.addControl('webAccCountryCode', new FormControl(null, [Validators.pattern(constants.numberWithPlusSign)]));

            this.webAccForm.addControl('webAccOutlets', new FormControl({ value: [], disabled: true }, Validators.required));

            this.webAccForm.addControl('communicationType', new FormControl('sms', Validators.required));

            this.groupChange();

        } else if (action === 'remove') {
            this.webAccForm.removeControl('webAccPhone');
            this.webAccForm.removeControl('webAccCountryCode');
            this.webAccForm.removeControl('webAccOutlets');
            this.webAccForm.removeControl('communicationType');
        }
    }

    getOutletList() {

        const currentSelectedGroupIds = this.webAccForm.controls['webAccGroup'].value.map(element => element.id);

        const selectedOutlets = [];

        const payload = {
            organisationId: this.webAccForm.controls['webAccOrg'].value,
            groupId: this.webAccForm.controls['webAccGroup'].value.map(element => element.id)
        }

        const currentRole = +this.webAccForm.controls['webAccRole'].value;

        if (payload.organisationId && (currentRole === 7)) {
            this.outletService.getOutletsForWebUser(payload).subscribe((res) => {
                this.commonService.setLoader(false);

                this.outletList = [...res.data];

                if (this.isEdit) {
                    this.oldOutletList.map((item) => {
                        if (currentSelectedGroupIds.includes(item.associaltedGroupId)) {
                            this.outletList.push(item);
                            selectedOutlets.push(item);
                        }
                    })

                    this.webAccForm.get('webAccOutlets').setValue(selectedOutlets);

                }

            });
        }
    }


    groupSelection() {
        if (+this.webAccForm.get('webAccRole').value === 2 && +this.webAccForm.get('webAccOrg').value > 0) {
            if (this.grpList.length > 0) {
                this.webAccForm.get('webAccGroup').setValue(this.grpList);
                this.webAccForm.get('webAccGroup').disable();
            }
        } else if (+this.webAccForm.get('webAccRole').value !== 2) {
            if (+this.webAccForm.get('webAccOrg').value > 0) {
                this.webAccForm.get('webAccGroup').enable();
            }
        }
    }

    async getGroupList(orgId) {
        await this.sharedService.getGrpList(orgId, true).then((res: GroupModel[]) => {
            this.grpList = res;
        });
    }

    isItemSelected(selectedArray, id) {
        return selectedArray && selectedArray.length > 0 ? selectedArray.some(item => item.id === id) : false;
    }

    selectAllWebOptions(fieldName) {

        if (fieldName === 'groups') {
            (this.webAccForm.value['webAccGroup'].length === this.grpList.length) ? this.webAccForm.patchValue({ webAccGroup: [] }) : this.webAccForm.patchValue({ webAccGroup: this.grpList });
        }

        if (fieldName === 'outlets') {
            (this.webAccForm.value['webAccOutlets'].length === this.outletList.length) ? this.webAccForm.patchValue({ webAccOutlets: [] }) : this.webAccForm.patchValue({ webAccOutlets: this.outletList });
        }

        this.groupChange();

    }

    groupChange() {
        if (!!this.webAccForm.get('webAccGroup') && this.webAccForm.get('webAccGroup').value.length > 0 && +this.webAccForm.get('webAccRole').value === 7) {
            this.getOutletList();
            this.webAccForm.get('webAccOutlets').enable();
        }
        if (!!this.webAccForm.get('webAccGroup') && this.webAccForm.get('webAccGroup').value.length === 0) {
            this.webAccForm.get('webAccOutlets').disable();
            //this.webAccForm.get('webAccOutlets').setValidators(null);
        }
    }
}
