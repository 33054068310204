import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, zip, Subscription } from 'rxjs';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { OrganizationModel } from '../models/organization.model';
import { Role } from '../models/roles.enum';
import { LoggedInUserService } from '../services/loggedInUser.service';
import { VisitRequestModel } from '../models/visitRequest.model';
import { SharedService } from '../services/shared.service';
import { NgForm } from '@angular/forms';
import { VisitService } from '../services/visit.service';
import { ObjectResponseModel } from '../models/reponse.model';
import { constants } from '../shared/directives/constants';
import { VisitListModel } from '../models/visit.model';
import { CommonService } from '../services/common.service';
import { IKendoColumnModel } from '../models/kendoColumn.model';
import { convertTimeZone, getFormatedDate, setCurrentTime } from '../shared/handler.functions';
import { ExcelExportData, Workbook } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { ToastrService } from 'ngx-toastr';
import { TerritoryModel } from '../models/territories.model';
import { UserModel } from '../models/mobile-user.model';

export interface DateRange {
  from: string;
  to: string;
  pageSize: number;
  page: number;
}

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html'
})

export class VisitComponent implements OnInit {
  readonly constants = constants;
  readonly Role = Role;
  orgList: OrganizationModel[] = [];
  territoryList: TerritoryModel[] = [];
  mobileUserList: UserModel[] = [];
  visit: VisitRequestModel;
  visitList: VisitListModel;
  userRole: string;
  todaysDate = new Date();
  defaultOptionsCount = 2;
  defaultOptionsStatusList = [];
  // Grid
  gridView: GridDataResult;
  state: State = {
    skip: 0,
    take: 50
  };
  columns: IKendoColumnModel[];
  isMobile: boolean = false;
  isMobileSubscriber: Subscription;
  timeZone: string;

  constructor(private toastr: ToastrService, private loggedInUserService: LoggedInUserService, private commonService: CommonService, private sharedService: SharedService, private visitService: VisitService) {
    this.allData = this.allData.bind(this);
    this.isMobileSubscriber = this.commonService.getIsMobile().subscribe((res: boolean) => {
      this.isMobile = res;
      this.bindColumns();
    });
  }

  bindColumns() {
    this.columns = [
      {
        field: "outletShipTo",
        title: "Ship To",
        width: 120,
        locked: true
      },
      {
        field: "outletName",
        title: "Outlet",
        width: 150,
        locked: !this.isMobile
      },
      {
        field: "groupName",
        title: "Group",
        width: 150,
      },
      {
        field: "territory",
        title: "Territory",
        width: 150,
      },
      {
        field: "startDate",
        title: "Start",
        width: 180,
        cellFormat: data => getFormatedDate(data.startDate, constants.dateTimeFormat),
        cellTextCenter: true
      },
      {
        field: "endDate",
        title: "End",
        width: 180,
        cellFormat: data => getFormatedDate(data.endDate, constants.dateTimeFormat) || '-',
        cellTextCenter: true
      },
      {
        field: "userName",
        title: "User",
        width: 110,
      },
      //  {
      //   field: "inavlidNo",
      //   title: "Invalid",
      //   width: 90,
      //   cellFormat: data => data.inavlidNo || '-',
      //   cellTextCenter: true
      // },
      {
        field: "vortexSoakNo",
        title: "Vortex Soak",
        width: 100,
        cellTextCenter: true
      }, {
        field: "vortexRecircNo",
        title: "Vortex Recirc",
        width: 110,
        cellTextCenter: true
      }, {
        field: "manualNo",
        title: "Manual",
        width: 100,
        cellTextCenter: true
      }, {
        field: "refusalNo",
        title: "Not Cleaned",
        width: 100,
        cellTextCenter: true
      },
      {
        field: "verxAutoNo",
        title: "Verx Auto",
        width: 100,
        cellTextCenter: true
      },
      {
        field: "verxManualNo",
        title: "Verx Manual",
        width: 100,
        cellTextCenter: true
      },
      {
        field: "verxLiteAutoNo",
        title: "Verx Lite Auto",
        width: 100,
        cellTextCenter: true
      },
      {
        field: "plcsAutoNo",
        title: "PLCS Auto No",
        width: 100,
        cellTextCenter: true
      },
      {
        field: "plcsManualNo",
        title: "PLCS Manual No",
        width: 100,
        cellTextCenter: true
      },
      {
        field: "vortexIAutoNo",
        title: "Vortex I Auto",
        width: 100,
        cellTextCenter: true
      },
      {
        field: "vortexIManualNo",
        title: "Vortex I Manual",
        width: 100,
        cellTextCenter: true
      },
      {
        field: "vortexNAutoNo",
        title: "Vortex N Auto",
        width: 100,
        cellTextCenter: true
      },
      {
        field: "vortexNManualNo",
        title: "Vortex N Manual",
        width: 100,
        cellTextCenter: true
      }
    ]

  }

  ngOnInit() {
    this.userRole = this.loggedInUserService.getUserData('role');
    this.timeZone = this.loggedInUserService.getUserData('timezone');
    this.visit = new VisitRequestModel();
    this.visit.organisationId = null;
    this.visit.from = new Date();
    this.visit.to = new Date();
    this.bindColumns();
    if (this.userRole === Role[1]) {
      this.sharedService.getOrgList(true).then((res: OrganizationModel[]) => {
        this.orgList = res;
        if (!this.visit.organisationId) {
          //this.visit.organisationId = this.orgList[0].id;
          this.changeOrganisation();
          //this.getVisits();
        }
      });
    } else {
      this.visit.organisationId = this.loggedInUserService.getUserData('organisationId');
      this.changeOrganisation();
      this.getVisits();
    }
  }

  changeOrganisation(updateAllFilters = true) {
    if (updateAllFilters) {
      this.visit.territory = [];
      this.visit.user = [];
    }
    let userId = 0;
    if (this.userRole === Role[3]) {
      userId = this.loggedInUserService.getUserData('id');
    }

    if (this.visit.organisationId) {
      this.sharedService.getTerritoryList(this.visit.organisationId, 0, 0, 0, true, userId).then((res: TerritoryModel[]) => {
        this.territoryList = res;
        updateAllFilters && (this.visit.territory = this.territoryList);
        this.defaultOptionsStatusList.push(1);
        this.doVisitSearch();
      });

      this.sharedService.getMobileUsersList(this.visit.organisationId, true, userId).then((res: UserModel[]) => {
        this.mobileUserList = res;
        updateAllFilters && (this.visit.user = this.mobileUserList);
        this.defaultOptionsStatusList.push(1);
        this.doVisitSearch();
      });
    }


  }

  // REALTED to grid Functionality paging , sorting , groupping
  pageChange(page: PageChangeEvent): void {
    this.state.skip = page.skip;
    this.getVisits();
  }

  groupChange(group: GroupDescriptor[]) {
    this.state.group = group;
    this.loadData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.state.sort = sort;
    this.loadData();
  }

  loadData() {
    // this.gridView = process(this.visitList.visitResponse, this.state);
    this.gridView = {
      data: process(this.visitList.visitResponse, { group: this.state.group, sort: this.state.sort }).data,
      total: this.visitList.totalCount
    }
  }

  refreshReport(event, form: NgForm) {
    event.stopPropagation();
    if (form.form.invalid) {
      return;
    }
    this.state.skip = 0;
    this.getVisits();
  }

  getVisits() {
    const params = {
      skip: this.state.skip,
      take: this.state.take,
      organisationId: +this.visit.organisationId,
      from: setCurrentTime(this.visit.from),
      to: setCurrentTime(this.visit.to),
      territory: this.visit.territory.map(terr => terr.id),
      user: this.visit.user.map(user => user.id) || undefined,
      outletName: this.visit.outletName
    }
    if (+this.visit.shipTo) {
      params['shipTo'] = +this.visit.shipTo
    }

    if (this.userRole === Role[3]) {
      params['userId'] = this.loggedInUserService.getUserData('id');
    }

    this.visitService.getVisits(params).subscribe((res: ObjectResponseModel) => {
      this.commonService.setLoader(false);
      if (res) {
        //function to convert date as per timezone
        convertTimeZone(res.data.visitResponse, ['startDate', 'endDate'], this.timeZone);

        this.visitList = res.data;

        if (res.statusCode === constants.success) {
          this.loadData();
        }
      }
    });
  }

  // export all data
  allData() {
    const params = {
      skip: 0,
      take: this.visitList.totalCount,
      organisationId: +this.visit.organisationId,
      from: setCurrentTime(this.visit.from),
      to: setCurrentTime(this.visit.to),
      territory: this.visit.territory.map(terr => terr.id),
      user: this.visit.user.map(user => user.id) || undefined,
      outletName: this.visit.outletName
    };

    let resData = [];
    return new Promise((resolve, reject) => {
      this.visitService.getVisits(params).subscribe((res: ObjectResponseModel) => {
        if (res) {
          if (res.statusCode === constants.success) {
            this.commonService.setLoader(false);
            if (res.data.visitResponse.length > 0) {
              resData = res.data.visitResponse;
              const result: ExcelExportData = {
                data: resData,
                group: []
              };
              resolve(result);
            } else {
              this.toastr.error('No. records to Export Data');
              return false;
            }
          } else if (res.statusCode === constants.error) {
            this.commonService.setLoader(false);
            reject();
          }
        }
      });
    });
  }

  isItemSelected(selectedArray, id) {
    return selectedArray.some(item => item.id === id);
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.isMobileSubscriber.unsubscribe();
  }

  selectAllOptions(target: string, destination: string) {
    (this.visit[target].length === this[destination].length) ? this.visit[target] = [] : this.visit[target] = this[destination];
  }

  doVisitSearch() {
    this.defaultOptionsCount === this.defaultOptionsStatusList.length ? this.getVisits() : undefined;
  }
}