import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting

// Modules
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { VisitModule } from './visit/visit.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { OrganizationDetailModule } from './organization-detail/organization-detail.module';
import { DeviceModule } from './device/device.module';
import { CleanParamsModule } from './clean-params/clean-params.module';
import { OutletModule } from './outlet/outlet.module';
import { AccountModule } from './account/account.module';
import { UploadFileModule } from './upload-file/upload-file.module';
import { ReasonsModule } from './reasons/reasons.module';
import { HistoryModule } from './history/history.module';


// Components / Services
import { AppComponent } from './app.component';
import { RestApi } from './network/RestAPI';
import { RequestHelperService } from './network/RequestHelper';
import { LoggedInUserService } from './services/loggedInUser.service';
import { CleansModule } from './cleans/cleans.module';
import { CleanGenModule } from './clean-gen/clean-gen.module';
import { SyncLogsModule } from './sync-logs/sync-logs.module';
import { AssetTypeModule } from './asset-type/asset-type.module';
import { EmailConfigurationModule } from './email-configuration/email-configuration.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import "@progress/kendo-angular-intl/locales/fr/all";
import "@progress/kendo-angular-intl/locales/es/all";
import "@progress/kendo-angular-intl/locales/it/all";
import "@progress/kendo-angular-intl/locales/de/all";
import "@progress/kendo-angular-intl/locales/en/all";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,'./assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    ChartsModule,
    AuthModule,
    LayoutModule,
    DashboardModule,
    VisitModule,
    OrganizationDetailModule,
    DeviceModule,
    CleanParamsModule,
    OutletModule,
    AccountModule,
    UploadFileModule,
    ReasonsModule,
    HistoryModule,
    CleansModule,
    CleanGenModule,
    SyncLogsModule,
    AssetTypeModule,
    EmailConfigurationModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (HttpLoaderFactory),
          deps: [HttpClient]
      } 
    }),
    GridModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: localStorage.getItem('lang') || 'en' }, RestApi, RequestHelperService,LoggedInUserService,HttpClient],
  bootstrap: [AppComponent],
})
export class AppModule { }
