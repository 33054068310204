export enum TypeOfClean {
    Soak = 0,
    Recirc = 1
}

export const TypeOfCleanOptions = [
    {
        id: TypeOfClean.Soak,
        name: "Soak"
    },
    {
        id: TypeOfClean.Recirc,
        name: "Recirc"
    },
];
