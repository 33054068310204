import { Component, OnInit } from '@angular/core';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { Role } from 'src/app/models/roles.enum';
import { OutletService } from 'src/app/services/outlet.service';
import { SharedService } from 'src/app/services/shared.service';
import { CommonService } from 'src/app/services/common.service';
import { NgForm } from '@angular/forms';
import { UploadFileModel } from '../models/uploadFileModel';
import { IKendoColumnModel } from '../models/kendoColumn.model';
import { State, process } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { SearchDataPipe } from '../pipes/search-data.pipe';
import { ToastrService } from 'ngx-toastr';
import { API_URL } from '../network/endpoints';
import { OrganizationModel } from '../models/organization.model';
import { GroupModel } from '../models/groups.model';
import { MESSAGE } from '../shared/messages';
import { constants } from '../shared/directives/constants';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
})
export class UploadFileComponent implements OnInit {
  readonly URL_ENDPOINT = API_URL;
  readonly Role = Role;
  readonly MESSAGE = MESSAGE;
  uploadModel: UploadFileModel;
  orgList: OrganizationModel[] = [];
  grpList: GroupModel[] = [];
  filebase64data;
  fileData: File;
  userRole: string;
  fileUploaded: boolean = false;
  uploadAssetResponse;
  uploadOutletResponse;
  assetGridData: any = [];
  outletGridData: any = [];
  searchKeyword: string;
  searchableColumns = ["name"];
  state: State = {
    skip: 0,
    take: 50
  };
  outletState: State = {
    skip: 0,
    take: 50
  };
  columns: IKendoColumnModel[] = [];
  outletColumns: IKendoColumnModel[] = []
  constructor(private toastr: ToastrService, private loggedInUserService: LoggedInUserService, private commonService: CommonService, private outletService: OutletService, private sharedService: SharedService) { 
    this.allOutletData = this.allOutletData.bind(this);
    this.allAssetData = this.allAssetData.bind(this);
  }

  ngOnInit() {
    this.userRole = this.loggedInUserService.getUserData('role');
    this.uploadModel = {
      organisationId: null,
      file: '',
    };
    this.columns = [
      {
        field: 'shipTo',
        title: 'Ship To',
        width: 200,
        groupable: true
      },
      {
        field: 'name',
        title: 'Name',
        width: 200,
        groupable: false
      },
      {
        field: 'quantity',
        title: 'Quantity',
        width: 80,
        groupable: false
      },
      {
        field: 'reason',
        title: 'Reason',
        width: 200,
        groupable: true
      }
    ];
    this.outletColumns = [
      {
        field: 'shipTo',
        title: 'Ship To',
        width: 200,
        groupable: true
      },
      {
        field: 'name',
        title: 'Name',
        width: 300,
        groupable: false
      },
      {
        field: 'territoryName',
        title: 'Territory Name',
        width: 200,
        groupable: true
      },
      {
        field: 'cycleNumber',
        title: 'Cycle Number',
        width: 200,
        groupable: true,
        cellFormat: data => data.cycleNumber || '-'
      },
      {
        field: 'reason',
        title: 'Reason',
        width: 200,
        groupable: true
      }];

    if (this.userRole === Role[1]) {
      this.sharedService.getOrgList().then((resData: OrganizationModel[]) => {
        this.orgList = resData;
        this.commonService.setLoader(false);
      });
    } else {
      this.uploadModel.organisationId = this.loggedInUserService.getUserData('organisationId');
      this.changeOrganisation();
    }
  }

  changeOrganisation(org?: OrganizationModel) {
    if (org) {
      this.uploadModel.organisation = org;
      this.uploadModel.organisationId = org.id
      this.uploadModel.group = null;
      this.uploadModel.groupId = null;
    }
    this.sharedService.getGrpList(this.uploadModel.organisationId).then((res: GroupModel[]) => {
      this.grpList = res;
      this.commonService.setLoader(false);
    });
  }

  convertFileToByteArray(file, form: NgForm) {
    if (!file) {
      return;
    }
    if (!['zip', 'csv'].includes(file.name.split('.')[1])) {
      form.controls['file'].setErrors({ format: 'Please attach zip/csv format' });
      return;
    }
    form.controls['file'].setErrors(null);
    const reader = new FileReader();
    this.fileData = file;
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result;
      this.filebase64data = base64data.toString().split(',')[1];
      // console.log(this.filebase64data);
    };
    reader.onerror = err => {
      console.log('error in converting file')
    };
  }

  uploadFile(form: NgForm) {
    if (form.form.invalid) {
      return;
    }
    const params: UploadFileModel = {
      file: this.filebase64data,
      userId: this.loggedInUserService.getUserData('id'),
      fileName: this.fileData.name.split('.')[1] === 'zip' ? 'zip' : this.fileData.name,
      organisationId: +this.uploadModel.organisationId,
      groupId: +this.uploadModel.groupId
    }
    this.uploadAssetResponse = null;
    this.uploadOutletResponse = null;
    this.uploadModel.file = null;
    setTimeout(() => {
      form.controls['file'].setErrors(null);
    },0) 
    this.outletService.uploadFile(params).subscribe(res => {
      this.commonService.setLoader(false);
      if (res) {
        if (res.statusCode === constants.success && res.data) {
          if (res.data.generalFileFlag === 1) {
            if (res.data.uploadAssetResponse) {
              // Asset Response 
              if (res.data.uploadAssetResponse.fileFlag === 0 && res.data.uploadAssetResponse.totalRows > 0) {
                this.fileUploaded = true;
                this.uploadAssetResponse = res.data.uploadAssetResponse;
                this.loadAssetItems();
              } else if (res.data.uploadAssetResponse.fileFlag === -2) {
                this.uploadAssetResponse = null;
                this.toastr.error('Asset File Format is invalid');
              } else {
                this.uploadAssetResponse = null;
              }
            }
            if (res.data.uploadOutletResponse) {
              // Outlet Response 
              if (res.data.uploadOutletResponse.fileFlag === 0 && res.data.uploadOutletResponse.totalRows > 0) {
                this.fileUploaded = true;
                this.uploadOutletResponse = res.data.uploadOutletResponse;
                this.loadOutletItems();
              } else if (res.data.uploadOutletResponse.fileFlag === -2) {
                this.uploadOutletResponse = null;
                this.toastr.error('Outlet File Format is invalid');
              } else {
                this.uploadOutletResponse = null;
              }
            }
            if (res.data.uploadZipResponse) {
              if (res.data.uploadOutletResponse.fileFlag === 5) {
                this.fileUploaded = true;
                this.uploadAssetResponse = null;
                this.uploadOutletResponse = null;
                this.toastr.error('Asset File not Found in zip');
                this.toastr.error('Outlet File not Found in zip ');
              } else if (res.data.uploadOutletResponse.fileFlag === 3) {
                this.uploadAssetResponse = null;
                this.toastr.error('Asset File not Found in zip');
              } else if (res.data.uploadOutletResponse.fileFlag === 4) {
                this.uploadOutletResponse = null;
                this.toastr.error('Outlet File not Found in zip ');
              }
            }
          } else if (res.data.generalFileFlag === -1) {
            this.toastr.error('Asset/Outlet File Name is invalid');

          }
        } else if (res.statusCode === constants.failed) {
          this.toastr.error('Operation Failed', 'Please try later');
        }
      }
    });
  }

  private loadAssetItems() {
    this.assetGridData = process( this.uploadAssetResponse.notValidRecordListForAssets || [], this.state);
  }

  private loadOutletItems() {
    this.outletGridData = process( this.uploadOutletResponse.notValidRecordListForOutlets || [], this.outletState);
  }

  allAssetData() {
    return {
      data: this.uploadAssetResponse.notValidRecordListForAssets || [],
      group: []
    }
  }

  allOutletData() {
    return {
      data: this.uploadOutletResponse.notValidRecordListForOutlets || [],
      group: []
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadAssetItems();
  }

  public outletDataStateChange(state: DataStateChangeEvent): void {
    this.outletState = state;
    this.loadOutletItems();
  }


}
