import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';
import { generateQueryParams } from '../shared/handler.functions';

@Injectable({
  providedIn: 'root'
})
export class MethodService {

  constructor(private restAPI: RestApi) { }
  getMethod(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.METHOD.GET_METHOD}`);
    return this.restAPI.handleRequest(url, METHODS.Post,params);
  }

  getMethodAndTypeOfCleans(params) {
    const url = RestApi.buildUrl(`${ENDPOINTS.METHOD.GET_METHOD_AND_CLEAN_TYPES}`);
    return this.restAPI.handleRequest(url, METHODS.Post,params);
  }
}
