import { DatePipe } from '@angular/common';
import { constants } from './directives/constants';
import * as moment from 'moment';

export const generateQueryParams = (json) => {
   return '?' + Object.keys(json).map(function (key) {
      return encodeURIComponent(key) + '=' +
         encodeURIComponent(json[key]);
   }).join('&');
};

export const validatePassword = (pwd) => {
   if (!(/(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!£@#$%^&*])[a-zA-Z0-9!£@#$%^&*]{8,50}$/).test(pwd)) {
      return false;
   }
   return true;
}

export const getFormatedDate = (date, format = constants.dateFormat) => {
   return date ? new DatePipe('en').transform(date.split('+')[0], format) : null
};

export const getFormattedTime = (time) => {
   return time ? new DatePipe('en').transform(time.split('+')[0], 'hh:mm') : null
}

export const generatePassword = () => {
   const length = 8;
   const charset = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
   let pwd = "";
   for (var i = 0, n = charset.length; i < length; ++i) {
      pwd += charset.charAt(Math.floor(Math.random() * n));
   }
   if (!(RegExp(constants.passwordRegex).test(pwd))) {
      pwd = generatePassword();
   }
   return pwd;
}


export const setCurrentTime = (date): Date => {
   const currentTime = new Date();
   return new Date(new Date(date).setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds()));
}

export const convertTimeZone = (data, fieldsList, userTimeZone) => {

   const tz = userTimeZone || 'GMT';
   if (data && data.length > 0) {
      data.map(
         (item) => {
            const obj = item;
            for (const key in obj) {
               if (fieldsList.includes(key)) {
                  if (item[key]) {
                     const isoDate = new Date(obj[key]).toISOString();
                     obj[key] = (new Date(isoDate).toLocaleString('en-US',
                        {
                           timeZone: tz
                        }
                     ));
                  }
               }
            }
         }
      );
   }
}

export const convertTimeZoneField = (field, userTimeZone) => {

   const tz = userTimeZone || 'GMT';
   const isoDate = new Date(field).toISOString();
   field = (new Date(isoDate).toLocaleString('en-US',
      {
         timeZone: tz
      }));
   return field;
}