import { Role, roles } from 'src/app/models/roles.enum';
import { WebAccountService } from './../../services/web-account.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { SearchDataPipe } from "src/app/pipes/search-data.pipe";
import { process, State } from "@progress/kendo-data-query";
import { IKendoColumnModel } from 'src/app/models/kendoColumn.model';
import { DataStateChangeEvent, GroupableSettings } from '@progress/kendo-angular-grid';
import { convertTimeZone, getFormatedDate } from 'src/app/shared/handler.functions';
import { AccountModel } from 'src/app/models/web-accounts.model';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { MESSAGE } from 'src/app/shared/messages';
import { constants } from 'src/app/shared/directives/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-web-accounts',
  templateUrl: './web-accounts.component.html',
})
export class WebAccountsComponent implements OnInit {

  modalRef: BsModalRef;
  webAccList = [];
  public gridData: any = [];
  searchKeyword: string;
  searchableColumns = ["name", "email", "role", "organisationName", "groups"];
  state: State = {
    skip: 0,
    take: 50
  };
  columns: IKendoColumnModel[];
  accGrps: object[] = [];
  confirmModalRef: BsModalRef;
  selectedWebAcc: AccountModel;
  webAccIsEdit: boolean = false;
  role: string;
  roleId: number;
  groupableSettings: GroupableSettings;
  timeZone: string;
  allRoles = roles;
  filteredAccList = [];
  copyWebAccList = [];
  editable: boolean;
  lockUnlock: boolean;
  accountType: number;

  constructor(private toastr: ToastrService, private commonService: CommonService, private modalService: BsModalService, private webAccService: WebAccountService, private loggedInUserService: LoggedInUserService, private translate: TranslateService) { }


  ngOnInit() {
    this.timeZone = this.loggedInUserService.getUserData('timezone');

    this.translate.onLangChange.subscribe((res) => {
      this.translationChanges();
    });

    this.getAccList();
    this.role = this.loggedInUserService.getUserData('role');
    this.roleId = this.loggedInUserService.getUserData('accountType');
 
    this.columns = [
      {
        field: "name",
        title: "Name",
        width: 150,
        sortable: true,
        locked: true
      },
      {
        field: "email",
        title: "Email",
        width: 250,
        sortable: true
      },
      {
        field: "lastLogin",
        title: "Last Login",
        width: 180,
        sortable: true,
        cellTextCenter: true,
        cellFormat: (data) => getFormatedDate(data.lastLogin, constants.dateTimeFormat) || '-'
      },
      {
        field: "role",
        title: "Role",
        width: 200,
        groupable: true,
        sortable: true
      },
      {
        field: "organisationName",
        title: "Organisation",
        width: 150,
        display: this.role !== Role[1],
        groupable: true,
        sortable: true
      },
      {
        field: "groups",
        title: "Groups",
        width: 200,
        cellFormat: (data) => (data.groups || '-'),
      },
      {
        field: "createdAt",
        title: "Created On",
        width: 180,
        cellTextCenter: true,
        sortable: true,
        cellFormat: (data) => getFormatedDate(data.createdAt, constants.dateTimeFormat)
      },
      {
        field: "createdBy",
        title: "Created By",
        width: 230,
        sortable: true
      },
      {
        field: "updatedAt",
        title: "Updated On",
        width: 180,
        cellTextCenter: true,
        sortable: true,
        cellFormat: data => getFormatedDate(data.updatedAt, constants.dateTimeFormat) || '-'
      },
      {
        field: "updatedBy",
        title: "Updated By",
        width: 230,
        sortable: true,
        cellFormat: (data) => data.updatedBy || "-"
      },
      {
        field: "loginAttempts",
        title: "Login Attempts",
        width: 150,
        sortable: true,
        cellTextCenter: true,
        cellFormat: (data) => data.loginAttempts === null ? '-' : data.loginAttempts
      }
    ];
  }

  addWebAccount(template: TemplateRef<any>) {
    this.webAccIsEdit = false;
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog modal-dialog-centered', ignoreBackdropClick: true });
  }

  public closeModalPopup() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  private getAccList(): void {
    const params = {
      "organisationId": this.loggedInUserService.getUserData('organisationId'),
      "groupId": 0
    };

    this.webAccService.getWebAccList(params).subscribe(
      (res) => {
        this.commonService.setLoader(false);
        if (res && res.statusCode === 200 && res.data) {
          console.log(res);
          convertTimeZone(res.data, ['createdAt', 'updatedAt', 'lastLogin'], this.timeZone);

          this.webAccList = res.data;
          this.webAccList.forEach((curr) => {

            //for super admin
            if (this.roleId == 1) {
              curr.editable = true;
            }

            //for org super user
            if (this.roleId == 4) {
              if ([5, 2, 3, 6, 7].includes(curr.accountType)) {
                curr.lockUnlock = false;
                curr.editable = true;
              } else {
                curr.lockUnlock = true;
              }
            }

            //for org super admin
            if (this.roleId == 5) {
              if ([2, 3, 6, 7].includes(curr.accountType)) {
                curr.lockUnlock = false;
                curr.editable = true;
              }
              else {
                curr.lockUnlock = true;
              }
            }
            //for org admin
            if (this.roleId == 2) {
              if ([3, 7].includes(curr.accountType)) {
                curr.editable = true;
                curr.lockUnlock = false;
              } else {
                curr.lockUnlock = true;
              }
              if (curr.accountType === 6) {
                curr.lockUnlock = false;
              }
            }
          })

          const selfIndex = this.webAccList.findIndex(user => user.id === this.loggedInUserService.getUserData('id'));
          if (selfIndex > -1) {
            this.webAccList.splice(selfIndex, 1);
          }

          this.webAccList.forEach((acc) => {
            acc.role = roles.find((curr) => { return acc.accountType == curr.id }).name;
            acc.groups = acc.accountGroup && acc.accountGroup.length > 0 ? acc.accountGroup.map(grp => grp.name).join(', ') : null
          });
          this.copyWebAccList = [...this.webAccList];
          if (this.accountType) {
            this.filteredAccList = this.webAccList.filter((acc) => acc.accountType === this.accountType);
            this.webAccList = this.filteredAccList;
          }
          const searchedRecords = new SearchDataPipe().transform(
            this.webAccList,
            this.searchKeyword,
            this.searchableColumns
          );
          this.gridData = process(searchedRecords, this.state);
        }
      }
    );

  }

  private loadItems() {
    const searchedRecords = new SearchDataPipe().transform(
      this.webAccList,
      this.searchKeyword,
      this.searchableColumns
    );
    this.gridData = process(searchedRecords, this.state);
  }

  // REALTED to grid Functionality paging , sorting , groupping
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadItems();
  }

  public openPopup(data, template: TemplateRef<any>) {
    this.selectedWebAcc = data;
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog modal-dialog-centered', ignoreBackdropClick: true });
  }

  public changePassword(newPassword) {
    const params = {
      id: this.selectedWebAcc.id,
      password: newPassword,
      updatedBy: this.loggedInUserService.getUserData('id')
    }
    this.webAccService.changeAccountPassword(params).subscribe(res => {
      // this.commonService.setLoader(false);
      if (res && res.statusCode === 200 && res.data > 0) {
        this.toastr.success(MESSAGE.PASSWORD_CHANGED_SUCCESSFULLY)
        this.getAccList();
        this.closeModalPopup();
      } else {
        this.toastr.error(MESSAGE.RECORD_DOES_NOT_EXIST)
        this.closeModalPopup();
      }
    });
  }

  public filterSearch() {
    this.loadItems();
    this.state.skip = 0;
  }

  // confirm reset 2FA
  public reset2FA(webAcc) {
    const bodyParams = {
      userId: webAcc.id,
      updatedBy: this.loggedInUserService.getUserData('id')
    };
    this.webAccService.reset2FA(bodyParams).subscribe(
      (res) => {
        if (res) {
          this.commonService.setLoader(false);
          if (res.statusCode === 200 && res.data && res.data.loginFlag > 0) {
            this.toastr.success(MESSAGE.RESET2FA_DONE_SUCCESSFULLY, "");
            this.getAccList();
            this.closeModalPopup();
          } else if (res.statusCode === 404 && res.data === -3) {
            this.toastr.error(MESSAGE.UNABLE_TO_RESET2FA, "", { timeOut: 10000 });
            this.closeModalPopup();
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // archive group or change status
  public archivedWebAcc(webAcc) {
    const bodyParams = {
      id: webAcc.id,
      archived: !webAcc.archived,
      updatedBy: this.loggedInUserService.getUserData('id')
    };

    this.webAccService.archiveWebAcc(bodyParams).subscribe(
      (res) => {
        if (res) {
          this.commonService.setLoader(false);
          if (res.statusCode === 200 && res.data > 0) {
            this.toastr.success(MESSAGE.ARCHIVED_DONE_SUCCESSFULLY, "");
            this.getAccList();
            this.closeModalPopup();
          } else if (res.statusCode === 404 && res.data === -3) {
            this.toastr.error(MESSAGE.UNABLE_TO_DEACTIVATE, "", { timeOut: 10000 });
            this.closeModalPopup();
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }


  // lock unlock account
  dolockUnlockConfirmation(event, dataItem, template: TemplateRef<any>) {
    event.preventDefault();
    event.stopPropagation();
    dataItem.locked = !dataItem.locked;
    this.openPopup(dataItem, template);
  }

  public lockUnlockAcc(webAcc) {
    const bodyParams = {
      id: webAcc.id,
      locked: !webAcc.locked,
      updatedBy: this.loggedInUserService.getUserData('id')
    };

    this.webAccService.lockUnlockWebAcc(bodyParams).subscribe(
      (res) => {
        if (res) {
          this.commonService.setLoader(false);
          if (res.statusCode === 200 && res.data > 0) {
            if (webAcc.locked) {
              this.toastr.success(MESSAGE.UNLOCKED__DONE_SUCCESSFULLY, "");
            } else {
              this.toastr.success(MESSAGE.LOCKED__DONE_SUCCESSFULLY, "");
            }
            this.getAccList();
            this.closeModalPopup();
          } else if (res.statusCode === 404 && res.data === -3) {
            this.toastr.error(MESSAGE.REC_UPDATING_FAILED, "", { timeOut: 10000 });
            this.closeModalPopup();
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public editWebAcc(webAcc, template: TemplateRef<any>) {
    this.webAccIsEdit = true;
    this.selectedWebAcc = Object.assign({}, webAcc);
    this.openModal(template);
  }

  private openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog modal-dialog-centered',
      ignoreBackdropClick: true
    });
  }

  // Final Submit After first Modal i.e SAVE API_CALL
  public submitModalPopup(template: TemplateRef<any>) {
    this.getAccList();
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  translationChanges() {
    this.translate.get('Drag a column header and drop it here to group by that column').subscribe((res) => {
      this.groupableSettings = { enabled: true, emptyText: res, showFooter: false };
    });
  }

  roleChange(data) {
    const accountType = +data.target.value;
    this.accountType = +data.target.value;
    this.webAccList = this.copyWebAccList;
    if (accountType) {
      this.filteredAccList = this.copyWebAccList.filter((acc) => acc.accountType === accountType);
      this.webAccList = this.filteredAccList;
    }
    this.loadItems();
  }

}
