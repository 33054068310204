export class CleanParamGen1 {
    id: number;
    userId: number;
    userName: string;
    unit: string;
    cleanCal: number;
    recircCal: number;
    doseRinse: number;
    rinseMin: number;
    maxFlow: number;
    maxLinesSlope: number;
    maxLinesConstant: number;
    minLines: number;
    r0: number;
    r1: number;
    r2: number;
    r3: number;
    r4: number;
    r5: number;
    s0: number;
    s1: number;
    s2: number;
    s3: number;
    s4: number;
    s5: number;
    s6: number;
    s7: number;
    maxInputlines: number;
    constructor() { }
}