import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CleanParamsService } from '../../../services/clean-params.service';
import { CommonService } from '../../../services/common.service';
import { State, process } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { SearchDataPipe } from '../../../pipes/search-data.pipe';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DefaultCleanParams } from '../../../models/cleanParam.model';
import { LoggedInUserService } from '../../../services/loggedInUser.service';
import { ToastrService } from 'ngx-toastr';
import { MESSAGE } from '../../../shared/messages';
import { Role } from '../../../models/roles.enum';
import { Observable } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DeviceType } from 'src/app/models/deviceType.enum';

@Component({
  selector: "app-verx-lite-clean-params",
  templateUrl: "./verx-lite-clean-params.component.html",
  styleUrls: ["./verx-lite-clean-params.component.css"],
})
export class VerxLiteCleanParamsComponent implements OnInit {
  @ViewChild("setDefaultSettingPopup", { static: true }) setDefaultSettingPopup: any;
  defaultCleanParams: DefaultCleanParams;
  cleanParamsList = [];
  selectDeviceType;
  gridData: any = [];
  selectedDevices = [];
  selectedSerialNos = [];
  allSelected = false;
  modalRef: BsModalRef;
  confirmModalRef: BsModalRef;
  manageState: Observable<object>;
  verxCleanParams: any = {};

  device = {
    deviceCleanTypeId: DeviceType.Verxlite, //this.service.getSelectedRoutes(),
    deviceOrganizationId:
      this.loggedInUserService.getUserData("organisationId"),
  };
  childPath;
  roleType;
  routes;
  columns = [
    {
      field: "serialNumber",
      title: "Device Serial no.",
      width: 130,
      forDevice: [8],
    },
    {
      field: "isDeviceSettingActive",
      title: "Clean Parameter Status",
      width: 130,
      forDevice: [8],
    },
    {
      field: "processType",
      title: "Process Type (Type of Clean)",
      width: 120,
      forDevice: [8],
    },
    {
      field: "numberOfSteps",
      title: "No. of Steps",
      width: 70,
      forDevice: [8],
    },
    {
      field: "flowmeterCalibration",
      title: "Flowmeter Calibration",
      width: 100,
      forDevice: [8],
    },
    {
      field: "flowrateAverage",
      title: "Flowrate Averaging Litres",
      width: 130,
      forDevice: [8],
    },
    {
      field: "conductivityAverage",
      title: "Conductivity Averaging litres",
      width: 130,
      forDevice: [8],
    },
    {
      field: "temperatureAverage",
      title: "Temperature Averaging Litres",
      width: 130,
      forDevice: [8],
    },
    {
      field: "errorClearVol",
      title: "Error Clear Vol",
      width: 100,
      forDevice: [8],
    },
    {
      field: "tempMaxWater",
      title: "Temp Max Water",
      width: 90,
      forDevice: [8],
    },
    {
      field: "tempMinWater",
      title: "Temp Min Water",
      width: 90,
      forDevice: [8],
    },
    {
      field: "pumpOnTime",
      title: "Pump On Time",
      width: 140,
      forDevice: [8],
    },
    {
      field: "pumpOffTime",
      title: "Pump Off Time",
      width: 140,
      forDevice: [8],
    },
    {
      field: "fobOnTime",
      title: "Fob On Time",
      width: 140,
      forDevice: [8],
    },
  ];

  searchableColumns = this.columns.map((col) => col.field);
  searchKeyword: string;
  public state: State = {
    skip: 0,
    take: 50,
  };
  constructor(
    private commonService: CommonService,
    private service: CleanParamsService,
    private loggedInUserService: LoggedInUserService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
    this.allData = this.allData.bind(this);
    this.childPath = this.service.childPath.filter(
      (obj) => obj.name === "verx"
    );
  }

  ngOnInit() {
    console.log(this.device)
    this.getAllCleanParams(this.device);
    this.roleType = this.loggedInUserService.getUserData("role");

    const superAdmin = this.roleType === Role[1];
    this.routes = [
      {
        title: "Device",
        display: superAdmin,
        path: "manageDevices",
      },
      {
        title: "Clean Parameters",
        display: superAdmin,
        path: "cleanparams",
      },
      {
        title: "Default",
        display: superAdmin,
        path: "multipleDefaultSetting",
      },
    ];
    this.childPath = this.service.childPath.filter(
      (obj) => obj.name === "verx"
    );
    this.service.setSelectedRoutes(DeviceType.Verxlite);
    this.selectDeviceType = this.service.getSelectedRoutes();
  }

  cellCheck(deviceId, serialNo) {
    const index = this.selectedDevices.indexOf(deviceId);
    if (index === -1) {
      this.selectedDevices.push(deviceId);
    } else {
      this.selectedDevices.splice(index, 1);
    }
    this.allSelected =
      this.selectedDevices.length === this.cleanParamsList.length;

    const idx = this.selectedSerialNos.indexOf(serialNo);
    if (index === -1) {
      this.selectedSerialNos.push(serialNo);
    } else {
      this.selectedSerialNos.splice(idx, 1);
    }
  }

  checkAll() {
    if (!this.allSelected) {
      this.selectedDevices = this.cleanParamsList.map((item) => item.deviceId);
      this.selectedSerialNos = this.cleanParamsList.map(
        (item) => item.serialNumber
      );
      this.allSelected = true;
    } else {
      this.selectedSerialNos = [];
      this.selectedDevices = [];
      this.allSelected = false;
    }
  }
  // REALTED to grid Functionality paging , sorting
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadItems();
  }

  private loadItems() {
    const searchedRecords = new SearchDataPipe().transform(
      this.cleanParamsList,
      this.searchKeyword,
      this.searchableColumns
    );
    this.gridData = process(searchedRecords, this.state);
  }

  public filterSearch() {
    this.loadItems();
    this.state.skip = 0;
  }

  getAllCleanParams(deviceSelect) {
    this.cleanParamsList = [];
    this.service.getAllCleanParams(deviceSelect).subscribe((res) => {
      this.commonService.setLoader(false);
      if (res && res.statusCode === 200 && res.data) {
        this.cleanParamsList = res.data.map((x) => x["runSetting"]);
        this.loadItems();
      }
    });
  }

  refreshGrid() {
    this.getAllCleanParams(this.device);
  }

  // export all data
  allData() {
    const result: ExcelExportData = {
      data: this.cleanParamsList,
      group: [],
    };
    return result;
  }

  // Sets default params to selected devices
  setDefaultParams(): void {
    /**
     * Uncomment below code for old default flow.
     */
    /* const params: DefaultCleanParams = {
      userId: this.loggedInUserService.getUserData('id'),
      deviceCleanTypeId: this.selectDeviceType,
      devices: this.selectedDevices.map(device => {
        return { deviceId: device };
      })
    };

    this.service.setDefaults(params).subscribe(res => {
      if (res.statusCode === 200) {
        this.toastr.success(MESSAGE.CLEAN_PARAMS_SET_TO_DEFAULT);
        this.selectedDevices = [];
        this.selectedSerialNos = [];
        this.refreshGrid();
      }
    }, err => console.error(err)); */

    this.defaultCleanParams = {
      userId: this.loggedInUserService.getUserData("id"),
      deviceCleanTypeId: this.device.deviceCleanTypeId,
      devices: this.selectedDevices.map((device) => {
        return { deviceId: device };
      }),
      selectedOrg: this.device.deviceOrganizationId
    };

    // Open popup to set default settings.
    this.modalRef = this.modalService.show(this.setDefaultSettingPopup, {
      class: "modal-dialog-centered",
      ignoreBackdropClick: true,
    });
  }

  deviceSelect(value) {
    console.log(value);
    
    this.allSelected = false;
    this.service.setSelectedRoutes(value);
    this.selectDeviceType = this.service.getSelectedRoutes();

    if (this.selectedDevices.length === 0) {
    } else {
      this.selectedDevices = [];
    }
    if (value === 2) {
      this.getAllCleanParams(
        (this.device = {
          deviceCleanTypeId: 2,
          deviceOrganizationId: this.device.deviceOrganizationId,
        })
      );
    } else {
      this.getAllCleanParams(
        (this.device = {
          deviceCleanTypeId: 3,
          deviceOrganizationId: this.device.deviceOrganizationId,
        })
      );
    }
  }
  isSelectedItem(item) {
    return item === this.service.getSelectedRoutes();
  }

  changeStatusConfirmation(event, dataItem, template: TemplateRef<any>) {
    event.preventDefault();
    event.stopPropagation();
    dataItem.isDeviceSettingActive = !dataItem.isDeviceSettingActive;
    this.openPopup(dataItem, template);
  }

  public openPopup(data, template: TemplateRef<any>) {
    this.verxCleanParams = data;
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
  }

  public closeModalPopup() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  public setStatus(verxCleanParams) {
    const bodyParams = {
      deviceId: verxCleanParams.deviceId,
      runSettingId: verxCleanParams.runSettingId,
      deviceCleanTypeId: verxCleanParams.deviceCleanTypeId,
      isDeviceSettingActive:
        verxCleanParams.isDeviceSettingActive == true ? false : true,
    };
    this.service.saveverxCleanStatus(bodyParams).subscribe(
      (res) => {
        if (res) {
          this.commonService.setLoader(false);
          if (res.statusCode === 200 && res.data) {
            this.toastr.success(
              MESSAGE.RESET2FA_CLENPARMSTATUS_SUCCESSFULLY,
              ""
            );
            this.getAllCleanParams(this.device);
            this.closeModalPopup();
          } else if (res.statusCode === 404 && res.data === -3) {
            this.toastr.error(MESSAGE.UNABLE_TO_CLENPARMSTATUS, "", {
              timeOut: 10000,
            });
            this.closeModalPopup();
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // Final Submit After first Modal i.e SAVE API_CALL
  public submitModalPopup(template: TemplateRef<any>) {
    this.refreshGrid();
    if (!this.modalRef) {
      this.modalRef.hide();
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }
}
