export const MESSAGE = {
    // Login
    OTP_ERROR: 'Invalid authentication code',
    ACOOUNT_IS_LOCKED: "Account has been locked. Please contact support",
    EMAIL_PWD_INVALID: "Invalid email or password. Please try again.",
    EMAIL_INVALID: "Please enter a valid email address",
    EMAILID_ALREADY_EXIST: 'Email Id Already Exist',
    // Organization
    ORG_NAME_EXIST: 'Organization Name already exist.',
    // Groups
    NO_GROUPS: 'There is no group(s) associated with this organisation',
    NO_TERR: 'There is no territories associated with this organisation',
    // Device
    DEVICE_SERIAL_NO_UUID_EXIST: 'Device Serial Number / UUID already exist',
    // Clean Params
    DEFAULT_CLEAN_PARAMS_UPDATED: 'Default Clean parameters updated Successfully',
    ERROR_CLEAN_PARAMS_UPDATED: 'Error in updating Clean parameters',
    CLEAN_PARAMS_UPDATED: 'Clean parameters updated Successfully',
    CLEAN_PARAMS_SET_TO_DEFAULT: 'Clean parameters set to default Successfully',
    // Outlet
    OUTLET_SHIPTO_ALREADY_EXIST: 'Outlet ID already exists',
    OUTLET_NAME_EXIST_IN_ORG: 'Outlet name already exist in organisation',
    // Common
    PASSWORD_CHANGED_SUCCESSFULLY: 'Password Changed Successfully',
    RECORD_DOES_NOT_EXIST: 'Record does not exist',
    PASSWORD_NOT_VALID: "Password does not confirm to rules",
    // Unable to deactivate
    UNABLE_TO_DEACTIVATE: 'Unable to deactivate',
    UNABLE_TO_DEACTIVATE_ORGANISATION: 'Unable to deactivate the record as the Groups are associated',
    UNABLE_TO_DEACTIVATE_GROUP: 'Unable to deactivate the record as the Regions are associated',
    UNABLE_TO_DEACTIVATE_REGION: 'Unable to deactivate the record as the Areas are associated',
    UNABLE_TO_DEACTIVATE_AREA: 'Unable to deactivate the record as the Territories are associated',
    UNABLE_TO_DEACTIVATE_TERRITORY: 'Unable to deactivate the record as the Outlets are associated',

    // Unable to activate
    UNABLE_TO_ACTIVATE: 'Unable to activate',
    UNABLE_TO_ACTIVATE_GROUP: 'Unable to activate the record as the  Organisation is inactive',
    UNABLE_TO_ACTIVATE_REGION: 'Unable to activate the record as the Group is inactive',
    UNABLE_TO_ACTIVATE_AREA: 'Unable to activate the record as the Region is inactive',
    UNABLE_TO_ACTIVATE_TERRITORY: 'Unable to activate the record as the Area is inactive',

    ARCHIVED_DONE_SUCCESSFULLY: 'Record updated sucessfully',
    RESET2FA_DONE_SUCCESSFULLY: 'Reset 2FA done Successfully',
    UNABLE_TO_RESET2FA: ' Unable to do reset 2FA',
    REC_ADD_SUCCESS: 'Record added sucessfully',
    REC_UPDATED_SUCCESS: 'Record updated sucessfully',
    RECORD_SAVING_FAILED: 'Record saving failed',
    REC_UPDATING_FAILED: 'Record updating failed',
    RECORD_ALREADY_EXIST: 'Record already exists',
    FORM_CONATINS_ERROR: 'Form contains errors',
    //lock unlock
    NEW_PASSWORD_IS_SAME_AS_OLD: 'New password is same as old, please change.',
    LOCKED__DONE_SUCCESSFULLY: 'Account locked successfully',
    UNLOCKED__DONE_SUCCESSFULLY: 'Account unlocked successfully',
    // Organisation Details 
    NO_GROUP_FOR_ORGANISATION: 'Please add group for selected organisation',
    NO_TAMPLATE_FOR_GRPUP: 'Please add Tamplate Type for selected group',
    NO_REGION_FOR_GROUP: 'Please add region for selected group',
    NO_AREA_FOR_REGION: 'Please add area for selected region',
    NO_TERRITORY_FOR_AREA: 'Please add territory for selected area',
    NO_TERRITORY_FOR_ORGANISATION: 'Please add territory for selected organisation',
    NO_TERRITORY_FOR_REGION: 'Please add territory for selected region',

    // Manual Reason
    MANUAL_REASON_UPDATED: 'Manual reason has been updated successfully',

    // Refusal reason
    REFUSAL_REASON_UPDATED: 'Refusal reason has been updated successfully',
    OPERATION_FAILED: 'Operation Failure',
    RESET2FA_CLENPARMSTATUS_SUCCESSFULLY: 'Clean parameter status updated Successfully',
    UNABLE_TO_CLENPARMSTATUS: ' Unable to do reset clean parameter status',

    ADD_DEFAULT_SETTINGS: 'Default setting added Successfully',
    UPDATE_DEFAULT_SETTINGS: 'Default setting updated Successfully',
    DEFAULT_SETTING_STATUS_UPDATED : 'Default setting status updated Successfully',
    DEFAULT_SETTING_STATUS_ERROR : 'Unable to do reset default setting status'
}

