import { Injectable } from '@angular/core';
import { RestApi, METHODS } from '../network/RestAPI';
import { ENDPOINTS } from '../network/endpoints';

@Injectable()
export class RefusalReasonService {

  constructor(private restAPI: RestApi) { }

  // get list of refusal reasons
  getRefusalReasonList(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.REFUSAL_REASON.GET_REFUSAL_REASONS}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // edit or add refusal reason
  saveRefusalReason(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.REFUSAL_REASON.SAVE_REFUSAL_REASON}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }

  // archive refusal reason
  archiveRefusalReason(bodyParams) {
    const url = RestApi.buildUrl(`${ENDPOINTS.REFUSAL_REASON.ARCHIVE_REFUSAL_REASON}`);
    return this.restAPI.handleRequest(url, METHODS.Post,bodyParams);
  }
}
