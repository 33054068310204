import { AfterViewInit, Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { RefusalReasonModel } from "src/app/models/refusalReason.model";
import { IKendoColumnModel } from "src/app/models/kendoColumn.model";
import { State, process } from "@progress/kendo-data-query";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/services/common.service";
import { LoggedInUserService } from "src/app/services/loggedInUser.service";
import { Role } from "src/app/models/roles.enum";
import { SearchDataPipe } from "src/app/pipes/search-data.pipe";
import { DataStateChangeEvent, GroupableSettings } from "@progress/kendo-angular-grid";
import { MESSAGE } from "src/app/shared/messages";
import { RefusalReasonService } from "src/app/services/refusal-reason.service";
import { convertTimeZone, getFormatedDate } from "src/app/shared/handler.functions";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-refusal-reasons",
  templateUrl: "./refusal-reasons.component.html"
})
export class RefusalReasonsComponent implements OnInit, AfterViewInit {
  modalRef: BsModalRef;
  refusalReasonList: RefusalReasonModel[] = [];
  gridData: any = [];
  searchKeyword: string;
  searchableColumns = ["name", "organisationName", "groups"];
  state: State = {
    skip: 0,
    take: 50
  };
  columns: IKendoColumnModel[];
  confirmModalRef: BsModalRef;
  selectedRefusalReason: RefusalReasonModel;
  refusalReasonIsEdit: boolean = false;
  role: string;
  groupableSettings: GroupableSettings;
  timeZone: string;
  constructor(
    private toastr: ToastrService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private refusalReasonService: RefusalReasonService,
    private loggedInUserService: LoggedInUserService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.timeZone = this.loggedInUserService.getUserData('timezone');
    this.translate.onLangChange.subscribe( (res) => {
      this.translationChanges();
    });
    this.getRefusalReasonList();
    this.role = this.loggedInUserService.getUserData("role");
    this.columns = [
      {
        field: "name",
        title: "Not Cleaned Reason",
        sortable: true
      },
      {
        field: "organisationName",
        title: "Organisation",
        display: this.role !== Role[1],
        groupable: true,
        sortable: true
      },
      {
        field: "createdAt",
        title: "Created On",
        sortable: true,
        width: 130,
        cellTextCenter: true,
        cellFormat: data => getFormatedDate(data.createdAt)
      }
    ];
  }

  ngAfterViewInit(): void {
    this.translationChanges();
  }

  addRefusalReason(template: TemplateRef<any>) {
    this.refusalReasonIsEdit = false;
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true
    });
  }

  public closeModalPopup() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  private getRefusalReasonList(): void {
    const params = {
      organisationId: this.role !== Role[1] ? this.loggedInUserService.getUserData("organisationId") : 0,
    };

    this.refusalReasonService.getRefusalReasonList(params).subscribe(res => {
      this.commonService.setLoader(false);
      if (res && res.statusCode === 200 && res.data) {
        convertTimeZone(res.data, ['createdAt', 'updatedAt'], this.timeZone);
        this.refusalReasonList = res.data;
        this.loadItems();
      }
    });
  }

  private loadItems() {
    const searchedRecords = new SearchDataPipe().transform(
      this.refusalReasonList,
      this.searchKeyword,
      this.searchableColumns
    );
    this.gridData = process(searchedRecords, this.state);
  }

  // REALTED to grid Functionality paging , sorting , groupping
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadItems();
  }

  public filterSearch() {
    this.loadItems();
    this.state.skip = 0;
  }

  // confirmation for archive
  public askForConfirmation(refusalReason, template: TemplateRef<any>) {
    this.selectedRefusalReason = refusalReason;
    this.confirmModalRef = this.modalService.show(template, {
      class: "modal-confirm modal-dialog-centered second",
      ignoreBackdropClick: true
    });
  }

  // cancel archive popup
  public dismissActiveInactiveConfirmModal() {
    this.confirmModalRef.hide();
    this.confirmModalRef = null;
  }

  // confirm archive popup
  public finallyCloseStatusConfirmModal(webAcc) {
    this.archivedWebAcc(webAcc);
    this.dismissActiveInactiveConfirmModal();
  }

  // archive group or change status
  private archivedWebAcc(webAcc) {
    const bodyParams = {
      id: webAcc.id,
      archived: !webAcc.archived,
      updatedBy: this.loggedInUserService.getUserData("id")
    };

    this.refusalReasonService.archiveRefusalReason(bodyParams).subscribe(
      res => {
        if (res) {
          if (res.statusCode === 200 && res.data > 0) {
            this.toastr.success(MESSAGE.ARCHIVED_DONE_SUCCESSFULLY, "");
            this.getRefusalReasonList();
            this.commonService.setLoader(false);
          } else if (res.statusCode === 404 && res.data === -3) {
            this.commonService.setLoader(false);
            this.toastr.error(MESSAGE.UNABLE_TO_DEACTIVATE, "", {
              timeOut: 10000
            });
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  public editRefusalReason(refusalReason, template: TemplateRef<any>) {
    this.refusalReasonIsEdit = true;
    this.selectedRefusalReason = Object.assign({}, refusalReason);
    this.openModal(template);
  }

  private openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true
    });
  }

  // Final Submit After first Modal i.e SAVE API_CALL
  public submitModalPopup(template: TemplateRef<any>) {
    this.getRefusalReasonList();
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  translationChanges() {
    this.translate.get('Drag a column header and drop it here to group by that column').subscribe((res) => {
    this.groupableSettings = { enabled: true, emptyText: res, showFooter: false};
    });
  }

}
