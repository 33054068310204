import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html'
})
export class ConfirmationPopupComponent implements OnInit {

  @Input() modalId: string;
  @Input() bodyText: string;
  @Input() confirmButtonText: string = 'Yes';
  @Input() cancelButtonText: string = 'No';
  @Input() hideCancel: boolean = false;
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  dismissModal() {
    this.cancel.emit();
  }

  confirmed() {
    this.confirm.emit();
  }

}
