import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutChartComponent implements OnInit {
  @Input() soakGraph = false
  @Input() data = [];
  recircChartResponse = [];
  soakDonutChartResponse = [];
  verxAutoDonutResponse = [];
  verxManualDonutResponse = [];
  verxLiteAutoDonutResponse = [];
  PLCSAutoDonutResponse = [];
  PLCSManualDonutResponse = [];
  vortexIAutoDonutResponse = [];
  vortexIManualDonutResponse = [];
  vortexNAutoDonutResponse = [];
  vortexNManualDonutResponse = [];
  TotalDonutResponse = [];
  totalDonutCount: number;
  cdFlag = false;
  public recircChartOptions = {
    title: {
      text: "Merit Score"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }
  public soakChartOptions = {
    title: {
      text: "Soak Merit"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }
  public verxAutoChartOptions = {
    title: {
      text: "Soak Merit"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }
  public verxManualChartOptions = {
    title: {
      text: "Soak Merit"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }
  public PLCSAutoChartOptions = {
    title: {
      text: "Soak Merit"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }
  public PLCSManualChartOptions = {
    title: {
      text: "Soak Merit"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }

  public vortexIAutoChartOptions = {
    title: {
      text: "Soak Merit"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }

  public vortexIManualChartOptions = {
    title: {
      text: "Soak Merit"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }

  public verxLiteAutoChartOptions = {
    title: {
      text: "Merit Score"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }

  public vortexNAutoChartOptions = {
    title: {
      text: "Soak Merit"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }

  public vortexNManualChartOptions = {
    title: {
      text: "Soak Merit"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }

  public totalDonutChartOptions = {
    title: {
      text: "Merit Score"
    },
    legend: {
      padding: 10,
      position: "bottom"
    },
    series: {
      labels: {
        align: "center",
        visible: true,
        position: 'center',
        distance: 0,
        content: (e) => {
          if (e && e['value'] === 0) return null;
          const marker = (e.percentage * 100).toFixed(2) + '%'
          return `${marker}\nlines: ${e.dataItem.lineCount} (${e.dataItem.linePercentage}%)`;
        }
      },
      tooltip: {
        visible: false,
        format: '{0} * 2'
      }
    },
    seriesDefaults: {
      type: "donut",
      labels: {
        visible: true,
      }
    }
  }
  constructor(private translate: TranslateService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((res) => {
      this.translationChanges();
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    this.recircChartResponse = [];
    this.soakDonutChartResponse = [];
    this.verxAutoDonutResponse = [];
    this.verxManualDonutResponse = [];
    this.verxLiteAutoDonutResponse = [];
    this.PLCSAutoDonutResponse = [];
    this.PLCSManualDonutResponse = [];
    this.vortexIAutoDonutResponse = [];
    this.vortexIManualDonutResponse = [];
    this.vortexNAutoDonutResponse = [];
    this.vortexNManualDonutResponse = [];
    this.TotalDonutResponse = [];

    if (this.data.length > 0) {
      this.recircChartResponse = this.data.find(type => type['name'].toLowerCase() === "vortex recirc").data;
      this.soakDonutChartResponse = this.data.find(type => type['name'].toLowerCase() === "vortex soak").data;
      this.verxAutoDonutResponse = this.data.find(type => type['name'].toLowerCase() === "verx auto").data;
      this.verxManualDonutResponse = this.data.find(type => type['name'].toLowerCase() === "verx manual").data;
      this.verxLiteAutoDonutResponse = this.data.find(type => type['name'].toLowerCase() === "verx lite – bt").data;
      this.PLCSAutoDonutResponse = this.data.find(type => type['name'].toLowerCase() === "plcs auto").data;
      this.PLCSManualDonutResponse = this.data.find(type => type['name'].toLowerCase() === "plcs manual").data;
      this.vortexIAutoDonutResponse = this.data.find(type => type['name'].toLowerCase() === "vortex-i auto").data;
      this.vortexIManualDonutResponse = this.data.find(type => type['name'].toLowerCase() === "vortex-i manual").data;
      this.vortexNAutoDonutResponse = this.data.find(type => type['name'].toLowerCase() === "vortex-n auto").data;
      this.vortexNManualDonutResponse = this.data.find(type => type['name'].toLowerCase() === "vortex-n manual").data;
      this.TotalDonutResponse = this.data.find(type => type['name'].toLowerCase() === "totaldonutchart").data;
    }

    const recircTotalCount = this.recircChartResponse.map(x => x['count']).reduce((a, b) => a + b, 0);
    const soakTotalCount = this.soakDonutChartResponse.map(x => x['count']).reduce((a, b) => a + b, 0);
    const verxAutoCount = this.verxAutoDonutResponse.map(x => x['count']).reduce((a, b) => a + b, 0);
    const verxManualCount = this.verxManualDonutResponse.map(x => x['count']).reduce((a, b) => a + b, 0);
    const verxLiteAutoCount = this.verxLiteAutoDonutResponse.map(x => x['count']).reduce((a, b) => a + b, 0);
    const PLCSAutoCount = this.PLCSAutoDonutResponse.map(x => x['count']).reduce((a, b) => a + b, 0);
    const PLCSManualCount = this.PLCSManualDonutResponse.map(x => x['count']).reduce((a, b) => a + b, 0);
    const vortexIAutoCount = this.vortexIAutoDonutResponse.map(x => x['count']).reduce((a, b) => a + b, 0);
    const vortexIManualCount = this.vortexIManualDonutResponse.map(x => x['count']).reduce((a, b) => a + b, 0);
    const vortexNAutoCount = this.vortexNAutoDonutResponse.map(x => x['count']).reduce((a, b) => a + b, 0);
    const vortexNManualCount = this.vortexNManualDonutResponse.map(x => x['count']).reduce((a, b) => a + b, 0);

    this.totalDonutCount = this.TotalDonutResponse.map(x => x['count']).reduce((a, b) => a + b, 0);

    this.recircChartOptions.title.text = `Total: ${recircTotalCount}    Vortex Recirc Merit`;
    this.soakChartOptions.title.text = `Total: ${soakTotalCount}   Vortex Soak Merit`;
    this.verxAutoChartOptions.title.text = `Total: ${verxAutoCount}   Verx Auto Merit`;
    this.verxManualChartOptions.title.text = `Total: ${verxManualCount}    Verx Manual Merit`;
    this.verxLiteAutoChartOptions.title.text = `Total: ${verxLiteAutoCount}    Verx Lite Auto Merit`;
    this.PLCSAutoChartOptions.title.text = `Total: ${PLCSAutoCount}   PLCS Auto Merit`;
    this.PLCSManualChartOptions.title.text = `Total: ${PLCSManualCount}    PLCS Manual Merit`;
    this.vortexIAutoChartOptions.title.text = `Total: ${vortexIAutoCount}   Vortex-I Auto Merit`;
    this.vortexIManualChartOptions.title.text = `Total: ${vortexIManualCount}    Vortex-I Manual Merit`;
    this.vortexNAutoChartOptions.title.text = `Total: ${vortexNAutoCount}   Vortex-N Auto Merit`;
    this.vortexNManualChartOptions.title.text = `Total: ${vortexNManualCount}   Vortex-N Manual Merit`;
    this.translationChanges();
  }

  translationChanges() {
    this.translate.get(['Total', 'Merit Score']).subscribe((res) => {
      this.totalDonutChartOptions.title = { text: `${res['Total']}: ${this.totalDonutCount} ${res['Merit Score']}` };

      if (!this.cdFlag) {
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.cdFlag = true;
    this.cd.markForCheck();
  }

}
