import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { DeviceComponent } from './device.component';
import { ManageDeviceComponent } from './manage-device/manage-device.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { DeviceService } from '../services/device.service';
import { ToggleDeviceComponent } from './toggleDevice/toggle-device/toggle-device.component';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '../layout/layout.module';
import { TranslateModule } from '@ngx-translate/core';
import { MultipleDefaultSettingComponent } from './multiple-default-setting/multiple-default-setting.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ManageDefaultCleanParamsComponent } from './multiple-default-setting/manage-default-clean-params/manage-default-clean-params.component';
import { CustomFormsModule } from 'ng2-validation';
// export const options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
    declarations: [DeviceComponent, ManageDeviceComponent, ToggleDeviceComponent, MultipleDefaultSettingComponent, ManageDefaultCleanParamsComponent],
    imports: [
        CommonModule,
        LayoutModule,
        NgxMaskModule.forRoot(),
        FormsModule,
        SharedModule,
        GridModule,
        RouterModule,
        TranslateModule,
        InputsModule,
        // CustomFormsModule
    ],
    providers: [DeviceService],
    schemas: [
        NO_ERRORS_SCHEMA
    ]
})
export class DeviceModule { }
